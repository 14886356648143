<mat-card [id]="group.groupId" class="group-card" (click)="navigateToGroup()" [ngClass]="{ center: isMobile }">
	<div class="header">
		<h2>{{ group.groupName }}</h2>
		<img width="75" height="75" [src]="'../assets/icons/logos/' + group.type.toLowerCase() + '.svg'" />
	</div>
	<mat-divider />
	<h4>{{ group.status }}</h4>
	<h4>{{ group.score | currency : "USD" : "symbol" : "1.2-2" }} | {{ group.finalPosition ?? 0 | scoreboardPlace }}</h4>

	<ng-container *ngIf="teams$ | async as teams">
		<div class="teams">
			<ng-container *ngFor="let teamId of group.roster">
				<img *ngIf="getTeam(teams, teamId) as team" width="50" height="50" [src]="team.logo" />
			</ng-container>
		</div>
	</ng-container>
</mat-card>

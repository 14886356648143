import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { ITeam, ITeamMatchup } from "../models/team";
import { Store } from "@ngrx/store";
import { Subject, takeUntil } from "rxjs";
import { currentBreakPoint } from "../state/app/app.selectors";
import { BreakPointDisplayNameMap } from "../models/constants";
import { Breakpoints } from "@angular/cdk/layout";

@Directive({
	selector: "[showAbbreviationIfTruncated]"
})
export class ShowAbbreviationIfTruncatedDirective implements OnInit, OnDestroy {
	@Input() team: ITeamMatchup | undefined;

	private currentBreakPoint = "";

	destroyed = new Subject<void>();

	constructor(private elementRef: ElementRef<HTMLElement>, private store: Store) {}

	ngOnInit(): void {
		this.store
			.select(currentBreakPoint)
			.pipe(takeUntil(this.destroyed))
			.subscribe((currentBreakPoint) => {
				if (this.currentBreakPoint !== currentBreakPoint) {
					const element = this.elementRef.nativeElement;
					this.currentBreakPoint = currentBreakPoint;
					if (
						currentBreakPoint === BreakPointDisplayNameMap.get(Breakpoints.XSmall) ||
						currentBreakPoint === BreakPointDisplayNameMap.get(Breakpoints.Small)
					) {
						element.textContent = this.team?.abbreviation!!;
					} else if (currentBreakPoint === BreakPointDisplayNameMap.get(Breakpoints.Medium)) {
						element.textContent = this.team?.abbreviation!!;
					} else {
						element.textContent = this.team?.displayName!!;
					}
				}
			});
	}

	ngOnDestroy(): void {
		this.destroyed.next();
		this.destroyed.complete();
	}
}

import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject, take } from "rxjs";
import { INotification } from "src/app/models/notification";
import { EventService } from "src/app/services/event.service";
import { NotificationService } from "src/app/services/notification.service";
import { SnackbarService } from "src/app/services/snackbar.service";

@Component({
	selector: "am-system-admin",
	templateUrl: "./system-admin.component.html",
	styleUrls: ["./system-admin.component.scss"]
})
export class SystemAdminComponent implements OnInit {
	filePath = new FormControl("");
	newFilePath = new FormControl("");

	newFile: File | null = null;

	cacheLoading: boolean;
	uploading: boolean;
	notifications: FormArray<FormGroup>;

	constructor(
		private eventService: EventService,
		private snackbar: SnackbarService,
		private notificationService: NotificationService,
		private fb: FormBuilder
	) {}

	ngOnInit(): void {
		this.loadNotifications();
	}

	updateCache(): void {
		if (this.filePath.value) {
			this.cacheLoading = true;
			this.eventService
				.updateCacheControl(this.filePath.value)
				.then(() => {
					this.cacheLoading = false;
					this.snackbar.success(`Successful updated cache for ${this.filePath.value}`);
				})
				.catch((error) => {
					this.cacheLoading = false;
					this.snackbar.error(error);
				});
		}
	}

	onFileChange(event: Event): void {
		const files = (event.target as HTMLInputElement).files;

		if (files) {
			this.newFile = files[0];
		}
	}

	addFile(): void {
		console.log("TODO: Figure out access key");
		// if (this.newFile && this.newFilePath.value) {
		// 	this.uploading = true;
		// 	this.eventService
		// 		.addFile(this.newFilePath.value, this.newFile)
		// 		.then(() => {
		// 			this.uploading = false;
		// 			this.snackbar.success(`Successfully upload ${this.newFile?.name}`);
		// 		})
		// 		.catch((error) => {
		// 			this.uploading = false;
		// 			this.snackbar.error(error);
		// 		});
		// }
	}

	createFormGroup(notification: INotification): FormGroup {
		return this.fb.group({
			id: this.fb.control(notification.id),
			icon: this.fb.control(notification.icon || ""),
			title: this.fb.control(notification.title || "", Validators.required),
			message: this.fb.control(notification.message || "", Validators.required),
			actionLink: this.fb.control(notification.actionLink || "")
		});
	}

	loadNotifications(): void {
		this.notificationService
			.getNotifications()
			.pipe(take(1))
			.subscribe((notifications) => {
				this.notifications = this.fb.array(notifications.map((x) => this.createFormGroup(x)));
			});
	}

	addNotification(): void {
		this.notifications.push(
			this.createFormGroup({
				title: "",
				icon: undefined,
				message: "",
				actionLink: undefined,
				createDate: new Date()
			})
		);
	}

	deleteNotification(id: string): void {
		this.notificationService.deleteNotification(id).then(() => {
			this.snackbar.success("Successfully delete notification");
			this.loadNotifications();
		});
	}

	saveNotification(notification: FormGroup): void {
		this.notificationService
			.createNotification({
				id: notification.get("id")?.value,
				icon: notification.get("icon")?.value,
				title: notification.get("title")?.value,
				message: notification.get("message")?.value,
				actionLink: notification.get("actionLink")?.value,
				createDate: new Date()
			})
			.then(() => {
				this.snackbar.success("Successfully created the notification");

				this.loadNotifications();
			});
	}
}

import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { compareAsc, isFuture } from "date-fns";
import { Observable, filter, map, take } from "rxjs";
import { IEvent, IPublicRoom } from "src/app/models/event";
import { IGroup } from "src/app/models/group";
import { GroupService } from "src/app/services/group.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { BaseComponent } from "src/app/shared/components/base.component";
import { currentUID, isMobile } from "src/app/state/app/app.selectors";
import { events } from "src/app/state/event/event.selectors";
import { publicRoomsWithCapacity } from "src/app/state/group/group.selectors";
import { GroupInfoDialog } from "../group-info-dialog/group-info-dialog.component";
import { IPhaseScoringOverview } from "../scoring-overview/scoring-overview.component";
import { PublicRoomDialogComponent } from "../scoring-overview-dialog/public-room-dialog.component";
import { MatButton } from "@angular/material/button";
import {
	MatTable,
	MatColumnDef,
	MatHeaderCellDef,
	MatHeaderCell,
	MatCellDef,
	MatCell,
	MatHeaderRowDef,
	MatHeaderRow,
	MatRowDef,
	MatRow
} from "@angular/material/table";
import { MatTooltip } from "@angular/material/tooltip";
import { MatIcon } from "@angular/material/icon";
import { NgIf, AsyncPipe, CurrencyPipe, DatePipe } from "@angular/common";
import { LoaderComponent } from "../loader/loader.component";

@Component({
	selector: "am-public-room",
	templateUrl: "public-room.component.html",
	styleUrls: ["public-room.component.scss"],
	standalone: true,
	imports: [
		LoaderComponent,
		NgIf,
		MatIcon,
		MatTooltip,
		MatTable,
		MatColumnDef,
		MatHeaderCellDef,
		MatHeaderCell,
		MatCellDef,
		MatCell,
		MatButton,
		MatHeaderRowDef,
		MatHeaderRow,
		MatRowDef,
		MatRow,
		AsyncPipe,
		CurrencyPipe,
		DatePipe
	]
})
export class PublicRoomComponent extends BaseComponent implements OnInit, OnChanges {
	@Input() eventId: string;

	event$: Observable<IEvent | undefined>;
	publicGroups$: Observable<IGroup[]>;
	currentUID$: Observable<string>;
	isMobile$: Observable<boolean>;

	dataSource: IPublicRoom[] = [];
	displayColumns = ["name", "numOfManagers", "auctionDate", "action"];
	mobileDisplayColumns = ["numOfManagers", "auctionDate", "action"];
	joining = false;

	constructor(
		private store: Store,
		private groupService: GroupService,
		private router: Router,
		private snackbarService: SnackbarService,
		private dialog: MatDialog
	) {
		super();
	}

	ngOnChanges(): void {
		this.event$ = this.store.select(events).pipe(
			filter((events) => !!events && events.length > 0),
			map((events) => {
				const event = events.find((x) => x.id === this.eventId);

				this.dataSource = event
					? event.publicRooms
							.filter((x) => {
								return isFuture(x.auctionDate) && !x.isFull;
							})
							.sort((a, b) => compareAsc(a.auctionDate, b.auctionDate))
					: [];

				return event;
			})
		);
	}

	ngOnInit(): void {
		this.isMobile$ = this.store.select(isMobile);
		this.currentUID$ = this.store.select(currentUID);
		this.publicGroups$ = this.store.select(publicRoomsWithCapacity);
	}

	getGroupCount(groups: IGroup[], room: IPublicRoom): number {
		if (room.groupId == null) {
			return 0;
		} else {
			return groups.find((x) => x.id === room.groupId)?.managerIds.length ?? 0;
		}
	}

	getBuyInByScoringPreset(scoringPresetId: string, event: IEvent): number | undefined {
		return event.scoringPresets.find((x) => x.id === scoringPresetId)?.buyIn;
	}

	isCurrentInGroup(groups: IGroup[], room: IPublicRoom, currentUID: string): boolean {
		if (room.groupId == null) {
			return false;
		} else {
			return groups.find((x) => x.id === room.groupId)?.managerIds.includes(currentUID) ?? false;
		}
	}

	login() {
		this.router.navigate(["login"], { queryParams: { redirectPath: "home" + encodeURIComponent("#") + "public-room" } });
	}

	joinGroup(publicRoom: IPublicRoom, currentUID: string, event: IEvent): void {
		this.groupService
			.joinPublicRoom(publicRoom, currentUID, event)
			.pipe(take(1))
			.subscribe({
				next: (groupId) => {
					this.router.navigate([`event/${this.eventId}/${groupId}`]);
				},
				error: (e) => {
					this.snackbarService.warn(`Failed to join group: ${e.message}`);
				}
			});
	}

	openGroupInfo(publicRoom: IPublicRoom, event: IEvent, currentUID: string, isAlreadyInGroup: boolean): void {
		if (publicRoom.groupId && isAlreadyInGroup) {
			this.dialog.open(GroupInfoDialog, { data: { eventId: event.id, groupId: publicRoom.groupId } });
		} else {
			const scoring = event.scoringPresets.find((x) => x.id === publicRoom.scoringPresetId);

			if (!scoring) {
				throw new Error(`Missing Scoring ${publicRoom.scoringPresetId}`);
			}

			const totalPot = publicRoom.numOfManagers * scoring.buyIn;

			const scoringOverview: IPhaseScoringOverview[] = scoring.phaseScorings.map((phaseScoring) => {
				const phase = event.phases.find((x) => x.id === phaseScoring.phaseId);

				if (!phase) {
					throw new Error(`Missing Phase ${phaseScoring.phaseId}`);
				}

				return {
					name: phase.name,
					amountForAllTiers: phaseScoring.amountForAllTiers ?? 0,
					amountToMakePhase: phaseScoring.amountToMakePhase ?? 0,
					tiers: phaseScoring.tiers.map((tierScoring) => {
						const tier = event.tiers.find((x) => x.id === tierScoring.tierId);

						if (!tier) {
							throw new Error(`Missing Tier ${tierScoring.tierId}`);
						}

						return {
							name: tier?.name,
							amountForTier: tierScoring.defaultValue * totalPot
						};
					})
				};
			});

			this.dialog
				.open(PublicRoomDialogComponent, {
					data: { publicRoom, scoringOverview, totalPot }
				})
				.afterClosed()
				.pipe(take(1))
				.subscribe((result) => {
					this.joining = result;
					if (result) {
						this.joinGroup(publicRoom, currentUID, event);
					}
				});
		}
	}
}

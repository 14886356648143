import { Injectable } from "@angular/core";
import {
	addDoc,
	collection,
	doc,
	docData,
	Firestore,
	getDocs,
	query,
	QueryDocumentSnapshot,
	setDoc,
	SnapshotOptions,
	where
} from "@angular/fire/firestore";
import { from, map, Observable } from "rxjs";
import { ILeague } from "../models/league";

@Injectable({
	providedIn: "root"
})
export class LeagueService {
	constructor(private fs: Firestore) {}

	getAll(): Observable<ILeague[]> {
		return from(getDocs(query(collection(this.fs, "leagues")).withConverter(leagueConverter))).pipe(
			map((response) => response.docs.map((d) => d.data()))
		);
	}

	getActive(): Observable<ILeague[]> {
		return from(getDocs(query(collection(this.fs, "leagues"), where("status", "==", "Active")).withConverter(leagueConverter))).pipe(
			map((response) => response.docs.map((d) => d.data()))
		);
	}

	getLiveUpdatesByID(leagueId: string): Observable<ILeague | undefined> {
		return docData<ILeague>(doc(this.fs, "leagues", leagueId).withConverter(leagueConverter), { idField: "id" });
	}

	async create(league: ILeague) {
		return (await addDoc(collection(this.fs, "leagues").withConverter(leagueConverter), league)).id;
	}

	async update(league: ILeague) {
		await setDoc(
			doc(this.fs, "leagues", league.id).withConverter(leagueConverter),
			{
				...league
			},
			{ merge: true }
		);
	}
}

const leagueConverter = {
	toFirestore: (league: ILeague) => {
		const games = league.games.map((x) => {
			return {
				...x,
				startTime: x.startTime != null ? x.startTime?.toISOString() : undefined
			};
		});

		return {
			sport: league.sport,
			type: league.type,
			year: league.year,
			status: league.status,
			description: league.description,
			integrationData: league.integrationData,
			isPromotional: league.isPromotional,
			teams: league.teams,
			games: games,
			series: league.series,
			regions: league.regions
		};
	},
	fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions | undefined) => {
		let data = snapshot.data(options);

		return {
			id: snapshot.id,
			...data,
			games: data["games"].map((x: any) => {
				return {
					...x,
					startTime:
						x.startTime != null ? (typeof x.startTime === "object" ? x.startTime.toDate() : new Date(x.startTime)) : undefined
				};
			})
		} as ILeague;
	}
};

import { trigger, state, style, transition, animate } from "@angular/animations";
import { Component, inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { BehaviorSubject, combineLatest, filter, map, Observable, startWith, switchMap, take, takeUntil, tap } from "rxjs";
import { IGroup } from "src/app/models/group";
import { GroupService } from "src/app/services/group.service";
import { BaseComponent } from "src/app/shared/components/base.component";
import { GroupAuditDialogComponent } from "../group-audit-dialog/group-audit-dialog.component";
import { IEvent } from "src/app/models/event";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { endOfDay, isAfter, isBefore, startOfDay } from "date-fns";
import { MatIcon } from "@angular/material/icon";
import { NgIf, AsyncPipe, JsonPipe, CommonModule } from "@angular/common";
import { MatButton, MatIconButton } from "@angular/material/button";
import {
	MatTable,
	MatColumnDef,
	MatHeaderCellDef,
	MatHeaderCell,
	MatCellDef,
	MatCell,
	MatHeaderRowDef,
	MatHeaderRow,
	MatRowDef,
	MatRow,
	MatTableModule
} from "@angular/material/table";
import { MatRadioGroup, MatRadioButton } from "@angular/material/radio";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { ILeague } from "src/app/models/league";
import { MatSelectModule } from "@angular/material/select";
import { LeagueStore } from "src/app/state/league.store";
import { Store } from "@ngrx/store";
import { events } from "src/app/state/event/event.selectors";

@Component({
	selector: "am-group-lookup",
	templateUrl: "./group-lookup.component.html",
	styleUrls: ["./group-lookup.component.scss"],
	animations: [
		trigger("detailExpand", [
			state("collapsed", style({ height: "0px", minHeight: "0" })),
			state("expanded", style({ height: "*" })),
			transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
		])
	],
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		MatFormField,
		MatLabel,
		MatInput,
		MatRadioGroup,
		MatRadioButton,
		MatTableModule,
		CommonModule,
		MatSelectModule,
		MatButton,
		MatIconButton,
		MatIcon
	]
})
export class GroupLookupComponent extends BaseComponent implements OnInit {
	readonly leagueStore = inject(LeagueStore);

	filters: FormGroup;
	columns = ["name", "status", "size", "actions", "expand"];
	selectedGroupId: string | null;

	leagueId = new FormControl<string | null>(null);
	eventId = new FormControl<string | null>(null);

	leagues = this.leagueStore.entities;

	groups$: Observable<IGroup[]>;
	events$: Observable<IEvent[]>;

	constructor(
		private fb: FormBuilder,
		private groupService: GroupService,
		private router: Router,
		private dialog: MatDialog,
		private store: Store
	) {
		super();
		this.filters = this.fb.group({
			groupId: this.fb.control(""),
			type: this.fb.control("all"),
			auctionDate: this.fb.control(null),
			size: this.fb.control(0)
		});
	}

	ngOnInit(): void {
		this.events$ = combineLatest([this.leagueId.valueChanges, this.store.select(events)]).pipe(
			takeUntil(this.destroyed$),
			tap(() => this.eventId.setValue(null)),
			map(([leagueId, events]) => events.filter((event) => event.leagueId === leagueId))
		);

		this.groups$ = combineLatest([this.eventId.valueChanges, this.filters.valueChanges.pipe(startWith({}))]).pipe(
			takeUntil(this.destroyed$),
			filter(([eventId]) => !!eventId),
			switchMap(([eventId, filters]) => {
				if (!eventId) {
					return [];
				}

				if (filters.groupId) {
					return this.groupService.getByID(eventId, filters.groupId).pipe(map((event) => (event ? [event] : [])));
				} else if (filters.userId) {
					return this.groupService.getForEventAndUser(eventId, filters.userId);
				} else {
					return this.groupService.getForEventAndUser(eventId, undefined, filters.type);
				}
			}),
			map((groups) => {
				const size = this.filters.value.size;

				if (size) {
					groups = groups.filter((x) => x.managerIds.length >= size);
				}

				const auctionDate = new Date(this.filters.value.auctionDate);

				auctionDate.setHours(auctionDate.getHours() + 12);

				if (
					this.filters.value.auctionDate != null &&
					auctionDate &&
					auctionDate instanceof Date &&
					auctionDate.getFullYear() > 2023
				) {
					const start = startOfDay(auctionDate);
					const end = endOfDay(auctionDate);

					groups = groups.filter((x) => x.auctionDate && isBefore(x.auctionDate, end) && isAfter(x.auctionDate, start));
				}

				return groups;
			})
		);
	}

	setSelected(groupId: string) {
		this.selectedGroupId = this.selectedGroupId === groupId ? null : groupId;
	}

	navigateToGroup(group: IGroup) {
		const url = this.router.serializeUrl(this.router.createUrlTree(["event", group.eventId, group.id]));
		window.open(url, "_blank");
	}

	openAuditDialog(group: IGroup): void {
		// TODO
		// this.dialog.open(GroupAuditDialogComponent, { data: { group: group, eventId: group.eventId, league: this.league } });
	}

	deleteGroup(eventId: string, groupId: string): void {
		this.dialog
			.open(ConfirmationDialogComponent, { data: { text: "Are you sure you want to delete this group? This cannot be undone." } })
			.afterClosed()
			.pipe(take(1))
			.subscribe((result) => {
				if (result) {
					this.groupService.deleteGroup(eventId, groupId);
				}
			});
	}
}

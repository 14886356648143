<div class="dialog">
	<h2>Integration Data</h2>
	<mat-dialog-content>
		<am-loader [loading]="loading$ | async">
			<mat-tab-group>
				@if (integrationData$ | async; as integrationData) {
				<mat-tab label="Teams">
					<div class="actions">
						<button mat-button (click)="selectAllTeams()">Select All</button>
						<button mat-button (click)="clearAllTeams()">Clear All</button>
					</div>
					<mat-selection-list #teamList color="primary" multiple (selectionChange)="teamSelected($event)">
						@for (team of integrationData.teamSummaries; track team) {
						<mat-list-option [value]="team">{{ team.displayName }}</mat-list-option>
						}
					</mat-selection-list>
				</mat-tab>

				}
				<mat-tab label="Games">
					<div class="actions">
						<button mat-button (click)="selectAllGames()">Select All</button>
						<button mat-button (click)="clearAllGames()">Clear All</button>
						<mat-form-field>
							<mat-label>By Status</mat-label>
							<mat-select [formControl]="gameStatus">
								<mat-option [value]="null">--</mat-option>
								<mat-option value="Upcoming">Upcoming</mat-option>
								<mat-option value="In Progress">In Progress</mat-option>
								<mat-option value="Final">Final</mat-option>
							</mat-select>
						</mat-form-field>
						@if (integrationData$ | async; as integrationData) {
						<mat-form-field>
							<mat-label>By Round</mat-label>
							<mat-select [formControl]="gameRound">
								<mat-option [value]="null">--</mat-option>
								@for (round of getRounds(integrationData.gameSummaries); track round) {

								<mat-option [value]="round">{{ round }}</mat-option>
								}
							</mat-select>
						</mat-form-field>
						}
					</div>
					<mat-selection-list #gameList color="primary" multiple (selectionChange)="gameSelected($event)">
						@for (game of filterGames$ | async; track game) {
						<mat-list-option [value]="game">
							<span matListItemTitle>{{ game.name }}</span>
							<span matListItemLine>{{ game.startTime | date }} - {{ game.round }}</span>
						</mat-list-option>
						}
					</mat-selection-list>
				</mat-tab>
			</mat-tab-group>
		</am-loader>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-button color="warn" (click)="dialogRef.close(false)">Close</button>
		<button mat-stroked-button (click)="save()">Save</button>
	</mat-dialog-actions>
</div>

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { FirebaseError } from "firebase/app";
import { AnalyticsService } from "src/app/services/analytics.service";
import { AuthenticationService } from "src/app/services/authentication.service";

@Component({
	selector: "am-generic-login",
	templateUrl: "./generic-login.component.html",
	styleUrls: ["./generic-login.component.scss"]
})
export class GenericLoginComponent {
	loginForm: FormGroup = this.fb.group({
		email: new FormControl("", [Validators.required, Validators.email]),
		password: new FormControl("", Validators.required)
	});

	updating: boolean = false;
	errorMessage: string;
	failedVerification: boolean = false;
	handleRedirect: Function;

	constructor(
		public store: Store,
		private fb: FormBuilder,
		private router: Router,
		private authenticationService: AuthenticationService,
		private analyticService: AnalyticsService
	) {}

	get email() {
		return this.loginForm?.get(["email"]) as FormControl;
	}

	get password() {
		return this.loginForm?.get(["password"]) as FormControl;
	}

	async login() {
		this.updating = true;
		this.loginForm.disable();
		try {
			await this.authenticationService.signInWithEmail(this.email.value, this.password.value);
			this.handleRedirect();
		} catch (error) {
			console.log(error);
			if (error instanceof FirebaseError) {
				if (error.message.includes("needs to be verified")) {
					this.errorMessage = "Your email must be verified. Resend verification email below";
					this.failedVerification = true;
				} else {
					this.errorMessage = "Login Failed";
				}
			} else {
			}
		}
		this.updating = false;
		this.loginForm.enable();
		await this.analyticService.logEvent("login", { provider: "Generic" });
	}

	resendSendVerificationEmail() {
		this.router.navigate(["login", "verification"], { queryParams: { email: this.email.value } });
	}

	goToSignup() {
		this.router.navigate(["login", "signup"]);
	}

	goToReset() {
		this.router.navigate(["login", "reset-password"]);
	}
}

import { Component, Input } from "@angular/core";
import { BaseComponent } from "../../components/base.component";
import { IMatchup } from "src/app/models/game";
import { IRegion, ITier } from "src/app/models/event";
import { Store } from "@ngrx/store";

@Component({
	selector: "am-nfl-bracket",
	templateUrl: "./nfl-bracket.component.html",
	styleUrls: ["./nfl-bracket.component.scss"]
})
export class NFLBracketComponent {
	@Input() tiers: ITier[];
	@Input() matchups: IMatchup[];
	@Input() regions: IRegion[];

	getMatchupsByRound(tierId: string, regionId: string): IMatchup[] {
		return this.matchups.filter((x) => x.tierId === tierId && x.regionId === regionId);
	}

	getRegionByName(name: string): IRegion | undefined {
		return this.regions.find((x) => x.name === name);
	}

	removeSuperBowl(tiers: ITier[]): ITier[] {
		return tiers.filter((x) => x.id !== "superbowl");
	}
}

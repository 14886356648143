import { Injectable } from "@angular/core";
import { onSnapshot } from "@angular/fire/firestore";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, filter, map, of, switchMap, withLatestFrom } from "rxjs";
import { UserService } from "src/app/services/user.service";
import * as userEventGroupActions from "./user-event-group.action";
import { currentUID } from "../app/app.selectors";
import { IUserEventGroup } from "src/app/models/user";

@Injectable()
export class UserEventGroupEffects {
	constructor(private actions$: Actions, private userService: UserService, private store: Store) {}

	getUserEventGroups$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userEventGroupActions.getUserEventGroup),
			filter((action) => !!action.uid),
			withLatestFrom(this.store.select(currentUID)),
			switchMap(([action, currentUid]) => {
				if (action.uid !== currentUid) {
					throw new Error("Should only load the current user's userEventGroups");
				}

				return this.userService.getUserEventGroupByUid(action.uid).pipe(
					map((userEventGroupQuery) => {
						onSnapshot(userEventGroupQuery, (querySnapshot) => {
							const userEventGroups: IUserEventGroup[] = [];

							querySnapshot.forEach((userEventGroup) => {
								userEventGroups.push(userEventGroup.data() as IUserEventGroup);
							});

							this.store.dispatch(userEventGroupActions.upsertUserEventGroups({ userEventGroups: userEventGroups }));
						});

						return userEventGroupActions.getUserEventGroupSuccess();
					}),
					catchError((error) => {
						return of(userEventGroupActions.getUserEventGroupFailed({ errorMessage: error }));
					})
				);
			})
		)
	);
}

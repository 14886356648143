<mat-card [ngClass]="{ 'extra-height': eventStarted() || true }">
	<ng-container *ngIf="!matchup.clinchingAmount">
		<ng-container [ngSwitch]="matchup.gameStatus">
			<div *ngSwitchCase="'Final'" class="start-time bold">Final</div>
			<div *ngSwitchCase="'In Progress'" class="start-time bold">
				In Progress {{ matchup.tvProvider != null ? "on " + matchup.tvProvider : "" }}
			</div>
			<div *ngSwitchDefault class="start-time">
				{{ matchup.startTime != null ? (matchup.startTime | date : "M/dd h:mm a") : "" }}
				{{ matchup.tvProvider != null ? "on " + matchup.tvProvider : "" }}
			</div>
		</ng-container>
	</ng-container>
	<mat-divider />
	<div class="matchup">
		<div *ngIf="matchup.team1 != null; else emptyBlock" class="block left" [ngClass]="{ focus: matchup.team1Owner?.color === 'focus' }">
			<div class="header-left">
				<img width="75" height="75" [src]="matchup.team1.logo" />
				<h1>{{ matchup.gameStatus !== "Upcoming" ? matchup.team1.score ?? 0 : "" }}</h1>
			</div>
			<h3>{{ matchup.team1.seed ? "#" + matchup.team1.seed : "" }} {{ matchup.team1.displayName }}</h3>
			<span *ngIf="matchup.team1.record" class="record">({{ matchup.team1.record }})</span>
			<ng-container *ngIf="matchup.team1Owner != null">
				<span class="spacer"></span>
				<div class="footer">
					<div>{{ matchup.team1Owner.owner }}</div>
					<ng-container *ngIf="matchup.team1Owner.groupRank !== 0">
						<mat-divider [vertical]="true"></mat-divider>
						<div>{{ matchup.team1Owner.groupRank | scoreboardPlace }}</div>
					</ng-container>
				</div>

				<div class="color-block color-left" [style.background-color]="matchup.team1Owner.color"></div>
			</ng-container>
		</div>
		<div class="divider-container">
			<mat-divider class="divider" [vertical]="true"></mat-divider>
			<span>vs</span>
			<span matTooltip="The game's value" class="value">{{ matchup.value | currency : "USD" : "symbol" : "1.2-2" }}</span>

			<mat-divider class="divider" [vertical]="true"></mat-divider>
		</div>
		<div
			*ngIf="matchup.team2 != null; else emptyBlock"
			class="block right"
			[ngClass]="{ focus: matchup.team2Owner?.color === 'focus' }"
		>
			<div class="header-right">
				<h1>{{ matchup.gameStatus !== "Upcoming" ? matchup.team2.score ?? 0 : "" }}</h1>
				<img width="75" height="75" [src]="matchup.team2.logo" />
			</div>
			<h3>{{ matchup.team2.seed ? "#" + matchup.team2.seed : "" }} {{ matchup.team2.displayName }}</h3>
			<span *ngIf="matchup.team2.record" class="record">({{ matchup.team2.record }})</span>
			<ng-container *ngIf="matchup.team2Owner != null">
				<span class="spacer"></span>
				<div class="footer">
					<div>{{ matchup.team2Owner.owner }}</div>
					<ng-container *ngIf="matchup.team2Owner.groupRank !== 0">
						<mat-divider [vertical]="true"></mat-divider>
						<div>{{ matchup.team2Owner.groupRank | scoreboardPlace }}</div>
					</ng-container>
				</div>

				<div class="color-block color-right" [style.background-color]="matchup.team2Owner.color"></div>
			</ng-container>
		</div>
	</div>
</mat-card>

<ng-template #emptyBlock>
	<div class="block"></div>
</ng-template>

import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AuctionTutorialDialogComponent } from "../auction-tutorial-dialog/auction-tutorial-dialog.component";
import { Router } from "@angular/router";
import { AnalyticsService } from "src/app/services/analytics.service";
import { IEvent } from "src/app/models/event";
import { Store } from "@ngrx/store";
import { currentEvent } from "src/app/state/event/event.selectors";
import { filter, take } from "rxjs";

@Component({
	selector: "am-ad-dialog",
	templateUrl: "./ad-dialog.component.html",
	styleUrls: ["./ad-dialog.component.scss"]
})
export class AdDialogComponent implements OnInit {
	currentEvent: IEvent;

	constructor(
		public dialogRef: MatDialogRef<AuctionTutorialDialogComponent>,
		private router: Router,
		private store: Store,
		@Inject(MAT_DIALOG_DATA) private data: { event: IEvent },
		private analyticsService: AnalyticsService
	) {}

	ngOnInit(): void {
		this.store
			.select(currentEvent)
			.pipe(
				filter((x) => !!x),
				take(1)
			)
			.subscribe((x) => {
				if (x) {
					this.currentEvent = x;
				}
			});
	}

	createRoom(): void {
		this.analyticsService.logEvent("ad_create_room");

		let newRelativeUrl = this.router.createUrlTree(["event", this.currentEvent?.id, "createGroup"]);

		let baseUrl = window.location.href.replace(this.router.url, "");
		window.open(baseUrl + newRelativeUrl, "_blank");
		this.dialogRef.close();
	}
}

import { Pipe, PipeTransform } from "@angular/core";
import { IGame } from "../../models/game";

@Pipe({
	name: "gamefilter",
	pure: false
})
export class GameFilterPipe implements PipeTransform {
	transform(games: IGame[], tierId: string): any {
		if (!games || !tierId) {
			return games;
		}

		return games.filter((game) => game.tierId === tierId || game.tierId === tierId);
	}
}

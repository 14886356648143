import { Component, inject, OnInit } from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { MatButton } from "@angular/material/button";
import { MatOption } from "@angular/material/core";
import { CommonModule } from "@angular/common";
import { MatSelect } from "@angular/material/select";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { LeagueStore } from "src/app/state/league.store";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";

@Component({
	selector: "am-select-event-admin-dialog",
	template: `
		<div class="select-event-admin-dialog">
			<h3>Select an League</h3>
			<div></div>
			<mat-form-field appearance="outline">
				<mat-label>League</mat-label>
				<mat-select [formControl]="selectedLeague">
					<mat-option *ngFor="let league of leagues()" [value]="league.id"> {{ league.year }} {{ league.sport }} </mat-option>
				</mat-select>
			</mat-form-field>
			<div class="actions">
				<button mat-stroked-button color="warn" (click)="dialogRef.close()">Close</button>
				<button mat-raised-button (click)="openSystemAdmin()">System Admin</button>
				<button mat-raised-button (click)="createNew()">New</button>
				<button mat-raised-button [disabled]="this.selectedLeague.invalid" (click)="navigate()">Open</button>
			</div>
		</div>
	`,
	styles: [
		`
			.select-event-admin-dialog {
				display: flex;
				flex-direction: column;
				margin: 16px;
				width: 450px;

				.actions {
					display: flex;
					gap: 8px;
					justify-content: space-between;
					flex-wrap: wrap;
				}
			}
		`
	],
	standalone: true,
	imports: [MatFormField, MatLabel, MatSelect, CommonModule, MatOption, MatButton, ReactiveFormsModule, MatInputModule, MatCheckboxModule]
})
export class SelectAdminDialog implements OnInit {
	readonly leagueStore = inject(LeagueStore);
	leagues = this.leagueStore.entities;
	selectedLeague = new FormControl<string | null>(null, Validators.required);
	eventId = new FormControl<string | null>(null, Validators.required);
	dryRun = new FormControl<boolean>(true);

	constructor(
		public dialogRef: MatDialogRef<SelectAdminDialog>,
		private router: Router
	) {}

	ngOnInit(): void {
		this.leagueStore.loadAllLeagues();
	}

	navigate(): void {
		this.router.navigate(["admin", "league", this.selectedLeague.value]);
		this.dialogRef.close();
	}

	openSystemAdmin(): void {
		this.router.navigate(["admin", "system"]);
		this.dialogRef.close();
	}

	createNew(): void {
		this.router.navigate(["admin", "league"]);
		this.dialogRef.close();
	}
}

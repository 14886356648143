import { Component, Input, OnInit } from "@angular/core";
import { BaseComponent } from "../../components/base.component";
import { IRegion, ITier } from "src/app/models/event";
import { IMatchup } from "src/app/models/game";
import { Store } from "@ngrx/store";
import { Observable, combineLatest, filter, map, take, takeUntil } from "rxjs";
import { currentBreakPoint } from "src/app/state/app/app.selectors";
import { FormControl } from "@angular/forms";
import { teams } from "src/app/state/event/event.selectors";
import {
	currentGroupManagers,
	currentGroupRosters,
	currentGroupStandings,
	currentGroupStatus,
	currentManagerScores,
	managerColors
} from "src/app/state/group/group.selectors";
import { users } from "src/app/state/user/user.selectors";

interface IOwnerInfo {
	uid: string;
	color: string;
	alive: number;
	totalTeams: number;
	displayName: string;
	score: number;
	standing: number;
	status: string;
}

@Component({
	selector: "am-ncaam-bracket",
	templateUrl: "./ncaam-bracket.component.html",
	styleUrls: ["./ncaam-bracket.component.scss"]
})
export class NCAAMBracketComponent extends BaseComponent implements OnInit {
	@Input() tiers: ITier[];
	@Input() matchups: IMatchup[];
	@Input() regions: IRegion[];
	@Input() isAuction: boolean = false;

	viewControl: FormControl = new FormControl("normal");
	innerWidth: number;

	ownerInfo$: Observable<IOwnerInfo[]>;

	sortDiplayOrder = (a: any, b: any) => {
		if (a.displayOrder === b.displayOrder) {
			return 0;
		}

		if (a.displayOrder == null) {
			return 1;
		}

		if (b.displayOrder == null) {
			return -1;
		}

		return a.displayOrder - b.displayOrder;
	};

	constructor(private store: Store) {
		super();
	}

	ngOnInit(): void {
		this.isBracketMobile$()
			.pipe(take(1))
			.subscribe((x) => {
				if (x) {
					this.viewControl.setValue("simple");
				}
			});

		this.ownerInfo$ = combineLatest([
			this.store.select(teams),
			this.store.select(currentGroupManagers),
			this.store.select(managerColors),
			this.store.select(currentGroupRosters),
			this.store.select(users),
			this.store.select(currentManagerScores),
			this.store.select(currentGroupStandings),
			this.store.select(currentGroupStatus)
		]).pipe(
			takeUntil(this.destroyed$),
			filter(
				([teams, managers, managerColors, rosters, users, scores, standings, status]) =>
					!!teams && !!managers && !!managerColors && !!rosters && !!users && !!scores && !!standings && !!status
			),
			map(([teams, managers, managerColors, rosters, users, scores, standings, status]) => {
				const owners: IOwnerInfo[] = [];

				managers?.forEach((uid) => {
					const roster = rosters?.get(uid);
					let alive = 0;

					roster?.forEach((teamId) => {
						if (!teams.find((x) => x.id === teamId)?.isEliminated) {
							alive++;
						}
					});

					owners.push({
						uid: uid,
						color: managerColors?.get(uid) ?? "",
						alive: alive,
						totalTeams: roster?.length ?? 0,
						displayName: users.find((x) => x.uid === uid)?.displayName ?? "",
						score: scores?.get(uid) ?? 0,
						standing: standings.get(uid) ?? 0,
						status: status ?? ""
					});
				});

				return owners.sort((a, b) => b.score - a.score);
			})
		);
	}

	get view(): any {
		return this.viewControl.value;
	}

	isBracketMobile$(): Observable<boolean> {
		return this.store.select(currentBreakPoint).pipe(
			takeUntil(this.destroyed$),
			map((breakpoint) => breakpoint === "XSmall" || breakpoint === "Small")
		);
	}

	getRegionByLocation(location: string): IRegion | undefined {
		return this.regions.find((x) => x.location === location);
	}

	getMatchupsByRound(tierId: string, regionId: string): IMatchup[] {
		return this.getMatchupsByTier(tierId)
			.filter((x) => x.regionId === regionId)
			.sort((a, b) => this.sortDiplayOrder(a, b));
	}

	getMatchupsByTier(tierId: string): IMatchup[] {
		return this.matchups.filter((x) => x.tierId === tierId).sort((a, b) => this.sortDiplayOrder(a, b));
	}

	getFinalFourMatchByRegion(location: string): IMatchup | undefined {
		const region = this.getRegionByLocation(location);

		if (!region) {
			throw new Error(`Incorrect region location: ${location}`);
		}

		return this.matchups.find((x) => x.regionId === region.id);
	}

	getChampionshipMatchup(): IMatchup | undefined {
		const region = this.regions.find((x) => x.name === "Championship");

		if (!region) {
			throw new Error("Missing championship matchup");
		}

		return this.matchups.find((x) => x.regionId === region.id);
	}

	removeFinalFourTiers(): ITier[] {
		return this.tiers.filter((x) => x.displayName !== "Semifinals" && x.displayName !== "Championship");
	}

	getFirstRoundTier(): string {
		return this.tiers[0].id;
	}
}

<div *ngIf="form">
	<h3 mat-dialog-title>Update Game</h3>
	<form [formGroup]="form">
		<mat-dialog-content>
			<div class="section">
				<mat-form-field appearance="outline">
					<mat-label>Team</mat-label>
					<mat-select formControlName="team1Id">
						<mat-option>--</mat-option>
						@for (team of teams; track $index) {
							<mat-option [value]="team.id"> {{ team.displayName }} {{ team.seed }}</mat-option>
						}
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Score</mat-label>
					<input type="number" matInput formControlName="team1Score" />
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Status</mat-label>
					<mat-select formControlName="status" required>
						<mat-option value="Upcoming">Upcoming</mat-option>
						<mat-option value="In Progress">In Progress</mat-option>
						<mat-option value="Final">Final</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="section">
				<mat-form-field appearance="outline">
					<mat-label>Team</mat-label>
					<mat-select formControlName="team2Id">
						<mat-option>--</mat-option>
						@for (team of teams; track $index) {
							<mat-option [value]="team.id"> {{ team.displayName }} {{ team.seed }}</mat-option>
						}
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Score</mat-label>
					<input type="number" matInput formControlName="team2Score" />
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Round</mat-label>
					<input formControlName="round" matInput placeholder="Round" />
				</mat-form-field>
			</div>
			<div class="section">
				<mat-form-field appearance="outline">
					<mat-label>Region</mat-label>
					<mat-select formControlName="regionId">
						@for (region of regions; track $index) {
							<mat-option [value]="region.id">{{ region.name }}</mat-option>
						}
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Clinching Amount</mat-label>
					<input formControlName="clinchingAmount" matInput type="number" />
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Display Order</mat-label>
					<input formControlName="displayOrder" type="number" min="0" matInput />
				</mat-form-field>
			</div>
			<div class="section">
				<mat-form-field appearance="outline">
					<mat-label>Total Number of Games</mat-label>
					<input formControlName="totalNumberOfGames" matInput type="number" />
				</mat-form-field>
			</div>
		</mat-dialog-content>
		<mat-dialog-actions class="actions">
			<button mat-button color="warn" class="delete-button" (click)="deleteSeries()">Delete Game</button>
			<button mat-stroked-button (click)="save()">Save</button>
			<button mat-button color="warn" (click)="dialogRef.close(false)">Close</button>
		</mat-dialog-actions>
	</form>
</div>

<div id="nfl-bracket">
	<div class="region afc" *ngIf="getRegionByName('AFC') as region">
		<h1>AFC</h1>
		<div class="round">
			<div *ngFor="let tier of removeSuperBowl(tiers)" class="matchups">
				<am-bracket-matchup-box
					*ngFor="let matchup of getMatchupsByRound(tier.id, region.id)"
					[matchup]="matchup"
					[isReverse]="false"
				></am-bracket-matchup-box>
			</div>
		</div>
	</div>
	<div class="region superbowl" *ngIf="getRegionByName('Super Bowl') as region">
		<h1 style="text-align: center">{{ region.name }}</h1>
		<div class="round">
			<div class="matchups">
				<am-bracket-matchup-box
					*ngFor="let matchup of getMatchupsByRound('Championship', region.id)"
					[matchup]="matchup"
					[isReverse]="false"
				></am-bracket-matchup-box>
			</div>
		</div>
	</div>
	<div class="region nfc" *ngIf="getRegionByName('NFC') as region">
		<h1>NFC</h1>
		<div class="round">
			<div *ngFor="let tier of removeSuperBowl(tiers)" class="matchups">
				<am-bracket-matchup-box
					*ngFor="let matchup of getMatchupsByRound(tier.id, region.id)"
					[matchup]="matchup"
					[isReverse]="true"
				></am-bracket-matchup-box>
			</div>
		</div>
	</div>
</div>

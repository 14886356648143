<h1 mat-dialog-title>Edit Series</h1>
<mat-dialog-content>
	<mat-card [formGroup]="dataForm" class="current-game">
		{{ this.currentSeries.id }}
		<div>
			<mat-form-field>
				<mat-label>Team</mat-label>
				<mat-select formControlName="team1Id">
					<mat-option>--</mat-option>
					<mat-option *ngFor="let team of teams" [value]="team.id"> {{ team.location }} {{ team.seed }}</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field>
				<mat-label>Score</mat-label>
				<input type="number" matInput formControlName="team1Score" />
			</mat-form-field>
		</div>
		<div>
			<mat-form-field>
				<mat-label>Team</mat-label>
				<mat-select formControlName="team2Id">
					<mat-option>--</mat-option>
					<mat-option *ngFor="let team of teams" [value]="team.id"> {{ team.location }} {{ team.seed }} </mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field>
				<mat-label>Score</mat-label>
				<input type="number" matInput formControlName="team2Score" />
			</mat-form-field>
		</div>
		<div>
			<mat-form-field>
				<mat-label>Status</mat-label>
				<mat-select formControlName="status" required>
					<mat-option value="Upcoming">Upcoming</mat-option>
					<mat-option value="In Progress">In Progress</mat-option>
					<mat-option value="Final">Final</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field>
				<mat-label>Region</mat-label>
				<mat-select formControlName="regionId">
					<mat-option *ngFor="let region of regions" [value]="region.id">{{ region.name }}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div>
			<mat-form-field>
				<mat-label>Display Order</mat-label>
				<input formControlName="displayOrder" type="number" min="0" matInput />
			</mat-form-field>
		</div>
		<div>
			<mat-form-field>
				<mat-label>Total Number Of Games</mat-label>
				<input formControlName="totalNumberOfGames" type="number" min="0" matInput />
			</mat-form-field>
			<mat-form-field>
				<mat-label>Clinching Amount</mat-label>
				<input formControlName="clinchingAmount" type="number" min="0" matInput />
			</mat-form-field>
		</div>
	</mat-card>
</mat-dialog-content>
<mat-dialog-actions class="actions">
	<button mat-button (click)="close()">Close</button>
	<button mat-button (click)="submit()" class="left" [disabled]="!dataForm.valid || dataForm.pristine">
		<span *ngIf="!updating">Update</span>
		<mat-spinner *ngIf="updating" diameter="25"></mat-spinner>
	</button>
</mat-dialog-actions>

import { Injectable } from "@angular/core";
import {
	collection,
	doc,
	docData,
	DocumentData,
	Firestore,
	getDoc,
	getDocs,
	Query,
	query,
	QueryConstraint,
	QueryDocumentSnapshot,
	where,
	deleteDoc
} from "@angular/fire/firestore";
import { Functions, httpsCallableData } from "@angular/fire/functions";
import { catchError, combineLatest, from, map, Observable, of } from "rxjs";
import { IGroup, IGroupScoreAudit } from "../models/group";
import { IGroupInvite } from "../models/invite";
import { IEvent, IPublicRoom, IScoringPreset } from "../models/event";

@Injectable({
	providedIn: "root"
})
export class GroupService {
	constructor(
		private fs: Firestore,
		private fns: Functions
	) {}

	getForEventAndUser(eventId: string, uid?: string, type?: string): Observable<IGroup[]> {
		let collectionRef = collection(this.fs, "events", eventId, "groups").withConverter(groupConverter);
		let constraints: QueryConstraint[] = [];

		if (uid) {
			constraints.push(where("managerIds", "array-contains", uid));
		}

		if (type !== "all") {
			if (type === "public") {
				constraints.push(where("isPublic", "==", true));
				constraints.push(where("isBot", "==", false));
			} else if (type === "private") {
				constraints.push(where("isPublic", "==", false));
				constraints.push(where("isBot", "==", false));
			} else if (type === "bot") {
				constraints.push(where("isBot", "==", true));
			}
		}

		return from(getDocs(query(collectionRef, ...constraints))).pipe(map((response) => response.docs.map((d) => d.data())));
	}

	getByID(eventId: string, groupId: string): Observable<IGroup | undefined> {
		return from(getDoc(doc(this.fs, "events", eventId, "groups", groupId).withConverter(groupConverter))).pipe(
			map((group) => group.data())
		);
	}

	joinValuePicks(eventId: string, roster: string[], teams: string[], event: IEvent, scoring: IScoringPreset | null): Observable<string> {
		if (scoring == null) {
			throw new Error("Join value picks requires scoring defaults");
		}

		const groupScoring: {
			scoringPresetId: string;
			buyin: number;
			tierValues: Record<string, number>;
			phaseValues: Record<string, number>;
			makePhaseValues: Record<string, number>;
		} = {
			scoringPresetId: scoring.id,
			buyin: scoring.buyIn,
			makePhaseValues: {},
			phaseValues: {},
			tierValues: {}
		};

		event.phases.forEach((phase) => {
			const phaseScoring = scoring.phaseScorings.find((x) => x.phaseId === phase.id);

			if (phaseScoring == null) {
				throw Error(`Can't find scoring for phase: ${phase.id}`);
			}

			let amountForAllTiers = phaseScoring.amountForAllTiers || 0;
			groupScoring.makePhaseValues[phase.id] = phaseScoring?.amountToMakePhase || 0;
			if (amountForAllTiers > 0) {
				groupScoring.phaseValues[phase.id] = amountForAllTiers;
			} else {
				phaseScoring.tiers?.forEach((tier) => {
					groupScoring.tierValues[tier.tierId] = tier.defaultValue || 0;
				});
			}
		});

		const callable = httpsCallableData(this.fns, "group-joinValuePicks");
		return callable({
			leagueId: event.leagueId,
			eventId,
			roster,
			teams,
			scoring: groupScoring,
			prices: Object.fromEntries(event.valuePicks!!)
		}) as Observable<string>;
	}

	getUsersActiveGroups(uid: string, eventIds: string[]): Observable<IGroup[][]> {
		const observables: Observable<IGroup[]>[] = [];

		eventIds.forEach((eventId) => {
			let collectionRef = collection(this.fs, "events", eventId, "groups").withConverter(groupConverter);

			observables.push(
				from(
					getDocs(
						query(
							collectionRef,
							where("managerIds", "array-contains", uid),
							where("status", "in", ["Created", "Ready", "In Auction", "In Progress"])
						)
					)
				).pipe(map((response) => response.docs.map((d) => d.data())))
			);
		});

		return combineLatest([...observables]);
	}

	getByEventandGroupIDs(groups: { eventId: string; groupId: string }[]): Observable<(IGroup | undefined)[]> {
		var observables: Observable<IGroup | undefined>[] = [];

		groups.forEach((group) => {
			observables.push(
				this.getByID(group.eventId, group.groupId).pipe(
					catchError((error) => {
						console.log(error);
						return of(undefined);
					})
				)
			);
		});

		return combineLatest([...observables]);
	}

	getLiveUpdatesByID(eventId: string, groupId: string): Observable<IGroup | undefined> {
		return docData<IGroup>(doc(this.fs, "events", eventId, "groups", groupId).withConverter(groupConverter), { idField: "id" });
	}

	createGroup(group: Partial<IGroup>): Observable<string> {
		const callable = httpsCallableData(this.fns, "group-createGroup");
		return callable({ group }) as Observable<string>;
	}

	updateGroup(eventId: string, group: Partial<IGroup>) {
		const callable = httpsCallableData(this.fns, "group-updateGroup");
		return callable({ eventId, group: groupConverter.toFirestore(group as IGroup) }) as Observable<IGroup>;
	}

	getGroupInviteByID(inviteId: string): Observable<IGroupInvite | undefined> {
		return from(getDoc(doc(this.fs, "invites", inviteId))).pipe(
			map((response) => (response.exists() ? ({ ...response.data(), id: response.id } as IGroupInvite) : undefined))
		);
	}

	leaveGroup(eventId: string, groupId: string): Observable<string> {
		const callable = httpsCallableData(this.fns, "group-leaveGroup");
		return callable({
			eventId,
			groupId
		}) as Observable<string>;
	}

	acceptGroupInvite(invite: IGroupInvite, eventType: string): Observable<string> {
		const callable = httpsCallableData(this.fns, "group-acceptGroupInvite");
		return callable({
			inviteId: invite.id,
			eventId: invite.eventId,
			groupId: invite.groupId,
			isSharedInvite: invite.isSharedInvite,
			eventType
		}) as Observable<string>;
	}

	deleteGroup(eventId: string, groupId: string, uid?: string): Promise<void> {
		const groupRef = doc(this.fs, "events", eventId, "groups", groupId);
		return deleteDoc(groupRef);
	}

	joinPublicRoomFirestore(groupId: string, eventType: string, eventId: string, publicRoomId: string): Observable<string> {
		const callable = httpsCallableData(this.fns, "group-joinPublicGroup");
		return callable({
			eventId: eventId,
			groupId: groupId,
			eventType,
			publicRoomId
		}) as Observable<string>;
	}

	getPublicRoomsWithCapacity(eventId: string): Observable<Query<DocumentData>> {
		return of(
			query(
				collection(this.fs, `events/${eventId}/groups`),
				where("isPublic", "==", true),
				where("status", "==", "Created")
			).withConverter(groupConverter)
		);
	}

	getGroupAuditData(eventId: string, groupId: string): Observable<IGroupScoreAudit[] | undefined> {
		return from(getDoc(doc(this.fs, "events", eventId, "group-audits", groupId).withConverter(groupAuditConverter))).pipe(
			map((group) => group.data() ?? undefined)
		);
	}

	fillWithBots(eventId: string, groupId: string): Observable<string> {
		const callable = httpsCallableData(this.fns, "group-fillWithBots");
		return callable({ eventId, groupId }) as Observable<string>;
	}

	joinBotRoom(currentUID: string, event: IEvent, scoring: IScoringPreset): Observable<string> {
		const auctionDate = new Date();

		const newGroup = {
			name: `${event.name} AI`,
			numOfManagers: event.defaultManagers,
			auctionDate: auctionDate,
			leagueId: event.leagueId,
			isPublic: false,
			isBot: true,
			type: "auction",
			scoreType: "points",
			owner: currentUID,
			eventId: event.id,
			managerIds: [currentUID],
			scoring: {
				scoringPresetId: scoring.id,
				buyin: scoring.buyIn,
				makePhaseValues: {},
				phaseValues: {},
				tierValues: {}
			}
		} as Partial<IGroup>;

		event.phases.forEach((phase) => {
			const phaseScoring = scoring.phaseScorings.find((x) => x.phaseId === phase.id);

			if (phaseScoring == null) {
				throw Error(`Can't find scoring for phase: ${phase.id}`);
			}

			let amountForAllTiers = phaseScoring.amountForAllTiers || 0;
			newGroup.scoring!!.makePhaseValues[phase.id] = phaseScoring?.amountToMakePhase || 0;
			if (amountForAllTiers > 0) {
				newGroup.scoring!!.phaseValues[phase.id] = amountForAllTiers;
			} else {
				phaseScoring.tiers?.forEach((tier) => {
					newGroup.scoring!!.tierValues[tier.tierId] = tier.defaultValue || 0;
				});
			}
		});

		return this.createGroup(newGroup);
	}

	joinPublicRoom(publicRoom: IPublicRoom, currentUID: string, event: IEvent): Observable<string> {
		if (publicRoom.groupId == null) {
			const newPublicGroup = {
				name: publicRoom.name,
				numOfManagers: publicRoom.numOfManagers,
				auctionDate: publicRoom.auctionDate,
				isPublic: true,
				isBot: false,
				owner: undefined,
				eventId: event.id,
				managerIds: [currentUID],
				publicRoomId: publicRoom.id
			} as Partial<IGroup>;

			const scoringPreset = event.scoringPresets.find((x) => x.id === publicRoom.scoringPresetId);

			if (scoringPreset == null) {
				throw Error(`Can't find scoring for publc room: ${publicRoom.id}`);
			}

			newPublicGroup.scoring = {
				scoringPresetId: scoringPreset.id,
				buyin: scoringPreset.buyIn,
				makePhaseValues: {},
				phaseValues: {},
				tierValues: {},
				dynamic: {}
			};

			event.phases.forEach((phase) => {
				const phaseScoring = scoringPreset?.phaseScorings.find((x) => x.phaseId === phase.id);

				if (phaseScoring == null) {
					throw Error(`Can't find scoring for phase: ${phase.id}`);
				}

				let amountForAllTiers = phaseScoring.amountForAllTiers || 0;
				newPublicGroup.scoring!!.makePhaseValues[phase.id] = phaseScoring?.amountToMakePhase || 0;
				newPublicGroup.scoring!!.dynamic[phase.id] = phaseScoring?.dynamic ?? false;
				if (amountForAllTiers > 0) {
					newPublicGroup.scoring!!.phaseValues[phase.id] = amountForAllTiers;
				} else {
					phaseScoring.tiers?.forEach((tier) => {
						newPublicGroup.scoring!!.tierValues[tier.tierId] = tier.defaultValue || 0;
						newPublicGroup.scoring!!.dynamic[tier.tierId] = phaseScoring?.dynamic ?? false;
					});
				}
			});

			return this.createGroup(newPublicGroup);
		} else {
			return this.joinPublicRoomFirestore(publicRoom.groupId, event.type, event.id, publicRoom.id);
		}
	}
}

export const groupConverter = {
	toFirestore: (group: IGroup) => {
		return {
			...group,
			auctionDate: group.auctionDate ? group.auctionDate : undefined,
			managerColors: group.managerColors ? Object.fromEntries(group.managerColors) : undefined,
			rosters: group.rosters ? Object.fromEntries(group.rosters) : undefined,
			prices: group.prices ? Object.fromEntries(group.prices) : undefined,
			scores: group.scores ? Object.fromEntries(group.scores) : undefined,
			eventId: undefined
		};
	},
	fromFirestore: (snapshot: QueryDocumentSnapshot) => {
		let data = snapshot.data();

		return {
			...data,
			type: data["type"] ?? "auction",
			scoreType: data["scoreType"] ?? "money",
			id: snapshot.id,
			auctionDate: data["auctionDate"]
				? data["auctionDate"]?.toString().includes("Z")
					? new Date(data["auctionDate"])
					: data["auctionDate"].toDate()
				: undefined,
			rosters: data["rosters"] ? new Map(Object.entries(data["rosters"])) : new Map(),
			managerColors: data["managerColors"] ? new Map(Object.entries(data["managerColors"])) : new Map(),
			prices: data["prices"] ? new Map(Object.entries(data["prices"])) : new Map(),
			scores: data["scores"] ? new Map<string, number>(Object.entries(data["scores"])) : new Map(),
			eventId: snapshot.ref.parent.parent?.id ?? ""
		} as IGroup;
	}
};

export const groupAuditConverter = {
	toFirestore: (group: any) => {
		throw new Error("You cannot upload to firestore directly this data should be handled via backend");
	},
	fromFirestore: (snapshot: QueryDocumentSnapshot) => {
		let data = snapshot.data();

		return data["scoreAudits"].map((x: any) => {
			return {
				...x,
				time: new Date(x.time)
			};
		}) as IGroupScoreAudit[];
	}
};

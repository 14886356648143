import { animate, group, style, transition, trigger } from "@angular/animations";
import { Component, inject, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSelectChange, MatSelect } from "@angular/material/select";
import { MatTabChangeEvent, MatTabGroup, MatTab } from "@angular/material/tabs";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { isAfter } from "date-fns";
import {
	BehaviorSubject,
	Observable,
	combineLatest,
	filter,
	interval,
	map,
	of,
	switchMap,
	take,
	takeUntil,
	tap,
	timer,
	withLatestFrom
} from "rxjs";
import { IAuction } from "src/app/models/auction";
import { determineGameValue } from "src/app/models/constants";
import { IEvent } from "src/app/models/event";
import { IMatchup } from "src/app/models/game";
import { ITeam, ITeamData, ITeamOwner } from "src/app/models/team";
import { IUser } from "src/app/models/user";
import { UserService } from "src/app/services/user.service";
import { AdDialogComponent } from "src/app/shared/dialogs/ad-dialog/ad-dialog.component";
import { AuctionTutorialDialogComponent } from "src/app/shared/components/auction-tutorial-dialog/auction-tutorial-dialog.component";
import { BaseComponent } from "src/app/shared/components/base.component";
import { GroupInfoDialog } from "src/app/shared/components/group-info-dialog/group-info-dialog.component";
import { currentUID, getConfig, isMobile, userRoles } from "src/app/state/app/app.selectors";
import { getTimeOffset, notInterested, setCurrentAuction, startAuction, submitBid } from "src/app/state/auction/auction.actions";
import {
	auction,
	budgets,
	currentTeam,
	managers,
	pricePerTeam,
	startingAuction,
	submittingBid,
	totalTeamsPerManager,
	timeOffset,
	rosters
} from "src/app/state/auction/auction.selectors";
import { setSelectedEvent } from "src/app/state/event/event.actions";
import { currentEvent } from "src/app/state/event/event.selectors";
import { setSelectedGroup } from "src/app/state/group/group.actions";
import { currentGroup, managerColors } from "src/app/state/group/group.selectors";
import { currentUserTutorialVersion, users } from "src/app/state/user/user.selectors";
import { CountdownPipe } from "../../shared/pipes/countdown.pipe";
import { MatSlider, MatSliderThumb } from "@angular/material/slider";
import { EventBracketComponent } from "../event-bracket/event-bracket.component";
import { MatDivider } from "@angular/material/divider";
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatOption } from "@angular/material/core";
import { MatListItemIcon } from "@angular/material/list";
import { MatIcon } from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";
import { MatIconButton, MatButton, MatFabButton } from "@angular/material/button";
import { CommonModule } from "@angular/common";
import { LeagueStore } from "src/app/state/league.store";
import { toObservable } from "@angular/core/rxjs-interop";

interface AuctionUserData {
	user: IUser | undefined;
	amountAvailable: number;
	roster: ITeamData[];
	color: string | undefined;
	selected: boolean;
	maxBid: number;
	placeholderArray: any[];
	uid: string;
}

@Component({
	selector: "am-auction",
	templateUrl: "./auction.component.html",
	styleUrls: ["./auction.component.scss"],
	animations: [
		trigger("slideInOut", [
			transition(":enter", [
				style({ "transform": "translateX(-100%)", "opacity": 0, "width": 0, "overflow-x": "hidden" }),
				group([
					animate("100ms 750ms", style({ opacity: 1, width: "100%" })),
					animate("750ms 850ms", style({ transform: "translateX(0%)" }))
				])
			]),
			transition(":leave", [
				style({ "transform": "translateX(0)", "opacity": 1, "position": "relative", "left": 0, "overflow-x": "hidden" }),
				animate("750ms", style({ transform: "translateX(100%)" }))
			])
		])
	],
	standalone: true,
	imports: [
		CommonModule,
		MatIconButton,
		MatTooltip,
		MatIcon,
		MatListItemIcon,
		MatSelect,
		MatOption,
		MatButton,
		MatFormField,
		MatLabel,
		MatInput,
		FormsModule,
		MatDivider,
		EventBracketComponent,
		MatTabGroup,
		MatTab,
		MatFabButton,
		MatSlider,
		MatSliderThumb,
		CountdownPipe,
		ReactiveFormsModule
	]
})
export class AuctionComponent extends BaseComponent implements OnInit {
	readonly leagueStore = inject(LeagueStore);

	isMobile$ = this.store.select(isMobile).pipe(takeUntil(this.destroyed$));
	currentUID$ = this.store.select(currentUID).pipe(takeUntil(this.destroyed$));
	auction$ = this.store.select(auction).pipe(takeUntil(this.destroyed$));
	event$ = this.store.select(currentEvent).pipe(takeUntil(this.destroyed$));
	totalTeamsPerManager$ = this.store.select(totalTeamsPerManager).pipe(takeUntil(this.destroyed$));
	startingAuction$ = this.store.select(startingAuction).pipe(takeUntil(this.destroyed$));
	submittingBid$ = this.store.select(submittingBid).pipe(takeUntil(this.destroyed$));

	allowedToStartAuction$: Observable<boolean> = of(false);
	userAuctionData$: Observable<AuctionUserData[]>;
	selectedUserData$: Observable<AuctionUserData | undefined>;
	myAuctionData$: Observable<AuctionUserData | undefined>;
	currentSelectedUser$ = new BehaviorSubject<string | undefined>(undefined);
	bidTimerSeconds$: Observable<number>;
	currentBidData$: Observable<{
		currentTeam: ITeam | undefined;
		currentBid: number;
		currentOwner: IUser | undefined;
		notInterestedOwners: string[];
	}>;
	teams$ = toObservable(this.leagueStore.teams);
	teamsByDivision$ = toObservable(this.leagueStore.teamsByDivision);

	bracketMatchups$: Observable<IMatchup[]>;
	teamsDataByDivision$: Observable<Map<string, ITeamData[]>>;

	seenAd = false;

	bidDelay: boolean = false;
	customBid = new FormControl<number>(0, { nonNullable: true });
	mobileView: string = "teams";
	showResult: boolean = false;
	resultOwner: IUser | undefined = undefined;
	resultBid: number | undefined = undefined;
	resultTeam: ITeam | undefined = undefined;
	mobileTabIndex = 0;

	currentLeague = this.leagueStore.currentLeague;

	private currentTeam: string = "";

	constructor(
		private store: Store,
		private activatedroute: ActivatedRoute,
		private dialog: MatDialog,
		private router: Router,
		private userService: UserService
	) {
		super();
	}

	override ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
		this.store.dispatch(setCurrentAuction({ auctionId: "" }));
	}

	ngOnInit(): void {
		let auctionId = this.activatedroute.snapshot.params["auctionId"];

		if (auctionId) {
			this.store.dispatch(getTimeOffset());
			this.store.dispatch(setCurrentAuction({ auctionId: auctionId }));
		}

		this.auction$
			.pipe(
				filter((a) => !!a && !!a.id),
				take(1),
				withLatestFrom(this.store.select(getConfig("TutorialVersion")), this.store.select(currentUserTutorialVersion))
			)
			.subscribe(([auction, tutorialVersion, currentUserTutorialVersion]) => {
				const eventId = auction?.eventId;
				const leagueId = auction?.leagueId;

				if (eventId) {
					this.store.dispatch(setSelectedEvent({ eventId: eventId }));
				}

				if (leagueId) {
					this.leagueStore.setCurrentLeague(leagueId);
				}

				const dayOldAuctionTime = new Date(auction?.startTime!!);
				dayOldAuctionTime.setDate(dayOldAuctionTime.getDate() - 1);

				if (
					+tutorialVersion > (currentUserTutorialVersion != null ? currentUserTutorialVersion : 0) &&
					isAfter(new Date(), dayOldAuctionTime) &&
					auction &&
					auction.status !== "Completed" &&
					auction.status !== "Abandoned"
				) {
					this.openTutorial(auction, tutorialVersion);
				}

				let groupId = auction?.groupId;
				if (groupId) {
					this.store.dispatch(setSelectedGroup({ eventId: eventId!!, groupId: groupId }));
				}
			});

		this.allowedToStartAuction$ = this.auction$.pipe(
			filter((auction) => auction?.status === "Ready"),
			withLatestFrom(this.store.select(timeOffset)),
			switchMap(([auction, offset]) =>
				interval(1000).pipe(
					map(() => {
						if (auction?.startTime) {
							let diff = Math.floor((auction.startTime.getTime() + offset - new Date().getTime()) / 1000);
							if (diff > 0) {
								return false;
							}
						}
						return true;
					})
				)
			)
		);

		this.getUserAuctionData();
		this.loadLiveAuctionData();

		this.currentUID$
			.pipe(
				filter((currentUID) => !!currentUID),
				take(1)
			)
			.subscribe((currentUID) => {
				this.currentSelectedUser$.next(currentUID);
			});

		this.selectedUserData$ = combineLatest([this.userAuctionData$, this.currentSelectedUser$]).pipe(
			filter(([userAuctionData, currentSelectedUser]) => !!currentSelectedUser && !!userAuctionData && userAuctionData.length > 0),
			map(([userAuctionData, currentSelectedUser]) => {
				return userAuctionData.find((x) => x.uid === currentSelectedUser);
			})
		);

		this.myAuctionData$ = this.userAuctionData$.pipe(
			takeUntil(this.destroyed$),
			withLatestFrom(this.store.select(currentUID), this.auction$, this.store.select(userRoles)),
			filter(([userData, uid, auction, _]) => !!userData && userData.length > 0 && !!uid && !!auction),
			map(([userData, uid, auction, userRoles]) => {
				let user = userData.find((x) => x.uid === uid);

				if (user?.roster.length === auction?.totalTeamsPerManager && !this.seenAd) {
					this.seenAd = true;
					this.openAd(auction!!.eventId);
				}

				if (userRoles.find((x) => "admin") != null) {
					user = userData[0];
				}

				return user;
			})
		);

		this.store
			.select(currentEvent)
			.pipe(
				filter((x) => !!x),
				take(1)
			)
			.subscribe((event) => {
				if (this.isBracketOnly(event!!)) {
					this.getBracketMatchups();
				} else {
					this.getTeamsByDivision();
				}
			});
	}

	loadLiveAuctionData() {
		this.currentBidData$ = combineLatest([this.auction$, this.teams$, this.store.select(users)]).pipe(
			filter(([auction, teams, users]) => auction?.status === "In Progress" && !!teams),
			tap(([auction, teams, users]) => {
				if (auction?.currentTeam && this.currentTeam != auction.currentTeam) {
					if (this.currentTeam !== "" && (auction.mostRecentResult === "Won" || auction.mostRecentResult === "Skip")) {
						this.showResult = true;
						this.resultTeam = teams!!.find((x) => x.id === this.currentTeam);

						if (auction.mostRecentResult === "Won") {
							this.resultBid = auction.pricePerTeam.get(this.currentTeam);

							auction.rosters.forEach((teams, uid) => {
								if (teams.includes(this.currentTeam)) {
									this.resultOwner = users.find((user) => user.uid === uid);
								}
							});
						} else if (auction.mostRecentResult === "Skip") {
							this.resultOwner = undefined;
						}

						timer(3500)
							.pipe(take(1))
							.subscribe((x) => {
								this.showResult = false;
							});
					}

					this.currentTeam = auction.currentTeam;
					document.getElementById(this.currentTeam)?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
					this.customBid.setValue(1);
				}
				let nextBid = (auction?.currentBid || 0) + 1;
				if (auction?.currentBid && this.customBid && nextBid > this.customBid.value) {
					this.customBid.setValue(nextBid);
				}
			}),
			map(([auction, teams, users]) => {
				if (teams == null) {
					throw new Error("Teams are missing");
				}

				return {
					currentTeam: teams.find((t) => t.id === auction?.currentTeam),
					currentBid: auction?.currentBid || 0,
					currentOwner: users.find((u) => u.uid === auction?.currentOwner),
					notInterestedOwners: auction?.notInterestedOwners ?? []
				};
			})
		);

		// Delay submissions after auction changes occur
		combineLatest([this.auction$, this.store.select(currentUID)])
			.pipe(
				filter(([a, uid]) => !!a && this.isInterested(a.notInterestedOwners, uid) && a.mostRecentResult !== "Pass"),
				tap(() => (this.bidDelay = true)),
				switchMap(() => timer(1500))
			)
			.subscribe(() => (this.bidDelay = false));

		this.bidTimerSeconds$ = combineLatest([this.auction$, this.store.select(timeOffset)]).pipe(
			filter(([auction]) => auction?.status === "In Progress"),
			switchMap(([auction, timeOffset]) =>
				interval(1000).pipe(
					map(() => {
						let timeLeft = 0;
						if (auction?.expirationTime) {
							let diff = Math.floor((auction.expirationTime.getTime() - new Date().getTime() + timeOffset) / 1000);
							if (diff > 0) {
								timeLeft = diff;
							}
						}
						return timeLeft;
					})
				)
			)
		);
	}

	fullRoster$(): Observable<Map<string, ITeam[]>> {
		return combineLatest([this.store.select(rosters), this.teams$]).pipe(
			takeUntil(this.destroyed$),
			map(([rosters, teams]) => {
				if (teams == null) {
					throw new Error("Teams are missing");
				}
				``;
				let fullRoster = new Map<string, ITeam[]>();
				rosters.forEach((roster, uid) => {
					let teamList: ITeam[] = [];
					roster.forEach((m) => {
						let team = teams.find((t) => t.id === m);
						if (team) {
							teamList.push(team);
						}
					});
					fullRoster.set(uid, teamList);
				});
				return fullRoster;
			})
		);
	}

	getUserAuctionData(): void {
		this.userAuctionData$ = combineLatest([
			this.store.select(managers),
			this.store.select(currentUID),
			this.store.select(budgets),
			this.fullRoster$(),
			this.store.select(pricePerTeam),
			this.store.select(users),
			this.store.select(managerColors),
			this.totalTeamsPerManager$
		]).pipe(
			filter(([managers, currentUid]) => !!managers && managers.length > 0 && !!currentUid),
			map(([managers, currentUid, budgets, rosters, prices, users, managerColors, totalTeams]) => {
				let userData: AuctionUserData[] = [];
				let newManagerList = [...(managers || [])];

				//set currentUser to first
				let userIndex = newManagerList?.findIndex((manager) => manager === currentUid);
				if (userIndex >= 0) {
					newManagerList.splice(userIndex, 1);
					newManagerList.unshift(managers?.find((x) => x === currentUid)!!);
				}

				newManagerList.forEach((manager) => {
					let userInfo = users.find((u) => u.uid === manager);
					let budget = budgets.has(manager) ? budgets.get(manager)!! : 0;
					let teamData = rosters.has(manager) ? rosters.get(manager) : [];
					let selected = currentUid == manager;
					let roster: ITeamData[] = [];
					for (let team of teamData || []) {
						roster.push({
							team: team,
							color: managerColors?.get(manager),
							price: prices?.get(team.id) || 0
						});
					}

					userData.push({
						user: userInfo,
						amountAvailable: budget,
						roster: roster,
						color: managerColors?.get(manager),
						maxBid: (budget || 0) - (totalTeams - (roster.length + 1)),
						selected: selected,
						placeholderArray: new Array(totalTeams),
						uid: manager
					});
				});

				return userData;
			})
		);
	}

	getTeamsByDivision(): void {
		this.teamsDataByDivision$ = combineLatest([this.userAuctionData$, this.teamsByDivision$, this.store.select(currentTeam)]).pipe(
			takeUntil(this.destroyed$),
			map(([userData, allTeamsByDivision, currentTeam]) => {
				let sortedMap = new Map<string, ITeamData[]>();
				let teamData: ITeamData[] = [];

				for (let user of userData) {
					teamData = teamData.concat(user.roster);
				}

				allTeamsByDivision.forEach((teams, division) => {
					sortedMap.set(
						division,
						teams.map((team) => {
							let teamInfo = teamData.find((d) => d.team.id === team.id);
							if (teamInfo) {
								return teamInfo;
							} else if (team.id === currentTeam) {
								return { team: team, color: "focus" } as ITeamData;
							} else {
								return { team: team } as ITeamData;
							}
						})
					);
				});

				sortedMap.forEach((teams) => {
					teams.sort((a, b) => (a.team?.seed ?? 0) - (b.team?.seed ?? 0));
				});

				return sortedMap;
			})
		);
	}

	getBracketMatchups(): void {
		this.bracketMatchups$ = combineLatest([
			this.userAuctionData$,
			this.teams$,
			this.event$,
			this.store.select(currentTeam),
			this.store.select(currentGroup)
		]).pipe(
			takeUntil(this.destroyed$),
			filter(([userAuctionData, teams, event, _, group]) => !!userAuctionData && !!teams && !!event && !!group),
			map(([userAuctionData, teams, event, currentTeam, group]) => {
				const isSeries = event!!.phases[0].isSeriesBased;

				let games: any[] = [];
				if (isSeries) {
					// games = event!!.series; TODO
				} else {
					// games = event!!.games;
				}

				return games.map((game) => {
					const team1 = teams!!.find((x) => x.id === game.team1Id);
					const team2 = teams!!.find((x) => x.id === game.team2Id);
					let gameValue = -1;

					let team1Owner: ITeamOwner | undefined = undefined;
					let team2Owner: ITeamOwner | undefined = undefined;
					let owner1AuctionData: AuctionUserData | undefined;
					let owner2AuctionData: AuctionUserData | undefined;

					userAuctionData.forEach((userData) => {
						userData.roster.forEach((roster) => {
							if (game.team1Id && roster.team.id === game.team1Id) {
								owner1AuctionData = userData;
							} else if (game.team2Id && roster.team.id === game.team2Id) {
								owner2AuctionData = userData;
							}
						});
					});

					team1Owner = owner1AuctionData
						? {
								color: owner1AuctionData?.color || "",
								price: owner1AuctionData?.roster.find((x) => x.team.id === game.team2Id)?.price || 0,
								groupScore: 0,
								owner: owner1AuctionData?.user?.displayName || "",
								groupRank: 0,
								uid: owner1AuctionData?.uid
							}
						: undefined;

					team2Owner = owner2AuctionData
						? {
								color: owner2AuctionData?.color || "",
								price: owner2AuctionData?.roster.find((x) => x.team.id === game.team2Id)?.price || 0,
								groupScore: 0,
								owner: owner2AuctionData?.user?.displayName || "",
								groupRank: 0,
								uid: owner2AuctionData?.uid
							}
						: undefined;

					if (currentTeam != null) {
						if (team1?.id === currentTeam) {
							team1Owner = {
								price: 0,
								color: "focus",
								groupRank: 0,
								uid: "",
								owner: "",
								groupScore: 0
							};
						}

						if (team2?.id === currentTeam) {
							team2Owner = {
								price: 0,
								color: "focus",
								groupRank: 0,
								uid: "",
								owner: "",
								groupScore: 0
							};
						}
					}

					if (currentTeam != null && team1?.id === currentTeam) {
						team1Owner = {
							price: 0,
							color: "focus",
							groupRank: 0,
							uid: "",
							owner: "",
							groupScore: 0
						};
					}

					if (currentTeam != null && team2?.id === currentTeam) {
						team2Owner = {
							price: 0,
							color: "focus",
							groupRank: 0,
							uid: "",
							owner: "",
							groupScore: 0
						};
					}

					if (group) {
						const totalPot = group.numOfManagers * group.scoring.buyin;
						const teir = event?.tiers.find((x) => x.id === game.tierId);

						if (teir) {
							gameValue = determineGameValue(group, totalPot, game.tierId);
						}
					}

					return {
						id: game.id,
						gameStatus: game.status,
						winnerId: game.winnerId,
						startTime: game.startTime,
						tvProvider: game.tvProvider,
						round: game.round,
						groupType: group?.type,
						scoreType: group?.scoreType,
						team1: team1
							? {
									...team1,
									score: game.team1Score,
									isEliminated: false // never show eliminated on auction
								}
							: null,
						team2: team2
							? {
									...team2,
									score: game.team2Score,
									isEliminated: false // never show eliminated on auction
								}
							: null,
						team1Owner: team1Owner,
						team2Owner: team2Owner,
						tierId: game.tierId,
						regionId: game.regionId,
						value: gameValue,
						groupStatus: group?.status,
						eventStatus: event!!.status,
						displayOrder: game.displayOrder,
						clinchingAmount: game.clinchingAmount,
						subMatchups: []
					} as IMatchup;
				});
			})
		);
	}

	pass(): void {
		this.store.dispatch(notInterested());
	}

	submitBid(bidAmount: number | undefined) {
		if (bidAmount) {
			this.store.dispatch(submitBid({ bid: bidAmount }));
		}
	}

	startAuction(auctionId: string) {
		this.store.dispatch(startAuction({ auctionId: auctionId }));
	}

	selectUser(event: MatSelectChange): void {
		this.currentSelectedUser$.next(event.value);
	}

	returnToGroup(eventId: string, groupId: string) {
		this.router.navigate(["event/" + eventId + "/" + groupId]);
	}

	openGroupInfo(eventId: string, groupId: string): void {
		this.dialog.open(GroupInfoDialog, { data: { eventId, groupId } });
	}

	isBracketOnly(event: IEvent): boolean {
		return event.phases.length === 1 && event.phases[0].viewType === "Bracket";
	}

	isInterested(notInterestedOwners: string[], uid: string): boolean {
		return !notInterestedOwners?.includes(uid);
	}

	openAd(eventId: string): void {
		this.dialog.open(AdDialogComponent, { data: eventId });
	}

	onTabChange(event: MatTabChangeEvent): void {
		this.mobileTabIndex = event.index;
	}

	openRoster(userInfo: AuctionUserData, isMobile: boolean): void {
		if (isMobile) {
			this.mobileTabIndex = 1;
		}

		this.currentSelectedUser$.next(userInfo.uid);
	}

	openTutorial(auction: IAuction, tutorialVersion: any): void {
		this.dialog
			.open(AuctionTutorialDialogComponent, {
				data: { numberOfTeams: auction?.totalTeams, rosterSize: auction?.totalTeamsPerManager }
			})
			.afterClosed()
			.pipe(
				take(1),
				filter((result) => result),
				withLatestFrom(this.currentUID$)
			)
			.subscribe(([_, uid]) => {
				if (tutorialVersion) {
					this.userService.saveTutorialVersion(uid, +tutorialVersion);
				}
			});
	}
}

import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { isFuture } from "date-fns";
import { Observable, map, takeUntil } from "rxjs";
import { IGroup } from "src/app/models/group";
import { ITeam } from "src/app/models/team";
import { SnackbarService } from "src/app/services/snackbar.service";
import { BaseComponent } from "src/app/shared/components/base.component";
import { getTeamsByEventId } from "src/app/state/event/event.selectors";
import { getStandingByGroupId } from "src/app/state/group/group.selectors";

@Component({
	selector: "am-group-card",
	templateUrl: "./group-card.component.html",
	styleUrls: ["./group-card.component.scss"]
})
export class GroupCardComponent extends BaseComponent implements OnInit {
	@Input() group: IGroup;
	@Input() eventId: string;
	@Input() type: string;
	@Input() uid: string;
	@Input() isMobile: boolean | null = false;

	teams$: Observable<ITeam[] | undefined>;
	standing$: Observable<Map<string, number>>;

	constructor(private router: Router, private store: Store, private snackbar: SnackbarService) {
		super();
	}

	ngOnInit(): void {
		this.teams$ = this.store.select(getTeamsByEventId(this.eventId)).pipe(takeUntil(this.destroyed$));
		this.standing$ = this.store.select(getStandingByGroupId(this.group.id));
	}

	navigateToGroup(group: IGroup): void {
		if (group.status === "Ready" || group.status === "In Auction") {
			this.router.navigate(["auction", group.auctionId]);
		} else {
			this.router.navigate(["event", this.eventId, group.id]);
		}
	}

	determineGroupState(): string {
		// Missing Managers
		if (this.group.managerIds.length !== this.group.numOfManagers) {
			return "WaitingOnManagers";
		}

		// Auction hasn't started
		if (this.group.auctionDate && this.isAuctionInFuture(this.group)) {
			return "WaitingOnAuction";
		}

		// Auction is Currently happening
		if (
			this.group.status === "In Auction" ||
			(this.group.auctionDate && !this.isAuctionInFuture(this.group) && this.group.status === "Ready")
		) {
			return "AuctionHappening";
		}

		if (this.group.status === "In Progress") {
			return "InProgress";
		}

		return "Error";
	}

	getCurrentUserScore(): number {
		if (this.uid && this.group && this.group.rosters.has(this.uid)) {
			const currentUserRoster = this.group.rosters.get(this.uid)!!;
			let score = 0;

			currentUserRoster.forEach((team) => {
				score += this.group.scores.has(team) ? this.group.scores.get(team)!! : 0;
			});

			return score;
		}

		return -1;
	}

	getCurrentUsersTeams(teams: ITeam[]): ITeam[] {
		if (this.uid && this.group && this.group.rosters.has(this.uid)) {
			const currentUserRoster = this.group.rosters.get(this.uid)!!;

			return teams.filter((x) => currentUserRoster.includes(x.id)).sort((a, b) => Number(a.isEliminated) - Number(b.isEliminated));
		}

		return [];
	}

	isAuctionInFuture = (group: IGroup) => isFuture(group.auctionDate!!);

	copyInviteLink(event: any, group: IGroup): void {
		event.stopPropagation();
		navigator.clipboard.writeText(`https://auctionmadness.com/invite?inviteId=${group?.sharedInviteId}`);
		this.snackbar.success("Copied the invite link to the clipboard.");
	}
}

import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { BehaviorSubject, combineLatest, filter, map, Observable, startWith, takeUntil, tap } from "rxjs";
import { IGameIntegrationInfo } from "src/app/models/game";
import { ITeamIntegrationInfo } from "src/app/models/team";
import { IntegrationService } from "src/app/services/integration.service";
import { LoaderComponent } from "../../components/loader/loader.component";
import { MatButtonModule } from "@angular/material/button";
import { MatTabsModule } from "@angular/material/tabs";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatListModule, MatSelectionList, MatSelectionListChange } from "@angular/material/list";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { BaseComponent } from "../../components/base.component";

@Component({
	selector: "am-integration-dialog",
	standalone: true,
	imports: [
		CommonModule,
		LoaderComponent,
		MatDialogModule,
		MatButtonModule,
		MatTabsModule,
		MatCheckboxModule,
		MatListModule,
		MatSelectModule,
		MatFormFieldModule,
		ReactiveFormsModule
	],
	templateUrl: "./integration-dialog.component.html",
	styleUrl: "./integration-dialog.component.scss"
})
export class IntegrationDialogComponent extends BaseComponent implements OnInit {
	@ViewChild("gameList", { static: false }) private gameList: MatSelectionList;
	@ViewChild("teamList", { static: false }) private teamList: MatSelectionList;

	gameStatus = new FormControl(null);
	gameRound = new FormControl(null);

	loading$ = new BehaviorSubject<boolean>(true);
	integrationData$: Observable<{
		teamSummaries: ITeamIntegrationInfo[];
		gameSummaries: IGameIntegrationInfo[];
	}>;
	filterGames$: Observable<IGameIntegrationInfo[]>;

	selectedTeams: any[] | null = [];
	selectedGames: any[] | null = [];

	constructor(
		public dialogRef: MatDialogRef<IntegrationDialogComponent>,
		private integrationService: IntegrationService,

		@Inject(MAT_DIALOG_DATA) private data: { leagueId: string; seasonId: string; type: string; sport: string }
	) {
		super();
	}

	ngOnInit(): void {
		this.integrationData$ = this.integrationService
			.getTeamAndGameData(this.data.leagueId, this.data.seasonId, this.data.type, this.data.sport)
			.pipe(tap(() => this.loading$.next(false)));

		this.filterGames$ = combineLatest([
			this.integrationData$,
			this.gameStatus.valueChanges.pipe(startWith(undefined)),
			this.gameRound.valueChanges.pipe(startWith(undefined))
		]).pipe(
			takeUntil(this.destroyed$),
			filter(([data, ,]) => !!data && data.gameSummaries.length > 0),
			map(([data, gameStatus, gameRound]) => {
				let games = data.gameSummaries;

				if (gameStatus) {
					games = games.filter((x) => x.status === gameStatus);
				}

				if (gameRound) {
					games = games.filter((x) => x.round === gameRound);
				}

				return games;
			})
		);
	}

	teamSelected(event: MatSelectionListChange): void {
		this.selectedTeams = event.source._value;
	}

	gameSelected(event: MatSelectionListChange): void {
		this.selectedGames = event.source._value;
	}

	selectAllGames(): void {
		var options = this.gameList.selectAll();
		this.selectedGames = options.map((x) => x.value);
	}

	clearAllGames(): void {
		this.gameList.deselectAll();
		this.selectedGames = [];
	}

	selectAllTeams(): void {
		var options = this.teamList.selectAll();
		this.selectedTeams = options.map((x) => x.value);
	}

	clearAllTeams(): void {
		this.teamList.deselectAll();
		this.selectedTeams = [];
	}

	getRounds(integrationData: IGameIntegrationInfo[]): string[] {
		return [...new Set(integrationData.map((x) => x.round))];
	}

	save(): void {
		this.dialogRef.close({
			teams: this.selectedTeams,
			games: this.selectedGames
		});
	}
}

import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { format } from "date-fns";
import { IGame } from "src/app/models/game";
import { IRegion } from "src/app/models/league";
import { ITeam } from "src/app/models/team";

@Component({
	selector: "am-game-edit-dialog",
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatSelectModule,
		MatInputModule,
		MatCheckboxModule,
		MatButtonModule,
		MatDialogModule
	],
	templateUrl: "./game-edit-dialog.component.html",
	styleUrl: "./game-edit-dialog.component.scss"
})
export class GameEditDialogComponent implements OnInit {
	form: FormGroup;
	game: IGame;
	teams: ITeam[];
	regions: IRegion[];

	constructor(
		public dialogRef: MatDialogRef<GameEditDialogComponent>,
		private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) private data: { game: IGame; teams: ITeam[]; regions: IRegion[] }
	) {
		this.game = this.data.game;
		this.teams = this.data.teams;
		this.regions = this.data.regions;
	}

	ngOnInit(): void {
		this.form = this.fb.group({
			id: this.fb.control(this.game?.id || crypto.randomUUID()),
			status: this.fb.control(this.game?.status || undefined),
			team1Id: this.fb.control(this.game?.team1Id || undefined),
			team2Id: this.fb.control(this.game?.team2Id || undefined),
			team1Score: this.fb.control(this.game?.team1Score || undefined),
			team2Score: this.fb.control(this.game?.team2Score || undefined),
			round: this.fb.control(this.game?.round || undefined),
			winnerId: this.fb.control(this.game?.winnerId || undefined),
			displayOrder: this.fb.control(this.game?.displayOrder || undefined),
			startTime: this.fb.control(this.game?.startTime ? format(this.game?.startTime, "yyyy-MM-dd HH:mm") : undefined),
			tvProvider: this.fb.control(this.game?.tvProvider || undefined),
			integrationId: this.fb.control(this.game?.integrationId || undefined),
			eliminateLoser: this.fb.control(this.game?.eliminateLoser || undefined),
			seriesId: this.fb.control(this.game?.seriesId || undefined),
			highlightLink: this.fb.control(this.game?.highlightLink || undefined),
			regionId: this.fb.control(this.game?.regionId || undefined)
		});
	}

	save(): void {
		const game = {
			...this.game,
			...this.form.value,
			startTime: this.form.value.startTime != null ? new Date(this.form.value.startTime) : null
		} as IGame;

		if (game.status === "Final" && game.team1Score && game.team2Score) {
			game.winnerId = game.team1Score > game.team2Score ? game.team1Id : game.team2Id;
		}

		this.dialogRef.close(game);
	}

	deleteGame(): void {
		this.dialogRef.close({ deleteGame: true, id: this.game.id });
	}
}

import { Component, Input, OnChanges } from "@angular/core";
import { IMatchup } from "src/app/models/game";
import { ScheduleMatchupBoxComponent } from "../schedule-matchup-box/schedule-matchup-box.component";
import { MatDivider } from "@angular/material/divider";
import { NgClass, NgIf, NgTemplateOutlet } from "@angular/common";
import { MatCard } from "@angular/material/card";
import { TooltipComponent } from "../tooltip/tooltip.component";

@Component({
    selector: "am-bracket-matchup-box-simple",
    templateUrl: "./bracket-matchup-box-simple.component.html",
    styleUrls: ["./bracket-matchup-box-simple.component.scss"],
    standalone: true,
    imports: [TooltipComponent, MatCard, NgClass, NgIf, MatDivider, NgTemplateOutlet, ScheduleMatchupBoxComponent]
})
export class BracketMatchupBoxSimpleComponent {
	@Input() matchup: IMatchup;
	@Input() isReverse: boolean;
}

import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable, combineLatest, filter, map, takeUntil } from "rxjs";
import { determineGameValue } from "src/app/models/constants";
import { IEvent, IPhase, ITier } from "src/app/models/event";
import { IGame, IGameBase, IMatchup, ISeries } from "src/app/models/game";
import { IGroup } from "src/app/models/group";
import { ITeam, ITeamOwner } from "src/app/models/team";
import { IUser } from "src/app/models/user";
import { BaseComponent } from "src/app/shared/components/base.component";
import { isMobile } from "src/app/state/app/app.selectors";
import { setSelectedPhase } from "src/app/state/event/event.actions";
import { currentEvent, selectedPhase, teams } from "src/app/state/event/event.selectors";
import { currentGroup, currentGroupStandings } from "src/app/state/group/group.selectors";
import { getUsersData } from "src/app/state/user/user.actions";
import { users } from "src/app/state/user/user.selectors";

@Component({
	selector: "am-event-schedule",
	templateUrl: "./event-schedule.component.html",
	styleUrls: ["./event-schedule.component.scss"]
})
export class EventScheduleComponent extends BaseComponent implements OnInit {
	event$: Observable<IEvent | undefined>;
	group$: Observable<IGroup | undefined>;
	currentPhase$: Observable<IPhase | undefined>;
	selectedGames$: Observable<IGame[]>;
	selectedSeries$: Observable<ISeries[]>;
	selectedMatchups$: Observable<IMatchup[]>;
	isMobile$: Observable<boolean>;
	users$: Observable<IUser[]>;

	selectedTierIds$ = new BehaviorSubject<string[] | undefined>(undefined);
	managerFilter$ = new BehaviorSubject<Map<string, boolean>>(new Map());

	isFilterOpen = false;

	constructor(private store: Store, private sanitizer: DomSanitizer) {
		super();
	}

	ngOnInit(): void {
		this.event$ = this.store.select(currentEvent).pipe(takeUntil(this.destroyed$));
		this.group$ = this.store.select(currentGroup).pipe(takeUntil(this.destroyed$));
		this.isMobile$ = this.store.select(isMobile).pipe(takeUntil(this.destroyed$));
		this.users$ = this.store.select(users).pipe(takeUntil(this.destroyed$));
		this.currentPhase$ = this.store.select(selectedPhase).pipe(takeUntil(this.destroyed$));

		combineLatest([this.event$, this.currentPhase$])
			.pipe(
				filter((event, currentPhase) => !!event && !!currentPhase),
				takeUntil(this.destroyed$)
			)
			.subscribe(([event, currentPhase]) => {
				const isCurrentPhaseBracket = currentPhase!!.viewType === "Bracket";

				const currentTier = event!!.tiers.find((x) => x.isCurrent);
				const tierIds: string[] = [];

				if (isCurrentPhaseBracket) {
					const allBracketTierIds = event?.tiers.filter((x) => x.phaseId === currentPhase!!.id).map((x) => x.id);

					if (allBracketTierIds) {
						tierIds.push(...allBracketTierIds);
					}
				} else if (currentTier) {
					tierIds.push(currentTier!!.id);
				}

				this.selectedTierIds$.next(tierIds);
			});

		this.selectedGames$ = combineLatest([this.event$, this.selectedTierIds$]).pipe(
			filter(([event, selectedTierIds]) => {
				return !!event && !!selectedTierIds && selectedTierIds.length > 0;
			}),
			takeUntil(this.destroyed$),
			map(([event, selectedTierIds]) => {
				return event!!.games.filter((x) => selectedTierIds?.includes(x.tierId));
			})
		);

		this.selectedSeries$ = combineLatest([this.event$, this.selectedTierIds$]).pipe(
			filter(([event, selectedTierIds]) => {
				return !!event && !!selectedTierIds && selectedTierIds.length > 0;
			}),
			takeUntil(this.destroyed$),
			map(([event, selectedTierIds]) => {
				return event!!.series?.filter((x) => selectedTierIds?.includes(x.tierId));
			})
		);

		this.group$
			.pipe(
				takeUntil(this.destroyed$),
				filter((group) => !!group)
			)
			.subscribe((group) => {
				this.store.dispatch(getUsersData({ userIds: group!!.managerIds }));

				const mangaerFilterInit = new Map<string, boolean>();

				group?.managerIds.forEach((managerId) => {
					mangaerFilterInit.set(managerId, true);
				});

				this.managerFilter$.next(mangaerFilterInit);
			});

		this.getMatchups();
	}

	getUserById(uid: string, users: IUser[]): string {
		return users.find((x) => x.uid === uid)?.displayName ?? "Unknown Manager";
	}

	isBracket(selectedTierIds: string[], event: IEvent): boolean {
		const bracketPhase = event.phases.find((x) => x.viewType === "Bracket");

		if (!bracketPhase) {
			return false;
		}

		const selectedTiers = event.tiers.filter((x) => selectedTierIds.includes(x.id));

		return selectedTiers[0].phaseId === bracketPhase.id;
	}

	getTiers(event: IEvent, tierIds: string[]): ITier[] {
		return event.tiers.filter((x) => tierIds.includes(x.id)).sort((a, b) => a.order - b.order);
	}

	updateManagerFilter(managerId: string, show: boolean): void {
		const managerFilterMap = this.managerFilter$.value;

		managerFilterMap.set(managerId, show);

		this.managerFilter$.next(managerFilterMap);
	}

	getMatchups(): void {
		this.selectedMatchups$ = combineLatest([
			this.users$,
			this.selectedGames$,
			this.store.select(teams),
			this.group$,
			this.store.select(currentGroupStandings),
			this.managerFilter$,
			this.event$,
			this.selectedSeries$,
			this.currentPhase$
		]).pipe(
			takeUntil(this.destroyed$),
			filter(([users, selectedGames, teams, _, currentGroupStandings, managerFilter, event, selectedSeries, currentPhase]) => {
				return (selectedGames.length > 0 || selectedSeries.length > 0) && teams.length > 0 && !!event && !!currentPhase;
			}),
			map(([users, selectedGames, teams, group, standings, managerFilter, event, selectedSeries, currentPhase]) => {
				const managersToShow: string[] = [];

				managerFilter.forEach((value, key) => {
					if (value) {
						managersToShow.push(key);
					}
				});

				let games: any[] = [];

				// There is a group and the group has auctions
				if (group && group.status === "In Progress" && managersToShow.length !== group.numOfManagers) {
					const teamsToShow: string[] = [];

					group.rosters.forEach((value, key) => {
						if (managersToShow.includes(key)) {
							teamsToShow.push(...value);
						}
					});

					if (currentPhase?.isSeriesBased) {
						games = selectedSeries.filter(
							(x) => (x.team1Id && teamsToShow.includes(x.team1Id)) || (x.team2Id && teamsToShow.includes(x.team2Id))
						);
					} else {
						games = selectedGames.filter(
							(x) => (x.team1Id && teamsToShow.includes(x.team1Id)) || (x.team2Id && teamsToShow.includes(x.team2Id))
						);
					}
				} else {
					if (currentPhase?.isSeriesBased) {
						games = selectedSeries;
					} else {
						games = selectedGames;
					}
				}

				return games
					.map((game: any) =>
						this.createMatchup(game, group, standings, teams, users, event!!, currentPhase?.isSeriesBased ?? false)
					)
					.sort((a, b) => {
						if (a.startTime === b.startTime) {
							return 0;
						}

						if (a.startTime == null) {
							return 1;
						}

						if (b.startTime == null) {
							return -1;
						}

						return a.startTime.getTime() - b.startTime.getTime();
					});
			})
		);
	}

	selectWeek(data: { tiers: ITier[]; phaseId: string }): void {
		this.store.dispatch(setSelectedPhase({ phaseId: data.phaseId }));

		this.selectedTierIds$.next(data.tiers.map((x) => x.id));
	}

	private createMatchup(
		game: any,
		group: IGroup | undefined,
		standings: Map<string, number>,
		teams: ITeam[],
		users: IUser[],
		event: IEvent,
		isSeries: boolean,
		numberOfSeriesGames?: number
	): IMatchup {
		const team1 = teams.find((x) => x.id === game.team1Id);
		const team2 = teams.find((x) => x.id === game.team2Id);

		let team1Owner: ITeamOwner | undefined = undefined;
		let team2Owner: ITeamOwner | undefined = undefined;
		let gameValue = -1;

		// We have a group and the Auction is complete
		if (group && (group.status === "In Progress" || group.status === "Complete")) {
			let owner1 = "";
			let owner2 = "";

			group.rosters.forEach((roster, owner) => {
				if (game.team1Id && roster.includes(game.team1Id!!)) {
					owner1 = owner;
				}
				if (game.team2Id && roster.includes(game.team2Id!!)) {
					owner2 = owner;
				}
			});

			team1Owner =
				owner1 !== ""
					? {
							color: group.managerColors.get(owner1) || "",
							price: group?.prices.get(game.team1Id || "") || 0,
							groupScore: group?.scores.get(game.team1Id || "") || 0,
							owner: users.find((u) => u.uid === owner1)?.displayName || "",
							groupRank: standings.get(owner1) ?? 0,
							uid: owner1
					  }
					: undefined;

			team2Owner =
				owner2 !== ""
					? {
							color: group.managerColors.get(owner2) || "",
							price: group?.prices.get(game.team2Id || "") || 0,
							groupScore: group?.scores.get(game.team2Id || "") || 0,
							owner: users.find((u) => u.uid === owner2)?.displayName || "",
							groupRank: standings.get(owner2) ?? 0,
							uid: owner2
					  }
					: undefined;
		}

		if (group) {
			const totalPot = group.numOfManagers * group.scoring.buyin;
			const teir = event?.tiers.find((x) => x.id === game.tierId);

			if (teir) {
				const phase = event?.phases.find((x) => x.id === teir.phaseId);

				if (phase) {
					gameValue = determineGameValue(group, totalPot, game.tierId, phase.id);
				}
			}
		}

		return {
			id: game.id,
			gameStatus: game.status,
			winnerId: game.winnerId,
			startTime: game.startTime,
			tvProvider: game.tvProvider,
			team1: team1
				? {
						...team1,
						score: game.team1Score
				  }
				: null,
			team2: team2
				? {
						...team2,
						score: game.team2Score
				  }
				: null,
			team1Owner: team1Owner,
			team2Owner: team2Owner,
			tierId: game.tierId,
			regionId: game.regionId,
			value: numberOfSeriesGames ? gameValue / numberOfSeriesGames : gameValue,
			groupStatus: group?.status,
			eventStatus: event!!.status,
			displayOrder: game.displayOrder,
			clinchingAmount: game.clinchingAmount,
			highlightLink: game.highlightLink ? this.sanitizer.bypassSecurityTrustResourceUrl(game.highlightLink) : undefined,
			subMatchups: isSeries
				? event.games
						.filter((x) => x.seriesId === game.id)
						.map((g) => this.createMatchup(g, group, standings, teams, users, event, false, game.totalNumberOfGames))
				: []
		} as IMatchup;
	}
}

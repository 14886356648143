import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, map } from "rxjs";
import { currentBreakPoint, getConfigAsBoolean, isMobile } from "src/app/state/app/app.selectors";
import { BaseComponent } from "src/app/shared/components/base.component";
import { AuthenticationService } from "src/app/services/authentication.service";
import { ActivatedRoute, NavigationBehaviorOptions, Router } from "@angular/router";
import { GenericLoginComponent } from "./components/generic-login/generic-login.component";
import { AnalyticsService } from "src/app/services/analytics.service";

@Component({
	selector: "am-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"]
})
export class LoginComponent extends BaseComponent implements OnInit {
	smallScreen$: Observable<boolean>;
	mode$: Observable<string>;
	showFacebookLogin: Observable<boolean>;
	isMobile$ = this.store.select(isMobile);

	constructor(
		private store: Store,
		private activatedRoute: ActivatedRoute,
		private authenticationService: AuthenticationService,
		private router: Router,
		private analyticService: AnalyticsService
	) {
		super();
	}

	ngOnInit(): void {
		this.showFacebookLogin = this.store.select(getConfigAsBoolean("AuthFacebookProvider"));

		this.smallScreen$ = this.store
			.select(currentBreakPoint)
			.pipe(map((breakpoint) => breakpoint === "XSmall" || breakpoint === "Small"));
		this.mode$ = this.activatedRoute.queryParams.pipe(map((params) => params["mode"]));

		this.authenticationService.checkRedirectResult().then((result) => {
			if (result?.user) {
				this.handleRedirect();
			}
		});
	}

	async loginWithGoogle() {
		await this.authenticationService.signInWithGoogle();
		await this.analyticService.logEvent("login", { provider: "Google" });
		this.handleRedirect();
	}

	async loginWithApple() {
		await this.authenticationService.signInWithApple();
		await this.analyticService.logEvent("login", { provider: "Apple" });
		this.handleRedirect();
	}

	async loginWithFacebook() {
		await this.authenticationService.signInWithFacebook();
		await this.analyticService.logEvent("login", { provider: "Facebook" });
		this.handleRedirect();
	}

	handleRedirect() {
		let redirect = this.activatedRoute?.snapshot?.queryParams?.["redirectPath"];
		if (redirect) {
			//this.router.navigateByUrl(test, test2);
			window.location.href = decodeURIComponent(redirect);
		} else {
			this.router.navigate(["home"]);
		}
	}

	passFunctionToChild(componentRef: Component) {
		if (componentRef instanceof GenericLoginComponent) {
			componentRef.handleRedirect = this.handleRedirect;
		}
	}

	goToHomepage() {
		this.router.navigate([""]);
	}
}

import { Component, Input } from "@angular/core";
import { ITier } from "src/app/models/event";
import { IMatchup } from "src/app/models/game";

@Component({
	selector: "am-league-bracket",
	templateUrl: "./league-bracket.component.html",
	styleUrls: ["./league-bracket.component.scss"]
})
export class LeagueBracketComponent {
	@Input() tiers: ITier[];
	@Input() matchups: IMatchup[];

	getMatchupsByRound(tierId: string): IMatchup[] {
		return this.matchups.filter((x) => x.tierId === tierId);
	}
}

<mat-dialog-content>
	<h2>Delete Account</h2>
	<div>Confirm you sure you would like to delete your account by entering your display name below</div>
	<ul>
		<li>Auction Madness will not be able to recover your account.</li>
		<li>All personal information will be removed</li>
		<li>A placholder name will be left behind in any active groups</li>
	</ul>
	<div>Display Name: {{ displayName }}</div>
	<mat-form-field class="email-input">
		<mat-label>Enter Display Name</mat-label>
		<input matInput [formControl]="emailConfirmation">
	</mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-stroked-button (click)="close()">Cancel</button>
	<button class="left" mat-raised-button color="warn" (click)="deleteAccount()" [disabled]="!emailConfirmation.valid">Delete Account</button>
</mat-dialog-actions>

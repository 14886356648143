import { NgModule } from "@angular/core";
import { GroupCardComponent } from "./group-card/group-card.component";
import { AllMaterialModule } from "src/app/shared/all-material-module";
import { CommonModule } from "@angular/common";
import { PipeModule } from "../pipes/pipe.module";
import { EditProfileDialog } from "./edit-profile-dialog/edit-profile-dialog.component";
import { SnackbarComponent } from "./snackbar/snackbar.component";
import { ReactiveFormsModule } from "@angular/forms";
import { ScheduleCalendarComponent } from "./schedule-calendar/schedule-calendar.component";
import { ScheduleMatchupBoxComponent } from "./schedule-matchup-box/schedule-matchup-box.component";
import { FooterComponent } from "./footer/footer.component";
import { MatIconRegistry } from "@angular/material/icon";
import { EventNavigationComponent } from "./event-navigation/event-navigation.component";
import { ChangeGroupDialogComponent } from "./change-group-dialog/change-group-dialog.component";
import { AppRoutingModule } from "src/app/app-routing.module";
import { GroupInfoDialog } from "./group-info-dialog/group-info-dialog.component";
import { SelectEventAdminDialog } from "./select-event-admin-dialog/select-event-admin-dialog.component";
import { PublicRoomComponent } from "./public-room/public-room.component";
import { GroupLookupComponent } from "./group-lookup/group-lookup.component";
import { TeamCardComponent } from "./team-card/team-card.component";
import { TooltipComponent } from "./tooltip/tooltip.component";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { GameEditDialogComponent } from "./game-edit-dialog/game-edit-dialog.component";
import { BracketMatchupBoxComponent } from "./bracket-matchup-box/bracket-matchup-box.component";
import { LegalDialogComponent } from "./legal-dialog/legal-dailog.component";
import { LoaderComponent } from "./loader/loader.component";
import { GroupAuditDialogComponent } from "./group-audit-dialog/group-audit-dialog.component";
import { BracketMatchupBoxSimpleComponent } from "./bracket-matchup-box-simple/bracket-matchup-box-simple.component";
import { ScoringOverviewComponent } from "./scoring-overview/scoring-overview.component";
import { PublicRoomDialogComponent } from "./scoring-overview-dialog/public-room-dialog.component";
import { LoadingButtonComponent } from "./loading-button/loading-button.component";
import { AuctionTutorialDialogComponent } from "./auction-tutorial-dialog/auction-tutorial-dialog.component";
import { AdDialogComponent } from "./ad-dialog/ad-dialog.component";
import { CompleteGroupCardComponent } from "./completed-group-card/completed-group-card.component";
import { SeriesEditDialogComponent } from "./serie-edit-dialog.component/series-edit-dialog.component";
import { BracketSeriesMatchupBoxComponent } from "./bracket-series-matchup-box/bracket-series-matchup-box.component";
import { SeriesComponentDialogComponent } from "./series-component-dialog/series-component-dialog.component";

const components = [
	AuctionTutorialDialogComponent,
	GroupCardComponent,
	EditProfileDialog,
	SnackbarComponent,
	ScheduleCalendarComponent,
	ScheduleMatchupBoxComponent,
	FooterComponent,
	EventNavigationComponent,
	ChangeGroupDialogComponent,
	GroupInfoDialog,
	SelectEventAdminDialog,
	PublicRoomComponent,
	GroupLookupComponent,
	TeamCardComponent,
	TooltipComponent,
	ConfirmationDialogComponent,
	GameEditDialogComponent,
	BracketMatchupBoxComponent,
	LegalDialogComponent,
	LoaderComponent,
	GroupAuditDialogComponent,
	BracketMatchupBoxSimpleComponent,
	ScoringOverviewComponent,
	PublicRoomDialogComponent,
	AdDialogComponent,
	CompleteGroupCardComponent,
	SeriesEditDialogComponent,
	BracketSeriesMatchupBoxComponent,
	SeriesComponentDialogComponent
];

@NgModule({
	declarations: [...components],
	exports: [...components, LoadingButtonComponent],
	imports: [AllMaterialModule, CommonModule, AppRoutingModule, PipeModule, ReactiveFormsModule, LoadingButtonComponent],
	providers: [MatIconRegistry]
})
export class SharedComponentsModule {}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, take, takeUntil } from "rxjs";
import { IEvent, IPhase, IPhaseScoring, IPublicRoom, IRegion, IScoringPreset, ITier } from "src/app/models/event";
import { IGame, IGameIntegrationInfo, ISeries } from "src/app/models/game";
import { ITeam, ITeamIntegrationInfo } from "src/app/models/team";
import { EventService } from "src/app/services/event.service";
import { BaseComponent } from "src/app/shared/components/base.component";
import { getConfigAsBoolean } from "src/app/state/app/app.selectors";
import { IntegrationPhaseWizardDialogComponent } from "./components/integration-phase-wizard-dialog/integration-phase-wizard-dialog.component";
import { GameEditDialogComponent } from "src/app/shared/components/game-edit-dialog/game-edit-dialog.component";
import { format, parseISO } from "date-fns";
import { Store } from "@ngrx/store";
import { IntegrationService } from "src/app/services/integration.service";
import { SportsDBIntegrationKey } from "src/app/models/constants";
import { SeriesEditDialogComponent } from "src/app/shared/components/serie-edit-dialog.component/series-edit-dialog.component";

@Component({
	selector: "am-event-admin",
	templateUrl: "./event-admin.component.html",
	styleUrls: ["./event-admin.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventAdminComponent extends BaseComponent implements OnInit {
	eventForm: FormGroup;
	originalEventInfo?: IEvent;
	sportOptions: string[];
	formatOptions: string[];
	updating: boolean = false;
	eventId: string;

	teamTableColumns: string[] = [
		"seed",
		"division",
		"location",
		"name",
		"displayName",
		"record",
		"abbreviation",
		"hexcode",
		"logo",
		"isEliminated",
		"useAbbreviation",
		"action"
	];
	typeOptions: string[] = ["Basketball", "Football", "Baseball", "Hockey", "Generic"];
	showAdvancedDetails: boolean = true;
	showIntegrationFeature$: Observable<boolean>;
	showIntegrationData: boolean = false;
	integrationData: any;

	teamsToImport: ITeamIntegrationInfo[] = [];
	teamsToMerge: ITeamIntegrationInfo[] = [];
	gamesToImport: IGameIntegrationInfo[] = [];

	editLayout: boolean = false;

	constructor(
		private activatedRoute: ActivatedRoute,
		private dialog: MatDialog,
		private fb: FormBuilder,
		private eventService: EventService,
		private cdr: ChangeDetectorRef,
		private router: Router,
		private store: Store,
		private integrationService: IntegrationService
	) {
		super();
	}

	test(): void {}

	ngOnInit(): void {
		this.eventId = this.activatedRoute.snapshot.params["eventId"];
		this.showIntegrationFeature$ = this.store.select(getConfigAsBoolean("IntegrationFeature"));

		if (this.eventId) {
			this.eventService.getByID(this.eventId).subscribe((event) => {
				this.originalEventInfo = event;
				this.setupForm(event);
			});
		} else {
			this.setupForm();
		}

		this.sportOptions = ["Mens College Basketball", "NHL", "NBA", "NFL", "League of Legends Worlds", "Womens College Basketball"];
	}

	private setupForm(event?: IEvent) {
		const gamesByTier: Record<string, FormArray> = {};
		const seriesByTier: Record<string, FormArray> = {};
		const games = event?.games?.reverse();
		const series = event?.series?.reverse();

		while (games?.length) {
			const game = games.pop();
			const tierId = game?.tierId || "";
			if (game) {
				if (!gamesByTier[tierId]) {
					gamesByTier[tierId] = this.fb.array([]);
				}
				gamesByTier[tierId].push(this.createGameGroup(game));
			}
		}

		while (series?.length) {
			const currentSeries = series.pop();
			const tierId = currentSeries?.tierId || "";
			if (currentSeries) {
				if (!seriesByTier[tierId]) {
					seriesByTier[tierId] = this.fb.array([]);
				}
				seriesByTier[tierId].push(this.createSeriesGroup(currentSeries));
			}
		}

		const tiersByPhase: Record<string, FormArray> = {};
		const tiers = event?.tiers?.sort((a, b) => b.order - a.order);

		while (tiers?.length) {
			const tier = tiers.pop();
			if (tier) {
				if (!tiersByPhase[tier?.phaseId || "none"]) {
					tiersByPhase[tier?.phaseId || "none"] = this.fb.array([]);
				}
				tiersByPhase[tier?.phaseId || "none"].push(this.createTierGroup(tier, gamesByTier[tier.id], seriesByTier[tier.id]));
			}
		}

		console.log(event);

		this.eventForm = this.fb.group({
			id: this.fb.control(event?.id),
			name: this.fb.control(event?.name || ""),
			year: this.fb.control(event?.year || new Date().getFullYear()),
			sport: this.fb.control(event?.sport || ""),
			type: this.fb.control(event?.type || ""),
			status: this.fb.control(event?.status || "New"),
			isPromotional: this.fb.control(event?.isPromotional || false),
			description: this.fb.control(event?.description || ""),
			minAuctionDatetime: this.fb.control(event?.minAuctionDatetime ? format(event?.minAuctionDatetime, "yyyy-MM-dd HH:mm") : ""),
			maxAuctionDatetime: this.fb.control(event?.maxAuctionDatetime ? format(event?.maxAuctionDatetime, "yyyy-MM-dd HH:mm") : ""),
			defaultManagers: this.fb.control(event?.defaultManagers || 0),
			integrationData: this.fb.group({
				leagueId: this.fb.control(event?.integrationData?.leagueId),
				seasonId: this.fb.control(event?.integrationData?.seasonId)
			}),
			teams: this.fb.array(event?.teams?.map((t) => this.createTeamGroup(t)) || []),
			phases: this.fb.array(
				event?.phases?.sort((a, b) => a.order - b.order).map((p) => this.createPhaseGroup(p, tiersByPhase[p.id])) || []
			),
			publicRooms: this.fb.array(event?.publicRooms?.map((p) => this.createPublicRoomGroup(p)) || []),
			scoring: this.fb.array(event?.scoringPresets?.map((s) => this.createScoringPresetGroup(s)) || []),
			bots: this.fb.array(event?.bots?.map((b) => this.createBotGroup(b)) || [])
		});

		const currentStatus = this.eventForm.get("status")?.value;
		if (currentStatus !== "New") {
			this.eventForm.get("year")?.disable();
			this.eventForm.get("type")?.disable();
			this.eventForm.get("sport")?.disable();
			this.showAdvancedDetails = false;
		}

		if (["New", "Created", "Draft"].includes(currentStatus) || (event?.minAuctionDatetime && event?.minAuctionDatetime > new Date())) {
			this.editLayout = true;
		}

		this.eventForm
			.get("sport")
			?.valueChanges.pipe(takeUntil(this.destroyed$))
			.subscribe((sport) => {
				let types: Record<string, string> = {
					"Womens College Basketball": "Basketball",
					"Mens College Basketball": "Basketball",
					"NHL": "Hockey",
					"NBA": "Basketball",
					"NFL": "Football"
				};

				this.eventForm.get("type")?.patchValue(types[sport]);
			});

		this.cdr.detectChanges();
	}

	get teams() {
		return this.eventForm?.get(["teams"]) as FormArray<FormGroup>;
	}

	get phases() {
		return this.eventForm?.get(["phases"]) as FormArray<FormGroup>;
	}

	get scoringPresets() {
		return this.eventForm?.get(["scoring"]) as FormArray<FormGroup>;
	}

	get bots() {
		return this.eventForm?.get(["bots"]) as FormArray<FormArray>;
	}

	get publicRooms() {
		return this.eventForm?.get(["publicRooms"]) as FormArray<FormGroup>;
	}

	phaseScoringGroups(scoringIndex: number): FormArray<FormGroup> {
		return this.eventForm.get(["scoring", scoringIndex, "phaseScorings"]) as FormArray<FormGroup>;
	}

	phaseScoringGroup(scoringIndex: number, phaseScoringIndex: number): FormGroup {
		return this.phaseScoringGroups(scoringIndex).get([phaseScoringIndex]) as FormGroup;
	}

	getRegions(phaseIndex: number) {
		return this.eventForm?.get(["phases", phaseIndex, "regions"]) as FormArray<FormGroup>;
	}

	getScoringTiers(scoringIndex: number, phaseScoringIndex: number) {
		return this.phaseScoringGroup(scoringIndex, phaseScoringIndex).get(["tiers"]) as FormArray<FormGroup>;
	}

	getPhaseIndeById(phaseId: string): number {
		let phaseIndex = -1;

		this.phases.controls.forEach((x, i) => {
			if (x.controls["id"].value === phaseId) {
				phaseIndex = i;
			}
		});

		return phaseIndex;
	}

	getTiers(phaseIndex: number) {
		return this.eventForm?.get(["phases", phaseIndex, "tiers"]) as FormArray<FormGroup>;
	}

	getTier(phaseIndex: number, tierIndex: number) {
		return this.eventForm?.get(["phases", phaseIndex, "tiers", tierIndex]) as FormGroup;
	}

	getGames(phaseIndex: number, tierIndex: number) {
		return this.eventForm?.get(["phases", phaseIndex, "tiers", tierIndex, "games"]) as FormArray<FormGroup>;
	}

	getNonSeriesGames(phaseIndex: number, tierIndex: number): IGame[] {
		return this.getGames(phaseIndex, tierIndex).value.filter((x) => !x.seriesId);
	}

	getSeriesGames(phaseIndex: number, tierIndex: number, seriesId: number): IGame[] {
		return this.getGames(phaseIndex, tierIndex).value.filter((x) => x.seriesId === seriesId);
	}

	getAllSeries(phaseIndex: number, tierIndex: number) {
		return this.eventForm?.get(["phases", phaseIndex, "tiers", tierIndex, "series"]) as FormArray<FormGroup>;
	}

	getGame(phaseIndex: number, tierIndex: number, gameIndex: number) {
		return this.eventForm?.get(["phases", phaseIndex, "tiers", tierIndex, "games", gameIndex]) as FormGroup;
	}

	getSeries(phaseIndex: number, tierIndex: number, seriesIndex: number) {
		return this.eventForm?.get(["phases", phaseIndex, "tiers", tierIndex, "series", seriesIndex]) as FormGroup;
	}

	defaultName() {
		return `${this.eventForm.get("year")?.value} ${this.eventForm.get("sport")?.value}`;
	}

	isBracketPhase(phaseIndex: number): boolean {
		const phase = this.eventForm?.get(["phases", phaseIndex]) as FormGroup;

		return phase.value?.viewType === "Bracket";
	}

	currentTabChanged(event: any) {
		if (event?.index === 2) {
			this.moveViewToCurrentTier();
		}
	}

	moveViewToCurrentTier() {
		(this.phases.value as IPhase[]).forEach((phase, index) => {
			for (let tier of this.eventForm?.get(["phases", index, "tiers"])?.value || []) {
				if (tier.isCurrent) {
					let ref = document?.querySelector("#" + tier.id);
					ref?.scrollIntoView();
				}
			}
		});
	}

	// Temporary
	getTeamData(teamId: string): ITeam | undefined {
		return this.teams.value.find((t) => t.id === teamId);
	}

	addPhase() {
		this.phases.push(this.createPhaseGroup());
	}

	openPhaseDialog() {
		let ref = this.dialog.open(IntegrationPhaseWizardDialogComponent, {
			data: {
				leagueId: this.eventForm.get(["integrationData", "leagueId"])?.value,
				seasonId: this.eventForm.get(["integrationData", "seasonId"])?.value,
				type: this.eventForm.get("type")?.value,
				sport: this.eventForm.get("sport")?.value
			}
		});

		ref.afterClosed()
			.pipe(take(1))
			.subscribe((data) => {
				if (data) {
					const response = data as {
						tiers: { displayName: string; games: IGameIntegrationInfo[] }[];
						teams: ITeamIntegrationInfo[];
					};
					let phase = {
						id: crypto.randomUUID(),
						name: "",
						order: this.phases.length,
						tierPrefix: "Day",
						viewType: "List"
					} as IPhase;
					let tiers: FormArray = this.fb.array([]);

					for (let teamData of response.teams) {
						this.teams.push(
							this.createTeamGroup({
								id: crypto.randomUUID(),
								name: teamData.name,
								location: teamData.location,
								displayName: teamData.displayName,
								abbreviation: teamData.abbreviation,
								integrations: teamData.integrations
							} as ITeam)
						);
					}
					const teams = this.teams.value as ITeam[];

					response.tiers.forEach((tierIntegration, index) => {
						let tier = {
							id: crypto.randomUUID(),
							displayName: tierIntegration.displayName,
							order: index,
							phaseId: phase.id
						} as Partial<ITier>;

						let games = this.fb.array(
							tierIntegration.games.map((game) =>
								this.createGameGroup({ ...this.convertIntegrationGameToGame(game, teams), tierId: tier.id })
							)
						);

						tiers.push(this.createTierGroup(tier, games));
					});

					this.phases.push(this.createPhaseGroup(phase, tiers));
				}
			});
	}

	removePhase(phaseIndex: number) {
		this.phases.removeAt(phaseIndex);
	}

	setTierSize(phaseIndex: number, event: any) {
		let newSize = event.target.value;
		let currentLength = this.getTiers(phaseIndex).length;
		while (currentLength < newSize) {
			this.addTier(phaseIndex);
			currentLength++;
		}

		while (currentLength >= newSize) {
			this.removeTier(phaseIndex, currentLength);
			currentLength--;
		}
	}

	addScoringTier(scoringIndex: number, phaseScoringIndex: number) {
		this.getScoringTiers(scoringIndex, phaseScoringIndex).push(this.createScoringTierGroup());
	}

	addPhaseScoring(scoringIndex: number) {
		this.phaseScoringGroups(scoringIndex).push(this.createPhaseScoringPreset());
	}

	removePhaseScoring(scoringIndex: number, phaseScoringIndex: number) {
		this.phaseScoringGroups(scoringIndex).removeAt(phaseScoringIndex);
	}

	removeScoringTier(scoringIndex: number, phaseScoringIndex: number, scoringTierIndex: number) {
		this.getScoringTiers(scoringIndex, phaseScoringIndex).removeAt(scoringTierIndex);
	}

	removeBot(botIndex: number) {
		this.bots.removeAt(botIndex);
	}

	removeScoring(scoringIndex: number) {
		this.scoringPresets.removeAt(scoringIndex);
	}

	addTier(phaseIndex: number) {
		this.getTiers(phaseIndex).push(this.createTierGroup());
	}

	removeTier(phaseIndex: number, tierIndex: number) {
		this.getTiers(phaseIndex).removeAt(tierIndex);
	}

	removeRegion(phaseIndex: number, regionIndex: number) {
		this.getRegions(phaseIndex).removeAt(regionIndex);
	}

	addTeam() {
		this.teams.push(this.createTeamGroup());
	}

	removeTeam(teamIndex: number) {
		this.teams.removeAt(teamIndex);
	}

	createPhaseScoringPreset(phaseScoringPreset?: IPhaseScoring): FormGroup {
		return this.fb.group({
			phaseId: this.fb.control(phaseScoringPreset?.phaseId || undefined),
			amountToMakePhase: this.fb.control(phaseScoringPreset?.amountToMakePhase || undefined),
			amountForAllTiers: this.fb.control(phaseScoringPreset?.amountForAllTiers || undefined),
			dynamic: this.fb.control(phaseScoringPreset?.dynamic || undefined),
			tiers: this.fb.array(
				phaseScoringPreset?.tiers?.map((m) => {
					return this.fb.group({
						tierId: this.fb.control(m.tierId || undefined),
						defaultValue: this.fb.control(m.defaultValue || undefined)
					});
				}) || []
			)
		});
	}

	createPublicRoomGroup(publicRoom: IPublicRoom): FormGroup {
		const group = this.fb.group({
			id: this.fb.control(publicRoom?.id || ""),
			name: this.fb.control(publicRoom.name || "", Validators.required),
			numOfManagers: this.fb.control(publicRoom.numOfManagers || 0, Validators.required),
			auctionDate: this.fb.control(format(publicRoom.auctionDate, "yyyy-MM-dd HH:mm") || "", Validators.required),
			scoringPresetId: this.fb.control(publicRoom.scoringPresetId || undefined),
			groupId: this.fb.control(publicRoom.groupId || undefined),
			isFull: this.fb.control(publicRoom.isFull || false),
			isAutoRecreate: this.fb.control(publicRoom.isAutoRecreate || false)
		});

		group.controls["groupId"].disable();
		group.controls["isFull"].disable();

		if (publicRoom.isFull) {
			group.disable();
		}

		return group;
	}

	botValue(bot: any, teamId: string): any {
		return bot.value.find((x: { teamId: string; value: number }) => x.teamId === teamId)?.value ?? 0;
	}

	updateBotValue(value: any, teamId: string, botIndex: number): void {
		Object.keys(this.bots.at(botIndex).controls).forEach((x: string) => {
			const bot = this.bots.at(botIndex).get(x);

			if (bot && bot.value.teamId === teamId) {
				bot.get("value")?.setValue(Number(value.target.value));
			}
		});

		this.eventForm.markAsDirty();
	}

	createBotGroup(bot: Map<string, number>): FormArray {
		const botArray: any[] = [];

		bot.forEach((value, key) => {
			botArray.push({ key, value });
		});

		const array = this.fb.array(
			botArray.map((x) =>
				this.fb.group({
					teamId: this.fb.control(x.key),
					value: this.fb.control(x.value)
				})
			) || []
		);

		return array;
	}

	createScoringPresetGroup(scoringPreset: IScoringPreset): FormGroup {
		return this.fb.group({
			id: this.fb.control(scoringPreset.id || crypto.randomUUID()),
			name: this.fb.control(scoringPreset.name || undefined),
			sortOrder: this.fb.control(scoringPreset.sortOrder || -1),
			isDefault: this.fb.control(scoringPreset.isDefault || false),
			buyIn: this.fb.control(scoringPreset.buyIn || 0),
			phaseScorings: this.fb.array(scoringPreset.phaseScorings.map((s) => this.createPhaseScoringPreset(s)))
		});
	}

	createTeamGroup(team?: ITeam): FormGroup {
		return this.fb.group({
			id: this.fb.control(team?.id || crypto.randomUUID()),
			seed: this.fb.control(team?.seed || undefined),
			location: this.fb.control(team?.location || ""),
			name: this.fb.control(team?.name || ""),
			displayName: this.fb.control(team?.displayName || ""),
			division: this.fb.control(team?.division || ""),
			hexcode: this.fb.control(team?.hexcode || ""),
			logo: this.fb.control(team?.logo || ""),
			record: this.fb.control(team?.record || ""),
			abbreviation: this.fb.control(team?.abbreviation || ""),
			integrations: this.fb.array(team?.integrations.map((x) => this.createTeamIntegrationGroup(x)) || []),
			isEliminated: this.fb.control(team?.isEliminated || false),
			useAbbreviation: this.fb.control(team?.useAbbreviation || false)
		});
	}

	createTeamIntegrationGroup(team: { id: string; type: string }): FormGroup {
		return this.fb.group({
			id: team.id,
			type: team.type
		});
	}

	createRegionGroup(region?: IRegion): FormGroup {
		return this.fb.group({
			id: this.fb.control(region?.id || ""),
			name: this.fb.control(region?.name || ""),
			location: this.fb.control(region?.location || "")
		});
	}

	createPhaseGroup(phase?: IPhase, tiers?: FormArray): FormGroup {
		const regionGroups = phase?.regions?.map((x) => {
			return this.createRegionGroup(x);
		});

		const regions = new FormArray<FormGroup>(regionGroups ?? []);

		return this.fb.group({
			id: this.fb.control(phase?.id || crypto.randomUUID()),
			name: this.fb.control(phase?.name || ""),
			viewType: this.fb.control(phase?.viewType || "List"),
			tierPrefix: this.fb.control(phase?.tierPrefix || "Tier"),
			tiers: tiers || this.fb.array([]),
			numberOfTeamsInPhase: this.fb.control(phase?.numberOfTeamsInPhase || undefined),
			isSeriesBased: this.fb.control(phase?.isSeriesBased || false),
			regions: regions
		});
	}

	createScoringTierGroup(): FormGroup {
		return this.fb.group({
			tierId: this.fb.control(undefined),
			defaultValue: this.fb.control(0)
		});
	}

	createTierGroup(tier?: Partial<ITier>, games?: FormArray, series?: FormArray): FormGroup {
		return this.fb.group({
			id: this.fb.control(tier?.id || crypto.randomUUID()),
			displayName: this.fb.control(tier?.displayName || ""),
			order: this.fb.control(tier?.order || ""),
			isCurrent: this.fb.control(tier?.isCurrent || false),
			games: games || this.fb.array([]),
			series: series || this.fb.array([])
		});
	}

	createGameGroup(game?: Partial<IGame>): FormGroup {
		return this.fb.group({
			id: this.fb.control(game?.id || crypto.randomUUID()),
			tierId: this.fb.control(game?.tierId || ""),
			status: this.fb.control(game?.status || "Upcoming"),
			startTime: this.fb.control(game?.startTime || null),
			team1Id: this.fb.control(game?.team1Id || ""),
			team1Score: this.fb.control(game?.team1Score || null),
			team2Id: this.fb.control(game?.team2Id || ""),
			team2Score: this.fb.control(game?.team2Score || null),
			winnerId: this.fb.control(game?.winnerId || ""),
			integrationId: this.fb.control(game?.integrationId || ""),
			regionId: this.fb.control(game?.regionId || null),
			tvProvider: this.fb.control(game?.tvProvider || null),
			displayOrder: this.fb.control(game?.displayOrder || null),
			seriesId: this.fb.control(game?.seriesId || null),
			highlightLink: this.fb.control(game?.highlightLink || "")
		});
	}

	createSeriesGroup(series?: Partial<ISeries>): FormGroup {
		return this.fb.group({
			id: this.fb.control(series?.id || crypto.randomUUID()),
			tierId: this.fb.control(series?.tierId || ""),
			status: this.fb.control(series?.status || "Upcoming"),
			team1Id: this.fb.control(series?.team1Id || ""),
			team1Score: this.fb.control(series?.team1Score || null),
			team2Id: this.fb.control(series?.team2Id || ""),
			team2Score: this.fb.control(series?.team2Score || null),
			winnerId: this.fb.control(series?.winnerId || ""),
			regionId: this.fb.control(series?.regionId || null),
			displayOrder: this.fb.control(series?.displayOrder || null),
			totalNumberOfGames: this.fb.control(series?.totalNumberOfGames || null),
			clinchingAmount: this.fb.control(series?.clinchingAmount || null)
		});
	}

	updateIntegrationInfo(integrationData: { leagueId: string; seasonId: string }) {
		this.eventForm.get("integrationData")?.patchValue(integrationData);
	}

	updateSeriesGroup(newSeriesChanges: ISeries, phaseIndex: number): void {
		const allTiers = JSON.parse(JSON.stringify(this.getTiers(phaseIndex).value));

		let currentTierIndex = allTiers.findIndex((t: ITier) => t.id === newSeriesChanges.tierId);
		let currentSeriesIndex = allTiers[currentTierIndex].series.findIndex((g: IGame) => g.id === newSeriesChanges.id);

		// Update Current Game
		const currentSeriesRef = this.getSeries(phaseIndex, currentTierIndex, currentSeriesIndex);
		currentSeriesRef.patchValue(newSeriesChanges);
		currentSeriesRef.markAsDirty();
	}

	updateGameGroup(newGameChanges: IGame, phaseIndex: number) {
		const allTiers = JSON.parse(JSON.stringify(this.getTiers(phaseIndex).value));

		let currentTierIndex = allTiers.findIndex((t: ITier) => t.id === newGameChanges.tierId);
		let currentGameIndex = allTiers[currentTierIndex].games.findIndex((g: IGame) => g.id === newGameChanges.id);
		let prevState = allTiers[currentTierIndex].games[currentGameIndex] as IGame;

		// Update Current Game
		const currentGameRef = this.getGame(phaseIndex, currentTierIndex, currentGameIndex);
		currentGameRef.patchValue(newGameChanges);
		currentGameRef.markAsDirty();

		// Do we need to update another team/ game?
		if (
			(newGameChanges?.status === "Final" || prevState?.status === "Final") &&
			(prevState?.status !== newGameChanges.status || prevState?.winnerId != newGameChanges.winnerId)
		) {
			let losingTeamId =
				newGameChanges?.winnerId && newGameChanges.winnerId === newGameChanges?.team1Id
					? newGameChanges.team2Id
					: newGameChanges?.team1Id;
			let losingTeamIndex = this.teams.value.findIndex((t) => t.id === losingTeamId);
			let losingTeam = this.teams.value[losingTeamIndex];

			// New Winner! losing team is eliminated, update next game with winner
			if (newGameChanges?.status === "Final" && newGameChanges.winnerId && newGameChanges.eliminateLoser) {
				// Eliminate Losing Team
				if (losingTeam) {
					this.teams.at(losingTeamIndex).controls["isEliminated"].patchValue(true);
				}
			}
		}
	}

	updateAllSeries(games: IGame[], phaseIndex: number, tierIndex: number): void {
		const series = this.getAllSeries(phaseIndex, tierIndex).value as ISeries[];
		const allTiers = JSON.parse(JSON.stringify(this.getTiers(phaseIndex).value));

		for (let s of series) {
			const seriesGames = games.filter((x) => x.seriesId);

			let currentTierIndex = allTiers.findIndex((t: ITier) => t.id === s.tierId);
			let currentSeriesIndex = allTiers[currentTierIndex].series.findIndex((g: ISeries) => g.id === s.id);

			const team1Wins = seriesGames.filter((x) => x.winnerId === s.team1Id);
			const team2Wins = seriesGames.filter((x) => x.winnerId === s.team2Id);

			s.team1Score = team1Wins.length;
			s.team2Score = team2Wins.length;

			if (s.team1Id && s.team1Score === s.clinchingAmount) {
				s.winnerId = s.team1Id;
			} else if (s.team2Id && s.team2Score === s.clinchingAmount) {
				s.winnerId = s.team2Id;
			}

			if (s.winnerId) {
				s.status = "Final";
			} else if (s.team1Score > 0 || s.team2Score > 0) {
				s.status = "In Progress";
			} else {
				s.status = "Upcoming";
			}

			const currentSeriesRef = this.getSeries(phaseIndex, currentTierIndex, currentSeriesIndex);
			currentSeriesRef.patchValue(s);
			currentSeriesRef.markAsDirty();
		}
	}

	verifyEventStatus() {
		let status;
		const allPhasesIds = this.phases.value;
		const allTiers = [];

		for (let i = 0; i < allPhasesIds.length; i++) {
			allTiers.push(...JSON.parse(JSON.stringify(this.getTiers(i).value)));
		}

		let tierStatuses = [];
		for (let tier of allTiers) {
			if (tier["games"].every((g: IGame) => g.status === "Final") && tier["series"].every((s: ISeries) => s.status === "Final")) {
				tierStatuses.push("Complete");
			} else if (tier["games"].some((g: IGame) => g.status !== "Upcoming")) {
				tierStatuses.push("Started");
			}
		}

		if (tierStatuses.some((t) => t === "Started")) {
			status = "Started";
		} else if (allTiers.length === tierStatuses.length && tierStatuses.every((t) => t === "Complete")) {
			status = "Complete";
		}

		if (status && status !== this.eventForm.get("status")?.value) {
			this.eventForm.get("status")?.patchValue(status);
		}
	}

	setEventStatus(status: string) {
		this.eventForm.get("status")?.patchValue(status);
		this.eventForm.get("status")?.markAsDirty();
	}

	updateIsPromotional(isPromotional: boolean): void {
		this.eventForm.get("isPromotional")?.patchValue(isPromotional);
		this.eventForm.get("isPromotional")?.markAsDirty();
	}

	createGame(phaseIndex: number, tierIndex: number, series?: ISeries, event?: any) {
		event.stopPropagation();
		let tierForm = this.getTier(phaseIndex, tierIndex);
		let newGame = this.createGameGroup({
			id: crypto.randomUUID(),
			tierId: tierForm.get("id")?.value,
			seriesId: series?.id,
			team1Id: series?.team1Id,
			team2Id: series?.team2Id
		} as IGame);

		this.getGames(phaseIndex, tierIndex)?.push(newGame);
		tierForm.markAsDirty();
	}

	createSeries(phaseIndex: number, tierIndex: number) {
		let tierForm = this.getTier(phaseIndex, tierIndex);
		let newSeries = this.createSeriesGroup({ id: crypto.randomUUID(), tierId: tierForm.get("id")?.value } as ISeries);
		this.getAllSeries(phaseIndex, tierIndex)?.push(newSeries);
		tierForm.markAsDirty();
	}

	createBot() {
		const map = new Map<string, number>();

		Object.keys(this.teams.controls).forEach((_, index) => {
			const teamId = this.teams.controls.at(index)?.get("id")?.value;
			map.set(String(teamId), 0);
		});

		this.bots.push(this.createBotGroup(map));
	}

	createScoringPreset() {
		let scoringPreset = this.createScoringPresetGroup({
			id: crypto.randomUUID(),
			name: "",
			sortOrder: -1,
			isDefault: false,
			buyIn: 0,
			phaseScorings: []
		});
		this.scoringPresets.push(scoringPreset);
	}

	createPublicRoom() {
		let newPublicRoom = this.createPublicRoomGroup({
			id: crypto.randomUUID(),
			auctionDate: new Date(),
			name: "New Public Room",
			scoringPresetId: "",
			numOfManagers: 1,
			groupId: undefined,
			isFull: false,
			isAutoRecreate: false
		});
		this.publicRooms?.push(newPublicRoom);
	}

	createRegion(phaseIndex: number): void {
		const phase = this.eventForm?.get(["phases", phaseIndex]) as FormGroup;
		const regions = phase.get("regions") as FormArray;
		regions.push(
			this.createRegionGroup({
				id: crypto.randomUUID(),
				name: "",
				location: ""
			})
		);
	}

	editSeries(series: ISeries, phaseIndex: number) {
		const regions: IRegion[] = [];

		this.getRegions(phaseIndex).controls.forEach((x) => {
			regions.push({
				id: x.get("id")?.value as string,
				name: x.get("name")?.value as string,
				location: x.get("location")?.value as string
			});
		});

		let ref = this.dialog.open(SeriesEditDialogComponent, {
			maxWidth: "800px",
			data: { series, teams: this.teams.value, regions: regions }
		});

		ref.afterClosed()
			.pipe(take(1))
			.subscribe((newSeriesChanges) => {
				if (newSeriesChanges) {
					this.updateSeriesGroup(newSeriesChanges, phaseIndex);
					this.verifyEventStatus();
					this.cdr.detectChanges();
				}
			});
	}

	editGame(game: IGame, phaseIndex: number) {
		const regions: IRegion[] = [];

		this.getRegions(phaseIndex).controls.forEach((x) => {
			regions.push({
				id: x.get("id")?.value as string,
				name: x.get("name")?.value as string,
				location: x.get("location")?.value as string
			});
		});

		let ref = this.dialog.open(GameEditDialogComponent, {
			maxWidth: "800px",
			data: { game, teams: this.teams.value, regions: regions }
		});

		ref.afterClosed()
			.pipe(take(1))
			.subscribe((newGameChanges) => {
				if (newGameChanges) {
					this.updateGameGroup(newGameChanges, phaseIndex);
					this.verifyEventStatus();
					this.cdr.detectChanges();
				}
			});
	}

	getRegionName(regionId: string | undefined, phaseIndex: number): string | undefined {
		const regions: IRegion[] = [];

		this.getRegions(phaseIndex).controls.forEach((x) => {
			regions.push({
				id: x.get("id")?.value as string,
				name: x.get("name")?.value as string,
				location: x.get("location")?.value as string
			});
		});

		return regions.find((x) => x.id === regionId)?.name;
	}

	deletSerie(serieIndex: number, tierIndex: number, phaseIndex: number) {
		this.getAllSeries(phaseIndex, tierIndex).removeAt(serieIndex);
		this.getTier(phaseIndex, tierIndex).markAsDirty();
		this.cdr.detectChanges();
	}

	deleteGame(gameIndex: number, tierIndex: number, phaseIndex: number, gameId?: string) {
		if (gameId) {
			gameIndex = this.getGames(phaseIndex, tierIndex).value.findIndex((x) => x.id === gameId);
		}
		this.getGames(phaseIndex, tierIndex).removeAt(gameIndex);
		this.getTier(phaseIndex, tierIndex).markAsDirty();
		this.cdr.detectChanges();
	}

	deleteRoom(roomIndex: number): void {
		this.publicRooms.removeAt(roomIndex);
		this.publicRooms.markAsDirty();
		this.cdr.detectChanges();
	}

	toggleIntegrationData(status?: boolean) {
		if (status === undefined) {
			this.showIntegrationData = !this.showIntegrationData;
		} else {
			this.showIntegrationData = status;
		}
	}

	integrationDataSelected(data: { teams: ITeamIntegrationInfo[]; games: IGameIntegrationInfo[] }) {
		this.teamsToImport = data.teams;
		this.teamsToMerge = [];
		this.gamesToImport = data.games;

		// Check for merges
		for (const team of this.teams.value as ITeam[]) {
			if (!team.integrations.find((x) => x.type === SportsDBIntegrationKey)?.id) {
				const matchingTeam = data.teams.find((t) => t.name === team.name);
				if (matchingTeam) {
					this.teamsToMerge.push(matchingTeam);
				}
			}
		}
	}

	// TODO: Integration is not updating....

	mergeTeams() {
		for (let teamData of this.teamsToMerge) {
			(this.teams.value as ITeam[]).forEach((team, index) => {
				if (!team.integrations.find((x) => x.type === SportsDBIntegrationKey)?.id && team.name === teamData.name) {
					this.teams.at(index).patchValue({
						id: team.id || crypto.randomUUID(),
						name: teamData.name,
						abbreviation: teamData.abbreviation,
						integrations: teamData.integrations,
						displayName: teamData.displayName,
						hexcode: "#" + teamData.hexcode,
						location: teamData.location,
						logo: team.logo ?? teamData.logo // keep our logo first
					});

					const integrations = this.teams.at(index).get(["integrations"]) as FormArray<FormGroup>;

					teamData.integrations.forEach((x) => {
						integrations.push(this.createTeamIntegrationGroup(x));
					});

					this.teams.markAsDirty();
				}
			});

			this.teamsToImport = this.teamsToImport.filter(
				(t) =>
					t.integrations.find((x) => x.type === SportsDBIntegrationKey) !==
					teamData.integrations.find((x) => x.type === SportsDBIntegrationKey)
			);
		}

		this.teamsToMerge = [];
	}

	importTeams() {
		for (let teamData of this.teamsToImport) {
			this.teams.push(
				this.createTeamGroup({
					id: crypto.randomUUID(),
					name: teamData.name,
					abbreviation: teamData.abbreviation,
					integrations: teamData.integrations,
					displayName: teamData.displayName,
					hexcode: "#" + teamData.hexcode,
					location: teamData.location,
					logo: teamData.logo
				} as ITeam)
			);
		}

		this.teamsToImport = [];
		this.teamsToMerge = [];
	}

	convertIntegrationGameToGame(game: IGameIntegrationInfo, teams: ITeam[], existingGameToMerge?: IGame) {
		const team1 = teams.find((t) => t.integrations.find((x) => x.type === SportsDBIntegrationKey)?.id === game.homeTeamIntegrationId);
		const team1Score = game.homeTeamScore;
		const team2 = teams.find((t) => t.integrations.find((x) => x.type === SportsDBIntegrationKey)?.id === game.awayTeamIntegrationId);
		const team2Score = game.awayTeamScore;

		let winnerId = undefined;
		if (game.status === "Final") {
			if (team1 && team1Score > team2Score) {
				winnerId = team1.id;
			}

			if (team2 && team2Score > team1Score) {
				winnerId = team2.id;
			}
		}

		return {
			...existingGameToMerge,
			id: game.id || existingGameToMerge?.id || crypto.randomUUID(),
			startTime: game.startTime != null ? new Date(game.startTime) : null,
			integrationId: game.integrationId,
			team1Id: team1?.id,
			team1Score: team1Score,
			team2Id: team2?.id,
			team2Score: team2Score,
			winnerId: winnerId,
			status: game.status,
			tvProvider: game.tvProvider,
			displayOrder: undefined
		} as Partial<IGame>;
	}

	importGames(phaseIndex: number, tierIndex: number) {
		const teams = this.teams.value as ITeam[];
		for (let game of this.gamesToImport) {
			this.getGames(phaseIndex, tierIndex).push(
				this.createGameGroup({
					...this.convertIntegrationGameToGame(game, teams),
					tierId: this.getTier(phaseIndex, tierIndex).value.id
				})
			);
		}

		this.gamesToImport = [];
	}

	mergeGames(phaseIndex: number, tierIndex: number) {
		const teams = this.teams.value as ITeam[];
		(this.getGames(phaseIndex, tierIndex).value as IGame[]).forEach((existingGame, index) => {
			if (!existingGame.integrationId) {
				// This should have a better check
				let teamA = teams.find((t) => t.id === existingGame.team1Id);
				let teamB = teams.find((t) => t.id === existingGame.team2Id);
				let game = this.gamesToImport.find(
					(newGame) =>
						newGame.awayTeamIntegrationId === teamA?.integrations.find((x) => x.type === SportsDBIntegrationKey)?.id &&
						newGame.homeTeamIntegrationId === teamB?.integrations.find((x) => x.type === SportsDBIntegrationKey)?.id
				);
				if (!game) {
					game = this.gamesToImport.find(
						(newGame) =>
							newGame.homeTeamIntegrationId === teamA?.integrations.find((x) => x.type === SportsDBIntegrationKey)?.id &&
							newGame.awayTeamIntegrationId === teamB?.integrations.find((x) => x.type === SportsDBIntegrationKey)?.id
					);
				}

				if (game) {
					this.updateGameGroup(this.convertIntegrationGameToGame(game, teams, existingGame) as IGame, phaseIndex);
					this.gamesToImport = this.gamesToImport.filter((g) => g.integrationId != game?.integrationId);
				}
			}
		});
	}

	refreshGamesInTier(phaseIndex: number, tierIndex: number) {
		const teams = this.teams.value as ITeam[];
		const games = this.getGames(phaseIndex, tierIndex).value as IGame[];

		const leagueId = this.eventForm.get(["integrationData", "leagueId"])?.value;
		const seasonId = this.eventForm.get(["integrationData", "seasonId"])?.value;

		this.integrationService
			.getGamesByIds(
				leagueId,
				seasonId,
				games.map((g) => g.integrationId)
			)
			.pipe(take(1))
			.subscribe((gameIntegrations) => {
				for (let gameIntegration of gameIntegrations) {
					let existingGameIndex = games.findIndex((g) => g.integrationId === gameIntegration.integrationId);
					if (existingGameIndex != -1) {
						let newGameData = this.convertIntegrationGameToGame(gameIntegration, teams, games[existingGameIndex]) as IGame;
						this.updateGameGroup(newGameData, phaseIndex);
						this.verifyEventStatus();
					}
				}

				const updateGames = this.getGames(phaseIndex, tierIndex).value as IGame[];

				if (updateGames.filter((x) => x.seriesId).length > 0) {
					this.updateAllSeries(
						updateGames.filter((x) => x.seriesId),
						phaseIndex,
						tierIndex
					);
				}

				this.cdr.detectChanges();
			});
	}

	async onSubmit() {
		try {
			this.updating = true;
			let formData = JSON.parse(JSON.stringify(this.eventForm.getRawValue()));

			const phases = [];
			const tiers = [];
			const games = [];
			const series = [];

			let phaseIndex = 0;
			for (let phase of formData.phases) {
				if (!phase.id || phase.id === "none") {
					phase.id = crypto.randomUUID();
				}

				let tierIndex = 0;
				for (let tier of phase.tiers) {
					if (!tier.id) {
						tier.id = crypto.randomUUID();
					}

					for (let game of tier.games) {
						game.tierId = tier.id;
						games.push(game);
					}

					for (let currentSeries of tier.series) {
						currentSeries.tierId = tier.id;
						series.push(currentSeries);
					}

					delete tier.games;
					delete tier.series; // TODO: Why?
					tier.displayName = tier.displayName || phase.tierPrefix + " " + (tierIndex + 1);
					tiers.push({ ...tier, order: tier.order ?? tierIndex, phaseId: phase.id } as ITier);
					tierIndex++;
				}

				delete phase.tiers;
				phases.push({ ...phase, order: phaseIndex });
				phaseIndex++;
			}

			console.log(formData.integrationData);

			let final: IEvent = {
				...this.originalEventInfo,
				id: formData.id,
				name: formData.name || this.defaultName(),
				year: formData.year,
				sport: formData.sport,
				type: formData.type,
				isPromotional: formData.isPromotional,
				status: formData.status === "New" ? "Draft" : formData.status,
				teams: formData.teams,
				phases: phases,
				tiers: tiers,
				games: games,
				series: series,
				description: formData.description,
				integrationData: formData.integrationData,
				minAuctionDatetime: new Date(formData.minAuctionDatetime),
				maxAuctionDatetime: new Date(formData.maxAuctionDatetime),
				defaultManagers: formData.defaultManagers,
				publicRooms: formData.publicRooms.map((room: any) => {
					return {
						...room,
						auctionDate: parseISO(room.auctionDate).toISOString()
					};
				}),
				scoringPresets: formData.scoring,
				bots: formData.bots.map((x: any[]) => {
					const map = new Map<string, number>();
					x.forEach((bot) => {
						map.set(bot.teamId, bot.value);
					});

					return map;
				})
			};

			if (final.id) {
				await this.eventService.updateEvent(final);
			} else {
				var id = await this.eventService.createEvent(final);
				this.eventForm.get("id")?.patchValue(id);
				this.router.navigate(["event/" + id + "/admin"]);
			}

			this.eventForm.markAsPristine();
			this.updating = false;
		} catch (e) {
			console.log(e);
			this.updating = false;
		}
	}
}

import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { BehaviorSubject, Observable, filter, take, takeUntil, tap, withLatestFrom } from "rxjs";
import { IEvent } from "src/app/models/event";
import { IGroup, IGroupScoreAudit } from "src/app/models/group";
import { GroupService } from "src/app/services/group.service";
import { BaseComponent } from "src/app/shared/components/base.component";
import { MatButton } from "@angular/material/button";
import {
	MatTable,
	MatColumnDef,
	MatHeaderCellDef,
	MatHeaderCell,
	MatCellDef,
	MatCell,
	MatHeaderRowDef,
	MatHeaderRow,
	MatRowDef,
	MatRow
} from "@angular/material/table";
import { NgIf, AsyncPipe, CurrencyPipe, DatePipe } from "@angular/common";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { ILeague } from "src/app/models/league";

@Component({
	selector: "am-group-audit",
	templateUrl: "./group-audit-dialog.component.html",
	styleUrls: ["./group-audit-dialog.component.scss"],
	standalone: true,
	imports: [
		MatDialogContent,
		MatFormField,
		MatLabel,
		MatInput,
		FormsModule,
		ReactiveFormsModule,
		NgIf,
		MatTable,
		MatColumnDef,
		MatHeaderCellDef,
		MatHeaderCell,
		MatCellDef,
		MatCell,
		MatHeaderRowDef,
		MatHeaderRow,
		MatRowDef,
		MatRow,
		MatDialogActions,
		MatButton,
		AsyncPipe,
		CurrencyPipe,
		DatePipe
	]
})
export class GroupAuditDialogComponent extends BaseComponent implements OnInit {
	teamNameControl = new FormControl("");
	group: Observable<IGroup>;
	groupAudits$: Observable<IGroupScoreAudit[] | undefined>;
	filteredGroupAudit$ = new BehaviorSubject<IGroupScoreAudit[] | undefined>([]);

	columns = ["time", "team", "user", "amount", "updated", "game", "tier"];

	constructor(
		public dialogRef: MatDialogRef<GroupAuditDialogComponent>,
		private groupService: GroupService,
		@Inject(MAT_DIALOG_DATA) public data: { group: IGroup; eventId: string; league: ILeague }
	) {
		super();
	}
	ngOnInit(): void {
		this.groupAudits$ = this.groupService
			.getGroupAuditData(this.data.eventId, this.data.group.id)
			.pipe((take(1), tap((x) => this.filteredGroupAudit$.next(x))));

		this.teamNameControl.valueChanges
			.pipe(
				takeUntil(this.destroyed$),
				filter((x) => !!x),
				withLatestFrom(this.groupAudits$)
			)
			.subscribe(([teamName, groupAudits]) => {
				this.filteredGroupAudit$.next(groupAudits?.filter((x) => this.getTeamNameByID(x.teamId).includes(teamName!!)));
			});
	}

	getTeamNameByID(teamId: string): string {
		return this.data.league.teams.find((x) => x.id === teamId)?.displayName ?? "Unknown";
	}

	getOwnerByTeam(teamId: string): string | undefined {
		let owner = "Unknown";

		for (let [key, value] of this.data.group.rosters.entries()) {
			if (value.includes(teamId)) owner = key;
		}

		return owner;
	}
}

<div class="header-form">
	<mat-form-field appearance="outline">
		<mat-label>League</mat-label>
		<mat-select [formControl]="leagueId">
			@for (league of leagues(); track $index) {
				<mat-option [value]="league.id"> {{ league.sport }} {{ league.year }} </mat-option>
			}
		</mat-select>
	</mat-form-field>

	<mat-form-field appearance="outline">
		<mat-label>Event</mat-label>
		<mat-select [formControl]="eventId">
			@for (event of events$ | async; track $index) {
				<mat-option [value]="event.id"> {{ event.name }} </mat-option>
			}
		</mat-select>
	</mat-form-field>
</div>

<form [formGroup]="filters" class="filters">
	<mat-form-field class="filter" appearance="outline">
		<mat-label>User Id</mat-label>
		<input matInput formControlName="userId" />
	</mat-form-field>
	<mat-form-field class="filter" appearance="outline">
		<mat-label>Group Id</mat-label>
		<input matInput formControlName="groupId" />
	</mat-form-field>
	<mat-radio-group formControlName="type">
		<mat-radio-button value="public">Public</mat-radio-button>
		<mat-radio-button value="private">Private</mat-radio-button>
		<mat-radio-button value="bot">Bots</mat-radio-button>
		<mat-radio-button value="all">All</mat-radio-button>
	</mat-radio-group>
	<mat-form-field appearance="outline">
		<mat-label>Auction Date</mat-label>
		<input matInput type="date" formControlName="auctionDate" />
	</mat-form-field>
	<mat-form-field appearance="outline">
		<mat-label>Group Size</mat-label>
		<input matInput type="number" formControlName="size" />
	</mat-form-field>
</form>

@if (groups$ | async; as groups) {
	<span>Group Count: {{ groups.length }}</span>
	<table mat-table [dataSource]="groups" multiTemplateDataRows>
		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef>Group Name</th>
			<td mat-cell *matCellDef="let element">
				<button mat-button (click)="navigateToGroup(element)">{{ element?.["name"] }}</button>
			</td>
		</ng-container>
		<ng-container matColumnDef="status">
			<th mat-header-cell *matHeaderCellDef>Status</th>
			<td mat-cell *matCellDef="let element">{{ element?.["status"] }}</td>
		</ng-container>
		<ng-container matColumnDef="size">
			<th mat-header-cell *matHeaderCellDef>Size</th>
			<td mat-cell *matCellDef="let element">{{ element?.["managerIds"]?.length || 0 }} / {{ element?.["numOfManagers"] }}</td>
		</ng-container>
		<ng-container matColumnDef="actions">
			<th mat-header-cell *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let element">
				<button mat-raised-button color="warn" style="margin-right: 8px" (click)="deleteGroup(element.eventId, element.id)">
					Delete Group
				</button>
				<button mat-raised-button (click)="openAuditDialog(element)">View Scoring Audit</button>
			</td>
		</ng-container>
		<ng-container matColumnDef="expand">
			<th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
			<td mat-cell *matCellDef="let element">
				<button mat-icon-button aria-label="expand row" (click)="setSelected(element.id); $event.stopPropagation()">
					<mat-icon *ngIf="selectedGroupId !== element.id">keyboard_arrow_down</mat-icon>
					<mat-icon *ngIf="selectedGroupId === element.id">keyboard_arrow_up</mat-icon>
				</button>
			</td>
		</ng-container>

		<ng-container matColumnDef="expandedDetail">
			<td mat-cell *matCellDef="let element" [attr.colspan]="columns.length">
				<div class="group-detail" [@detailExpand]="element.id == selectedGroupId ? 'expanded' : 'collapsed'">
					<pre><p>{{ element | json }}</p></pre>
				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="columns" class="header-row"></tr>
		<tr
			mat-row
			*matRowDef="let element; columns: columns"
			class="group-score-row"
			[class.group-score-row-expanded-row]="selectedGroupId === element?.uid"
			(click)="setSelected(element.uid)"
		></tr>
		<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
	</table>
}

import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { BehaviorSubject, Observable, filter, take, takeUntil, tap, withLatestFrom } from "rxjs";
import { IEvent } from "src/app/models/event";
import { IGroup, IGroupScoreAudit } from "src/app/models/group";
import { GroupService } from "src/app/services/group.service";
import { BaseComponent } from "src/app/shared/components/base.component";

@Component({
	selector: "am-group-audit",
	templateUrl: "./group-audit-dialog.component.html",
	styleUrls: ["./group-audit-dialog.component.scss"]
})
export class GroupAuditDialogComponent extends BaseComponent implements OnInit {
	teamNameControl = new FormControl("");
	group: Observable<IGroup>;
	groupAudits$: Observable<IGroupScoreAudit[] | undefined>;
	filteredGroupAudit$ = new BehaviorSubject<IGroupScoreAudit[] | undefined>([]);

	columns = ["time", "team", "user", "amount", "updated", "game", "tier"];

	constructor(
		public dialogRef: MatDialogRef<GroupAuditDialogComponent>,
		private groupService: GroupService,
		@Inject(MAT_DIALOG_DATA) public data: { group: IGroup; event: IEvent }
	) {
		super();
	}
	ngOnInit(): void {
		this.groupAudits$ = this.groupService
			.getGroupAuditData(this.data.event.id, this.data.group.id)
			.pipe((take(1), tap((x) => this.filteredGroupAudit$.next(x))));

		this.teamNameControl.valueChanges
			.pipe(
				takeUntil(this.destroyed$),
				filter((x) => !!x),
				withLatestFrom(this.groupAudits$)
			)
			.subscribe(([teamName, groupAudits]) => {
				this.filteredGroupAudit$.next(groupAudits?.filter((x) => this.getTeamNameByID(x.teamId).includes(teamName!!)));
			});
	}

	getTeamNameByID(teamId: string): string {
		return this.data.event.teams.find((x) => x.id === teamId)?.displayName ?? "Unknown";
	}

	getOwnerByTeam(teamId: string): string | undefined {
		let owner = "Unknown";

		for (let [key, value] of this.data.group.rosters.entries()) {
			if (value.includes(teamId)) owner = key;
		}

		return owner;
	}
}

import { Injectable } from "@angular/core";
import {
	Firestore,
	QueryDocumentSnapshot,
	SnapshotOptions,
	addDoc,
	collection,
	deleteDoc,
	doc,
	getDocs,
	query,
	updateDoc
} from "@angular/fire/firestore";
import { INotification } from "../models/notification";
import { Observable, from, map } from "rxjs";
import { setDoc } from "firebase/firestore";

@Injectable({
	providedIn: "root"
})
export class NotificationService {
	constructor(private fs: Firestore) {}

	getNotifications(): Observable<INotification[]> {
		return from(getDocs(query(collection(this.fs, "notifications")).withConverter(notificationConverter))).pipe(
			map((response) => response.docs.map((d) => d.data()))
		);
	}

	async createNotification(notification: INotification) {
		return await addDoc(collection(this.fs, "notifications").withConverter(notificationConverter), notification);
	}

	async updateNotification(notification: INotification) {
		if (notification.id) {
			await setDoc(doc(this.fs, "notifications", notification.id).withConverter(notificationConverter), notification);
		}
	}

	async deleteNotification(id: string) {
		return await deleteDoc(doc(this.fs, "notifications", id));
	}
}

const notificationConverter = {
	toFirestore: (notification: INotification) => {
		return {
			icon: notification.icon,
			title: notification.title,
			message: notification.message,
			actionLink: notification.actionLink,
			createDate: notification.createDate.toISOString()
		};
	},
	fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions | undefined) => {
		let data = snapshot.data(options);
		return {
			id: snapshot.id,
			...data,
			createDate: new Date(data["createDate"])
		} as INotification;
	}
};

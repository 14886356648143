import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { IMatchup } from "src/app/models/game";
import { SeriesComponentDialogComponent } from "../series-component-dialog/series-component-dialog.component";
import { Store } from "@ngrx/store";
import { isMobile } from "src/app/state/app/app.selectors";
import { BaseComponent } from "../base.component";
import { takeUntil } from "rxjs";
import { MatCardModule } from "@angular/material/card";
import { CommonModule } from "@angular/common";
import { MatDividerModule } from "@angular/material/divider";

// TODO: Figure out why this is calling like 7 times
@Component({
	selector: "am-bracket-series-matchup-box",
	standalone: true,
	imports: [MatCardModule, CommonModule, MatDividerModule],
	templateUrl: "./bracket-series-matchup-box.component.html",
	styleUrls: ["./bracket-series-matchup-box.component.scss"]
})
export class BracketSeriesMatchupBoxComponent extends BaseComponent implements OnChanges, OnInit {
	@Input() matchup: IMatchup;
	@Input() isReverse: boolean;
	@Input() isAuction: boolean;

	hasOwners: boolean;
	detailsOpen: boolean = false;
	isMobile: boolean;

	constructor(private dialog: MatDialog, private store: Store) {
		super();
	}

	ngOnInit(): void {
		this.store
			.select(isMobile)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((isMobile) => (this.isMobile = isMobile));
	}

	ngOnChanges(): void {
		this.hasOwners = this.matchup.team1Owner != null || this.matchup.team2Owner != null;
	}

	get team1Wins(): IMatchup[] {
		return this.matchup.subMatchups.filter((x) => x.winnerId === this.matchup.team1?.id);
	}

	get team2Wins(): IMatchup[] {
		return this.matchup.subMatchups.filter((x) => x.winnerId === this.matchup.team2?.id);
	}

	getRemainingGames(gamesWon: number): number[] {
		return Array((this.matchup.clinchingAmount ?? 0) - gamesWon).fill(1) as number[];
	}

	openDetails(): void {
		if (this.matchup.team1 == null || this.matchup.team2 == null) {
			return;
		}

		this.dialog.open(SeriesComponentDialogComponent, {
			data: { main: this.matchup, isMobile: this.isMobile },
			minWidth: this.isMobile ? 375 : 600
		});
	}
}

import { Injectable } from "@angular/core";
import {
	Firestore,
	getDoc,
	doc,
	collection,
	query,
	getDocs,
	QueryDocumentSnapshot,
	SnapshotOptions,
	addDoc,
	docData,
	updateDoc,
	where
} from "@angular/fire/firestore";
import { catchError, from, map, Observable } from "rxjs";
import { IEvent } from "../models/event";
import { Functions, httpsCallableData } from "@angular/fire/functions";
import { ITeam } from "../models/team";
import { SportsDBIntegrationKey } from "../models/constants";
import { FullMetadata, Storage, UploadResult, ref, updateMetadata, uploadBytes } from "@angular/fire/storage";
import { IGame, ISeries } from "../models/game";

@Injectable({
	providedIn: "root"
})
export class EventService {
	constructor(private fs: Firestore, private fns: Functions, private storage: Storage) {}

	getRecent(isAdminUser: boolean = false): Observable<IEvent[]> {
		return from(
			getDocs(
				query(collection(this.fs, "events"), isAdminUser ? where("name", "!=", "") : where("status", "!=", "Draft")).withConverter(
					eventConverter
				)
			)
		).pipe(
			map((response) =>
				response.docs.map((d) => {
					return d.data();
				})
			),
			catchError((e) => {
				console.error(e);
				throw new Error(e);
			})
		);
	}

	getByID(eventId: string): Observable<IEvent | undefined> {
		return from(getDoc(doc(this.fs, "events", eventId).withConverter(eventConverter))).pipe(map((response) => response.data()));
	}

	getLiveUpdatesByID(eventId: string): Observable<IEvent | undefined> {
		return docData(doc(this.fs, "events", eventId).withConverter(eventConverter), { idField: "id" });
	}

	updateGameScores(): void {
		const callable = httpsCallableData(this.fns, "events-updateGameScores");
		callable();
	}

	// Move this to storage service
	updateCacheControl(filePath: string): Promise<FullMetadata> {
		const storageRef = ref(this.storage, filePath);

		return updateMetadata(storageRef, { cacheControl: "public,max-age=5256000" });
	}

	addFile(path: string, file: File): Promise<UploadResult> {
		const storageRef = ref(this.storage, path + "/" + file.name);

		return uploadBytes(storageRef, file, { cacheControl: "public,max-age=5256000" });
	}

	async createEvent(event: IEvent) {
		return (await addDoc(collection(this.fs, "events").withConverter(eventConverter), event)).id;
	}

	async updateEvent(event: IEvent) {
		await updateDoc(doc(this.fs, "events", event.id).withConverter(eventConverter), {
			...event,
			minAuctionDatetime: event.minAuctionDatetime.toISOString(),
			maxAuctionDatetime: event.maxAuctionDatetime.toISOString(),
			bots: event.bots ? event.bots?.map((x) => Object.fromEntries(x)) : undefined
		});
	}
}

const eventConverter = {
	toFirestore: (event: IEvent) => {
		return {
			name: event.name,
			description: event.description,
			year: event.year,
			sport: event.sport,
			type: event.type,
			status: event.status,
			isPromotional: event.isPromotional,
			phases: event.phases,
			tiers: event.tiers,
			teams: event.teams,
			games: event.games.map((x) => {
				return {
					...x,
					startTime: x.startTime != null ? x.startTime?.toISOString() : undefined
				};
			}),
			integrationData: event.integrationData,
			publicRooms: event.publicRooms,
			minAuctionDatetime: event.minAuctionDatetime.toISOString(),
			maxAuctionDatetime: event.maxAuctionDatetime.toISOString(),
			bots: event.bots
		};
	},
	fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions | undefined) => {
		let data = snapshot.data(options);
		return {
			id: snapshot.id,
			...data,
			tiers: data["tiers"] || data["rounds"],
			// TODO: Remove once all data has been fixed
			teams: data["teams"].map((x: any) => {
				if (x.integrations) {
					return x;
				}

				const team: ITeam = {
					...x,
					integrations: [{ id: x.integrationId, type: SportsDBIntegrationKey }]
				};

				return team;
			}),
			games: data["games"].map((x: IGame) => {
				let startTime: Date | undefined = x.startTime ? new Date(x.startTime) : undefined;

				if (startTime && isNaN(startTime.getTime())) {
					startTime = undefined;
				}

				return {
					...x,
					startTime: startTime
				};
			}),
			series: data["series"],
			bots: data["bots"]
				? data["bots"].map((x: any) => (!!x ? new Map(Object.entries(x)) : new Map()))
				: data["botValues"]?.map((x: any) => (!!x ? new Map(Object.entries(x)) : new Map())), //
			minAuctionDatetime: data["minAuctionDatetime"] ? new Date(data["minAuctionDatetime"]) : undefined,
			maxAuctionDatetime: data["maxAuctionDatetime"] ? new Date(data["maxAuctionDatetime"]) : undefined,
			publicRooms: data["publicRooms"]?.map((x: any) => {
				return {
					...x,
					auctionDate: new Date(x.auctionDate)
				};
			})
		} as IEvent;
	}
};

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { BehaviorSubject, filter, Observable, switchMap, takeUntil, tap } from "rxjs";
import { IEvent } from "src/app/models/event";
import { IGroupInvite } from "src/app/models/invite";
import { GroupService } from "src/app/services/group.service";
import { BaseComponent } from "src/app/shared/components/base.component";
import { isLoggedIn } from "src/app/state/app/app.selectors";
import { setSelectedEvent } from "src/app/state/event/event.actions";
import { currentEvent } from "src/app/state/event/event.selectors";
import { acceptGroupInvite } from "src/app/state/group/group.actions";

@Component({
	selector: "am-invite",
	templateUrl: "./invite.component.html",
	styleUrls: ["./invite.component.scss"]
})
export class InviteComponent extends BaseComponent implements OnInit {
	loaded$ = new BehaviorSubject<boolean>(false);
	isUserLoggedIn$: Observable<boolean>;
	invite$ = new BehaviorSubject<IGroupInvite | undefined>(undefined);
	currentEvent$: Observable<IEvent | undefined>;

	joining: boolean = false;

	constructor(private store: Store, private activatedRoute: ActivatedRoute, private groupService: GroupService, private router: Router) {
		super();
	}

	ngOnInit(): void {
		this.isUserLoggedIn$ = this.store.select(isLoggedIn);
		this.currentEvent$ = this.store.select(currentEvent);

		this.activatedRoute.queryParams
			.pipe(
				filter((params) => !!params["inviteId"]),
				switchMap((params) => {
					return this.groupService.getGroupInviteByID(params["inviteId"]);
				})
			)
			.pipe(
				takeUntil(this.destroyed$),
				tap(() => this.loaded$.next(true))
			)
			.subscribe((invite) => {
				if (invite) {
					this.store.dispatch(setSelectedEvent({ eventId: invite?.eventId || "" }));
				}
				this.loaded$.next(true);
				this.invite$.next(invite);
			});
	}

	login() {
		this.router.navigate(["login"], { queryParams: { redirectPath: this.router.url } });
	}

	joinGroup(invite: IGroupInvite, eventType: string) {
		this.joining = true;
		this.store.dispatch(acceptGroupInvite({ invite: invite, eventType: eventType }));
	}
}

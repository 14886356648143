import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { catchError, take, throwError } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";

@Component({
	selector: "am-password-reset",
	templateUrl: "./password-reset.component.html",
	styleUrls: ["./password-reset.component.scss"]
})
export class PaswordResetComponent {
	resetPassword: FormGroup = this.fb.group({
		email: new FormControl("", [Validators.required, Validators.email])
	});

	get email() {
		return this.resetPassword?.get(["email"]) as FormControl;
	}

	updating: boolean = false;
	success: boolean = false;
	errorMessage: string;

	constructor(public store: Store, private fb: FormBuilder, private router: Router, private authService: AuthenticationService) {}

	goToLogin() {
		this.router.navigate(["login"]);
	}

	async submitResetPassword() {
		this.updating = true;
		this.resetPassword.disable();
		this.authService
			.resetPassword(this.email.value)
			.pipe(
				take(1),
				catchError((error) => {
					this.errorMessage = "An error occured resetting password";
					this.resetPassword.enable();
					this.updating = false;
					throw error;
				})
			)
			.subscribe(() => {
				this.success = true;
				this.resetPassword.enable();
				this.updating = false;
			});
	}
}

<am-tooltip>
	<mat-card [id]="matchup.id" tooltip-content>
		<div
			class="team-box"
			[id]="matchup.team1?.id"
			[ngClass]="{
				focus: matchup.team1Owner?.color === 'focus',
				eliminated: matchup.team1?.isEliminated && matchup.groupStatus !== 'Complete'
			}"
			[style]="isReverse ? 'flex-direction: row-reverse' : 'flex-direction: row'"
		>
			<ng-container *ngIf="matchup.team1">
				<div
					class="color"
					*ngIf="matchup.team1Owner?.color && matchup.team1Owner?.color !== 'focus'"
					[style.background-color]="matchup.team1Owner?.color ?? 'white'"
				></div>
				<h5 class="seed" *ngIf="matchup.team1?.seed">{{ matchup.team1.seed }}</h5>
				<h5 class="name" [style]="isReverse ? 'text-align: right' : 'text-align: left'">
					{{ matchup.team1.displayName }}
				</h5>
				<h5 *ngIf="['Started', 'Complete', 'Archived'].includes(matchup.eventStatus)" class="scores">
					{{ matchup.team1.score ?? "" }}
				</h5>
			</ng-container>
		</div>
		<mat-divider />
		<div
			class="team-box"
			[id]="matchup.team2?.id"
			[ngClass]="{
				focus: matchup.team2Owner?.color === 'focus',
				eliminated: matchup.team2?.isEliminated && matchup.groupStatus !== 'Complete'
			}"
			[style]="isReverse ? 'flex-direction: row-reverse' : 'flex-direction: row'"
		>
			<ng-container *ngIf="matchup.team2">
				<div
					class="color"
					*ngIf="matchup.team2Owner?.color && matchup.team2Owner?.color !== 'focus'"
					[style.background-color]="matchup.team2Owner?.color ?? 'white'"
				></div>
				<h5 class="seed" *ngIf="matchup.team2.seed">{{ matchup.team2.seed }}</h5>
				<h5 class="name" [style]="isReverse ? 'text-align: right' : 'text-align: left'">
					{{ matchup.team2.displayName }}
				</h5>
				<h5 *ngIf="['Started', 'Complete', 'Archived'].includes(matchup.eventStatus)" class="scores">
					{{ matchup.team2.score ?? "" }}
				</h5>
			</ng-container>
		</div>
	</mat-card>
	<span tooltip>
		<ng-container [ngTemplateOutlet]="tooltipPopup"></ng-container>
	</span>
</am-tooltip>

<ng-template #tooltipPopup>
	<am-schedule-matchup-box *ngIf="matchup.team1 || matchup.team2" [matchup]="matchup"></am-schedule-matchup-box>
</ng-template>

<h2>Join Value Picks</h2>

<div class="content">
	<div class="text">You are about to join blah blah blah</div>

	<mat-divider></mat-divider>
	@for (team of data.teams; track $index) {
		<div class="team">
			<img width="40" height="40" [src]="team.logo" />
			<span class="name">{{ team.displayName }}</span>
			<span>{{ team.value | currency: "USD" : "symbol" : "1.2-2" }}</span>
		</div>
		<mat-divider></mat-divider>
	}

	<div class="text">Remaining Budget: {{ data.remainingBudget | currency: "USD" : "symbol" : "1.2-2" }}</div>
</div>

<mat-dialog-actions>
	<button style="margin-left: auto" mat-raised-button color="accent" (click)="join()">Confirm</button>
	<button mat-raised-button (click)="dialogRef.close()">Close</button>
</mat-dialog-actions>

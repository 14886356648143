<div class="group-edit">
	@if (league(); as league) {
		<div
			class="mobile-banner"
			*ngIf="isMobile$ | async; else banner"
			[style.background-image]="
				'linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0)), url(../assets/img/stock/' + league.type.toLowerCase() + '.png)'
			"
		>
			<div class="logo">
				<img width="100" [src]="'../assets/icons/logos/' + (league.type ? league.type.toLowerCase() : 'generic') + '.svg'" />
			</div>
			<h2>{{ groupId ? "Edit" : "Create" }} your Group for the {{ league.year }} {{ league.sport }}</h2>
			<span>
				Use this form to {{ groupId ? "edit" : "create" }} a group, invite friends, schedule an auction, and select a buy-in per
				player
			</span>
		</div>
		<ng-template #banner>
			<div
				class="banner"
				[style.background-image]="
					'linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0)), url(../assets/img/stock/' +
					league.type.toLowerCase() +
					'.jpg)'
				"
			>
				<div class="text">
					<h1>{{ groupId ? "Edit" : "Create" }} your Group for the {{ league.year }} {{ league.sport }}</h1>
					<span>
						Use this form to {{ groupId ? "edit" : "create" }} a group, invite friends, schedule an auction, and select a buy-in
						per player
					</span>
				</div>
				<div class="logo">
					<img width="150" [src]="'../assets/icons/logos/' + (league.type ? league.type.toLowerCase() : 'generic') + '.svg'" />
				</div>
			</div>
		</ng-template>
	}

	<form [formGroup]="groupForm" *ngIf="groupForm">
		<div class="group-section">
			<h2>General Information</h2>
			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>Group Name</mat-label>
				<input matInput formControlName="name" />
				<mat-error *ngIf="groupForm.controls['name'].hasError('required')">A title is required</mat-error>
			</mat-form-field>
			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>How many managers in your group, including yourself?</mat-label>
				<input matInput type="number" formControlName="numOfManagers" min="2" />
				<mat-error *ngIf="groupForm.controls['numOfManagers'].hasError('required')">A value is required</mat-error>
				<mat-error *ngIf="groupForm.controls['numOfManagers'].hasError('min')">Value must be higher than 2</mat-error>
				<mat-error *ngIf="groupForm.controls['numOfManagers'].hasError('minManagers')">
					You must remove a manager to change this value
				</mat-error>
				<mat-error *ngIf="groupForm.controls['numOfManagers'].hasError('maxManagers')"> Max number of managers exceeded </mat-error>
				<!-- TODO: <mat-error *ngIf="groupForm.controls['numOfManagers'].value < 4"></mat-error> -->
			</mat-form-field>
		</div>

		<div class="group-section" *ngIf="groupId">
			<h2>Managers ({{ managers.controls.length }}/{{ groupForm.controls["numOfManagers"].value }})</h2>
			<div class="manager-list">
				<div *ngFor="let manager of managers.controls; let i = index" [formGroup]="manager" class="manager">
					<mat-form-field appearance="outline" floatLabel="always">
						<mat-label>{{ manager.get("type")?.value }}</mat-label>
						<input matInput formControlName="displayName" readonly [ngClass]="manager.get('status')?.value?.toLowerCase()" />
					</mat-form-field>

					<mat-form-field appearance="outline">
						<mat-label>Color</mat-label>
						<mat-select formControlName="color" required [style.background-color]="manager.get('color')?.value">
							<mat-option
								*ngFor="let item of filteredColorMap(manager.get('color')?.value, managers.controls) | keyvalue"
								[value]="item.value"
							>
								<div [style.background-color]="item.value" [style.height]="'30px'" [style.width]="'100px'"></div>
							</mat-option>
						</mat-select>
					</mat-form-field>
					<button
						mat-icon-button
						*ngIf="manager.get('type')?.value !== 'Group Owner' && manager.get('uid')?.value !== currentUID"
						[disabled]="manager.get('uid')?.disabled"
						(click)="toggleManager(i)"
					>
						<mat-icon *ngIf="manager.get('status')?.value === 'Active'">person_remove</mat-icon>
						<mat-icon *ngIf="manager.get('status')?.value === 'Inactive'">person_add</mat-icon>
					</button>
				</div>
			</div>
			<ng-container *ngIf="sharedInviteId$ | async as sharedInviteId">
				<ng-container *ngIf="managers.controls.length < groupForm.controls['numOfManagers'].value">
					<h3>Share This Link To Invite Other Managers</h3>
					<div class="shared-link">
						<mat-form-field class="shared-form-field" appearance="outline">
							<input matInput [value]="'https://auctionmadness.com/invite?inviteId=' + sharedInviteId" readonly />
						</mat-form-field>
						<button mat-raised-button color="primary" (click)="copySharedInviteToClipboard(sharedInviteId)">Copy</button>
					</div>
				</ng-container>
			</ng-container>
		</div>

		<div formGroupName="auctionData" class="group-section">
			<h2>Schedule Auction</h2>
			<h4>
				For the best experience pick a time where all members are available. The auction time must be between
				{{ minAuctionDateTime | date: "MMM d, y, h:mm a" }} -
				{{ maxAuctionDatetime | date: "MMM d, y, h:mm a" }}
			</h4>
			<div class="auction-info">
				<mat-form-field appearance="outline">
					<mat-label>Auction Date</mat-label>
					<input matInput formControlName="auctionDate" [matDatepicker]="auctionPicker" />
					<mat-datepicker-toggle matIconSuffix [for]="auctionPicker"></mat-datepicker-toggle>
					<mat-datepicker #auctionPicker></mat-datepicker>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Auction Time</mat-label>
					<input type="time" matInput formControlName="auctionTime" />
				</mat-form-field>
			</div>
		</div>
		<div formGroupName="scoring" class="group-section">
			<h2>
				Scoring Settings
				<mat-icon
					style="margin-left: 4px"
					matTooltip="The group's buy-in determines the total score in the pool, meaning that at the end of the season all points should add up to buy-in x (number of player). Auction Madness does not collect any money."
				>
					info_outline
				</mat-icon>
			</h2>
			<h4>
				Auction Madness doesn't accept any money to play. If you choose to collect money outside of our site, the buy selected buy
				in can be used for gathering money from your group. At the end of the event, the managers scores will total up to the total
				buy in and the money can be paid out. However if you would like to play for free, these dollars can be considered points and
				will be tracked as such.
			</h4>
			<mat-button-toggle-group class="button-group" [formControl]="scoringPresetControl">
				<mat-button-toggle [value]="scoring.id" *ngFor="let scoring of event.scoringPresets">
					{{ scoring.name }}
				</mat-button-toggle>
				<mat-button-toggle value="custom">Custom</mat-button-toggle>
			</mat-button-toggle-group>

			<ng-container *ngIf="!groupForm.get(['scoring', 'freePlay'])?.value">
				<mat-form-field appearance="outline">
					<mat-label>What is the buy-in per player</mat-label>
					<input type="number" min="1" [readonly]="!isCustomScoring" matInput formControlName="buyInAmount" />
				</mat-form-field>
			</ng-container>
		</div>
		<ng-container *ngIf="scoringPresetControl.value !== 'custom'; else customOptions">
			<div class="scoring-details">
				<am-scoring-overview
					[phases]="createScoringOverviewPhase(event)"
					[totalPot]="scoring.get('totalPot')?.value"
				></am-scoring-overview>
			</div>
		</ng-container>

		<ng-template #customOptions>
			<div class="advanced-options">
				<h2>Advanced Options</h2>
				<span>Play with advanced Settings and modify total per game type.</span>
				<div class="table">
					<div class="row">
						<div class="col"><h3>Total Value By Game Type</h3></div>
						<div class="col"><h3>Value Per Game</h3></div>
					</div>
					<ng-container *ngFor="let scoringOption of scoringOptions.controls">
						<div class="row" *ngIf="!scoringOption.value?.hidden" [formGroup]="scoringOption">
							<div class="col">
								<mat-form-field appearance="outline">
									<mat-label>
										{{ scoringOption.get("name")?.value }} ({{ scoringOption.get("numberOfGames")?.value }})</mat-label
									>
									<input
										type="number"
										matInput
										(keypress)="decimalFilter($event)"
										[readonly]="!isCustomScoring"
										formControlName="totalValue"
									/>
									<span matTextPrefix>$&nbsp;</span>
								</mat-form-field>
							</div>
							<div class="col-divider">=</div>
							<div class="col">
								<mat-form-field appearance="outline">
									<mat-label>
										{{ scoringOption.get("name")?.value }} ({{ scoringOption.get("numberOfGames")?.value }})</mat-label
									>
									<input
										type="number"
										matInput
										(keypress)="decimalFilter($event)"
										formControlName="valuePerGame"
										readonly
									/>
									<span matTextPrefix>$&nbsp;</span>
								</mat-form-field>
							</div>
						</div>
					</ng-container>
					<div class="row">
						<div class="col">
							Scoring Total: {{ scoring.get("scoringOptionsTotal")?.value | currency: "USD" : "symbol" : "1.2-3" }}
						</div>
						<div class="col">Group Buy-in: {{ scoring.get("totalPot")?.value | currency: "USD" : "symbol" : "1.2-3" }}</div>
					</div>
				</div>

				<mat-error *ngIf="scoring.get('scoringOptionsTotal')?.value !== scoring.get('totalPot')?.value">
					Scoring Total must equal Group Buy-in
				</mat-error>
			</div>
		</ng-template>
		<ng-template #closedOptions>
			<div class="advanced-options-closed">
				<mat-divider></mat-divider>
			</div>
		</ng-template>
		<div class="action-buttons">
			<am-loading-button
				color="accent"
				(clicked)="saveGroup()"
				title="Submit"
				[loading]="updating"
				[disabled]="!groupForm.valid || groupForm.pristine || updating"
			>
			</am-loading-button>
			<button mat-raised-button color="warn" (click)="location.back()">Cancel</button>

			<am-loading-button title="Delete Group" *ngIf="canDeleteGroup" [loading]="deleting" color="warn" (clicked)="deleteGroup()">
			</am-loading-button>
		</div>
	</form>
</div>

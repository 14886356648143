import { createSelector, createFeatureSelector } from "@ngrx/store";
import { ITeam } from "src/app/models/team";
import { appInfoStateSelector, currentUID } from "../app/app.selectors";
import { teams } from "../event/event.selectors";
import { AuctionState } from "./auction.reducers";

export const auctionStateSelector = createFeatureSelector<AuctionState>("auction");

export const auction = createSelector(auctionStateSelector, appInfoStateSelector, (auctionState) =>
	auctionState?.auctionData?.startTime
		? { ...auctionState.auctionData, startTime: new Date(auctionState.auctionData.startTime.getTime() + auctionState?.timeOffset) }
		: auctionState?.auctionData
);

export const timeOffset = createSelector(auctionStateSelector, (state) => state.timeOffset);

export const pricePerTeam = createSelector(auction, (auction) => auction?.pricePerTeam);

export const managers = createSelector(auction, (auction) => auction?.managerIds);

export const rosters = createSelector(auction, (auction) => auction?.rosters || new Map<string, string[]>());

export const fullRosters = createSelector(rosters, teams, (rosters, teams) => {
	if (teams == null) {
		throw new Error("Teams are missing");
	}

	let fullRoster = new Map<string, ITeam[]>();
	rosters.forEach((roster, uid) => {
		let teamList: ITeam[] = [];
		roster.forEach((m) => {
			let team = teams.find((t) => t.id === m);
			if (team) {
				teamList.push(team);
			}
		});
		fullRoster.set(uid, teamList);
	});
	return fullRoster;
});

export const budgets = createSelector(auction, (auction) => (auction?.budgets ? auction?.budgets : new Map<string, number>()));

export const startingAuction = createSelector(auctionStateSelector, (state) => state.startingAuction);

export const submittingBid = createSelector(auctionStateSelector, (state) => state.submittingBid);

export const currentTeam = createSelector(auction, (auction) => auction?.currentTeam);

export const otherManagerIds = createSelector(auction, currentUID, (auction, currentUID) =>
	auction?.managerIds.filter((m) => m !== currentUID)
);

export const myRoster = createSelector(rosters, currentUID, teams, (rosters, currentUID, teams) => {
	if (teams == null) {
		throw new Error("Teams are missing");
	}

	if (rosters?.has(currentUID)) {
		let myTeams = [] as ITeam[];
		rosters?.get(currentUID)?.forEach((m) => {
			let team = teams.find((t) => t.id === m);
			if (team) {
				myTeams.push(team);
			}
		});
		return myTeams;
	} else {
		return [] as ITeam[];
	}
});

export const otherRosters = createSelector(rosters, currentUID, teams, (rosters, currentUID, teams) => {
	if (teams == null) {
		throw new Error("Teams are missing");
	}

	let rostersWithTeam = new Map();
	rosters.forEach((roster, uid) => {
		rostersWithTeam.set(
			uid,
			roster.map((r) => teams.find((t) => t.id === r))
		);
	});
	return rostersWithTeam;
});

export const otherBudgets = createSelector(budgets, currentUID, (budgets, currentUID) => {
	if (budgets) {
		let allBudgets = new Map(budgets.entries());
		allBudgets.delete(currentUID);
		return allBudgets;
	} else {
		return new Map<string, number>();
	}
});

export const unclaimedTeams = createSelector(auction, (auction) => {
	let teams: string[] = [];
	if (auction) {
		teams = teams.concat(auction.remainingTeams);
		teams = teams.concat(auction.retryTeamQueue);
	}

	return teams;
});

export const totalTeamsPerManager = createSelector(auction, (auction) => auction?.totalTeamsPerManager || 0);

import { Component, Inject } from "@angular/core";
import { BaseComponent } from "../base.component";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
	selector: "am-confirmation-dialog",
	templateUrl: "./confirmation-dialog.component.html",
	styleUrls: ["./confirmation-dialog.component.scss"]
})
export class ConfirmationDialogComponent extends BaseComponent {
	title = "Confirm";
	text = "Are you sure?";

	constructor(
		private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { title: string; text: string }
	) {
		super();

		if (data?.title) {
			this.title = data.title;
		}

		if (data?.text) {
			this.text = data.text;
		}
	}

	close(): void {
		this.dialogRef.close(false);
	}

	confirm(): void {
		this.dialogRef.close(true);
	}
}

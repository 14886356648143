import { Component, Input } from "@angular/core";
import { CdkOverlayOrigin, CdkConnectedOverlay } from "@angular/cdk/overlay";

@Component({
    selector: "am-tooltip",
    templateUrl: "./tooltip.component.html",
    styleUrls: ["./tooltip.component.scss"],
    standalone: true,
    imports: [CdkOverlayOrigin, CdkConnectedOverlay]
})
export class TooltipComponent {
	isOpen = false;
}

<div class="schedule-calendar">
	<button mat-icon-button (click)="updateIndex(-1)"><mat-icon>chevron_left</mat-icon></button>
	<div class="phase">
		<ng-container *ngIf="tiersToDisplay$ | async as tiersToDisplay">
			<div
				(click)="selectWeek(tierPhase.tiers, tierPhase.phase)"
				*ngFor="let tierPhase of tiersToDisplay"
				[ngClass]="{ selected: isSelected(tierPhase.tiers) }"
			>
				{{ tierPhase.type === "tier" ? tierPhase.tiers[0].displayName : tierPhase.phase.name }}
			</div>
		</ng-container>
	</div>

	<button mat-icon-button (click)="updateIndex(1)"><mat-icon>chevron_right</mat-icon></button>
</div>

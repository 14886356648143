import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { catchError, filter, switchMap, take } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel, MatError } from "@angular/material/form-field";
import { NgIf } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { MatButton } from "@angular/material/button";

@Component({
    selector: "am-resend-verification",
    templateUrl: "./resend-verification.component.html",
    styleUrls: ["./resend-verification.component.scss"],
    standalone: true,
    imports: [MatButton, MatIcon, NgIf, FormsModule, ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatError]
})
export class ResendVerificationComponent implements OnInit {
	resendVerification: FormGroup = this.fb.group({
		email: new FormControl("", [Validators.required, Validators.email])
	});

	get email() {
		return this.resendVerification?.get(["email"]) as FormControl;
	}

	updating: boolean = false;
	success: boolean = false;
	errorMessage: string;

	constructor(
		public store: Store,
		private fb: FormBuilder,
		private router: Router,
		private authService: AuthenticationService,
		private activatedRoute: ActivatedRoute
	) {}
	ngOnInit(): void {
		this.activatedRoute.queryParams.pipe(filter((params) => !!params["email"])).subscribe((params) => {
			this.email.patchValue(params["email"]);
			this.email.markAsDirty();
		});
	}

	goToLogin() {
		this.router.navigate(["login"]);
	}

	async submitVerification() {
		this.updating = true;
		this.resendVerification.disable();

		this.authService
			.resendVerificationEmail(this.email.value)
			.pipe(
				take(1),
				catchError((error) => {
					this.errorMessage = "An error occured resubmitting verification";
					this.resendVerification.enable();
					this.updating = false;
					throw error;
				})
			)
			.subscribe(() => {
				this.success = true;
				this.resendVerification.enable();
				this.updating = false;
			});
	}
}

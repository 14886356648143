<ng-container [ngSwitch]="sport">
	<ng-container *ngSwitchCase="'NFL'">
		<ng-container *ngIf="regions; else missingRegions">
			<am-nfl-bracket [rounds]="rounds" [matchups]="matchups" [regions]="regions"></am-nfl-bracket>
		</ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="'NHL'">
		<ng-container *ngIf="regions; else missingRegions">
			<am-nhl-bracket [rounds]="rounds" [matchups]="matchups" [regions]="regions" [isAuction]="isAuction"></am-nhl-bracket>
		</ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="'NBA'">
		<ng-container *ngIf="regions; else missingRegions">
			<am-nba-bracket [rounds]="rounds" [matchups]="matchups" [regions]="regions" [isAuction]="isAuction"></am-nba-bracket>
		</ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="'Mens College Basketball'">
		<ng-container *ngIf="regions; else missingRegions">
			<am-ncaam-bracket [rounds]="rounds" [matchups]="matchups" [regions]="regions" [isAuction]="isAuction"></am-ncaam-bracket>
		</ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="'League of Legends Worlds'">
		<am-league-bracket [rounds]="rounds" [matchups]="matchups"></am-league-bracket>
	</ng-container>
</ng-container>

<ng-template #missingRegions>
	<h3>Regions are missing for this phase of the event.</h3>
</ng-template>

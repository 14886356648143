import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { da } from "date-fns/locale";
import { IMatchup } from "src/app/models/game";

@Component({
	selector: "am-series-component-dialog",
	templateUrl: "./series-component-dialog.component.html",
	styleUrls: ["./series-component-dialog.component.scss"]
})
export class SeriesComponentDialogComponent {
	mainMatchup: IMatchup;
	isMobile: boolean;

	constructor(
		public dialogRef: MatDialogRef<SeriesComponentDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		private data: { main: IMatchup; isMobile: boolean }
	) {
		this.mainMatchup = data.main;
		this.isMobile = data.isMobile;
	}

	get orderSubMatchups(): IMatchup[] {
		return this.mainMatchup.subMatchups.sort((a, b) => (a.startTime?.getTime() ?? 0) - (b.startTime?.getTime() ?? 0));
	}
}

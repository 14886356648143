import { Component, Input } from "@angular/core";

@Component({
	selector: "am-tooltip",
	templateUrl: "./tooltip.component.html",
	styleUrls: ["./tooltip.component.scss"]
})
export class TooltipComponent {
	isOpen = false;
}

import { Component, Input } from "@angular/core";
import { IMatchup } from "src/app/models/game";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { isWithinInterval } from "date-fns";
import { ScheduleMatchupBoxComponent } from "../../components/schedule-matchup-box/schedule-matchup-box.component";
import { BracketSeriesMatchupBoxComponent } from "../../components/bracket-series-matchup-box/bracket-series-matchup-box.component";
import { NgIf, NgFor } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { MatButtonToggleGroup, MatButtonToggle } from "@angular/material/button-toggle";
import { IRegion } from "src/app/models/league";

@Component({
	selector: "am-nhl-bracket",
	templateUrl: "./nhl-bracket.component.html",
	styleUrls: ["./nhl-bracket.component.scss"],
	standalone: true,
	imports: [
		MatButtonToggleGroup,
		FormsModule,
		ReactiveFormsModule,
		MatButtonToggle,
		MatIcon,
		NgIf,
		NgFor,
		BracketSeriesMatchupBoxComponent,
		ScheduleMatchupBoxComponent
	]
})
export class NHLBracketComponent {
	@Input() rounds: string[];
	@Input() matchups: IMatchup[];
	@Input() regions: IRegion[];
	@Input() isAuction: boolean;

	viewControl: FormControl = new FormControl("normal");

	getRegionByName(name: string): IRegion | undefined {
		return this.regions.find((x) => x.name === name);
	}

	getMatchupsByRound(round: string, regionId: string): IMatchup[] {
		return this.matchups.filter((x) => x.round === round && x.regionId === regionId).sort((a, b) => a.displayOrder - b.displayOrder);
	}

	get todayGames(): IMatchup[] {
		const today = new Date();
		today.setHours(0, 0, 0, 0);
		const tomorrow = new Date();
		tomorrow.setDate(today.getDate() + 1);
		tomorrow.setHours(0, 0, 0, 0);
		const allGames = this.matchups.flatMap((x) => x.subMatchups);

		return allGames.filter((x) => x.startTime && isWithinInterval(x.startTime, { start: today, end: tomorrow }));
	}

	getFinal(): IMatchup {
		const final = this.matchups.find((x) => x.round === "Stanley Cup Finals");

		if (!final) {
			throw new Error("Missing Stanley Cup Final");
		}

		return final;
	}
}

import { Breakpoints } from "@angular/cdk/layout";
import { IGroup } from "./group";

export const BreakPointDisplayNameMap = new Map([
	[Breakpoints.XSmall, "XSmall"],
	[Breakpoints.Small, "Small"],
	[Breakpoints.Medium, "Medium"],
	[Breakpoints.Large, "Large"],
	[Breakpoints.XLarge, "XLarge"]
]);

export const SportsDBIntegrationKey = "SPORTS_DB";
export const ESPNIntegrationKey = "ESPN";

export const ColorMap = new Map<number, string>([
	[0, "#008F99"],
	[1, "#002244"],
	[2, "#F59B14"],
	[3, "#69BE28"],
	[4, "#0076B6"],
	[5, "#C62C45"],
	[6, "#FFCF00"],
	[7, "#004C54"],
	[8, "#2B1142"],
	[9, "#FB4F14"],
	[10, "#8DD0D5"],
	[11, "#651562"],
	[12, "#9214F5"],
	[13, "#3D28BE"],
	[14, "#288F51"],
	[15, "#6D1818"]
]);

export const AuctionMadnessColors: string[] = [
	"mint-green",
	"peach",
	"sparkler",
	"regent-blue",
	"dark-pink",
	"cinnamon",
	"lilac",
	"pink-mist",
	"caribbean-sky",
	"beige",
	"off-white",
	"sky",
	"cream",
	"meat",
	"celestial",
	"spring-lily"
];

export const determineGameValue = (group: IGroup, totalPot: number, tierId: string | undefined, phaseId: string): number => {
	const amountForAllTiers = (group.scoring?.phaseValues?.[phaseId] || 0) * totalPot;

	if (amountForAllTiers) {
		return amountForAllTiers;
	} else {
		return (group.scoring.tierValues[tierId!!] || 0) * totalPot;
	}
};

<ng-container *ngIf="isMobile$ | async; else desktop">
	<am-event-navigation></am-event-navigation>
	<h2>Scoreboard</h2>
	<ng-container [ngTemplateOutlet]="table" [ngTemplateOutletContext]="{ isMobile: true }"></ng-container>
</ng-container>

<ng-template #desktop>
	<div class="content">
		<h2>Scoreboard</h2>
		<div id="scoreboard-desktop">
			<am-event-navigation></am-event-navigation>
			<ng-container [ngTemplateOutlet]="table" [ngTemplateOutletContext]="{ isMobile: false }"></ng-container>
		</div>
	</div>
</ng-template>

<ng-template #table let-isMobile="isMobile">
	<ng-container *ngIf="scores$ | async as scores; else noScores">
		<table
			[style.border-left]="isMobile ? '' : '1px solid lightgray'"
			[style.min-width]="isMobile ? '100vw' : 'calc(100vw - 300px)'"
			[style.padding]="isMobile ? '0px' : '25px'"
			[style.flex]="1"
			mat-table
			[dataSource]="scores"
			multiTemplateDataRows
		>
			<ng-container matColumnDef="rank">
				<th mat-header-cell *matHeaderCellDef>Rank</th>
				<td mat-cell *matCellDef="let element">{{ element["rank"] }}</td>
			</ng-container>
			<ng-container matColumnDef="displayName">
				<th mat-header-cell *matHeaderCellDef>Manager</th>
				<td mat-cell *matCellDef="let element">{{ element["displayName"] }}</td>
			</ng-container>
			<ng-container matColumnDef="totalScore">
				<th mat-header-cell *matHeaderCellDef>Points</th>
				<td mat-cell *matCellDef="let element">
					{{
						element["scoreType"] === "money"
							? (element["totalScore"] | currency: "USD" : "symbol" : "1.2-2")
							: (element["totalScore"] | number: "1.2-2")
					}}
				</td>
			</ng-container>
			<ng-container matColumnDef="teams">
				<th id="team-header" mat-header-cell *matHeaderCellDef>Teams</th>
				<td mat-cell *matCellDef="let element">
					<div class="team-list-row">
						<img
							width="35"
							[matTooltip]="team.isEliminated ? 'Team has been eliminated' : ''"
							[ngClass]="{ eliminated: team.isEliminated }"
							*ngFor="let team of element.teams"
							[src]="team.logo"
						/>
					</div>
				</td>
			</ng-container>
			<ng-container matColumnDef="expand">
				<th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
				<td mat-cell *matCellDef="let element">
					<button
						mat-icon-button
						aria-label="expand row"
						(click)="setSelected(element.uid); $event.stopPropagation()"
						*ngIf="element.teams.length > 0"
					>
						<mat-icon *ngIf="selectedManagerId !== element.uid">keyboard_arrow_down</mat-icon>
						<mat-icon *ngIf="selectedManagerId === element.uid">keyboard_arrow_up</mat-icon>
					</button>
				</td>
			</ng-container>

			<ng-container matColumnDef="expandedDetail">
				<td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
					<div class="manager-detail" [@detailExpand]="element.uid == selectedManagerId ? 'expanded' : 'collapsed'">
						<div class="team-list-full">
							<am-team-card *ngFor="let team of element.teams" [team]="team"></am-team-card>
						</div>
					</div>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand" class="header-row"></tr>
			<tr
				mat-row
				*matRowDef="let element; columns: columnsToDisplayWithExpand"
				class="manager-score-row"
				[class.manager-score-row-expanded-row]="selectedManagerId === element.uid"
				(click)="element.teams.length > 0 ? setSelected(element.uid) : null"
			></tr>
			<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
		</table>
	</ng-container>
	<ng-template #noScores>
		<h3 style="text-align: center">Come back when the group has started to see scores.</h3>
	</ng-template>
</ng-template>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatListOption } from "@angular/material/list";
import { BehaviorSubject, startWith, take, takeUntil } from "rxjs";
import { ESPNIntegrationKey, SportsDBIntegrationKey } from "src/app/models/constants";
import { IGame, IGameIntegrationInfo } from "src/app/models/game";
import { ITeam, ITeamIntegrationInfo } from "src/app/models/team";
import { IntegrationService } from "src/app/services/integration.service";
import { BaseComponent } from "src/app/shared/components/base.component";

@Component({
	selector: "am-integration-game-lookup",
	templateUrl: "./integration-game-lookup.component.html",
	styleUrls: ["./integration-game-lookup.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntegrationGameLookupComponent extends BaseComponent implements OnChanges {
	@Input() leagueId: string;
	@Input() seasonId: string;
	@Input() type: string;
	@Input() sport: string;
	@Input() existingTeams: ITeam[];
	@Input() existingGames: IGame[];

	loadingGames: boolean = false;
	apiTeams: ITeamIntegrationInfo[] = [];
	apiGames: IGameIntegrationInfo[] = [];

	rounds: string[] = [];
	statuses: string[] = [];

	gameFilters: FormGroup;

	teams: (ITeamIntegrationInfo & { inUse: boolean; selected: boolean })[];
	games: (IGameIntegrationInfo & { inUse: boolean; selected: boolean })[];

	pullingData = new BehaviorSubject<boolean>(false);

	selectedTeams: ITeamIntegrationInfo[] = [];
	selectedGames: IGameIntegrationInfo[] = [];

	@Output() selectionChange = new EventEmitter<{ teams: ITeamIntegrationInfo[]; games: IGameIntegrationInfo[] }>();

	constructor(private dataService: IntegrationService, private cdr: ChangeDetectorRef, private fb: FormBuilder) {
		super();

		this.gameFilters = this.fb.group({
			round: this.fb.control(""),
			status: this.fb.control("")
		});

		this.gameFilters.valueChanges.pipe(takeUntil(this.destroyed$), startWith({ round: "", status: "" })).subscribe((filters) => {
			this.games = this.filterGames(this.apiGames, this.existingGames);

			if (filters.round) {
				this.games = this.games.filter((g) => g.round === filters.round);
			}

			if (filters.status) {
				this.games = this.games.filter((g) => g.status === filters.status);
			}
			this.recalculateCounts();
			this.cdr.detectChanges();
		});
	}

	ngOnChanges() {
		this.teams = this.filterTeams(this.apiTeams, this.existingTeams);
		this.games = this.filterGames(this.apiGames, this.existingGames);

		const filters = this.gameFilters.value;

		if (filters.round) {
			this.games = this.games.filter((g) => g.round === filters.round);
		}

		if (filters.status) {
			this.games = this.games.filter((g) => g.status === filters.status);
		}

		this.recalculateCounts();
	}

	loadData() {
		this.loadingGames = true;
		this.dataService
			.getTeamAndGameData(this.leagueId, this.seasonId, this.type, this.sport)
			.pipe(take(1))
			.subscribe((response) => {
				this.apiTeams = response.teamSummaries;
				this.apiGames = response.gameSummaries;

				for (let game of this.apiGames) {
					if (!this.rounds.includes(game.round)) {
						this.rounds.push(game.round);
					}

					if (!this.statuses.includes(game.status)) {
						this.statuses.push(game.status);
					}
				}

				this.ngOnChanges();
				this.loadingGames = false;
				this.cdr.detectChanges();
			});
	}

	filterTeams(sourceTeams: ITeamIntegrationInfo[], existingTeams?: ITeam[]) {
		return sourceTeams.map((t) => {
			let inUse = existingTeams
				? existingTeams.findIndex(
						(e) =>
							e.integrations.find((x) => x.type === SportsDBIntegrationKey)?.id ===
							t.integrations.find((x) => x.type === SportsDBIntegrationKey)?.id
				  ) !== -1
				: false;
			return {
				...t,
				inUse: inUse,
				selected: inUse
					? false
					: this.teams.find(
							(te) =>
								te.integrations.find((x) => x.type === SportsDBIntegrationKey)?.id ===
								t.integrations.find((x) => x.type === SportsDBIntegrationKey)?.id
					  )?.selected || false
			};
		});
	}

	filterGames(sourceGames: IGameIntegrationInfo[], existingGames?: IGame[]) {
		return sourceGames.map((g) => {
			let inUse = existingGames ? existingGames.findIndex((e) => e.integrationId === g.integrationId) !== -1 : false;
			return {
				...g,
				inUse: inUse,
				selected: inUse ? false : this.games.find((ga) => ga.integrationId === g.integrationId)?.selected || false
			};
		});
	}

	selectedTeam(options: MatListOption[]) {
		for (let option of options) {
			let teamIndex = this.teams.findIndex(
				(t) =>
					t.integrations.find((x) => x.type === ESPNIntegrationKey)?.id ===
					option.value.integrations.find((y: any) => y.type === ESPNIntegrationKey)?.id
			);
			this.teams[teamIndex].selected = option.selected;
		}

		this.emitSelections();
	}

	selectedGame(options: MatListOption[]) {
		for (let option of options) {
			if (option.selected) {
				this.selectGame(option.value);
			} else {
				let gameIndex = this.games.findIndex((g) => g.integrationId === option.value.integrationId);
				this.games[gameIndex].selected = false;
			}
		}

		this.emitSelections();
	}

	selectGame(game: IGameIntegrationInfo & { inUse: boolean; selected: boolean }) {
		let gameIndex = this.games.findIndex((g) => g.integrationId === game.integrationId);
		this.games[gameIndex].selected = true;

		// Check if they are in the selected list
		if (this.checkTeamCanBeSelected(game.homeTeamIntegrationId)) {
			let teamIndex = this.teams.findIndex(
				(t) => t.integrations.find((x) => x.type === SportsDBIntegrationKey)?.id === game.homeTeamIntegrationId
			);
			if (teamIndex != -1) {
				this.teams[teamIndex].selected = true;
			}
		}

		if (this.checkTeamCanBeSelected(game.awayTeamIntegrationId)) {
			let teamIndex = this.teams.findIndex(
				(t) => t.integrations.find((x) => x.type === SportsDBIntegrationKey)?.id === game.awayTeamIntegrationId
			);
			if (teamIndex != -1) {
				this.teams[teamIndex].selected = true;
			}
		}
	}

	selectAllTeams() {
		for (let team of this.teams) {
			if (this.checkTeamCanBeSelected(team.integrations.find((x) => x.type === SportsDBIntegrationKey)?.id)) {
				team.selected = true;
			}
		}
		this.emitSelections();
	}

	removeAllTeamSelections() {
		this.teams = this.teams.map((t) => ({ ...t, selected: false }));
		this.emitSelections();
	}

	selectAllGames() {
		for (let game of this.games) {
			this.selectGame(game);
		}
		this.emitSelections();
	}

	removeAllGameSelections() {
		this.games = this.games.map((g) => ({ ...g, selected: false }));
		this.teams = this.teams.map((t) => ({ ...t, selected: false }));
		this.emitSelections();
	}

	checkTeamCanBeSelected(intergrationId: string | undefined) {
		// Is it currently selected
		if (this.teams.find((t) => t.integrations.find((x) => x.type === SportsDBIntegrationKey)?.id === intergrationId)?.selected) {
			return false;
		}

		// Is it already part of the event
		if (this.existingTeams.find((t) => t.integrations.find((x) => x.type === SportsDBIntegrationKey)?.id === intergrationId)) {
			return false;
		}

		return true;
	}

	recalculateCounts() {
		this.selectedTeams = this.teams?.filter((t) => t.selected) as ITeamIntegrationInfo[];
		this.selectedGames = this.games.filter((g) => g.selected) as IGameIntegrationInfo[];
	}

	emitSelections() {
		this.recalculateCounts();
		this.selectionChange.emit({ games: this.selectedGames, teams: this.selectedTeams });
	}
}

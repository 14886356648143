@if (event$ | async; as event) {
	<ng-container *ngIf="group$ | async as group">
		<ng-container *ngIf="{ value: isMobile$ | async } as isMobile">
			<div *ngIf="isMobile.value">
				<div
					(click)="showNavOptions = !showNavOptions"
					[ngClass]="{ 'full-width': isMobile.value, 'highlight': determineGroupState(group) === 'AuctionHappening' }"
					class="group-panel-header compact"
				>
					<img
						width="75"
						height="75"
						class="event-logo"
						[src]="'../assets/icons/logos/' + (event.type ? event.type.toLowerCase() : 'generic') + '.svg'"
					/>
					<div style="flex: 1" class="group-details">
						<h2>{{ group.name }}</h2>
						<ng-container *ngTemplateOutlet="groupStatus"></ng-container>
					</div>
					<button mat-icon-button>
						<mat-icon>{{ showNavOptions ? "keyboard_arrow_up" : "keyboard_arrow_down" }}</mat-icon>
					</button>
				</div>
				<div *ngIf="showNavOptions">
					<ng-container *ngTemplateOutlet="navList"></ng-container>
				</div>
			</div>
			<div class="group-side-nav" *ngIf="!isMobile.value">
				<img
					width="200"
					height="200"
					class="event-logo"
					[src]="'../assets/icons/logos/' + (event.type ? event.type.toLowerCase() : 'generic') + '.svg'"
				/>
				<h2>{{ group.name }}</h2>
				<ng-container *ngTemplateOutlet="groupStatus"></ng-container>
				<ng-container *ngTemplateOutlet="navList"></ng-container>
			</div>

			<ng-template #navList>
				<mat-nav-list class="left-align">
					<mat-divider></mat-divider>
					<mat-list-item (click)="navigate(['event', event.id, group.id, 'schedule'])">
						<mat-icon svgIcon="am-schedule" matListItemIcon></mat-icon>
						<span matListItemTitle [ngClass]="{ 'selected-page': selectedPage === 'schedule' || selectedPage === undefined }">
							Schedule
						</span>
					</mat-list-item>
					<mat-divider></mat-divider>
					<mat-list-item (click)="navigate(['event', event.id, group.id, 'scoreboard'])">
						<mat-icon svgIcon="am-scoreboard" matListItemIcon></mat-icon>
						<span matListItemTitle [ngClass]="{ 'selected-page': selectedPage === 'scoreboard' }">Scoreboard</span>
					</mat-list-item>
					<mat-divider *ngIf="group.auctionId"></mat-divider>
					<mat-list-item
						*ngIf="group.auctionId"
						(click)="navigate(['auction', group.auctionId])"
						[ngClass]="{ highlight: determineGroupState(group) === 'AuctionHappening' }"
					>
						<mat-icon svgIcon="am-auction" matListItemIcon></mat-icon>
						<span
							matListItemTitle
							[ngClass]="{
								'selected-page': selectedPage === 'auction'
							}"
							>Auction</span
						>
					</mat-list-item>
					<mat-divider></mat-divider>
					<mat-list-item (click)="navigate(['event', event.leagueId, event.id, group.id, 'edit'])">
						<mat-icon svgIcon="am-settings" matListItemIcon></mat-icon>
						<span matListItemTitle [ngClass]="{ 'selected-page': selectedPage === 'edit' }">Group Settings</span>
					</mat-list-item>
					<mat-divider></mat-divider>
					<ng-container *ngIf="{ value: determineGroupState(group) === 'WaitingOnManagers' } as isInviting">
						<mat-list-item (click)="openGroupInfo()" [ngClass]="{ highlight: isInviting.value }">
							<mat-icon [style.color]="'black'" matListItemIcon>groups</mat-icon>
							<span matListItemTitle [ngClass]="{ 'selected-page': selectedPage === 'edit' }">{{
								isInviting.value ? "Invite Managers" : "Group Info"
							}}</span>
						</mat-list-item>
					</ng-container>
				</mat-nav-list>
				<div class="group-actions">
					<am-loading-button
						class="leave-group-button"
						*ngIf="canLeaveGroup$ | async"
						[disabled]="leavingGroup$ | async"
						color="warn"
						(clicked)="leaveGroup(group.name)"
						[loading]="leavingGroup$ | async"
						title="Leave Group"
					>
					</am-loading-button>
					<button (click)="changeGroup()" mat-raised-button color="primary" class="change-group-button">Change Group</button>
				</div>
			</ng-template>

			<ng-template #groupStatus>
				<div class="large-font" [ngSwitch]="determineGroupState(group)">
					<ng-container *ngSwitchCase="'WaitingOnManagers'">
						<div>
							Waiting for {{ group.numOfManagers - group.managerIds.length }} manager{{
								group.numOfManagers - group.managerIds.length > 1 ? "s" : ""
							}}.
						</div>
					</ng-container>
					<ng-container *ngSwitchCase="'WaitingOnAuction'">
						<div>Auction: {{ group.auctionDate!! | countdown | async }}</div>
					</ng-container>
					<ng-container *ngSwitchCase="'AuctionHappening'">
						<h3>Auction Now!</h3>
					</ng-container>
					<ng-container *ngSwitchDefault>
						<div>
							{{ group.scoreType === "money" ? "$" : "" }} {{ getCurrentUserScore$() | async | number: "1.2-2" }} |
							{{ getCurrentUserStanding$() | async }} place
						</div>
					</ng-container>
				</div>
			</ng-template>
		</ng-container>
	</ng-container>
}

import { Component, Input } from "@angular/core";
import { IMatchup } from "src/app/models/game";
import { ScoreboardPlacePipe } from "../../pipes/scoreboard-place.pipe";
import { MatTooltip } from "@angular/material/tooltip";
import { MatDivider } from "@angular/material/divider";
import { CurrencyPipe, DatePipe, CommonModule } from "@angular/common";
import { MatCard } from "@angular/material/card";

@Component({
	selector: "am-schedule-matchup-box",
	templateUrl: "./schedule-matchup-box.component.html",
	styleUrls: ["./schedule-matchup-box.component.scss"],
	standalone: true,
	imports: [MatCard, CommonModule, MatDivider, MatTooltip, CurrencyPipe, DatePipe, ScoreboardPlacePipe]
})
export class ScheduleMatchupBoxComponent {
	@Input() matchup: IMatchup;

	detailsOpen: boolean = false;

	eventStarted(): boolean {
		return this.matchup.eventStatus !== "Open" && this.matchup.eventStatus !== "Created";
	}
}

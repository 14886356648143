import { NgModule } from "@angular/core";
import { EnabledControlFilterPipe } from "./enabled-control-filter.pipe";
import { GameFilterPipe } from "./game-filter.pipe";
import { SafeHtmlPipe } from "./safe-html.pipe";
import { CountdownPipe } from "./countdown.pipe";
import { ScoreboardPlacePipe } from "./scoreboard-place.pipe";

@NgModule({
	declarations: [CountdownPipe, SafeHtmlPipe, GameFilterPipe, EnabledControlFilterPipe, ScoreboardPlacePipe],
	exports: [CountdownPipe, SafeHtmlPipe, GameFilterPipe, EnabledControlFilterPipe, ScoreboardPlacePipe]
})
export class PipeModule {}

<am-tooltip>
	<mat-card appearance="outlined" tooltip-content [id]="matchup.id">
		<div
			[id]="matchup.team1?.id"
			class="team-box top"
			[ngClass]="{
				focus: matchup.team1Owner?.color === 'focus',
				eliminated: matchup.team1?.isEliminated && matchup.groupStatus !== 'Complete'
			}"
			[style]="isReverse ? 'flex-direction: row-reverse' : 'flex-direction: row'"
		>
			<ng-container *ngIf="matchup.team1">
				<div
					class="color"
					*ngIf="matchup.team1Owner?.color && matchup.team1Owner?.color !== 'focus'"
					[style.background-color]="matchup.team1Owner?.color ?? 'white'"
					[style.border-top-left-radius]="'2px'"
				></div>
				<h5 class="seed" *ngIf="matchup.team1.seed">{{ matchup.team1.seed }}</h5>
				<img [width]="40" [height]="40" [src]="matchup.team1.logo" />
				<span class="mat-caption" [style]="isReverse ? 'text-align: right' : 'text-align: left'">
					{{ matchup.team1.useAbbreviation ? matchup.team1.abbreviation : matchup.team1.displayName }}
				</span>
				<h1 *ngIf="['Started', 'Complete', 'Archived'].includes(matchup.eventStatus)" class="score">
					{{ matchup.gameStatus !== "Upcoming" ? matchup.team1.score ?? 0 : "" }}
				</h1>
			</ng-container>
		</div>
		<div
			[id]="matchup.team2?.id"
			class="team-box"
			[ngClass]="{
				focus: matchup.team2Owner?.color === 'focus',
				eliminated: matchup.team2?.isEliminated && matchup.groupStatus !== 'Complete'
			}"
			[style]="isReverse ? 'flex-direction: row-reverse' : 'flex-direction: row'"
		>
			<ng-container *ngIf="matchup.team2">
				<div
					class="color"
					*ngIf="matchup.team2Owner?.color && matchup.team2Owner?.color !== 'focus'"
					[style.background-color]="matchup.team2Owner?.color ?? 'white'"
					[style.border-bottom-left-radius]="'2px'"
				></div>
				<h5 class="seed" *ngIf="matchup.team2.seed">{{ matchup.team2.seed }}</h5>
				<img [width]="40" [height]="40" [src]="matchup.team2.logo" />
				<span class="mat-caption" [style]="isReverse ? 'text-align: right' : 'text-align: left'">
					{{ matchup.team2.useAbbreviation ? matchup.team2.abbreviation : matchup.team2.displayName }}
				</span>
				<h1 *ngIf="['Started', 'Complete', 'Archived'].includes(matchup.eventStatus)" class="score">
					{{ matchup.gameStatus !== "Upcoming" ? matchup.team2.score ?? 0 : "" }}
				</h1>
			</ng-container>
		</div>
	</mat-card>
	<span tooltip>
		<ng-container [ngTemplateOutlet]="tooltipPopup"></ng-container>
	</span>
</am-tooltip>

<ng-template #tooltipPopup>
	<am-schedule-matchup-box *ngIf="matchup.team1 || matchup.team2" [matchup]="matchup"></am-schedule-matchup-box>
</ng-template>

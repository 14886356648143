import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IPhaseScoringOverview, ScoringOverviewComponent } from "../scoring-overview/scoring-overview.component";
import { IPublicRoom } from "src/app/models/event";
import { DatePipe } from "@angular/common";
import { MatButton } from "@angular/material/button";
import { MatDivider } from "@angular/material/divider";

@Component({
    selector: "am-scoring-ovewview-dialog",
    template: `
		<h2>{{ data.publicRoom != null ? data.publicRoom.name : "Instant Auction" }}</h2>
		<h4 style="margin: 16px">Auction Time: {{ data.publicRoom != null ? (data.publicRoom.auctionDate | date : "short") : "Now!" }}</h4>
		<div class="mat-body" style="margin: 16px; font-weight:bold">
			Auction Madness doesn't accept any money to play. The dollar values can be considered points and will be tracked as such.
		</div>
		<mat-divider></mat-divider>
		<div style="margin: 16px;">
			<am-scoring-overview [phases]="data.scoringOverview" [totalPot]="data.totalPot"> </am-scoring-overview>
		</div>
		<div class="actions" style="margin: 16px;">
			<button mat-stroked-button (click)="dialogRef.close(false)">Close</button>
			<button mat-raised-button color="accent" (click)="dialogRef.close(true)">Join</button>
		</div>
	`,
    styles: [
        "h2 { background-color: #2b1141; color: white; margin: 0; padding: 16px; } .actions { margin-top: 16px; display: flex; gap: 8px; justify-content: right; }"
    ],
    standalone: true,
    imports: [MatDivider, ScoringOverviewComponent, MatButton, DatePipe]
})
export class PublicRoomDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<PublicRoomDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: { publicRoom: IPublicRoom; scoringOverview: IPhaseScoringOverview[]; totalPot: number; isAlreadyInGroup: boolean }
	) {}
}

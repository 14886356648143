import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "../base.component";
import { Store } from "@ngrx/store";
import { Observable, filter, map, takeUntil } from "rxjs";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { MatDialogRef } from "@angular/material/dialog";
import { activeEvents } from "src/app/state/event/event.selectors";
import { groups } from "src/app/state/group/group.selectors";
import { IGroup } from "src/app/models/group";
import { IEvent } from "src/app/models/event";

@Component({
	selector: "am-change-group-dialog",
	templateUrl: "./change-group-dialog.component.html",
	styleUrls: ["./change-group-dialog.component.scss"]
})
export class ChangeGroupDialogComponent extends BaseComponent implements OnInit {
	groups$: Observable<IGroup[]>;
	events$: Observable<IEvent[]>;
	selectedGroup = new FormControl<IGroup | undefined>(undefined);

	constructor(private store: Store, private router: Router, private dialogRef: MatDialogRef<ChangeGroupDialogComponent>) {
		super();
	}
	ngOnInit(): void {
		this.groups$ = this.store.select(groups).pipe(
			takeUntil(this.destroyed$),
			filter((groups) => !!groups),
			map((groups) => groups.filter((g) => g.status !== "Complete"))
		);

		this.events$ = this.store.select(activeEvents).pipe(takeUntil(this.destroyed$));
	}

	close(): void {
		this.dialogRef.close();
	}

	open(): void {
		if (this.selectedGroup.value != null) {
			const group: IGroup = this.selectedGroup.value;

			this.router.navigate(["event", group.eventId, group.id, "schedule"]);
			this.dialogRef.close();
		}
	}

	getEventType(eventId: string, events: IEvent[]): string {
		return events.find((e) => e.id === eventId)?.type.toLowerCase() ?? "generic";
	}
}

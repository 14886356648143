import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { combineLatest, startWith, takeUntil } from "rxjs";
import { IGame } from "src/app/models/game";
import { ITeam } from "src/app/models/team";
import { BaseComponent } from "../base.component";
import { IRegion } from "src/app/models/event";
import { format } from "date-fns";

@Component({
	selector: "am-game-edit-dialog",
	templateUrl: "./game-edit-dialog.component.html",
	styleUrls: ["./game-edit-dialog.component.scss"]
})
export class GameEditDialogComponent extends BaseComponent implements OnInit {
	dataForm: FormGroup;
	currentGame: IGame;
	teams: ITeam[];
	updating: boolean = false;
	deleting: boolean = false;
	regions: IRegion[];

	constructor(
		private dialogRef: MatDialogRef<GameEditDialogComponent>,
		private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) private data: { game: IGame; teams: ITeam[]; regions: IRegion[] }
	) {
		super();
		this.teams = this.data.teams;
		this.regions = this.data.regions;

		this.currentGame = this.data.game;
	}

	ngOnInit(): void {
		this.dataForm = this.fb.group({
			status: new FormControl(this.currentGame?.status ? this.currentGame.status : "Upcoming", Validators.required),
			team1Id: new FormControl(this.currentGame?.team1Id ? this.currentGame.team1Id : "", undefined),
			team1Score: new FormControl(this.currentGame?.team1Score ? this.currentGame.team1Score : undefined),
			team2Id: new FormControl(this.currentGame?.team2Id ? this.currentGame.team2Id : "", undefined),
			team2Score: new FormControl(this.currentGame?.team2Score ? this.currentGame.team2Score : undefined),
			regionId: new FormControl(this.currentGame?.regionId ? this.currentGame.regionId : undefined),
			startTime: new FormControl(this.currentGame?.startTime ? format(this.currentGame?.startTime, "yyyy-MM-dd HH:mm") : undefined),
			tvProvider: new FormControl(this.currentGame?.tvProvider ? this.currentGame.tvProvider : undefined),
			displayOrder: new FormControl(this.currentGame?.displayOrder ? this.currentGame.displayOrder : undefined),
			eliminateLoser: new FormControl(this.currentGame?.eliminateLoser ? this.currentGame.eliminateLoser : false),
			seriesId: new FormControl(this.currentGame?.seriesId ?? null),
			integrationId: new FormControl(this.currentGame?.integrationId ?? null),
			highlightLink: new FormControl(this.currentGame?.highlightLink ?? null)
		});

		combineLatest([
			this.dataForm.controls["team1Score"].valueChanges.pipe(startWith(0)),
			this.dataForm.controls["team2Score"].valueChanges.pipe(startWith(0))
		])
			.pipe(takeUntil(this.destroyed$))
			.subscribe(([team1Score, team2Score]) => {
				if (this.dataForm.get("status")?.value === "Upcoming" && (team1Score > 0 || team2Score > 0)) {
					this.dataForm.get("status")?.patchValue("In Progress");
				}
			});

		this.dataForm
			.get("status")
			?.valueChanges.pipe(takeUntil(this.destroyed$))
			.subscribe((status) => {
				if (status === "Final") {
					this.dataForm.get("team1Id")?.disable();
					this.dataForm.get("team1Score")?.disable();
					this.dataForm.get("team2Id")?.disable();
					this.dataForm.get("team2Score")?.disable();
				} else if (status === "In Progress") {
					this.dataForm.get("team1Id")?.disable();
					this.dataForm.get("team1Score")?.enable();
					this.dataForm.get("team2Id")?.disable();
					this.dataForm.get("team2Score")?.enable();
				} else {
					this.dataForm.get("team1Id")?.enable();
					this.dataForm.get("team1Score")?.enable();
					this.dataForm.get("team2Id")?.enable();
					this.dataForm.get("team2Score")?.enable();
				}
			});
	}

	close() {
		this.dialogRef.close();
	}

	loadTeamData(game: IGame, teams: ITeam[]): IGame {
		return {
			...game,
			team1: this.teams.find((t) => game.team1Id === t.id),
			team2: this.teams.find((t) => game.team2Id === t.id)
		};
	}

	submit() {
		let status = this.dataForm.get("status")?.value;
		let team1Id = this.dataForm.get("team1Id")?.value;
		let team1Score = this.dataForm.get("team1Score")?.value;
		let team2Id = this.dataForm.get("team2Id")?.value;
		let team2Score = this.dataForm.get("team2Score")?.value;
		let regionId = this.dataForm.get("regionId")?.value;
		let startTime = this.dataForm.get("startTime")?.value;
		let displayOrder = this.dataForm.get("displayOrder")?.value;
		let tvProvider = this.dataForm.get("tvProvider")?.value;
		let eliminateLoser = this.dataForm.get("eliminateLoser")?.value;
		let seriesId = this.dataForm.get("seriesId")?.value;
		let integrationId = this.dataForm.get("integrationId")?.value;
		let highlightLink = this.dataForm.get("highlightLink")?.value;
		let winnerId = undefined;

		if (status === "Final") {
			if (team1Score > team2Score) {
				winnerId = team1Id;
			} else if (team1Score < team2Score) {
				winnerId = team2Id;
			}
		}

		let final = {
			...this.currentGame,
			status: status,
			team1Id: team1Id,
			team1Score: team1Score,
			team2Id: team2Id,
			team2Score: team2Score,
			winnerId: winnerId,
			regionId: regionId,
			tvProvider: tvProvider,
			startTime: startTime ? new Date(startTime) : null,
			displayOrder: displayOrder,
			eliminateLoser: eliminateLoser,
			seriesId: seriesId,
			integrationId: integrationId,
			highlightLink: highlightLink
		};

		this.dialogRef.close(final);
	}
}

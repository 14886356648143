@if (currentBudget$ | async; as currentBudget) {
	<div class="header">
		<h2>Value Picks</h2>
		<button mat-raised-button color="accent" (click)="join(currentBudget.value)" [disabled]="currentBudget.value < 0">Join</button>
	</div>
	<h4>Current Budget: {{ currentBudget.value | currency: "USD" : "symbol" : "1.2-2" }}</h4>
	<div>Build the best roster of teams while staying under budget</div>
	<div class="teams">
		@for (team of sortedTeams; track $index) {
			@if (getTeamValue(team.id); as value) {
				<mat-card [ngClass]="{ disable: currentBudget.value < value && !selectedTeams.value.includes(team.id) }">
					<mat-card-header>
						<img mat-card-avatar [src]="team.logo" />
						<mat-card-title>
							{{ team.displayName }}
						</mat-card-title>

						<mat-card-subtitle>{{ team.division }}</mat-card-subtitle>

						<mat-checkbox
							[disabled]="currentBudget.value < value && !selectedTeams.value.includes(team.id)"
							(change)="addTeam(team.id)"
						>
							{{ value | currency: "USD" : "symbol" : "1.2-2" }}
						</mat-checkbox>
					</mat-card-header>
				</mat-card>
			}
		}
	</div>
}

import { CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { FormArray, FormControl } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { filter, map, Observable, startWith, switchMap, take, takeUntil } from "rxjs";
import { IEvent } from "src/app/models/event";
import { ITeam } from "src/app/models/team";
import { AnalyticsService } from "src/app/services/analytics.service";
import { GroupService } from "src/app/services/group.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { BaseComponent } from "src/app/shared/components/base.component";
import { ValuePicksDialogComponent } from "src/app/shared/dialogs/value-picks-dialog/value-picks-dialog.component";
import { compare } from "src/app/shared/functions/compare.function";
import { events } from "src/app/state/event/event.selectors";
import { LeagueStore } from "src/app/state/league.store";

@Component({
	selector: "am-value-picks",
	standalone: true,
	imports: [CommonModule, MatCheckboxModule, MatCardModule, MatButtonModule],
	templateUrl: "./value-picks.component.html",
	styleUrl: "./value-picks.component.scss"
})
export class ValuePicksComponent extends BaseComponent implements OnInit {
	readonly leagueStore = inject(LeagueStore);

	leagueId: string;
	eventId: string;
	event: IEvent | undefined;

	currentBudget$: Observable<{ value: number }>;
	selectedTeams = new FormArray<FormControl<string>>([]);

	league = this.leagueStore.currentLeague;
	teams = this.leagueStore.teams;

	get sortedTeams(): ITeam[] | undefined {
		return this.teams()?.sort((a, b) => compare(a.division, b.division, true));
	}

	constructor(
		private activatedRoute: ActivatedRoute,
		private store: Store,
		private dialog: MatDialog,
		private groupService: GroupService,
		private snackbarService: SnackbarService,
		private router: Router,
		private analyticService: AnalyticsService
	) {
		super();
		this.leagueId = this.activatedRoute.snapshot.params["leagueId"];
		this.eventId = this.activatedRoute.snapshot.params["eventId"];
	}

	ngOnInit(): void {
		if (this.leagueId) {
			this.leagueStore.setCurrentLeague(this.leagueId);
		}

		this.currentBudget$ = this.selectedTeams.valueChanges.pipe(
			startWith([]),
			takeUntil(this.destroyed$),
			map((selectedTeams) => {
				let value = 100;

				selectedTeams.forEach((teamId) => {
					value = value - this.getTeamValue(teamId);
				});

				return { value: Math.round(value * 100) / 100 };
			})
		);

		this.store
			.select(events)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((events) => (this.event = events.find((x) => x.id === this.eventId)));
	}

	addTeam(teamId: string): void {
		const index = this.selectedTeams.value.findIndex((x) => x === teamId);

		if (index >= 0) {
			this.selectedTeams.removeAt(index);
		} else {
			this.selectedTeams.push(new FormControl<string>(teamId, { nonNullable: true }));
		}
	}

	getTeamValue(teamId: string): number {
		if (this.event?.valuePicks && this.event?.valuePicks?.has(teamId)) {
			return this.event.valuePicks.get(teamId)!!;
		}

		throw Error(`No value for team with id: ${teamId}`);
	}

	join(remainingBudget: number): void {
		const dialogTeams = this.league()
			?.teams.filter((x) => this.selectedTeams.value.includes(x.id))
			.map((x) => {
				return {
					logo: x.logo,
					displayName: x.displayName,
					value: this.getTeamValue(x.id)
				};
			});

		const teamIds = this.league()?.teams.map((x) => x.id);

		if (teamIds && this.event) {
			this.dialog
				.open(ValuePicksDialogComponent, { data: { teams: dialogTeams, remainingBudget } })
				.afterClosed()
				.pipe(
					take(1),
					filter((x) => !!x),
					switchMap((x) =>
						this.groupService.joinValuePicks(
							this.eventId,
							this.selectedTeams.value,
							teamIds,
							this.event!!,
							this.event!!.scoringPresets.find((x) => x.isDefault) ?? null
						)
					)
				)
				.subscribe({
					next: (result) => {
						this.analyticService.logEvent("create_value_picks");
						this.router.navigate([`event/${this.eventId}/${result}`]);
					},
					error: (e) => this.snackbarService.warn(`Failed to create group: ${e.message}`)
				});
		}
	}
}

import { environment } from "./environments/environment";
import { AppComponent } from "./app/app.component";
import { provideStorage, getStorage } from "@angular/fire/storage";
import { provideRemoteConfig, getRemoteConfig } from "@angular/fire/remote-config";
import { provideFunctions, getFunctions, connectFunctionsEmulator } from "@angular/fire/functions";
import { provideMessaging, getMessaging } from "@angular/fire/messaging";
import { provideFirestore, initializeFirestore, connectFirestoreEmulator } from "@angular/fire/firestore";
import { Capacitor } from "@capacitor/core";
import { provideAuth, initializeAuth, indexedDBLocalPersistence, getAuth, connectAuthEmulator } from "@angular/fire/auth";
import { getApp } from "firebase/app";
import { provideAnimations } from "@angular/platform-browser/animations";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { UserEventGroupEffects } from "./app/state/user-event-group/user-event-group.effects";
import { ProfileEffects } from "./app/state/profile/profile.effects";
import { UserEffects } from "./app/state/user/user.effects";
import { AuctionEffects } from "./app/state/auction/auction.effects";
import { GroupEffects } from "./app/state/group/group.effects";
import { EventEffects } from "./app/state/event/event.effects";
import { AppEffects } from "./app/state/app/app.effects";
import { userEventGroupReducer } from "./app/state/user-event-group/user-event-group.reducers";
import { profileReducer } from "./app/state/profile/profile.reducers";
import { userReducer } from "./app/state/user/user.reducers";
import { auctionReducers } from "./app/state/auction/auction.reducers";
import { groupReducers } from "./app/state/group/group.reducers";
import { eventReducers } from "./app/state/event/event.reducers";
import { appReducers } from "./app/state/app/app.reducers";
import { AppRoutingModule } from "./app/app-routing.module";
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { BracketModule } from "./app/shared/brackets/bracket.module";
import { MatIconRegistry } from "@angular/material/icon";
import { ScreenTrackingService, UserTrackingService, provideAnalytics, initializeAnalytics } from "@angular/fire/analytics";
import { AuthenticationService } from "./app/services/authentication.service";
import { HttpClient, withInterceptorsFromDi, provideHttpClient } from "@angular/common/http";
import { FunctionClient } from "./app/services/base/function.service";
import { DefaultRouteReuseStrategy } from "./app/route-reuse-strategy";
import { FIREBASE_OPTIONS } from "@angular/fire/compat";
import { enableProdMode, importProvidersFrom } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { ReactiveFormsModule } from "@angular/forms";
import { EffectsModule } from "@ngrx/effects";
import { RouteReuseStrategy } from "@angular/router";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { MatDatepickerModule } from "@angular/material/datepicker";

if (environment.production) {
	enableProdMode();
}

bootstrapApplication(AppComponent, {
	providers: [
		provideFirebaseApp(() => initializeApp(environment.firebase)),
		provideAnalytics(() => {
			return initializeAnalytics(getApp());
		}),
		provideAuth(() => {
			let auth;
			if (Capacitor.isNativePlatform()) {
				auth = initializeAuth(getApp(), {
					persistence: indexedDBLocalPersistence
				});
			} else {
				auth = getAuth();
			}
			if (environment.useEmulators) {
				connectAuthEmulator(auth, "http://127.0.0.1:9099");
			}
			return auth;
		}),
		provideFirestore(() => {
			const firestore = initializeFirestore(getApp(), { ignoreUndefinedProperties: true });
			if (environment.useEmulators) {
				connectFirestoreEmulator(firestore, "localhost", 9000);
			}
			return firestore;
		}),
		provideMessaging(() => getMessaging()),
		provideFunctions(() => {
			const functions = getFunctions();
			if (environment.useEmulators) {
				connectFunctionsEmulator(functions, "127.0.0.1", 5001);
			}
			return functions;
		}),
		provideRemoteConfig(() => getRemoteConfig()),
		provideStorage(() => getStorage()),
		importProvidersFrom(
			// Shared
			BracketModule,
			BrowserModule,
			AppRoutingModule,
			StoreModule.forRoot({
				app: appReducers,
				event: eventReducers,
				group: groupReducers,
				auction: auctionReducers,
				user: userReducer,
				profile: profileReducer,
				userEventGroup: userEventGroupReducer
			}),
			EffectsModule.forRoot([
				AppEffects,
				EventEffects,
				GroupEffects,
				AuctionEffects,
				UserEffects,
				ProfileEffects,
				UserEventGroupEffects
			]),
			StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
			ReactiveFormsModule
		),
		{ provide: FIREBASE_OPTIONS, useValue: environment.firebase },
		{ provide: RouteReuseStrategy, useClass: DefaultRouteReuseStrategy },
		{
			provide: FunctionClient,
			useFactory: (http: HttpClient, auth: AuthenticationService) => new FunctionClient(http, auth),
			deps: [HttpClient, AuthenticationService]
		},
		ScreenTrackingService,
		UserTrackingService,
		MatIconRegistry,
		provideHttpClient(withInterceptorsFromDi()),
		provideAnimations()
	]
}).catch((err) => console.error(err));

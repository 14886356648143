import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { StoreModule } from "@ngrx/store";
import { appReducers } from "./state/app/app.reducers";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { EffectsModule } from "@ngrx/effects";
import { AppEffects } from "./state/app/app.effects";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { environment } from "../environments/environment";
import { initializeAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from "@angular/fire/analytics";
import { provideAuth, getAuth, connectAuthEmulator } from "@angular/fire/auth";
import { provideFirestore, connectFirestoreEmulator, initializeFirestore } from "@angular/fire/firestore";
import { provideFunctions, getFunctions, connectFunctionsEmulator } from "@angular/fire/functions";
import { provideRemoteConfig, getRemoteConfig } from "@angular/fire/remote-config";
import { provideStorage, getStorage } from "@angular/fire/storage";
import { AllMaterialModule } from "./shared/all-material-module";
import { eventReducers } from "./state/event/event.reducers";
import { EventEffects } from "./state/event/event.effects";
import { EventAdminComponent } from "./pages/event-admin/event-admin.component";
import { ProfileComponent } from "./pages/profile/profile.component";
import { ReactiveFormsModule } from "@angular/forms";
import { GroupEditComponent } from "./pages/group-edit/group-edit.component";
import { groupReducers } from "./state/group/group.reducers";
import { auctionReducers } from "./state/auction/auction.reducers";
import { GroupEffects } from "./state/group/group.effects";
import { AuctionEffects } from "./state/auction/auction.effects";
import { FIREBASE_OPTIONS } from "@angular/fire/compat";
import { userReducer } from "./state/user/user.reducers";
import { UserEffects } from "./state/user/user.effects";
import { UnauthenticatedComponent } from "./pages/unauthenticated/unauthenticated.component";
import { RouteReuseStrategy } from "@angular/router";
import { DefaultRouteReuseStrategy } from "./route-reuse-strategy";
import { InviteComponent } from "./pages/invite/invite.component";
import { ShowAbbreviationIfTruncatedDirective } from "./directives/show-abbreviation-if-truncated.directive";
import { MatIconRegistry } from "@angular/material/icon";
import { CarouselItemDirective } from "./directives/carousel-item.directive";
import { userEventGroupReducer } from "./state/user-event-group/user-event-group.reducers";
import { UserEventGroupEffects } from "./state/user-event-group/user-event-group.effects";
import { Capacitor } from "@capacitor/core";
import { LoginComponent } from "./pages/login/login.component";
import { GenericLoginComponent } from "./pages/login/components/generic-login/generic-login.component";
import { SignupComponent } from "./pages/login/components/signup/signup.component";
import { PaswordResetComponent } from "./pages/login/components/password-reset/password-reset.component";
import { ConfirmEmailComponent } from "./pages/login/components/confirm-email/confirm-email.component";
import { ConfirmPasswordResetComponent } from "./pages/login/components/confirm-password-reset/confirm-password-reset.component";
import { ResendVerificationComponent } from "./pages/login/components/resend-verification/resend-verification.component";
import { LegalComponent } from "./pages/legal/legal.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { indexedDBLocalPersistence, initializeAuth } from "firebase/auth";
import { getApp } from "firebase/app";
import { SharedComponentsModule } from "./shared/components/shared-component.module";
import { PipeModule } from "./shared/pipes/pipe.module";
import { EventPanelComponent } from "./pages/event-panel/event-panel.component";
import { EventScoreboardComponent } from "./pages/event-scoreboard/event-scoreboard.component";
import { EventScheduleComponent } from "./pages/event-schedule/event-schedule.component";
import { EventBracketComponent } from "./pages/event-bracket/event-bracket.component";
import { IntegrationGameLookupComponent } from "./pages/event-admin/components/integration-game-lookup/integration-game-lookup.component";
import { IntegrationLeagueLookupComponent } from "./pages/event-admin/components/integration-league-lookup/integration-league-lookup.component";
import { IntegrationPhaseWizardDialogComponent } from "./pages/event-admin/components/integration-phase-wizard-dialog/integration-phase-wizard-dialog.component";
import { BracketModule } from "./shared/brackets/bracket.module";
import { AuthenticationService } from "./services/authentication.service";
import { FunctionClient } from "./services/base/function.service";
import { ProfileManagementComponent } from "./pages/profile-management/profile-management.component";
import { DeleteProfileDialogComponent } from "./shared/components/delete-profile-dialog/delete-profile-dialog.component";
import { AuctionComponent } from "./pages/auction/auction.component";
import { AppLinksComponent } from "./pages/app-links/app-links.component";
import { getMessaging, provideMessaging } from "@angular/fire/messaging";
import { profileReducer } from "./state/profile/profile.reducers";
import { ProfileEffects } from "./state/profile/profile.effects";
import { SystemAdminComponent } from "./pages/system-admin/system-admin.component";

@NgModule({
	declarations: [
		AppComponent,
		ProfileComponent,
		InviteComponent,
		EventAdminComponent,
		SystemAdminComponent,
		IntegrationLeagueLookupComponent,
		IntegrationGameLookupComponent,
		IntegrationPhaseWizardDialogComponent,
		GroupEditComponent,
		LoginComponent,
		SignupComponent,
		PaswordResetComponent,
		ConfirmEmailComponent,
		ConfirmPasswordResetComponent,
		GenericLoginComponent,
		ResendVerificationComponent,
		LegalComponent,
		NotFoundComponent,
		UnauthenticatedComponent,
		AuctionComponent,
		AppLinksComponent,

		// Event
		EventPanelComponent,
		EventBracketComponent,
		EventScoreboardComponent,
		EventScheduleComponent,

		// User
		ProfileManagementComponent,
		DeleteProfileDialogComponent,

		// Directives
		ShowAbbreviationIfTruncatedDirective,
		CarouselItemDirective
	],
	imports: [
		AllMaterialModule, // Make this temporary

		// Shared
		SharedComponentsModule,
		BracketModule,
		PipeModule,

		HttpClientModule,
		BrowserModule,
		AppRoutingModule,
		StoreModule.forRoot({
			app: appReducers,
			event: eventReducers,
			group: groupReducers,
			auction: auctionReducers,
			user: userReducer,
			profile: profileReducer,
			userEventGroup: userEventGroupReducer
		}),
		EffectsModule.forRoot([AppEffects, EventEffects, GroupEffects, AuctionEffects, UserEffects, ProfileEffects, UserEventGroupEffects]),
		StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
		BrowserAnimationsModule,
		ReactiveFormsModule,
		provideFirebaseApp(() => initializeApp(environment.firebase)),
		provideAnalytics(() => initializeAnalytics(getApp())),
		provideAuth(() => {
			let auth;
			if (Capacitor.isNativePlatform()) {
				auth = initializeAuth(getApp(), {
					persistence: indexedDBLocalPersistence
				});
			} else {
				auth = getAuth();
			}
			if (environment.useEmulators) {
				connectAuthEmulator(auth, "http://127.0.0.1:9099");
				//FirebaseAuthentication.useEmulator({ host: "127.0.0.1", port: 9099 });
			}
			return auth;
		}),
		provideFirestore(() => {
			const firestore = initializeFirestore(getApp(), { ignoreUndefinedProperties: true });
			if (environment.useEmulators) {
				connectFirestoreEmulator(firestore, "localhost", 9000);
			}
			return firestore;
		}),
		provideMessaging(() => getMessaging()),
		provideFunctions(() => {
			const functions = getFunctions();
			if (environment.useEmulators) {
				connectFunctionsEmulator(functions, "127.0.0.1", 5001);
			}
			return functions;
		}),
		provideRemoteConfig(() => getRemoteConfig()),
		provideStorage(() => getStorage())
	],
	providers: [
		{ provide: FIREBASE_OPTIONS, useValue: environment.firebase },
		{ provide: RouteReuseStrategy, useClass: DefaultRouteReuseStrategy },
		{
			provide: FunctionClient,
			useFactory: (http: HttpClient, auth: AuthenticationService) => new FunctionClient(http, auth),
			deps: [HttpClient, AuthenticationService]
		},
		ScreenTrackingService,
		UserTrackingService,
		MatIconRegistry
	],
	bootstrap: [AppComponent]
})
export class AppModule {}

import { Component, inject, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router, RouterModule } from "@angular/router";
import { Store } from "@ngrx/store";
import { isFuture, isPast } from "date-fns";
import { BehaviorSubject, Observable, filter, map, switchMap, take, takeUntil } from "rxjs";
import { IEvent } from "src/app/models/event";
import { IGroup } from "src/app/models/group";
import { ICurrentUser, IUserEventGroup } from "src/app/models/user";
import { AnalyticsService } from "src/app/services/analytics.service";
import { GroupService } from "src/app/services/group.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { BaseComponent } from "src/app/shared/components/base.component";
import { PublicRoomDialogComponent } from "src/app/shared/components/scoring-overview-dialog/public-room-dialog.component";
import { IPhaseScoringOverview } from "src/app/shared/components/scoring-overview/scoring-overview.component";
import { isMobile } from "src/app/state/app/app.selectors";
import { groups } from "src/app/state/group/group.selectors";
import { userEventGroups } from "src/app/state/user-event-group/user-event-group.selectors";
import { currentUserData } from "src/app/state/user/user.selectors";
import { MatDivider } from "@angular/material/divider";
import { FooterComponent } from "../../shared/components/footer/footer.component";
import { PublicRoomComponent } from "../../shared/components/public-room/public-room.component";
import { CompleteGroupCardComponent } from "../../shared/components/completed-group-card/completed-group-card.component";
import { GroupCardComponent } from "../../shared/components/group-card/group-card.component";
import { MatTabsModule } from "@angular/material/tabs";
import { MatIcon } from "@angular/material/icon";
import { MatIconButton, MatButton } from "@angular/material/button";
import { CommonModule } from "@angular/common";
import { LeagueStore } from "src/app/state/league.store";
import { MatCardModule } from "@angular/material/card";
import { MatMenuModule } from "@angular/material/menu";
import { events } from "src/app/state/event/event.selectors";

@Component({
	selector: "am-profile",
	templateUrl: "./profile.component.html",
	styleUrls: ["./profile.component.scss"],
	standalone: true,
	imports: [
		CommonModule,
		MatIconButton,
		MatIcon,
		MatButton,
		MatCardModule,
		MatTabsModule,
		GroupCardComponent,
		CompleteGroupCardComponent,
		PublicRoomComponent,
		FooterComponent,
		MatDivider,
		RouterModule,
		MatMenuModule
	]
})
export class ProfileComponent extends BaseComponent implements OnInit {
	readonly leagueStore = inject(LeagueStore);

	currentTab$ = new BehaviorSubject<number>(0);
	leagueEvents$: Observable<IEvent[]>;
	isMobile$: Observable<boolean>;
	currentUser$: Observable<ICurrentUser | undefined>;
	activeGroups$: Observable<IGroup[]>;
	completeUserEventGroups$: Observable<IUserEventGroup[]>;
	recentlyCompleteUserEventGroups$: Observable<IUserEventGroup[]>;

	selectedGroupType$ = new BehaviorSubject<"Active" | "Complete">("Active");

	promotedLeague = this.leagueStore.promotedLeague;
	leagues = this.leagueStore.activeLeagues;

	joining = false;

	constructor(
		private store: Store,
		private router: Router,
		private groupService: GroupService,
		private dialog: MatDialog,
		private analyticsService: AnalyticsService,
		private snackbarService: SnackbarService
	) {
		super();
	}

	ngOnInit(): void {
		this.currentUser$ = this.store.select(currentUserData).pipe(
			takeUntil(this.destroyed$),
			filter((user) => !!user)
		);
		this.isMobile$ = this.store.select(isMobile).pipe(takeUntil(this.destroyed$));

		this.activeGroups$ = this.store
			.select(groups)
			.pipe((takeUntil(this.destroyed$), map((groups) => groups.filter((x) => x.status !== "Complete"))));

		this.completeUserEventGroups$ = this.store.select(userEventGroups).pipe(
			takeUntil(this.destroyed$),
			filter((userEventGroups) => !!userEventGroups),
			map((userEventGroups) => {
				return userEventGroups
					.filter((x) => x.status === "Complete")
					.filter((x) => {
						const date = new Date(x.date?.valueOf());
						date.setDate(date.getDate() + 5);

						return isPast(date);
					});
			})
		);

		this.recentlyCompleteUserEventGroups$ = this.store.select(userEventGroups).pipe(
			takeUntil(this.destroyed$),
			filter((userEventGroups) => !!userEventGroups),
			map((userEventGroups) => {
				return userEventGroups
					.filter((x) => x.status === "Complete")
					.filter((x) => {
						const date = new Date(x.date?.valueOf());
						date.setDate(date.getDate() + 5);

						return isFuture(date);
					});
			})
		);

		this.leagueEvents$ = this.store.select(events).pipe(
			takeUntil(this.destroyed$),
			map((events) => events.filter((event) => event.leagueId === this.promotedLeague()?.id))
		);
	}

	createGroup(leagueId: string, eventId: string) {
		this.router.navigate(["league", leagueId, eventId, "create"]);
	}

	valuePicks(leagueId: string, events: IEvent[]) {
		const event = events.find((x) => x.isDefault) ?? events[0];
		this.router.navigate(["league", leagueId, event.id, "value"]);
	}

	getLeagueType(leagueId: string): string {
		return this.leagues().find((x) => x.id === leagueId)?.type ?? "Generic";
	}

	joinBotRoom(events: IEvent[], currentUID: string): void {
		const event = events.find((x) => x.isDefault) ?? events[0];

		const scoring = event.scoringPresets.find((x) => x.isDefault);

		if (!scoring) {
			throw new Error(`Missing Default Scoring`);
		}

		const totalPot = event.defaultManagers * scoring.buyIn;

		const scoringOverview: IPhaseScoringOverview[] = scoring.phaseScorings.map((phaseScoring) => {
			const phase = event.phases.find((x) => x.id === phaseScoring.phaseId);

			if (!phase) {
				throw new Error(`Missing Phase ${phaseScoring.phaseId}`);
			}

			return {
				name: phase.name,
				amountForAllTiers: phaseScoring.amountForAllTiers ?? 0,
				amountToMakePhase: phaseScoring.amountToMakePhase ? phaseScoring.amountToMakePhase * totalPot : 0,
				tiers: phaseScoring.tiers.map((tierScoring) => {
					const tier = event.tiers.find((x) => x.id === tierScoring.tierId);

					if (!tier) {
						throw new Error(`Missing Tier ${tierScoring.tierId}`);
					}

					return {
						name: tier?.name,
						amountForTier: tierScoring.defaultValue * totalPot
					};
				})
			};
		});

		this.dialog
			.open(PublicRoomDialogComponent, {
				data: { undefined, scoringOverview, totalPot, isAlreadyInGroup: false },
				maxWidth: 400
			})
			.afterClosed()
			.pipe(take(1))
			.subscribe((result) => {
				this.joining = true;
				if (!result) {
					this.joining = false;
				} else {
					this.analyticsService.logEvent("join_bot_room");
					this.groupService
						.joinBotRoom(currentUID, event, scoring)
						.pipe(switchMap((groupId) => this.groupService.fillWithBots(event.id, groupId)))
						.subscribe({
							next: (auctionId) => {
								this.joining = false;
								this.router.navigate(["auction", auctionId]);
							},
							error: () => {
								this.joining = false;
								this.snackbarService.error("Error creating the bot group, please try again.");
							}
						});
				}
			});
	}
}

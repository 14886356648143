@if (league(); as league) {
	<form *ngIf="form" [formGroup]="form">
		<div class="section space-between">
			<h1>Event Admin</h1>

			<button mat-raised-button color="accent" (click)="save()">Save</button>
		</div>
		<div class="section">
			<mat-form-field appearance="outline">
				<mat-label>Name</mat-label>
				<input matInput formControlName="name" />
			</mat-form-field>
			<mat-form-field appearance="outline">
				<mat-label>Status</mat-label>
				<mat-select formControlName="status">
					<mat-option value="Created">Created</mat-option>
					<mat-option value="Draft">Draft</mat-option>
					<mat-option value="Open">Open</mat-option>
					<mat-option value="Started">Started</mat-option>
					<mat-option value="Complete">Complete</mat-option>
					<mat-option value="Archived">Archived</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-checkbox formControlName="isDefault">Is Default</mat-checkbox>
		</div>
		<div class="section">
			<mat-form-field appearance="outline">
				<mat-label>Min Auction Datetime</mat-label>
				<input matInput type="datetime-local" formControlName="minAuctionDatetime" />
			</mat-form-field>
			<mat-form-field appearance="outline">
				<mat-label>Max Auction Datetime</mat-label>
				<input matInput type="datetime-local" formControlName="maxAuctionDatetime" />
			</mat-form-field>
			<mat-form-field appearance="outline">
				<mat-label>Default Room Size</mat-label>
				<input matInput type="number" formControlName="defaultManagers" />
			</mat-form-field>
		</div>
		<h3>League: {{ league.year }} {{ league.sport }}</h3>
		<mat-tab-group>
			<mat-tab label="Included Teams">
				<h4>Check the teams you want included in this event</h4>
				<div>
					<mat-checkbox [checked]="includedTeams.value.length === league.teams.length" (change)="includeAllTeams(league.teams)">
						Include All
					</mat-checkbox>
				</div>
				@for (team of league.teams; track $index) {
					<mat-checkbox [checked]="includedTeams.value.includes(team.id)" (change)="updateIncludeTeam(team.id)">{{
						team.displayName
					}}</mat-checkbox>
				}
			</mat-tab>
			<mat-tab label="Phases">
				<button mat-button class="add" (click)="addPhase()">Add Phase</button>
				<mat-accordion>
					@for (phase of phases.controls; track phase; let phaseIndex = $index) {
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									{{ phase.get("name")?.value || "Unknown" }}
								</mat-panel-title>

								<button mat-stroked-button color="warn" (click)="deletePhase(phaseIndex)">Delete</button>
							</mat-expansion-panel-header>

							<div [formGroup]="phase">
								<div class="section">
									<mat-form-field appearance="outline">
										<mat-label>Name</mat-label>
										<input matInput formControlName="name" />
									</mat-form-field>
									<mat-form-field appearance="outline">
										<mat-label>View Type</mat-label>
										<mat-select formControlName="viewType">
											<mat-option value="Daily">Daily</mat-option>
											<mat-option value="Rounds">Rounds</mat-option>
											<mat-option value="Bracket">Bracket</mat-option>
										</mat-select>
									</mat-form-field>
									<mat-form-field appearance="outline">
										<mat-label>Number of teams in Phase</mat-label>
										<input matInput type="number" formControlName="numberOfTeamsInPhase" />
									</mat-form-field>
									<mat-checkbox formControlName="isSeriesBased">Series Based</mat-checkbox>
								</div>
							</div>
						</mat-expansion-panel>
					}
				</mat-accordion>
			</mat-tab>
			<mat-tab label="Tiers">
				<button mat-button class="add" (click)="addTier()">Add Tier</button>
				<mat-accordion>
					@for (tier of tiers.controls; track tier; let tierIndex = $index) {
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									{{ tier.get("name")?.value || "Unknown" }}
								</mat-panel-title>
								<mat-panel-description> Rounds {{ getTierRound(tierIndex).length }} </mat-panel-description>

								<button mat-stroked-button color="warn" (click)="deleteTier(tierIndex)">Delete</button>
							</mat-expansion-panel-header>

							<div [formGroup]="tier" class="section">
								<mat-form-field appearance="outline">
									<mat-label>Name</mat-label>
									<input matInput formControlName="name" />
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>Order</mat-label>
									<input matInput type="number" formControlName="order" />
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>Phase</mat-label>
									<mat-select formControlName="phaseId">
										@for (phase of phases.value; track phase) {
											<mat-option [value]="phase.id">{{ phase.name }}</mat-option>
										}
									</mat-select>
								</mat-form-field>
							</div>

							@if (getTierRound(tierIndex); as tierRound) {
								@for (round of rounds(); track round) {
									<mat-checkbox [checked]="tierRound.value.includes(round)" (change)="updateRound(tierIndex, round)">
										Round: {{ round }}
									</mat-checkbox>
								}
							}
						</mat-expansion-panel>
					}
				</mat-accordion>
			</mat-tab>

			<mat-tab label="Scoring">
				<button mat-button class="add" (click)="createScoringPreset()">Add Scoring Preset</button>
				<mat-accordion>
					@for (preset of scoringPresets.controls; track preset; let presetIndex = $index) {
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									{{ preset.get("name")?.value ?? "Unknown" }}
								</mat-panel-title>
								<!-- <button mat-stroked-button (click)="prievewScoring($event, form.value, presetIndex)">Preview</button> -->
								<button mat-stroked-button color="warn" (click)="deleteScoringPreset(presetIndex)">Delete</button>
							</mat-expansion-panel-header>

							<am-scoring-overview
								[phases]="getPrievewScoring(form.value, presetIndex)"
								[totalPot]="preset.get('buyIn')?.value * form.get('defaultManagers')?.value"
							></am-scoring-overview>

							<div class="section margin-top" [formGroup]="preset">
								<mat-form-field appearance="outline">
									<mat-label>Name</mat-label>
									<input matInput formControlName="name" />
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>Sort Order</mat-label>
									<input matInput type="number" formControlName="sortOrder" />
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>Buy In</mat-label>
									<input matInput type="number" formControlName="buyIn" />
								</mat-form-field>
								<mat-checkbox formControlName="isDefault" color="primary"> Is Default </mat-checkbox>
							</div>
							<mat-divider></mat-divider>
							<button mat-stroked-button class="add" (click)="addPhaseScoring(presetIndex)">Add Phase Scoring</button>

							@for (
								phaseScoring of phaseScoringGroups(presetIndex).controls;
								track phaseScoring;
								let phaseScoringIndex = $index
							) {
								<button
									mat-stroked-button
									color="warn"
									class="add"
									(click)="removePhaseScoring(presetIndex, phaseScoringIndex)"
								>
									Delete Scoring Phase
								</button>

								<div class="section margin-top" [formGroup]="phaseScoring">
									<mat-form-field appearance="outline">
										<mat-label>Phase</mat-label>
										<mat-select formControlName="phaseId">
											<mat-option *ngFor="let phase of phases.controls" [value]="phase.get('id')?.value">
												{{ phase.get("name")?.value }}
											</mat-option>
										</mat-select>
									</mat-form-field>
									<mat-form-field appearance="outline">
										<mat-label>Amount to make Phase</mat-label>
										<input matInput type="number" formControlName="amountToMakePhase" />
									</mat-form-field>
									<mat-checkbox formControlName="dynamic">Is Dynamic</mat-checkbox>
								</div>

								<button mat-button class="add" (click)="addScoringTier(presetIndex, phaseScoringIndex)">
									Add Tier Scoring
								</button>

								<div class="section flex">
									@for (
										scoringTier of getScoringTiers(presetIndex, phaseScoringIndex).controls;
										track scoringTier;
										let scoringTierIndex = $index
									) {
										<mat-card [formGroup]="scoringTier">
											<mat-form-field appearance="outline">
												<mat-label>Tier</mat-label>
												<mat-select formControlName="tierId">
													@for (tier of tiers.value; track tier) {
														<mat-option [value]="tier.id">{{ tier.name }}</mat-option>
													}
												</mat-select>
											</mat-form-field>

											<mat-form-field appearance="outline">
												<mat-label>Default Value</mat-label>
												<input matInput type="number" formControlName="defaultValue" />
											</mat-form-field>

											<button
												mat-button
												color="warn"
												class="add"
												(click)="removeScoringTier(presetIndex, phaseScoringIndex, scoringTierIndex)"
											>
												Delete Tier
											</button>
										</mat-card>
									}
								</div>
							}
						</mat-expansion-panel>
					}
				</mat-accordion>
			</mat-tab>
			<mat-tab label="Bots">
				<button mat-button class="add" (click)="createBot(league.teams)">Add Bot</button>
				<mat-accordion>
					@for (bot of bots.controls; track bot; let botIndex = $index) {
						<mat-expansion-panel hideToggle>
							<mat-expansion-panel-header>
								<mat-panel-title>Bot{{ botIndex + 1 }}</mat-panel-title>
								<button style="margin-left: auto" mat-stroked-button color="warn" (click)="removeBot(botIndex)">
									Delete
								</button>
							</mat-expansion-panel-header>
							<div class="section flex-wrap">
								@for (team of eventTeams(league); track team) {
									<div>
										<mat-form-field appearance="outline">
											<mat-label>{{ team.displayName }}</mat-label>
											<input
												matInput
												type="number"
												(change)="updateBotValue($event, team.id, botIndex)"
												[value]="botValue(bot, team.id)"
											/>
										</mat-form-field>
									</div>
								}
							</div>
						</mat-expansion-panel>
					}
				</mat-accordion>
			</mat-tab>

			<mat-tab label="Value Picks">
				<div class="section flex-wrap margin-top">
					@for (team of eventTeams(league); track team) {
						<div>
							<mat-form-field appearance="outline">
								<mat-label>{{ team.displayName }}</mat-label>
								<input matInput type="number" (change)="updatePickValue($event, team.id)" [value]="picksValue(team.id)" />
							</mat-form-field>
						</div>
					}
				</div>
			</mat-tab>
		</mat-tab-group>
	</form>
}

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { IEvent } from "src/app/models/event";
import { events } from "src/app/state/event/event.selectors";

@Component({
	selector: "am-select-event-admin-dialog",
	template: `
		<div class="select-event-admin-dialog">
			<h3>Select an Event</h3>
			<div></div>
			<mat-form-field appearence="outline">
				<mat-label>Event</mat-label>
				<mat-select [(value)]="selectedEventId">
					<mat-option *ngFor="let event of events$ | async" [value]="event.id">
						{{ event.name }}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<div class="actions">
				<button mat-stroked-button color="warn" (click)="dialogRef.close()">Close</button>
				<button mat-raised-button (click)="openSystemAdmin()">System Admin</button>
				<button mat-raised-button (click)="createNew()">New</button>
				<button mat-raised-button [disabled]="!selectedEventId" (click)="navigate()">Open</button>
			</div>
		</div>
	`,
	styles: [
		`
			.select-event-admin-dialog {
				display: flex;
				flex-direction: column;
				margin: 16px;
				width: 400px;

				.actions {
					display: flex;
					gap: 8px;
					justify-content: space-between;
				}
			}
		`
	]
})
export class SelectEventAdminDialog implements OnInit {
	events$: Observable<IEvent[]> = this.store.select(events);
	selectedEventId: string;

	form: FormGroup;

	constructor(
		public dialogRef: MatDialogRef<SelectEventAdminDialog>,
		private store: Store,
		private router: Router,
		private fb: FormBuilder
	) {}

	ngOnInit(): void {
		this.form = this.fb.group({
			// search
		});
	}

	navigate(): void {
		this.router.navigate(["event", this.selectedEventId, "admin"]);
		this.dialogRef.close();
	}

	openSystemAdmin(): void {
		this.router.navigate(["system", "admin"]);
		this.dialogRef.close();
	}

	createNew(): void {
		this.router.navigate(["event", "admin"]);
		this.dialogRef.close();
	}
}

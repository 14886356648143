import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { isFuture, isPast } from "date-fns";
import { BehaviorSubject, Observable, combineLatest, filter, map, switchMap, take, takeUntil, tap, withLatestFrom } from "rxjs";
import { IEvent, IPublicRoom } from "src/app/models/event";
import { IGroup } from "src/app/models/group";
import { ITeam } from "src/app/models/team";
import { ICurrentUser, IUserEventGroup } from "src/app/models/user";
import { AnalyticsService } from "src/app/services/analytics.service";
import { GroupService } from "src/app/services/group.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { BaseComponent } from "src/app/shared/components/base.component";
import { PublicRoomDialogComponent } from "src/app/shared/components/scoring-overview-dialog/public-room-dialog.component";
import { IPhaseScoringOverview } from "src/app/shared/components/scoring-overview/scoring-overview.component";
import { isMobile } from "src/app/state/app/app.selectors";
import { events, teams } from "src/app/state/event/event.selectors";
import { loadPublicRoomsWithCapacity } from "src/app/state/group/group.actions";
import { groups, publicRoomsWithCapacity } from "src/app/state/group/group.selectors";
import { userEventGroups } from "src/app/state/user-event-group/user-event-group.selectors";
import { currentUserData } from "src/app/state/user/user.selectors";

@Component({
	selector: "am-profile",
	templateUrl: "./profile.component.html",
	styleUrls: ["./profile.component.scss"]
})
export class ProfileComponent extends BaseComponent implements OnInit {
	isLoading$ = new BehaviorSubject<boolean>(true);
	isMobile$: Observable<boolean>;
	events$: Observable<IEvent[]>;
	teams$: Observable<ITeam[]>;
	currentUser$: Observable<ICurrentUser | undefined>;
	activeGroups$: Observable<IGroup[]>;
	completeUserEventGroups$: Observable<IUserEventGroup[]>;
	recentlyCompleteUserEventGroups$: Observable<IUserEventGroup[]>;
	publicGroups$: Observable<IGroup[]>;

	selectedEvent$ = new BehaviorSubject<IEvent | undefined>(undefined);
	selectedEventId$ = new BehaviorSubject<string>("");
	selectedGroupType$ = new BehaviorSubject<"Active" | "Complete">("Active");

	isEventNavOpen = false;
	joining = false;

	constructor(
		private store: Store,
		private router: Router,
		private groupService: GroupService,
		private dialog: MatDialog,
		private analyticsService: AnalyticsService,
		private snackbarService: SnackbarService
	) {
		super();
	}

	ngOnInit(): void {
		this.publicGroups$ = this.store.select(publicRoomsWithCapacity);
		this.events$ = this.store.select(events).pipe(takeUntil(this.destroyed$));
		this.currentUser$ = this.store.select(currentUserData).pipe(
			takeUntil(this.destroyed$),
			filter((user) => !!user),
			tap(() => this.isLoading$.next(false))
		);
		this.teams$ = this.store.select(teams).pipe(takeUntil(this.destroyed$));
		this.isMobile$ = this.store.select(isMobile).pipe(takeUntil(this.destroyed$));

		this.activeGroups$ = this.store
			.select(groups)
			.pipe((takeUntil(this.destroyed$), map((groups) => groups.filter((x) => x.status !== "Complete"))));

		this.completeUserEventGroups$ = this.store.select(userEventGroups).pipe(
			takeUntil(this.destroyed$),
			filter((userEventGroups) => !!userEventGroups),
			map((userEventGroups) => {
				return userEventGroups
					.filter((x) => x.status === "Complete")
					.filter((x) => {
						const date = new Date(x.date.valueOf());
						date.setDate(date.getDate() + 5);

						return isPast(date);
					});
			})
		);

		this.recentlyCompleteUserEventGroups$ = this.store.select(userEventGroups).pipe(
			takeUntil(this.destroyed$),
			filter((userEventGroups) => !!userEventGroups),
			map((userEventGroups) => {
				return userEventGroups
					.filter((x) => x.status === "Complete")
					.filter((x) => {
						const date = new Date(x.date.valueOf());
						date.setDate(date.getDate() + 5);

						return isFuture(date);
					});
			})
		);

		this.selectedEventId$
			.pipe(
				takeUntil(this.destroyed$),
				withLatestFrom(this.events$),
				filter(([eventId, events]) => !!eventId && !!events)
			)
			.subscribe(([eventId, events]) => {
				this.store.dispatch(loadPublicRoomsWithCapacity({ eventId }));

				this.selectedEvent$.next(events.find((x) => x.id === eventId));
			});

		this.events$
			.pipe(
				filter((events) => !!events && events.length > 0),
				take(1)
			)
			.subscribe((events) => {
				let event = events.find((x) => x.isPromotional);

				if (!event) {
					event = events[0];
				}

				this.selectedEventId$.next(event.id);
			});
	}

	getOpenEvents(events: IEvent[]): IEvent[] {
		return events.filter((x) => x.status === "Open");
	}

	selectEvent(eventId: string): void {
		this.selectedEventId$.next(eventId);
		this.isEventNavOpen = false;
	}

	selectGroupType(type: "Active" | "Complete"): void {
		this.selectedGroupType$.next(type);
	}

	getEventById(events: IEvent[], eventId: string): IEvent | undefined {
		return events.find((x) => x.id === eventId);
	}

	getGroupById(groups: IGroup[], groupId: string): IGroup | undefined {
		return groups.find((x) => x.id === groupId) ?? undefined;
	}

	createGroup(event: IEvent) {
		this.router.navigate(["event", event.id, "createGroup"]);
	}

	getEventType(events: IEvent[], eventId: string): string {
		return events.find((x) => x.id === eventId)?.type ?? "Generic";
	}

	getPublicGroupCount(groups: IGroup[], room: IPublicRoom): number {
		if (room.groupId == null) {
			return 0;
		} else {
			return groups.find((x) => x.id === room.groupId)?.managerIds.length ?? 0;
		}
	}

	getBuyInByScoringPreset(scoringPresetId: string, event: IEvent): number | undefined {
		return event.scoringPresets.find((x) => x.id === scoringPresetId)?.buyIn;
	}

	joinBotRoom(event: IEvent, currentUID: string): void {
		const scoring = event.scoringPresets.find((x) => x.isDefault);

		if (!scoring) {
			throw new Error(`Missing Default Scoring`);
		}

		const totalPot = event.defaultManagers * scoring.buyIn;

		const scoringOverview: IPhaseScoringOverview[] = scoring.phaseScorings.map((phaseScoring) => {
			const phase = event.phases.find((x) => x.id === phaseScoring.phaseId);

			if (!phase) {
				throw new Error(`Missing Phase ${phaseScoring.phaseId}`);
			}

			return {
				name: phase.name,
				amountForAllTiers: phaseScoring.amountForAllTiers ?? 0,
				amountToMakePhase: phaseScoring.amountToMakePhase ?? 0,
				tiers: phaseScoring.tiers.map((tierScoring) => {
					const tier = event.tiers.find((x) => x.id === tierScoring.tierId);

					if (!tier) {
						throw new Error(`Missing Tier ${tierScoring.tierId}`);
					}

					return {
						name: tier?.displayName,
						amountForTier: tierScoring.defaultValue * totalPot
					};
				})
			};
		});

		this.dialog
			.open(PublicRoomDialogComponent, {
				data: { undefined, scoringOverview, totalPot, isAlreadyInGroup: false }
			})
			.afterClosed()
			.pipe(take(1))
			.subscribe((result) => {
				this.joining = true;
				if (!result) {
					this.joining = false;
				} else {
					this.analyticsService.logEvent("join_bot_room");
					this.groupService
						.joinBotRoom(currentUID, event, scoring)
						.pipe(switchMap((groupId) => this.groupService.fillWithBots(event.id, groupId)))
						.subscribe({
							next: (auctionId) => {
								this.joining = false;
								this.router.navigate(["auction", auctionId]);
							},
							error: () => {
								this.joining = false;
								this.snackbarService.error("Error creating the bot group, please try again.");
							}
						});
				}
			});
	}
}

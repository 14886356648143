<mat-button-toggle-group class="toggle" [formControl]="viewControl">
	<mat-button-toggle value="normal"><mat-icon class="material-icons-outlined" svgIcon="bracket"></mat-icon></mat-button-toggle>
	<mat-button-toggle value="daily"><mat-icon>event</mat-icon></mat-button-toggle>
</mat-button-toggle-group>
<div id="nhl-bracket" *ngIf="viewControl.value === 'normal'">
	<div class="region east" *ngIf="getRegionByName('Western Conference') as region">
		<h2>Western Conference</h2>
		<div class="round">
			<div *ngFor="let round of rounds" class="matchups">
				<am-bracket-series-matchup-box
					*ngFor="let matchup of getMatchupsByRound(round, region.id)"
					[matchup]="matchup"
					[isReverse]="false"
					[isAuction]="isAuction"
				></am-bracket-series-matchup-box>
			</div>
		</div>
	</div>
	<div class="region" *ngIf="getRegionByName('Stanley Cup Finals') as region">
		<h2 style="text-align: center">{{ region.name }}</h2>
		<div class="round">
			<am-bracket-series-matchup-box
				[matchup]="getFinal()"
				[isReverse]="false"
				[isAuction]="isAuction"
			></am-bracket-series-matchup-box>
		</div>
	</div>
	<div class="region west" *ngIf="getRegionByName('Eastern Conference') as region">
		<h2>Eastern Conference</h2>
		<div class="round">
			<div *ngFor="let round of rounds" class="matchups">
				<am-bracket-series-matchup-box
					*ngFor="let matchup of getMatchupsByRound(round, region.id)"
					[matchup]="matchup"
					[isReverse]="true"
					[isAuction]="isAuction"
				></am-bracket-series-matchup-box>
			</div>
		</div>
	</div>
</div>
<div *ngIf="viewControl.value === 'daily'">
	<div class="games">
		<am-schedule-matchup-box *ngFor="let matchup of todayGames" [matchup]="matchup"></am-schedule-matchup-box>
	</div>
</div>

@if (league$ | async; as league) {
	@if (event$ | async; as event) {
		<ng-container *ngIf="isMobile$ | async; else desktop">
			<am-event-navigation></am-event-navigation>
			<ng-container *ngTemplateOutlet="scheduleSection"></ng-container>
			<ng-container [ngTemplateOutlet]="gamesSection" [ngTemplateOutletContext]="{ isMobile: true }"></ng-container>
		</ng-container>

		<ng-template #desktop>
			<ng-container *ngTemplateOutlet="scheduleSection"></ng-container>
			<div class="content">
				<am-event-navigation></am-event-navigation>
				<ng-container [ngTemplateOutlet]="gamesSection" [ngTemplateOutletContext]="{ isMobile: false }"></ng-container>
			</div>
		</ng-template>

		<ng-template #scheduleSection>
			<h2 class="header">{{ league.sport }} Schedule</h2>

			@if ({ value: selectedRound$ | async }; as selectedRound) {
				<am-schedule-calendar
					[tiers]="event.tiers"
					[games]="league.games"
					[phases]="event.phases"
					[eventStatus]="event.status"
					[selectedRound]="selectedRound.value"
					(selectRoundEvent)="selectedRound$.next($event)"
				></am-schedule-calendar>
			}
		</ng-template>

		<ng-template #gamesSection let-isMobile="isMobile">
			<ng-container *ngIf="selectedMatchups$ | async as selectedMatchups">
				@if (selectedRound$ | async; as selectedRound) {
					<ng-container *ngIf="!selectedRound.isBracket; else bracket">
						<ng-container *ngIf="group$ | async">
							<div class="options">
								<button
									mat-icon-button
									(click)="isFilterOpen = !isFilterOpen"
									cdkOverlayOrigin
									#filterTrigger="cdkOverlayOrigin"
									type="button"
								>
									<mat-icon iconPositionEnd>filter_alt</mat-icon>
								</button>
								<ng-template
									cdkConnectedOverlay
									[cdkConnectedOverlayOrigin]="filterTrigger"
									[cdkConnectedOverlayOpen]="isFilterOpen"
									[cdkConnectedOverlayOffsetX]="5"
									[cdkConnectedOverlayOffsetY]="5"
									(backdropClick)="isFilterOpen = !isFilterOpen"
								>
									<div class="filter mat-elevation-z8">
										<ng-container *ngIf="users$ | async as users">
											<h3>Filter Managers</h3>
											<ng-container *ngIf="managerFilter$ | async as managerFilter">
												<mat-checkbox
													*ngFor="let manager of managerFilter | keyvalue"
													[checked]="manager.value"
													(click)="updateManagerFilter(manager.key, !manager.value)"
												>
													{{ getUserById(manager.key, users) }}
												</mat-checkbox>
											</ng-container>
										</ng-container>
									</div>
								</ng-template>
							</div>
						</ng-container>
						<div class="games" [ngClass]="{ center: isMobile }">
							<am-schedule-matchup-box *ngFor="let matchup of selectedMatchups" [matchup]="matchup"></am-schedule-matchup-box>
						</div>
					</ng-container>
					<ng-template #bracket>
						<div [ngClass]="{ 'mobile-auction-width': isMobile, 'default-width': !isMobile }">
							<am-event-bracket
								[rounds]="selectedRound.rounds"
								[matchups]="selectedMatchups"
								[regions]="league.regions"
								[sport]="league.sport"
							></am-event-bracket>
						</div>
					</ng-template>
				}
			</ng-container>
		</ng-template>
	}
}

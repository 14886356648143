import { Component, Input } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CommonModule } from "@angular/common";

@Component({
	selector: "am-loader",
	template: `
		<mat-spinner style="margin: auto" *ngIf="loading"> color="primary" *ngIf="loading"></mat-spinner>
		<ng-content *ngIf="!loading"></ng-content>
	`,
	styles: [""],
	standalone: true,
	imports: [CommonModule, MatProgressSpinnerModule]
})
export class LoaderComponent {
	@Input() loading: boolean | null;
}

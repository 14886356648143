import { Injectable } from "@angular/core";
import { doc, docData, Firestore, QueryDocumentSnapshot, SnapshotOptions } from "@angular/fire/firestore";
import { Functions, httpsCallableData } from "@angular/fire/functions";
import { Observable } from "rxjs";
import { IAuction } from "../models/auction";
import { FunctionClient } from "./base/function.service";

@Injectable({
	providedIn: "root"
})
export class AuctionService {
	constructor(private fs: Firestore, private fns: Functions, private client: FunctionClient) {}

	getbyId(auctionId: string): Observable<IAuction> {
		return docData(doc(this.fs, "auctions", auctionId).withConverter(AuctionConverter));
	}

	getServerTime(): Observable<string> {
		const callable = httpsCallableData(this.fns, "getTime");
		return callable() as Observable<string>;
	}

	startAuction(auctionId: string): Observable<string> {
		return this.client.Put<string>(`auction-server/api/auction/${auctionId}/start`);
	}

	submitBid(auctionId: string, teamId: string, bid: number): Observable<string> {
		return this.client.Put<string>(`auction-server/api/auction/${auctionId}/team/${teamId}/bid/${bid}`);
	}

	pass(auctionId: string | undefined): Observable<boolean> {
		if (!auctionId) {
			throw new Error("Must have auctionId to toggle 'Not Interested'");
		}

		return this.client.Put<boolean>(`auction-server/api/auction/${auctionId}/pass`);
	}
}

const AuctionConverter = {
	toFirestore: (auction: IAuction) => {
		return {};
	},
	fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions | undefined) => {
		let data = snapshot.data(options);
		return {
			...data,
			id: snapshot.id,
			pricePerTeam: data["pricePerTeam"] ? new Map(Object.entries(data["pricePerTeam"])) : new Map(),
			budgets: data["budgets"] ? new Map(Object.entries(data["budgets"])) : new Map(),
			rosters: data["rosters"] ? new Map(Object.entries(data["rosters"])) : new Map(),
			startTime: data["startTime"]?.toString().includes("Z") ? new Date(data["startTime"]) : data["startTime"].toDate(),
			expirationTime: new Date(data["expirationTime"])
		} as IAuction;
	}
};

import { createSelector, createFeatureSelector } from "@ngrx/store";
import { ITeam } from "src/app/models/team";
import { EventState, selectAllEvents, selectEventIds } from "./event.reducers";
import { IGame } from "src/app/models/game";
import { IEvent, IPhase } from "src/app/models/event";

export const eventStateSelector = createFeatureSelector<EventState>("event");

export const events = createSelector(eventStateSelector, selectAllEvents);

export const eventIds = createSelector(eventStateSelector, selectEventIds);

export const selectedEventId = createSelector(eventStateSelector, (state) => state.selectedEventId);

export const selectedRound = createSelector(eventStateSelector, (state) => state.selectedRound);

export const currentEvent = createSelector(events, selectedEventId, (events, selectedEventId) =>
	events.find((x) => x.id === selectedEventId)
);

export const activeEvents = createSelector(events, (events) => events.filter((e) => e.status !== "Archived" && e.status !== "Complete"));

export const tiers = createSelector(currentEvent, (currentEvent) => (currentEvent ? currentEvent.tiers : []));

export const phases = createSelector(currentEvent, (currentEvent) =>
	((currentEvent ? JSON.parse(JSON.stringify(currentEvent.phases)) : []) as IPhase[]).sort((a: IPhase, b: IPhase) => a.order - b.order)
);

export const tiersInPhase = createSelector(currentEvent, selectedRound, (currentEvent, phaseId) =>
	(currentEvent ? currentEvent.tiers : []).filter((t) => t.phaseId === phaseId)
);

export const liveData = createSelector(eventStateSelector, (state) => state?.liveData);

export const getEventById = (eventId: string) =>
	createSelector(events, (events: IEvent[]) => {
		return events.find((x) => x.id === eventId);
	});

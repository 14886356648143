import { Component, Input } from "@angular/core";
import { IMatchup } from "src/app/models/game";

@Component({
	selector: "am-schedule-matchup-box",
	templateUrl: "./schedule-matchup-box.component.html",
	styleUrls: ["./schedule-matchup-box.component.scss"]
})
export class ScheduleMatchupBoxComponent {
	@Input() matchup: IMatchup;

	detailsOpen: boolean = false;

	eventStarted(): boolean {
		return this.matchup.eventStatus !== "Open" && this.matchup.eventStatus !== "Created";
	}
}

import { Component, Input } from "@angular/core";
import { IMatchup } from "src/app/models/game";
import { IRegion, ITier } from "src/app/models/event";
import { FormControl } from "@angular/forms";
import { isWithinInterval } from "date-fns";

@Component({
	selector: "am-nhl-bracket",
	templateUrl: "./nhl-bracket.component.html",
	styleUrls: ["./nhl-bracket.component.scss"]
})
export class NHLBracketComponent {
	@Input() tiers: ITier[];
	@Input() matchups: IMatchup[];
	@Input() regions: IRegion[];
	@Input() isAuction: boolean;

	viewControl: FormControl = new FormControl("normal");

	getRegionByName(name: string): IRegion | undefined {
		return this.regions.find((x) => x.name === name);
	}

	getMatchupsByRound(tierId: string, regionId: string): IMatchup[] {
		return this.matchups.filter((x) => x.tierId === tierId && x.regionId === regionId).sort((a, b) => a.displayOrder - b.displayOrder);
	}

	get todayGames(): IMatchup[] {
		const today = new Date();
		today.setHours(0, 0, 0, 0);
		const tomorrow = new Date();
		tomorrow.setDate(today.getDate() + 1);
		tomorrow.setHours(0, 0, 0, 0);
		const allGames = this.matchups.flatMap((x) => x.subMatchups);

		return allGames.filter((x) => x.startTime && isWithinInterval(x.startTime, { start: today, end: tomorrow }));
	}

	getFinal(): IMatchup {
		const finalTier = this.tiers.find((x) => x.displayName === "Stanley Cup Finals");
		const final = this.matchups.find((x) => x.tierId === finalTier?.id);

		if (!final) {
			throw new Error("Missing Stanley Cup Final");
		}

		return final;
	}
}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { take } from "rxjs";

@Component({
	selector: "am-legal",
	template: `<iframe id="legal-iframe" [srcdoc]="template | safeHtml"></iframe>`,
	styles: [
		`
			:host {
				flex: 1;
			}

			#legal-iframe {
				height: 100%;
				width: calc(100% - 4px);
				margin-left: auto;
			}
		`
	]
})
export class LegalComponent implements OnInit {
	public template = "";

	private cookieTemplate = `
	<div name="termly-embed" data-id="6274e296-7756-4240-96b8-9972264f8a91" data-type="iframe"></div>
	<script type="text/javascript">(function(d, s, id) {
		var js, tjs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) return;
		js = d.createElement(s); js.id = id;
		js.src = "https://app.termly.io/embed-policy.min.js";
		tjs.parentNode.insertBefore(js, tjs);
	}(document, 'script', 'termly-jssdk'));</script>
	`;

	private termsOfServiceTemplate = `
	<div name="termly-embed" data-id="363b7bf2-eaab-4d21-a8ed-d146d55376a7" data-type="iframe"></div>
	<script type="text/javascript">(function(d, s, id) {
		var js, tjs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) return;
		js = d.createElement(s); js.id = id;
		js.src = "https://app.termly.io/embed-policy.min.js";
		tjs.parentNode.insertBefore(js, tjs);
	}(document, 'script', 'termly-jssdk'));</script>
	`;

	private privacyPolicyTemplate = `
	<div name="termly-embed" data-id="415c1fa9-8bcf-4e1f-9d31-41cfff18ba3d" data-type="iframe"></div>
	<script type="text/javascript">(function(d, s, id) {
		var js, tjs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) return;
		js = d.createElement(s); js.id = id;
		js.src = "https://app.termly.io/embed-policy.min.js";
		tjs.parentNode.insertBefore(js, tjs);
	}(document, 'script', 'termly-jssdk'));</script>
	`;

	constructor(private route: ActivatedRoute) {}

	ngOnInit(): void {
		this.route.data.pipe(take(1)).subscribe((data: any) => {
			switch (data.type) {
				case "cookies":
					this.template = this.cookieTemplate;
					break;
				case "privacy-policy":
					this.template = this.termsOfServiceTemplate;
					break;
				case "terms-of-service":
					this.template = this.privacyPolicyTemplate;
					break;
				default:
					this.template = `<div>Page not found.</div>`;
			}
		});
		throw new Error("Method not implemented.");
	}
}

<ng-container *ngIf="leagueId && seasonId">
	<button mat-button (click)="loadData()">Get All Data</button>

	<ng-container *ngIf="!loadingGames; else loading">
		<mat-tab-group>
			<mat-tab *ngIf="teams.length > 0">
				<ng-template mat-tab-label>
					<div
						[matBadge]="selectedTeams.length"
						matBadgeSize="small"
						[matBadgeHidden]="selectedTeams.length === 0"
						matBadgeOverlap="false"
					>
						Teams
					</div>
				</ng-template>
				<div class="team-filters">
					<button mat-stroked-button (click)="selectAllTeams()">Select All</button>
					<button mat-stroked-button (click)="removeAllTeamSelections()">Remove All</button>
				</div>
				<mat-selection-list #teamList (selectionChange)="selectedTeam($event.options)" class="list">
					<mat-list-option *ngFor="let team of teams" [value]="team" [selected]="team.selected" [disabled]="team.inUse">
						{{ team.displayName }}
					</mat-list-option>
				</mat-selection-list>
			</mat-tab>
			<mat-tab *ngIf="games.length > 0" isActive>
				<ng-template mat-tab-label>
					<div
						[matBadge]="selectedGames.length"
						matBadgeSize="small"
						[matBadgeHidden]="selectedGames.length === 0"
						matBadgeOverlap="false"
					>
						Games
					</div>
				</ng-template>
				<div [formGroup]="gameFilters" class="game-filters">
					<div class="row">
						<mat-form-field>
							<mat-label>Round</mat-label>
							<mat-select formControlName="round">
								<mat-option [value]="">Any</mat-option>
								<mat-option *ngFor="let round of rounds" [value]="round">{{ round }}</mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field>
							<mat-label>Status</mat-label>
							<mat-select formControlName="status">
								<mat-option [value]="">Any</mat-option>
								<mat-option *ngFor="let status of statuses" [value]="status">{{ status }}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="row">
						<button mat-stroked-button (click)="selectAllGames()">Select All</button>
						<button mat-stroked-button (click)="removeAllGameSelections()">Remove All</button>
					</div>
				</div>
				<mat-selection-list #gameList (selectionChange)="selectedGame($event.options)" class="list">
					<mat-list-option *ngFor="let game of games" [value]="game" [selected]="game.selected" [disabled]="game.inUse">
						<span matListItemTitle>{{ game.name }}</span>
						<span matListItemLine>{{ game.startTime | date }} - {{ game.round }}</span>
					</mat-list-option>
				</mat-selection-list>
			</mat-tab>
		</mat-tab-group>
	</ng-container>
	<ng-template #loading>
		<div class="loader">
			<mat-spinner diameter="35"></mat-spinner>
		</div>
	</ng-template>
</ng-container>

import { Component, Input, OnChanges } from "@angular/core";
import { IMatchup } from "src/app/models/game";

@Component({
	selector: "am-bracket-matchup-box-simple",
	templateUrl: "./bracket-matchup-box-simple.component.html",
	styleUrls: ["./bracket-matchup-box-simple.component.scss"]
})
export class BracketMatchupBoxSimpleComponent {
	@Input() matchup: IMatchup;
	@Input() isReverse: boolean;
}

import { createSelector, createFeatureSelector } from "@ngrx/store";
import { ITeam } from "src/app/models/team";
import { EventState, selectAllEvents, selectEventIds } from "./event.reducers";
import { IGame } from "src/app/models/game";
import { IEvent, IPhase } from "src/app/models/event";

export const eventStateSelector = createFeatureSelector<EventState>("event");

export const events = createSelector(eventStateSelector, selectAllEvents);

export const eventIds = createSelector(eventStateSelector, selectEventIds);

export const selectedEventId = createSelector(eventStateSelector, (state) => state.selectedEventId);

export const selectedPhaseId = createSelector(eventStateSelector, (state) => state.selectedPhaseId);

export const currentEvent = createSelector(events, selectedEventId, (events, selectedEventId) =>
	events.find((x) => x.id === selectedEventId)
);

export const activeEvents = createSelector(events, (events) => events.filter((e) => e.status !== "Archived" && e.status !== "Complete"));

export const teams = createSelector(currentEvent, (currentEvent) => (currentEvent ? currentEvent.teams : []));

export const tiers = createSelector(currentEvent, (currentEvent) => (currentEvent ? currentEvent.tiers : []));

export const phases = createSelector(currentEvent, (currentEvent) =>
	((currentEvent ? JSON.parse(JSON.stringify(currentEvent.phases)) : []) as IPhase[]).sort((a: IPhase, b: IPhase) => a.order - b.order)
);

export const selectedPhase = createSelector(
	currentEvent,
	selectedPhaseId,
	(currentEvent, phaseId) => currentEvent?.phases?.find((p) => p.id === phaseId) || ({} as IPhase)
);

export const tiersInPhase = createSelector(currentEvent, selectedPhaseId, (currentEvent, phaseId) =>
	(currentEvent ? currentEvent.tiers : []).filter((t) => t.phaseId === phaseId)
);

export const liveData = createSelector(eventStateSelector, (state) => state?.liveData);

export const teamsByDivision = createSelector(teams, (teams) =>
	teams!!.reduce((map, team) => {
		if (!map.has(team.division)) {
			map.set(team.division, [team]);
		} else {
			map.get(team.division)?.push(team);
		}
		return map;
	}, new Map<string, ITeam[]>())
);

export const games = createSelector(currentEvent, teams, (currentEvent, teams) => {
	if (currentEvent == null || currentEvent.games == null) {
		return [] as IGame[];
	}

	return currentEvent.games.map(
		(g) =>
			({
				...g,
				team1: teams?.find((t) => t.id === g.team1Id),
				team2: teams?.find((t) => t.id === g.team2Id)
			} as IGame)
	);
});

export const orderedGames = createSelector(games, (games) => {
	return games.sort((a, b) => {
		const aID = Number(a.id);
		const bID = Number(b.id);

		if (aID > bID) {
			return 1;
		}
		if (aID < bID) {
			return -1;
		}

		return 0;
	});
});

export const getEventById = (eventId: string) =>
	createSelector(events, (events: IEvent[]) => {
		return events.find((x) => x.id === eventId);
	});

export const getTeamsByEventId = (eventId: string) =>
	createSelector(events, (events: IEvent[]) => events.find((x) => x.id === eventId)?.teams);

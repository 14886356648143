import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "../base.component";
import { MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from "@angular/material/dialog";
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BehaviorSubject, filter, take } from "rxjs";
import { ICurrentUser, IUser } from "src/app/models/user";
import { Store } from "@ngrx/store";
import { currentUserData } from "src/app/state/user/user.selectors";
import { UserService } from "src/app/services/user.service";
import { getUserData } from "src/app/state/user/user.actions";
import { MatButton } from "@angular/material/button";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatDivider } from "@angular/material/divider";

@Component({
    selector: "am-edit-profile",
    templateUrl: "./edit-profile-dialog.component.html",
    styleUrls: ["./edit-profile-dialog.component.scss"],
    standalone: true,
    imports: [MatDialogTitle, MatDivider, MatDialogContent, FormsModule, ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatDialogActions, MatButton]
})
export class EditProfileDialog extends BaseComponent implements OnInit {
	form: FormGroup;

	currentUser$ = new BehaviorSubject<ICurrentUser | undefined>(undefined);

	constructor(
		public dialogRef: MatDialogRef<EditProfileDialog>,
		private formBuilder: FormBuilder,
		private store: Store,
		private userService: UserService
	) {
		super();
	}

	ngOnInit(): void {
		this.store
			.select(currentUserData)
			.pipe(
				take(1),
				filter((user) => !!user)
			)
			.subscribe((user) => {
				this.currentUser$.next(user);

				this.form = this.formBuilder.group({
					displayName: new FormControl(user?.userInfo?.displayName, Validators.required)
				});
			});
	}

	submit(): void {
		let currentUser = this.currentUser$.value;

		if (this.form.dirty && currentUser?.uid) {
			this.userService
				.updateUser(currentUser.uid, this.form.get("displayName")?.value, currentUser?.userInfo?.profileIcon)
				.then(() => {
					this.store.dispatch(getUserData({ userId: currentUser!!.uid }));
					this.dialogRef.close();
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}
}

import { Pipe, PipeTransform } from "@angular/core";
import { ITier } from "../../models/event";
import { AbstractControl, FormGroup } from "@angular/forms";

@Pipe({
	name: "enabledControls",
	pure: false
})
export class EnabledControlFilterPipe implements PipeTransform {
	transform(controls: AbstractControl[]): any {
		return controls.filter((c) => c.enabled);
	}
}

import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from "@angular/material/dialog";
import { IMatchup } from "src/app/models/game";
import { MatButton } from "@angular/material/button";
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription } from "@angular/material/expansion";
import { MatTooltip } from "@angular/material/tooltip";
import { NgIf, NgFor, NgClass, CurrencyPipe, DatePipe } from "@angular/common";
import { MatDivider } from "@angular/material/divider";

@Component({
    selector: "am-series-component-dialog",
    templateUrl: "./series-component-dialog.component.html",
    styleUrls: ["./series-component-dialog.component.scss"],
    standalone: true,
    imports: [MatDialogTitle, MatDialogContent, MatDivider, NgIf, MatTooltip, MatAccordion, NgFor, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription, NgClass, MatDialogActions, MatButton, MatDialogClose, CurrencyPipe, DatePipe]
})
export class SeriesComponentDialogComponent {
	mainMatchup: IMatchup;
	isMobile: boolean;

	constructor(
		public dialogRef: MatDialogRef<SeriesComponentDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		private data: { main: IMatchup; isMobile: boolean }
	) {
		this.mainMatchup = data.main;
		this.isMobile = data.isMobile;
	}

	get orderSubMatchups(): IMatchup[] {
		return this.mainMatchup.subMatchups.sort((a, b) => (a.startTime?.getTime() ?? 0) - (b.startTime?.getTime() ?? 0));
	}
}

import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogRef, MatDialogContent, MatDialogActions } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { filter, take } from "rxjs";
import { UserService } from "src/app/services/user.service";
import { BaseComponent } from "src/app/shared/components/base.component";
import { currentUserData } from "src/app/state/user/user.selectors";
import { MatButton } from "@angular/material/button";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel } from "@angular/material/form-field";

@Component({
    selector: "am-delete-profile-dialog",
    templateUrl: "./delete-profile-dialog.component.html",
    styleUrls: ["./delete-profile-dialog.component.scss"],
    standalone: true,
    imports: [MatDialogContent, MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, MatDialogActions, MatButton]
})
export class DeleteProfileDialogComponent extends BaseComponent implements OnInit {
	displayName = "Test User";
	emailConfirmation = new FormControl("", [Validators.required, this.nameMatchValidator()]);

	constructor(private dialogRef: MatDialogRef<DeleteProfileDialogComponent>, private store: Store, private userService: UserService) {
		super();
	}

	ngOnInit(): void {
		this.store
			.select(currentUserData)
			.pipe(
				take(1),
				filter((user) => !!user)
			)
			.subscribe((user) => {
				if (user) {
					this.displayName = user?.userInfo?.displayName || "";
				}
			});
	}

	close(): void {
		this.dialogRef.close();
	}

	async deleteAccount(): Promise<void> {
		await this.userService.deleteUser();
		this.dialogRef.close(true);
	}

	nameMatchValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			return control.value.toUpperCase() !== this.displayName.toUpperCase() ? { nameDoesntMatch: { value: control.value } } : null;
		};
	}
}

<mat-toolbar color="primary" class="main-toolbar">
	<ng-container *ngIf="authChecked$ | async">
		<ng-container *ngIf="currentUser$ | async as user; else unauthenticated">
			<img style="cursor: pointer" height="54" width="54" (click)="navigate(['home'])" src="../assets/icons/logos/generic.svg" />

			<span class="spacer"></span>
			<ng-container *ngIf="isAdmin$ | async">
				<button mat-icon-button (click)="openEventAdmin()"><mat-icon>security</mat-icon></button>
			</ng-container>
			<ng-container *ngIf="notifications$ | async as notifications">
				<button
					matTooltip="Notifications"
					mat-icon-button
					type="button"
					(click)="enableShowNotifications(notifications)"
					cdkOverlayOrigin
					#trigger="cdkOverlayOrigin"
				>
					<mat-icon
						[matBadge]="
							unseenNotificaionCount(notifications, user.userInfo) > 0
								? unseenNotificaionCount(notifications, user.userInfo)
								: ''
						"
						matBadgeColor="warn"
					>
						notifications
					</mat-icon>
				</button>

				<ng-template
					cdkConnectedOverlay
					[cdkConnectedOverlayOrigin]="trigger"
					[cdkConnectedOverlayOpen]="showNotifications"
					[cdkConnectedOverlayHasBackdrop]="showNotifications"
					(backdropClick)="showNotifications = !showNotifications"
				>
					<div *ngIf="notifications$ | async as notifications" class="notifications">
						<div
							*ngFor="let notification of notifications"
							[id]="notification.id"
							class="notification"
							(click)="openNotification(notification, user.uid)"
						>
							<div class="notification-header">
								<mat-icon>{{ notification.icon }}</mat-icon>
								<h3>{{ notification.title }}</h3>
							</div>

							<div class="message">{{ notification.message }}</div>
							<span class="date">Created: {{ notification.createDate | date : "short" }}</span>
							<mat-divider></mat-divider>
						</div>
					</div>
				</ng-template>
			</ng-container>
			<button *ngIf="!(isMobile$ | async); else mobileProfileIcon" mat-icon-button [matMenuTriggerFor]="userMenu">
				<img *ngIf="user?.userInfo?.profileIcon != null" [src]="user.userInfo.profileIcon" />
				<mat-icon class="profile-icon" *ngIf="user?.userInfo?.profileIcon == null" svgIcon="am-profile"></mat-icon>
			</button>

			<div>{{ user?.userInfo?.displayName }}</div>

			<mat-menu #userMenu="matMenu">
				<button mat-menu-item (click)="navigate(['home'])">
					{{ user?.userInfo?.displayName }}
				</button>
				<button mat-menu-item (click)="openEditProfile()">
					<mat-icon>edit</mat-icon>
					Edit Profile
				</button>
				<button mat-menu-item (click)="logout()">
					<mat-icon>logout</mat-icon>
					Logout
				</button>
			</mat-menu>
		</ng-container>

		<ng-template #unauthenticated>
			<img height="54" width="54" (click)="navigate(['home'])" src="../assets/icons/logos/generic.svg" />
			<span class="spacer"></span>
			<button mat-flat-button color="primary" *ngIf="!(onLoginPage$ | async)" (click)="login()">Login</button>
		</ng-template>
	</ng-container>

	<ng-template #mobileProfileIcon>
		<button *ngIf="currentUser$ | async as user" mat-icon-button (click)="toggleDrawer()">
			<img *ngIf="user?.userInfo?.profileIcon" [src]="user.userInfo.profileIcon" />
			<mat-icon class="profile-icon" *ngIf="user?.userInfo?.profileIcon == null" svgIcon="am-profile"></mat-icon>
		</button>
	</ng-template>
</mat-toolbar>

<mat-sidenav-container [hasBackdrop]="true" (backdropClick)="closeDrawers()" class="sidenav-container">
	<mat-sidenav #rightDrawer mode="over" position="end">
		<ng-container *ngIf="currentUser$ | async as user">
			<div class="profile">
				<img width="125" height="125" *ngIf="user?.userInfo?.profileIcon" [src]="user.userInfo.profileIcon" />
				<mat-icon class="mobile-profile-icon" *ngIf="user?.userInfo?.profileIcon == null"> account_circle </mat-icon>
				<div>{{ user?.userInfo?.displayName }}</div>
				<div class="email">{{ user?.userInfo?.email || user?.privateInfo?.email }}</div>

				<mat-nav-list>
					<mat-list-item (click)="navigate(['home']); rightDrawer.close()">
						<mat-icon matListItemIcon>person</mat-icon>
						<span matListItemTitle>Profile</span>
					</mat-list-item>
					<mat-list-item (click)="openEditProfile()">
						<mat-icon matListItemIcon>edit</mat-icon>
						<span matListItemTitle>Edit Profile</span>
					</mat-list-item>
				</mat-nav-list>

				<span class="spacer"></span>

				<mat-divider></mat-divider>
				<button mat-button (click)="logout()">
					<mat-icon>logout</mat-icon>
					Logout
				</button>
			</div>
		</ng-container>
	</mat-sidenav>

	<div class="router-outlet">
		<router-outlet></router-outlet>
	</div>
</mat-sidenav-container>

<div class="content">
	<ng-container *ngIf="currentUser$ | async as user">
		<ng-container *ngIf="events$ | async as events">
			<ng-container *ngIf="selectedEvent$ | async as selectedEvent">
				<!-- HEADER -->
				<ng-container *ngIf="isMobile$ | async; else desktop">
					<div id="mobile-header">
						<div class="event-navigation" *ngIf="getOpenEvents(events).length > 1">
							<!-- Event Navigation -->
							<button
								mat-icon-button
								(click)="isEventNavOpen = !isEventNavOpen"
								cdkOverlayOrigin
								#mobileEventNavPopup="cdkOverlayOrigin"
							>
								<mat-icon>keyboard_arrow_down</mat-icon>
							</button>

							<ng-template
								cdkConnectedOverlay
								[cdkConnectedOverlayOrigin]="mobileEventNavPopup"
								[cdkConnectedOverlayOpen]="isEventNavOpen"
								[cdkConnectedOverlayOffsetX]="-45"
								[cdkConnectedOverlayOffsetY]="5"
								(backdropClick)="isEventNavOpen = !isEventNavOpen"
							>
								<div class="event-bar-popup mat-elevation-z8">
									<h4 (click)="selectEvent('All')" class="cursor-pointer">All</h4>
									<h4 *ngFor="let event of getOpenEvents(events)" (click)="selectEvent(event.id)" class="cursor-pointer">
										{{ event.name }}
									</h4>
								</div>
							</ng-template>
						</div>
						<div
							class="selected-event"
							[style.background-image]="'url(../../../assets/img/stock/' + selectedEvent.type.toLowerCase() + '.jpg)'"
						>
							<div class="event-box">
								<img
									width="125"
									height="125"
									[src]="
										'../assets/icons/logos/' +
										(selectedEvent?.type ? selectedEvent.type.toLowerCase() : 'generic') +
										'.svg'
									"
								/>
								<h2>{{ selectedEvent.name }}</h2>
								<p>{{ selectedEvent.description }}</p>
								<div class="buttons">
									<button mat-flat-button color="accent" (click)="createGroup(selectedEvent)">Create A Group</button>
								</div>
							</div>

							<ng-container *ngIf="publicGroups$ | async as publicGroups">
								<ng-container
									[ngTemplateOutlet]="tutorialWidget"
									[ngTemplateOutletContext]="{ publicGroups: publicGroups }"
								></ng-container>
							</ng-container>
						</div>
					</div>
				</ng-container>
				<ng-template #desktop>
					<div id="desktop-header">
						<div class="event-navigation" *ngIf="getOpenEvents(events).length > 1">
							<div *ngFor="let event of getOpenEvents(events)">
								<h3
									class="cursor-pointer"
									(click)="selectEvent(event.id)"
									[ngClass]="{ selected: selectedEvent.id === event.id }"
								>
									{{ event.type }}
								</h3>
							</div>
						</div>
						<div
							class="selected-event"
							[style.background-image]="'url(../../../assets/img/stock/' + selectedEvent.type.toLowerCase() + '.jpg)'"
						>
							<mat-card class="event-box">
								<img
									width="175"
									height="175"
									style="margin: auto"
									[src]="
										'../assets/icons/logos/' +
										(selectedEvent?.type ? selectedEvent.type.toLowerCase() : 'generic') +
										'.svg'
									"
								/>
								<div class="event-content">
									<h2>{{ selectedEvent.name }}</h2>
									<p>{{ selectedEvent.description }}</p>
									<button mat-flat-button color="accent" (click)="createGroup(selectedEvent)">Create A Group</button>
								</div>
							</mat-card>

							<ng-container *ngIf="publicGroups$ | async as publicGroups">
								<ng-container
									[ngTemplateOutlet]="tutorialWidget"
									[ngTemplateOutletContext]="{
										publicGroups: publicGroups
									}"
								></ng-container>
							</ng-container>
						</div>
					</div>
				</ng-template>
				<!-- GROUPS -->
				<ng-container *ngIf="{ value: isMobile$ | async } as isMobile">
					<mat-tab-group [mat-stretch-tabs]="!isMobile" mat-align-tabs="start">
						<mat-tab label="Active">
							<ng-container *ngIf="activeGroups$ | async as activeGroups">
								<div class="groups" [ngClass]="{ 'groups-mobile': isMobile.value }">
									<mat-card
										*ngIf="activeGroups?.length === 0"
										class="empty-group-card cursor-pointer"
										(click)="createGroup(selectedEvent!!)"
									>
										<mat-icon svgIcon="am-add"></mat-icon>
										<h2>Create a Group</h2>
									</mat-card>

									<div *ngFor="let group of activeGroups">
										<am-group-card
											[group]="group"
											[eventId]="group.eventId"
											[type]="getEventType(events, group.eventId)"
											[uid]="user.uid"
											[isMobile]="isMobile.value"
										></am-group-card>
									</div>

									<div *ngFor="let recentlyCompletedGroups of recentlyCompleteUserEventGroups$ | async">
										<am-completed-group-card
											[group]="recentlyCompletedGroups"
											[isMobile]="isMobile.value"
										></am-completed-group-card>
									</div>
								</div>
							</ng-container>
						</mat-tab>
						<ng-container *ngIf="completeUserEventGroups$ | async as completeGroups">
							<mat-tab *ngIf="completeGroups.length > 0" label="Completed">
								<div class="groups" [ngClass]="{ 'groups-mobile': isMobile.value }">
									<ng-container *ngFor="let group of completeGroups">
										<am-completed-group-card [group]="group" [isMobile]="isMobile.value"></am-completed-group-card>
									</ng-container>
								</div>
							</mat-tab>
						</ng-container>
					</mat-tab-group>
				</ng-container>

				<!-- PUBLIC ROOMS -->
				<div id="public-rooms" [class]="(isMobile$ | async) ? 'public-rooms-mobile' : 'public-rooms'">
					<am-public-room [eventId]="selectedEvent.id"></am-public-room>
				</div>

				<am-footer></am-footer>

				<ng-template #tutorialWidget>
					<mat-card class="tutorial-box">
						<div class="header">
							<h2>Instant Auction</h2>
							<img
								width="75"
								height="75"
								[src]="
									'../assets/icons/logos/' + (selectedEvent.type ? selectedEvent.type.toLowerCase() : 'generic') + '.svg'
								"
							/>
						</div>
						<mat-divider />
						<div class="font">Experience Auction Madness by challenging our AI in a live auction!</div>
						<mat-divider />
						<div class="font"><b>Auction Time: Now!</b></div>
						<div class="actions">
							<am-loading-button
								title="Play Now"
								[loading]="joining"
								color="accent"
								(clicked)="joinBotRoom(selectedEvent, user.uid)"
							></am-loading-button>
						</div>
					</mat-card>
				</ng-template>
			</ng-container>
		</ng-container>
	</ng-container>
</div>

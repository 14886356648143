<div class="content">
	@if (currentUser$ | async; as user) {
		<!-- HEADER -->

		@if (promotedLeague(); as league) {
			@if (leagueEvents$ | async; as events) {
				<div class="league" [style.background-image]="'url(../../../assets/img/stock/' + league.type.toLowerCase() + '.jpg)'">
					<mat-card>
						<mat-card-header>
							<div
								mat-card-avatar
								[style]="
									'background-image: url(../assets/icons/logos/' +
									league.type.toLowerCase() +
									'.svg); background-size: cover'
								"
							></div>
							<mat-card-title>{{ league.year }} {{ league.sport }}</mat-card-title>
							<mat-card-subtitle>Join Auctions Today</mat-card-subtitle>
						</mat-card-header>
						<mat-card-content class="card-content" [ngClass]="{ 'mobile-card': isMobile$ | async }">
							<p>
								{{ league.description }}
							</p>
							<img width="150" height="150" [src]="'../assets/icons/logos/' + league.type.toLowerCase() + '.svg'" />
						</mat-card-content>
						<mat-divider></mat-divider>
						<mat-card-actions>
							<button color="primary" mat-stroked-button [matMenuTriggerFor]="menu">Join</button>
							<mat-menu #menu="matMenu">
								@for (event of events; track $index) {
									<button mat-menu-item (click)="createGroup(league.id, event.id)">{{ event.name }}</button>
								}
								<button (click)="valuePicks(league.id, events)" mat-menu-item>Value Picks</button>
							</mat-menu>
							<button color="accent" (click)="joinBotRoom(events, user.uid)" mat-raised-button>Play Now</button>
						</mat-card-actions>
					</mat-card>
				</div>
			}
		}

		<!-- GROUPS -->
		<div class="group-tabs">
			<ng-container *ngIf="{ value: isMobile$ | async } as isMobile">
				<mat-tab-group [mat-stretch-tabs]="!isMobile" mat-align-tabs="start">
					<mat-tab label="Active">
						@if (activeGroups$ | async; as activeGroups) {
							<div class="groups" [ngClass]="{ 'groups-mobile': isMobile.value }">
								<mat-card appearance="outlined" *ngIf="activeGroups?.length === 0" class="empty-group-card cursor-pointer">
									<!-- (click)="createGroup(selectedEvent!!)" -->
									<mat-icon svgIcon="am-add"></mat-icon>
									<h2>Create a Group</h2>
								</mat-card>

								<div *ngFor="let group of activeGroups">
									<am-group-card
										[group]="group"
										[leagueId]="group.leagueId"
										[eventId]="group.eventId"
										[type]="getLeagueType(group.leagueId)"
										[uid]="user.uid"
										[isMobile]="isMobile.value"
									></am-group-card>
								</div>

								<div *ngFor="let recentlyCompletedGroups of recentlyCompleteUserEventGroups$ | async">
									<am-completed-group-card
										[group]="recentlyCompletedGroups"
										[isMobile]="isMobile.value"
									></am-completed-group-card>
								</div>
							</div>
						}
					</mat-tab>
					<ng-container *ngIf="completeUserEventGroups$ | async as completeGroups">
						<mat-tab *ngIf="completeGroups.length > 0" label="Completed">
							<div class="groups" [ngClass]="{ 'groups-mobile': isMobile.value }">
								<ng-container *ngFor="let group of completeGroups">
									<am-completed-group-card [group]="group" [isMobile]="isMobile.value"></am-completed-group-card>
								</ng-container>
							</div>
						</mat-tab>
					</ng-container>
				</mat-tab-group>
			</ng-container>
		</div>
	}

	<!-- PUBLIC ROOMS: TODO: Need to reconsider this -->
	<!-- <div id="public-rooms" [class]="(isMobile$ | async) ? 'public-rooms-mobile' : 'public-rooms'">
			<am-public-room [eventId]="selectedEvent.id"></am-public-room>
		</div> -->
</div>

<am-footer></am-footer>

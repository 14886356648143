<h2>Ready for more?</h2>

@if (currentLeague(); as currentLeague) {
<div class="content">
	<img
		width="100"
		height="100"
		[src]="'../assets/icons/logos/' + (currentLeague.type ? currentLeague.type.toLowerCase() : 'generic') + '.svg'"
	/>
	<div>
		Start a private {{ currentLeague.sport }} room and invite your family and friends to play! In a private room you can customize
		scoring, number of players and more!
	</div>
</div>

<mat-dialog-actions>
	<button style="margin-left: auto" mat-raised-button color="accent" (click)="createRoom()">Create Room</button>
	<button mat-raised-button (click)="dialogRef.close()">Close</button>
</mat-dialog-actions>
}

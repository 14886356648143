import { Component, Input } from "@angular/core";

export interface IPhaseScoringOverview {
	name: string;
	amountForAllTiers: number;
	amountToMakePhase: number;
	tiers: { name: string; amountForTier: number }[];
}

@Component({
	selector: "am-scoring-overview",
	templateUrl: "./scoring-overview.component.html",
	styleUrls: ["./scoring-overview.component.scss"]
})
export class ScoringOverviewComponent {
	@Input() phases: IPhaseScoringOverview[];
	@Input() totalPot: number;
}

import { Component, Inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { isMobile } from "src/app/state/app/app.selectors";

@Component({
	selector: "am-auction-tutorial",
	templateUrl: "./auction-tutorial-dialog.component.html",
	styleUrls: ["./auction-tutorial-dialog.component.scss"]
})
export class AuctionTutorialDialogComponent {
	showMeAgain = new FormControl(false);
	isMobile$ = this.store.select(isMobile);

	items: { icon: string; title: string; message: string }[] = [
		{
			icon: "shuffle",
			title: "Structure:",
			message: `A random team is selected out of ${this.data.numberOfTeams} teams for bidding when finished, a new team is selected randomly.`
		},
		{
			icon: "attach_money",
			title: "Budget:",
			message: `All managers have $100 to bid with.`
		},
		{
			icon: "format_list_bulleted",
			title: "Teams:",
			message: `All managers will have exactly ${this.data.rosterSize} teams, once all managers rosters are full, the auction concludes.`
		},
		{
			icon: "dangerous",
			title: "Restriction:",
			message: `Once you have ${this.data.rosterSize} teams on your roster it is full and you will no longer be able to bid on more teams.`
		},
		{
			icon: "error",
			title: "Constraint:",
			message: `You have a maximum bid which ensures you have enough to fill your roster (at least $1 per team).`
		},
		{
			icon: "schedule",
			title: "Time:",
			message: `All teams have 30 seconds of bidding, when a new bid comes in the timer resets to 15 seconds.`
		}
	];

	constructor(
		private store: Store,
		public dialogRef: MatDialogRef<AuctionTutorialDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private data: { numberOfTeams: number; rosterSize: number }
	) {}
}

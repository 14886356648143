import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, map, takeUntil } from "rxjs";
import { ITeam } from "src/app/models/team";
import { IUserEventGroup } from "src/app/models/user";
import { SnackbarService } from "src/app/services/snackbar.service";
import { BaseComponent } from "src/app/shared/components/base.component";
import { getTeamsByEventId } from "src/app/state/event/event.selectors";

@Component({
	selector: "am-completed-group-card",
	templateUrl: "./completed-group-card.component.html",
	styleUrls: ["./completed-group-card.component.scss"]
})
export class CompleteGroupCardComponent extends BaseComponent implements OnInit {
	@Input() group: IUserEventGroup;
	@Input() isMobile: boolean | null = false;

	teams$: Observable<ITeam[] | undefined>;
	standing$: Observable<Map<string, number>>;

	constructor(private router: Router, private store: Store, private snackbar: SnackbarService) {
		super();
	}

	ngOnInit(): void {
		this.teams$ = this.store.select(getTeamsByEventId(this.group.eventId)).pipe(takeUntil(this.destroyed$));
	}

	navigateToGroup(): void {
		this.router.navigate(["event", this.group.eventId, this.group.groupId]);
	}

	getTeam(teams: ITeam[], teamId: string): ITeam | undefined {
		return teams.find((x) => x.id === teamId);
	}
}

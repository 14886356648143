import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable, takeUntil } from "rxjs";
import { IEvent } from "src/app/models/event";
import { BaseComponent } from "src/app/shared/components/base.component";
import { isMobile } from "src/app/state/app/app.selectors";
import * as eventActions from "src/app/state/event/event.actions";
import { currentEvent } from "src/app/state/event/event.selectors";
import * as groupActions from "src/app/state/group/group.actions";

/* 
	TODO: Animate group-side-nav
	TODO: Should naturally be back to group from group edit page (if group exists)
*/

@Component({
	selector: "am-event-profile",
	templateUrl: "./event-panel.component.html",
	styleUrls: ["./event-panel.component.scss"]
})
export class EventPanelComponent extends BaseComponent implements OnInit {
	isMobile$: Observable<boolean>;
	event$: Observable<IEvent | undefined>;

	showGroupSideNav$ = new BehaviorSubject<boolean>(true);
	selectedTierId$ = new BehaviorSubject<string | undefined>(undefined);

	selectedPage: string;

	constructor(private store: Store, private route: ActivatedRoute, private router: Router) {
		super();
	}

	ngOnInit(): void {
		const eventId = this.route.snapshot.params["eventId"];
		const groupId = this.route.snapshot.params["groupId"];
		this.selectedPage = this.router.url.split("/")[4];

		this.isMobile$ = this.store.select(isMobile).pipe(takeUntil(this.destroyed$));

		this.store.dispatch(
			eventActions.setSelectedEvent({
				eventId: eventId
			})
		);

		if (groupId) {
			this.store.dispatch(groupActions.setSelectedGroup({ eventId: eventId, groupId: groupId }));
		}

		this.event$ = this.store.select(currentEvent);
	}
}

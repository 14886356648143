<mat-dialog-content class="content scrollbar-y">
	<button mat-icon-button class="close-button" (click)="close()"><mat-icon>close</mat-icon></button>
	<am-scoring-overview [phases]="phases" [totalPot]="totalPot"></am-scoring-overview>
	<h2>
		Managers <span *ngIf="managerForm?.value as formData">({{ formData.managers?.length }}/{{ formData.numOfManagers }})</span>
	</h2>
	<div class="sub-header">
		<span>Modify your group below</span><span *ngIf="sharedInviteUrl">, or share link with others to join</span>
	</div>
	<form *ngIf="managerForm?.value?.managers?.length > 0" [formGroup]="managerForm">
		<div class="manager-list">
			<div *ngFor="let manager of managers.controls; let i = index" [formGroup]="manager" class="manager">
				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label>{{ manager.get("type")?.value }}</mat-label>
					<input matInput formControlName="displayName" readonly [ngClass]="manager.get('status')?.value?.toLowerCase()" />
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Color </mat-label>
					<mat-select formControlName="color" required [style.background-color]="manager.get('color')?.value">
						<mat-option
							*ngFor="let item of filteredColorMap(manager.get('color')?.value, managers.controls) | keyvalue"
							[value]="item.value"
						>
							<div [style.background-color]="item.value" [style.height]="'30px'" [style.width]="'100px'"></div>
						</mat-option>
					</mat-select>
				</mat-form-field>
				<button mat-icon-button *ngIf="manager.get('type')?.value !== 'Group Admin' && canEdit" (click)="toggleManager(i)">
					<mat-icon *ngIf="manager.get('status')?.value === 'Active'">person_remove</mat-icon>
					<mat-icon *ngIf="manager.get('status')?.value === 'Inactive'">person_add</mat-icon>
				</button>
			</div>
		</div>
		<div class="manager-actions" *ngIf="!managerForm.pristine">
			<button mat-raised-button color="accent" [disabled]="updatingManagers" (click)="updateGroup()">Apply Manager Changes</button>
		</div>
	</form>
</mat-dialog-content>
<ng-container *ngIf="sharedInviteUrl?.value">
	<div class="share-link">
		<div class="copy-message">Use this link to invite friends!</div>
		<div class="copy-row">
			<mat-form-field appearance="outline">
				<input matInput [formControl]="sharedInviteUrl" readonly />
			</mat-form-field>
			<button mat-raised-button color="accent" (click)="copyUrlToClipboard(sharedInviteUrl.value)">Copy</button>
		</div>
	</div>
</ng-container>

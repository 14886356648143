import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "../base.component";
import { Observable, combineLatest, filter, map, take, takeUntil, withLatestFrom } from "rxjs";
import { IGroup } from "src/app/models/group";
import { IEvent } from "src/app/models/event";
import { currentEvent } from "src/app/state/event/event.selectors";
import { currentGroup, currentGroupStandings, leavingGroup } from "src/app/state/group/group.selectors";
import { Store } from "@ngrx/store";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { isMobile } from "src/app/state/app/app.selectors";
import { ChangeGroupDialogComponent } from "../change-group-dialog/change-group-dialog.component";
import { isFuture } from "date-fns";
import { ICurrentUser } from "src/app/models/user";
import { currentUserData } from "src/app/state/user/user.selectors";
import { ScoreboardPlacePipe } from "../../pipes/scoreboard-place.pipe";
import { GroupInfoDialog } from "../group-info-dialog/group-info-dialog.component";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { leaveGroup } from "src/app/state/group/group.actions";

@Component({
	selector: "am-event-navigation",
	templateUrl: "./event-navigation.component.html",
	styleUrls: ["./event-navigation.component.scss"]
})
export class EventNavigationComponent extends BaseComponent implements OnInit {
	event$: Observable<IEvent | undefined>;
	group$: Observable<IGroup | undefined>;
	currentUser$: Observable<ICurrentUser | undefined>;
	isMobile$: Observable<boolean>;
	canLeaveGroup$: Observable<boolean>;
	leavingGroup$: Observable<boolean>;

	selectedPage: string;
	showNavOptions: boolean = false;

	constructor(private store: Store, private activatedRoute: ActivatedRoute, private router: Router, private dialog: MatDialog) {
		super();
	}

	ngOnInit(): void {
		this.selectedPage = this.router.url.split("/")[4];
		this.event$ = this.store.select(currentEvent).pipe(takeUntil(this.destroyed$));
		this.group$ = this.store.select(currentGroup).pipe(takeUntil(this.destroyed$));
		this.isMobile$ = this.store.select(isMobile).pipe(takeUntil(this.destroyed$));
		this.currentUser$ = this.store.select(currentUserData).pipe(takeUntil(this.destroyed$));
		this.leavingGroup$ = this.store.select(leavingGroup);

		this.canLeaveGroup$ = combineLatest([this.event$, this.group$, this.currentUser$]).pipe(
			map(
				([event, group, currentUser]) =>
					group?.owner !== currentUser?.uid && event?.status === "Open" && group?.status === "Created"
			)
		);
	}

	navigate(route: string[]): void {
		this.router.navigate(route);
	}

	changeGroup(): void {
		this.dialog.open(ChangeGroupDialogComponent, { minWidth: 350 });
	}

	leaveGroup(groupName: string): void {
		this.dialog
			.open(ConfirmationDialogComponent, { data: { text: `Are you sure you want to leave the group: ${groupName}.` } })
			.afterClosed()
			.pipe(take(1), withLatestFrom(this.event$, this.group$))
			.subscribe(([result, event, group]) => {
				if (result && event?.id && group?.id) {
					this.store.dispatch(leaveGroup({ eventId: event.id, groupId: group.id }));
				}
			});
	}

	// TODO: This should be shared with the group-card.component.ts
	determineGroupState(group: IGroup): string {
		// Missing Managers
		if (group.managerIds.length !== group.numOfManagers) {
			return "WaitingOnManagers";
		}

		// Auction hasn't started
		if (group.auctionDate && this.isAuctionInFuture(group)) {
			return "WaitingOnAuction";
		}

		// Auction is Currently happening
		if (group.status === "In Auction" || (group.auctionDate && !this.isAuctionInFuture(group) && group.status === "Ready")) {
			return "AuctionHappening";
		}

		if (group.status === "In Progress") {
			return "InProgress";
		}

		return "Error";
	}

	getCurrentUserScore(): Observable<number> {
		return combineLatest([this.currentUser$, this.group$]).pipe(
			filter(([user, group]) => !!user && !!group),
			map(([user, group]) => {
				if (user && group && group.rosters.has(user.uid)) {
					const currentUserRoster = group.rosters.get(user.uid)!!;
					let score = 0;

					currentUserRoster.forEach((team) => {
						score += group.scores.has(team) ? group.scores.get(team)!! : 0;
					});

					return score;
				}

				return -1;
			})
		);
	}

	getCurrentUserStanding$(): Observable<string> {
		return combineLatest([this.currentUser$, this.store.select(currentGroupStandings)]).pipe(
			filter(([user, standings]) => !!user && !!standings),
			map(([user, standings]) => {
				if (user && standings.has(user.uid)) {
					const scoreboardPlacePipe = new ScoreboardPlacePipe();
					return scoreboardPlacePipe.transform(standings.get(user.uid)!!);
				} else {
					throw new Error("Unable to find user in standings");
				}
			})
		);
	}

	isOwner = (group: IGroup) =>
		this.currentUser$.pipe(
			filter((user) => !!user),
			map((user) => group.owner === user?.uid)
		);

	isAuctionInFuture = (group: IGroup) => isFuture(group.auctionDate!!);

	openGroupInfo(): void {
		const eventId = this.activatedRoute.snapshot.params["eventId"];
		const groupId = this.activatedRoute.snapshot.params["groupId"];
		this.dialog.open(GroupInfoDialog, { data: { eventId, groupId } });
	}
}

import { Component } from "@angular/core";

@Component({
	selector: "am-404",
	template: `<div id="not-found">
		<mat-icon>sick</mat-icon>
		<h2>Page not found, click <a routerLink="/home">here</a> to go back home.</h2>
	</div>`,
	styles: [
		`
			:host {
				flex: 1;
			}

			#not-found {
				height: calc(100% - 64px);
				text-align: center;

				mat-icon {
					margin: auto;
					font-size: 100px;
					height: 100px;
					width: 100px;
				}

				h2 {
					margin: 0;
					padding-top: 16px;
				}
			}
		`
	]
})
export class NotFoundComponent {}

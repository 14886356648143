import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { BaseComponent } from "../base.component";
import { IPhase, ITier } from "src/app/models/event";
import { BehaviorSubject, takeUntil } from "rxjs";
import { Store } from "@ngrx/store";
import { currentBreakPoint } from "src/app/state/app/app.selectors";
import { BreakPointDisplayNameMap } from "src/app/models/constants";
import { Breakpoints } from "@angular/cdk/layout";

interface ITierPhase {
	type: "tier" | "phase";
	tiers: ITier[];
	phase: IPhase;
}

@Component({
	selector: "am-schedule-calendar",
	templateUrl: "./schedule-calendar.component.html",
	styleUrls: ["./schedule-calendar.component.scss"]
})
export class ScheduleCalendarComponent extends BaseComponent implements OnInit, OnChanges {
	@Input() tiers: ITier[];
	@Input() phases: IPhase[];
	@Input() selectedTierIds: string[];
	@Input() selectedPhaseId: string;
	@Output() selectWeekEvent = new EventEmitter<{ tiers: ITier[]; phaseId: string }>();

	tiersToDisplay$ = new BehaviorSubject<ITierPhase[]>([]);
	selectedTierIndex = -1;
	// I hate this...
	isLargeBreakPoint: boolean | undefined = undefined;
	isMediumBreakPoint: boolean | undefined = undefined;
	isSmallBreakpoint: boolean | undefined = undefined;
	isXSmallBreakpoint: boolean | undefined = undefined;

	tiersTaken = 0;

	constructor(private store: Store) {
		super();
	}

	ngOnInit(): void {
		this.store
			.select(currentBreakPoint)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((currentBreakPoint) => {
				if (
					currentBreakPoint === BreakPointDisplayNameMap.get(Breakpoints.Large) ||
					(currentBreakPoint === BreakPointDisplayNameMap.get(Breakpoints.XLarge) && !this.isLargeBreakPoint)
				) {
					this.isLargeBreakPoint = true;
					this.isMediumBreakPoint = false;
					this.tiersTaken = 9;
				} else if (currentBreakPoint === BreakPointDisplayNameMap.get(Breakpoints.Medium) && !this.isMediumBreakPoint) {
					this.isLargeBreakPoint = false;
					this.isSmallBreakpoint = false;
					this.isMediumBreakPoint = true;
					this.tiersTaken = 5;
				} else if (currentBreakPoint === BreakPointDisplayNameMap.get(Breakpoints.Small) && !this.isSmallBreakpoint) {
					this.isMediumBreakPoint = false;
					this.isSmallBreakpoint = true;
					this.tiersTaken = 3;
				} else if (currentBreakPoint === BreakPointDisplayNameMap.get(Breakpoints.XSmall) && !this.isSmallBreakpoint) {
					this.isSmallBreakpoint = false;
					this.isXSmallBreakpoint = true;
					this.tiersTaken = 1;
				}

				this.calcWeeks();
			});
	}

	ngOnChanges(): void {
		this.tiers.forEach((tier, i) => {
			if (this.selectedTierIds.includes(tier.id)) {
				this.selectedTierIndex = i;
			}
		});

		this.calcWeeks();
	}

	calcWeeks(): void {
		let startIndex = 0;
		let endIndex = 0;

		const tierPhases: ITierPhase[] = [];

		this.phases.forEach((phase) => {
			if (phase.viewType === "List") {
				this.tiers
					.filter((x) => x.phaseId === phase.id)
					.forEach((tier) => {
						tierPhases.push({
							type: "tier",
							tiers: [tier],
							phase: phase
						});
					});
			} else {
				tierPhases.push({
					type: "phase",
					tiers: this.tiers.filter((x) => x.phaseId === phase.id),
					phase: phase
				});
			}
		});

		const movingPoint = Math.ceil(this.tiersTaken / 2);

		if (this.selectedTierIndex < 0 + movingPoint) {
			// At the start and should not change
			startIndex = 0;
			endIndex = this.tiersTaken;
		} else if (this.selectedTierIndex > tierPhases.length - movingPoint) {
			// At the end
			startIndex = tierPhases.length - this.tiersTaken;
			endIndex = tierPhases.length;
		} else {
			// In the middle
			startIndex = this.selectedTierIndex - movingPoint + 1;
			endIndex = this.selectedTierIndex + movingPoint;
		}

		this.tiersToDisplay$.next(tierPhases.slice(startIndex, endIndex));
	}

	selectWeek(tiers: ITier[], phase: IPhase): void {
		this.selectWeekEvent.emit({ tiers: tiers ? tiers : this.tiers.filter((x) => x.phaseId === phase.id), phaseId: phase.id });
	}

	isSelected(tiers: ITier[]): boolean {
		return this.arraysEqual(
			this.selectedTierIds,
			tiers.map((x) => x.id)
		);
	}

	arraysEqual(a: string[], b: string[]) {
		if (a === b) return true;
		if (a == null || b == null) return false;
		if (a.length !== b.length) return false;

		for (var i = 0; i < a.length; ++i) {
			if (a[i] !== b[i]) return false;
		}
		return true;
	}

	updateIndex(offset: number): void {
		const currentPhase = this.phases.find((x) => x.id === this.selectedPhaseId);

		if (currentPhase?.viewType === "Bracket") {
			offset = offset === -1 ? -this.tiers.filter((x) => x.phaseId === currentPhase.id).length : 1;
		}

		const newTier: ITier = this.tiers[this.selectedTierIndex + offset];

		if (!newTier) {
			return;
		}

		const newPhase = this.phases.find((x) => x.id === newTier.phaseId)!!;

		this.selectWeekEvent.emit({
			tiers: newPhase.viewType === "Bracket" ? this.tiers.filter((x) => x.phaseId === newPhase.id) : [newTier],
			phaseId: newPhase.id
		});
	}
}

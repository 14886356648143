<form [formGroup]="leagueForm">
	<mat-form-field>
		<mat-label>League</mat-label>
		<mat-select formControlName="leagueId">
			<mat-option *ngFor="let league of apiLeagues" [value]="league.integrationId">{{ league.name }}</mat-option>
		</mat-select>
		<mat-spinner diameter="15" *ngIf="leaguesLoading"></mat-spinner>
	</mat-form-field>
	<mat-form-field>
		<mat-label>Year</mat-label>
		<mat-select formControlName="seasonId">
			<mat-option *ngFor="let season of apiSeasons" [value]="season">{{ season }}</mat-option>
		</mat-select>
		<mat-spinner diameter="15" *ngIf="seasonsLoading"></mat-spinner>
	</mat-form-field>
</form>
<mat-dialog-content>
	<form [formGroup]="phaseForm" *ngIf="!loadingGames; else loading">
		<div class="form-row">
		<mat-form-field>
			<mat-label>Start Date</mat-label>
			<input matInput formControlName="startDate" [matDatepicker]="startPicker" />
			<mat-datepicker-toggle matIconSuffix [for]="startPicker"></mat-datepicker-toggle>
			<mat-datepicker #startPicker></mat-datepicker>
		</mat-form-field>
		<mat-form-field>
			<mat-label>End Date</mat-label>
			<input matInput formControlName="endDate" [matDatepicker]="endPicker" />
			<mat-datepicker-toggle matIconSuffix [for]="endPicker"></mat-datepicker-toggle>
			<mat-datepicker #endPicker></mat-datepicker>
		</mat-form-field>
		</div>
		<!-- <mat-select formControlName="splitOn" required>
			<mat-option>--</mat-option>
			<mat-option *ngFor="let option of splitOptions" [value]="option">
				{{ option }}
			</mat-option>
		</mat-select> -->
	</form>
	<div class="phase-preview">
		<mat-card *ngFor="let tier of (tiers | async)">
			{{ tier.displayName }} ({{ tier.games.length }})
		</mat-card>
	</div>
	<ng-template #loading>
		<div class="loader">
			<mat-spinner diameter="35"></mat-spinner>
		</div>
	</ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button (click)="submitPhase()">Submit</button>
</mat-dialog-actions>
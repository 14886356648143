import { Component, Input } from "@angular/core";
import { ITier } from "src/app/models/event";
import { IMatchup } from "src/app/models/game";
import { BracketMatchupBoxComponent } from "../../components/bracket-matchup-box/bracket-matchup-box.component";
import { NgFor } from "@angular/common";

@Component({
	selector: "am-league-bracket",
	templateUrl: "./league-bracket.component.html",
	styleUrls: ["./league-bracket.component.scss"],
	standalone: true,
	imports: [NgFor, BracketMatchupBoxComponent]
})
export class LeagueBracketComponent {
	@Input() rounds: string[];
	@Input() matchups: IMatchup[];

	getMatchupsByRound(round: string): IMatchup[] {
		return this.matchups.filter((x) => x.round === round);
	}
}

import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar";

export interface ISnackbarMessage {
	message: string;
	action: string;
}

@Component({
  selector: 'am-snack-bar',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['snackbar.component.scss'],
  templateUrl: 'snackbar.component.html'
})
export class SnackbarComponent {
  data: ISnackbarMessage;

  constructor(@Inject(MAT_SNACK_BAR_DATA) data: ISnackbarMessage, public snackBarRef: MatSnackBarRef<SnackbarComponent>) {
    this.data = data;
  }

	action(): void {
		this.snackBarRef.dismissWithAction();
	}

	get hasAction(): boolean {
		return !!this.data.action;
	}
}
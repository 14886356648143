import { trigger, state, style, transition, animate, group } from "@angular/animations";
import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, filter, map, startWith, switchMap, take, takeUntil, tap, withLatestFrom } from "rxjs";
import { IGroup } from "src/app/models/group";
import { GroupService } from "src/app/services/group.service";
import { BaseComponent } from "src/app/shared/components/base.component";
import { GroupAuditDialogComponent } from "../group-audit-dialog/group-audit-dialog.component";
import { IEvent } from "src/app/models/event";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { endOfDay, isAfter, isBefore, startOfDay } from "date-fns";

@Component({
	selector: "am-group-lookup",
	templateUrl: "./group-lookup.component.html",
	styleUrls: ["./group-lookup.component.scss"],
	animations: [
		trigger("detailExpand", [
			state("collapsed", style({ height: "0px", minHeight: "0" })),
			state("expanded", style({ height: "*" })),
			transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
		])
	]
})
export class GroupLookupComponent extends BaseComponent implements OnInit {
	@Input() event: IEvent;

	filters: FormGroup;
	columns = ["name", "status", "size", "actions", "expand"];
	selectedGroupId: string | null;

	groupList$: BehaviorSubject<IGroup[]> = new BehaviorSubject([] as IGroup[]);
	constructor(private fb: FormBuilder, private groupService: GroupService, private router: Router, private dialog: MatDialog) {
		super();
		this.filters = this.fb.group({
			userId: this.fb.control(""),
			groupId: this.fb.control(""),
			type: this.fb.control("all"),
			auctionDate: this.fb.control(null),
			size: this.fb.control(0)
		});
	}

	ngOnInit(): void {
		this.filters.valueChanges
			.pipe(
				startWith({}),
				takeUntil(this.destroyed$),
				switchMap((filters) => {
					this.groupList$.next([] as IGroup[]);

					if (filters.groupId) {
						this.filters.get("userId")?.disable({ emitEvent: false });
						return this.groupService.getByID(this.event.id, filters.groupId).pipe(map((event) => (event ? [event] : [])));
					} else if (filters.userId) {
						this.filters.get("groupId")?.disable({ emitEvent: false });
						return this.groupService.getForEventAndUser(this.event.id, filters.userId);
					} else {
						this.filters.get("userId")?.enable({ emitEvent: false });
						this.filters.get("groupId")?.enable({ emitEvent: false });
						return this.groupService.getForEventAndUser(this.event.id, undefined, filters.type);
					}
				})
			)
			.subscribe((groups) => {
				let finalGroups = [...this.groupList$.value, ...groups];
				const size = this.filters.value.size;

				if (size) {
					finalGroups = finalGroups.filter((x) => x.managerIds.length >= size);
				}

				const auctionDate = new Date(this.filters.value.auctionDate);

				auctionDate.setHours(auctionDate.getHours() + 12);

				if (
					this.filters.value.auctionDate != null &&
					auctionDate &&
					auctionDate instanceof Date &&
					auctionDate.getFullYear() > 2023
				) {
					const start = startOfDay(auctionDate);
					const end = endOfDay(auctionDate);

					finalGroups = finalGroups.filter((x) => x.auctionDate && isBefore(x.auctionDate, end) && isAfter(x.auctionDate, start));
				}

				this.groupList$.next(finalGroups);
			});
	}

	setSelected(groupId: string) {
		this.selectedGroupId = this.selectedGroupId === groupId ? null : groupId;
	}

	navigateToGroup(group: IGroup) {
		const url = this.router.serializeUrl(this.router.createUrlTree(["event", this.event.id, group.id]));
		window.open(url, "_blank");
	}

	openAuditDialog(group: IGroup): void {
		this.dialog.open(GroupAuditDialogComponent, { data: { group: group, event: this.event } });
	}

	deleteGroup(groupId: string): void {
		this.dialog
			.open(ConfirmationDialogComponent, { data: { text: "Are you sure you want to delete this group? This cannot be undone." } })
			.afterClosed()
			.pipe(take(1))
			.subscribe((result) => {
				if (result) {
					this.groupService.deleteGroup(this.event.id, groupId);
				}
			});
	}
}

import { Component, OnInit, Inject } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { BehaviorSubject, combineLatest, filter, take, takeUntil } from "rxjs";
import { ColorMap } from "src/app/models/constants";
import { IGroup } from "src/app/models/group";
import { GroupService } from "src/app/services/group.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { BaseComponent } from "src/app/shared/components/base.component";
import { currentUID } from "src/app/state/app/app.selectors";
import { currentEvent, selectedEventId } from "src/app/state/event/event.selectors";
import { setSelectedGroup } from "src/app/state/group/group.actions";
import { currentGroup, selectedGroupId } from "src/app/state/group/group.selectors";
import { usersMap } from "src/app/state/user/user.selectors";
import { IPhaseScoringOverview } from "../scoring-overview/scoring-overview.component";
import { setSelectedEvent } from "src/app/state/event/event.actions";

@Component({
	selector: "am-group-info-dialog",
	templateUrl: "./group-info-dialog.component.html",
	styleUrls: ["./group-info-dialog.component.scss"]
})
export class GroupInfoDialog extends BaseComponent implements OnInit {
	managerForm: FormGroup = this.fb.group({
		numOfManagers: this.fb.control(2),
		managers: this.fb.array([]),
		sharedInviteUrl: this.fb.control("")
	});

	removedManagers = new BehaviorSubject<string[]>([]);

	colorMap = ColorMap;
	canEdit: boolean = false;
	updatingManagers: boolean = false;

	totalPot: number;
	phases: IPhaseScoringOverview[];
	groupOwner: string;
	isOwner: boolean;

	constructor(
		public dialogRef: MatDialogRef<GroupInfoDialog>,
		private fb: FormBuilder,
		private store: Store,
		private groupService: GroupService,
		private snackbar: SnackbarService,
		@Inject(MAT_DIALOG_DATA) public data: { eventId: string; groupId: string }
	) {
		super();
	}

	ngOnInit(): void {
		if (this.data?.eventId && this.data?.groupId) {
			this.store.dispatch(setSelectedGroup({ eventId: this.data.eventId, groupId: this.data.groupId }));
			this.store.dispatch(setSelectedEvent({ eventId: this.data.eventId }));
		}

		combineLatest([this.store.select(currentEvent), this.store.select(currentGroup)])
			.pipe(
				filter(([event, group]) => !!event && !!group),
				takeUntil(this.destroyed$)
			)
			.subscribe(([event, group]) => {
				if (!!event && !!group) {
					this.totalPot = group.numOfManagers * group.scoring.buyin;
					this.groupOwner = group.owner;

					this.phases = event.phases.map((phase) => ({
						name: phase.name,
						amountToMakePhase: (group.scoring?.makePhaseValues?.[phase.id] || 0) * this.totalPot,
						amountForAllTiers: (group.scoring?.phaseValues?.[phase.id] || 0) * this.totalPot,
						tiers: event.tiers
							.filter((t) => t.phaseId === phase.id)
							.sort((a, b) => (a.order || 0) - (b.order || 0))
							.map((tier) => ({
								name: tier.displayName,
								amountForTier: (group.scoring?.tierValues?.[tier.id] || 0) * this.totalPot
							}))
					}));
				}
			});

		combineLatest([this.store.select(usersMap), this.managerForm.valueChanges]).subscribe(([userMap]) => {
			const managers = this.managers.value;
			if (managers) {
				managers.forEach((manager, index) => {
					let lookup = userMap[manager?.uid];
					if (!manager?.displayName && lookup) {
						this.managers.at(index)?.get("displayName")?.setValue(lookup.displayName, { emitEvent: false });
					}
				});
			}
		});

		combineLatest([this.store.select(currentGroup), this.store.select(currentUID), this.removedManagers])
			.pipe(takeUntil(this.destroyed$))
			.subscribe(([group, currentUID, removedList]) => {
				if (group?.owner === currentUID && ["Created", "Ready"].includes(group.status)) {
					this.canEdit = true;
				}

				if (group) {
					this.isOwner = group.owner === currentUID;

					this.managerForm.setValue(
						{
							numOfManagers: group?.numOfManagers,
							managers: this.managers.value,
							sharedInviteUrl: group?.sharedInviteId
								? `https://auctionmadness.com/invite?inviteId=${group?.sharedInviteId}`
								: ""
						},
						{
							emitEvent: false
						}
					);

					this.managerForm.setControl(
						"managers",
						this.fb.array(
							group.managerIds.map((m) => {
								const colorControl = this.fb.control(group.managerColors.get(m));
								if (currentUID !== m && group.owner !== currentUID) {
									colorControl.disable();
								}

								return this.fb.group({
									uid: this.fb.control(m),
									displayName: this.fb.control(""),
									type: this.fb.control(group.owner === m ? "Group Admin" : "Manager"),
									status: this.fb.control(removedList.includes(m) ? "Inactive" : "Active"),
									color: colorControl
								});
							})
						)
					);
				}
			});
	}

	get managers() {
		return this.managerForm?.get(["managers"]) as FormArray<FormGroup>;
	}

	get sharedInviteUrl() {
		return this.managerForm?.get(["sharedInviteUrl"]) as FormControl;
	}

	toggleManager(managerIndex: number) {
		let manager = this.managers.at(managerIndex).value;
		let removedList = this.removedManagers.value;
		const statusControl = this.managers.at(managerIndex)?.get(["status"]);

		if (statusControl && manager?.uid) {
			if (removedList.includes(manager.uid)) {
				removedList = removedList.filter((uid) => uid !== manager.uid);
				statusControl.setValue("Active");
				statusControl.markAsPristine();
			} else {
				removedList.push(manager.uid);
				statusControl.setValue("Inactive");
				statusControl.markAsDirty();
			}

			this.removedManagers.next(removedList);
		}
	}

	copyUrlToClipboard(url: string): void {
		navigator.clipboard.writeText(url);
		this.snackbar.success("Copied To Clipboard");
	}

	close() {
		this.dialogRef.close();
	}

	updateGroup(): void {
		this.updatingManagers = true;
		const allManagers = this.managers.value.map((m) => {
			return { id: m.uid, color: m.color };
		});
		const inactiveManagers = this.removedManagers.value;
		const activeManagers = allManagers.filter((m) => !inactiveManagers.includes(m.id));

		let managerIds: string[] = [];
		let managerColors = new Map<string, string>();

		for (let managerInfo of activeManagers) {
			managerIds.push(managerInfo.id);
			managerColors.set(managerInfo.id, managerInfo.color);
		}

		if (this.data?.eventId && this.data?.groupId) {
			this.groupService
				.updateGroup(this.data.eventId, {
					id: this.data.groupId,
					managerIds: managerIds,
					managerColors: managerColors,
					owner: this.groupOwner
				} as Partial<IGroup>)
				.pipe(take(1))
				.subscribe((result) => {
					this.managerForm.markAsPristine();
					this.updatingManagers = false;
				});
		} else {
			combineLatest([this.store.select(selectedEventId), this.store.select(selectedGroupId)])
				.pipe(take(1))
				.subscribe(([eventId, groupId]) => {
					if (eventId && groupId) {
						this.groupService
							.updateGroup(eventId, {
								id: groupId,
								managerIds: managerIds,
								managerColors: managerColors,
								owner: this.groupOwner
							} as Partial<IGroup>)
							.pipe(take(1))
							.subscribe((result) => {
								this.managerForm.markAsPristine();
								this.updatingManagers = false;
							});
					}
				});
		}
	}

	filteredColorMap(originalColor: string, managers: FormGroup[]): Map<number, string> {
		if (this.isOwner) {
			return ColorMap;
		}

		const takenColors = managers.map((x) => x.get("color")?.value);
		const filterMap = new Map<number, string>();

		ColorMap.forEach((value, key) => {
			if (value === originalColor || !takenColors.includes(value)) {
				filterMap.set(key, value);
			}
		});

		return filterMap;
	}
}

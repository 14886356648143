import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { combineLatest, startWith, takeUntil } from "rxjs";
import { ISeries } from "src/app/models/game";
import { ITeam } from "src/app/models/team";
import { BaseComponent } from "../base.component";
import { IRegion } from "src/app/models/event";

@Component({
	selector: "am-game-edit-dialog",
	templateUrl: "./series-edit-dialog.component.html",
	styleUrls: ["./series-edit-dialog.component.scss"]
})
export class SeriesEditDialogComponent extends BaseComponent implements OnInit {
	dataForm: FormGroup;
	currentSeries: ISeries;
	teams: ITeam[];
	updating: boolean = false;
	deleting: boolean = false;
	regions: IRegion[];

	constructor(
		private dialogRef: MatDialogRef<SeriesEditDialogComponent>,
		private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) private data: { series: ISeries; teams: ITeam[]; regions: IRegion[] }
	) {
		super();
		this.teams = this.data.teams;
		this.regions = this.data.regions;
		this.currentSeries = this.data.series;
	}

	ngOnInit(): void {
		this.dataForm = this.fb.group({
			status: new FormControl(this.currentSeries?.status ?? "Upcoming", Validators.required),
			team1Id: new FormControl(this.currentSeries?.team1Id ?? "", undefined),
			team1Score: new FormControl(this.currentSeries?.team1Score ?? undefined),
			team2Id: new FormControl(this.currentSeries?.team2Id ?? "", undefined),
			team2Score: new FormControl(this.currentSeries?.team2Score ?? undefined),
			regionId: new FormControl(this.currentSeries?.regionId ?? undefined),
			displayOrder: new FormControl(this.currentSeries?.displayOrder ?? undefined),
			totalNumberOfGames: new FormControl(this.currentSeries?.totalNumberOfGames ?? undefined),
			clinchingAmount: new FormControl(this.currentSeries?.clinchingAmount ?? undefined)
		});

		combineLatest([
			this.dataForm.controls["team1Score"].valueChanges.pipe(startWith(0)),
			this.dataForm.controls["team2Score"].valueChanges.pipe(startWith(0))
		])
			.pipe(takeUntil(this.destroyed$))
			.subscribe(([team1Score, team2Score]) => {
				if (this.dataForm.get("status")?.value === "Upcoming" && (team1Score > 0 || team2Score > 0)) {
					this.dataForm.get("status")?.patchValue("In Progress");
				}
			});

		this.dataForm
			.get("status")
			?.valueChanges.pipe(takeUntil(this.destroyed$))
			.subscribe((status) => {
				if (status === "Final") {
					this.dataForm.get("team1Id")?.disable();
					this.dataForm.get("team1Score")?.disable();
					this.dataForm.get("team2Id")?.disable();
					this.dataForm.get("team2Score")?.disable();
				} else if (status === "In Progress") {
					this.dataForm.get("team1Id")?.disable();
					this.dataForm.get("team1Score")?.enable();
					this.dataForm.get("team2Id")?.disable();
					this.dataForm.get("team2Score")?.enable();
				} else {
					this.dataForm.get("team1Id")?.enable();
					this.dataForm.get("team1Score")?.enable();
					this.dataForm.get("team2Id")?.enable();
					this.dataForm.get("team2Score")?.enable();
				}
			});
	}

	close() {
		this.dialogRef.close();
	}

	submit() {
		let status = this.dataForm.get("status")?.value;
		let team1Id = this.dataForm.get("team1Id")?.value;
		let team1Score = this.dataForm.get("team1Score")?.value;
		let team2Id = this.dataForm.get("team2Id")?.value;
		let team2Score = this.dataForm.get("team2Score")?.value;
		let regionId = this.dataForm.get("regionId")?.value;
		let displayOrder = this.dataForm.get("displayOrder")?.value;
		let totalNumberOfGames = this.dataForm.get("totalNumberOfGames")?.value;
		let clinchingAmount = this.dataForm.get("clinchingAmount")?.value;
		let winnerId = undefined;

		if (status === "Final") {
			if (team1Score > team2Score) {
				winnerId = team1Id;
			} else if (team1Score < team2Score) {
				winnerId = team2Id;
			}
		}

		let final = {
			...this.currentSeries,
			status: status,
			team1Id: team1Id,
			team1Score: team1Score,
			team2Id: team2Id,
			team2Score: team2Score,
			winnerId: winnerId,
			regionId: regionId,
			displayOrder: displayOrder,
			totalNumberOfGames: totalNumberOfGames,
			clinchingAmount: clinchingAmount
		};

		this.dialogRef.close(final);
	}
}

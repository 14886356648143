<div class="content">
	<ng-container *ngIf="isMobile$ | async; else desktopView">
		<div id="unauthenticated-mobile">
			<div class="header mobile-header">
				<h1>Draft your teams and dominate your group. Play for free now!</h1>
				<button (click)="signup()" color="accent" mat-raised-button>Sign Up</button>
			</div>
			<ng-container *ngIf="event$ | async as event">
				<mat-card class="event-promotion mobile-card">
					<div
						class="img-header"
						[style.background-image]="'url(../../../assets/img/stock/' + event.type.toLowerCase() + '.jpg)'"
					>
						<img
							width="200"
							height="200"
							[src]="'../assets/icons/logos/' + (event.type ? event.type.toLowerCase() : 'generic') + '.svg'"
						/>
					</div>
					<div class="event-content">
						<h1>{{ event.name }}</h1>
						<p>{{ event.description }}</p>
					</div>
					<div class="bottom-content">
						{{ event.minAuctionDatetime | countdown | async }} until the Season begins, join or create a group now!
					</div>
				</mat-card>
			</ng-container>
			<div class="mobile-content">
				<iframe
					src="https://www.youtube.com/embed/qEJ4tOJ3gA8?si=N96N5kckq93Qa6Uu"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen
				></iframe>
				<div>
					Auction madness is the most exciting way to enjoy your favorite sporting events, with a live auction of college and
					professional teams. Throughout the season, you are earning points to compete against your friends and win it all!
				</div>
			</div>
		</div>
	</ng-container>

	<ng-template #desktopView>
		<div id="unauthenticated">
			<div class="header desktop-header">
				<h1>Draft your teams and dominate your group. Play for free now!</h1>
				<div class="header-buttons">
					<button (click)="signup()" color="accent" mat-raised-button>Sign Up</button>
				</div>
			</div>
			<div class="content">
				<div class="left">
					<iframe
						height="100%"
						src="https://www.youtube.com/embed/qEJ4tOJ3gA8?si=N96N5kckq93Qa6Uu"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen
					></iframe>
					<div>
						Auction madness is the most exciting way to enjoy your favorite sporting events, with a live auction of college and
						professional teams. Throughout the season, you are earning points to compete against your friends and win it all!
					</div>
				</div>
				<div class="right">
					<ng-container *ngIf="event$ | async as event">
						<mat-card class="event-promotion desktop-card">
							<div
								class="img-header"
								[style.background-image]="'url(../../../assets/img/stock/' + event.type.toLowerCase() + '.jpg)'"
							>
								<img
									width="200"
									height="200"
									[src]="'../assets/icons/logos/' + (event.type ? event.type.toLowerCase() : 'generic') + '.svg'"
								/>
							</div>
							<div class="event-content">
								<h1>{{ event.name }}</h1>
								<p>{{ event.description }}</p>
							</div>
							<div class="bottom-content">
								{{ event.minAuctionDatetime | countdown | async }} until the Season begins, join or create a group now!
							</div>
						</mat-card>
					</ng-container>
				</div>
			</div>
		</div>
	</ng-template>

	<ng-container *ngIf="event$ | async as event">
		<div id="public-rooms" class="public-rooms">
			<am-public-room [eventId]="event.id"></am-public-room>
		</div>
	</ng-container>

	<am-footer></am-footer>
</div>

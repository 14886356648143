@if (auction$ | async; as auction) {
	@if (currentLeague(); as league) {
		@if (event$ | async; as event) {
			<ng-container *ngIf="!(isMobile$ | async); else mobileView">
				<ng-container *ngIf="userAuctionData$ | async as userAuctionData">
					<div class="content">
						<div class="sidenav">
							<div class="nav">
								<button
									matTooltip="Group info"
									mat-icon-button
									type="button"
									(click)="openGroupInfo(auction.eventId, auction.groupId)"
								>
									<mat-icon>info</mat-icon>
								</button>
								<button
									matTooltip="Go to Schedule"
									mat-icon-button
									type="button"
									(click)="returnToGroup(auction.eventId, auction.groupId)"
								>
									<mat-icon svgIcon="am-schedule" matListItemIcon></mat-icon>
								</button>
								<button matTooltip="How to play" mat-icon-button (click)="openTutorial(auction, undefined)">
									<mat-icon style="font-size: 24px">menu_book</mat-icon>
								</button>
							</div>
							<mat-select (selectionChange)="selectUser($event)" [value]="currentSelectedUser$ | async">
								<mat-option *ngFor="let userData of userAuctionData" [value]="userData.user?.uid">
									{{ userData.user?.displayName }}
								</mat-option>
							</mat-select>
							<ng-container *ngIf="selectedUserData$ | async as selectedUserData">
								<div class="rosters">
									<span *ngIf="totalTeamsPerManager$ | async as totalTeamsPerManager" class="teams-per-manager">
										{{ selectedUserData.roster.length }} / {{ totalTeamsPerManager }} Teams
									</span>
									<ng-container *ngFor="let item of selectedUserData.placeholderArray; let i = index">
										<div *ngIf="selectedUserData.roster[i] as team; else placeholder" class="team-card">
											<ng-container
												[ngTemplateOutlet]="teamCard"
												[ngTemplateOutletContext]="{
													teamData: { team: team.team, showColor: false, price: team.price }
												}"
											></ng-container>
										</div>
										<ng-template #placeholder>
											<div class="placeholder-team-card"></div>
										</ng-template>
									</ng-container>
								</div>
							</ng-container>
						</div>

						<div id="desktop">
							<ng-container [ngSwitch]="auction.status">
								<div *ngSwitchCase="'In Progress'" class="bid-container">
									<ng-container *ngIf="myAuctionData$ | async as myAuctionData">
										<ng-container *ngIf="currentBidData$ | async as currentBidData">
											<div
												[@slideInOut]
												*ngIf="showResult"
												class="result-view"
												[style]="'background-color: ' + resultTeam?.hexcode + '73'"
											>
												<div class="results">
													<img class="completed-bid-team-logo-back" [src]="resultTeam?.logo" />
													<img class="completed-bid-team-logo" [src]="resultTeam?.logo" />

													<h2 *ngIf="resultOwner">
														{{ resultTeam?.displayName }} awarded to: {{ resultOwner.displayName }} for ${{
															resultBid
														}}
													</h2>
													<h2 *ngIf="!resultOwner">
														No bids on {{ resultTeam?.displayName }}, they have been added to the back of the
														line.
													</h2>
												</div>
											</div>
											<div
												[@slideInOut]
												*ngIf="!showResult"
												class="current-offer"
												[ngClass]="{
													'mat-bid-owned': currentBidData.currentOwner?.uid === myAuctionData.uid,
													'mat-bid-lost':
														currentBidData.currentOwner?.uid &&
														currentBidData.currentOwner?.uid !== myAuctionData.uid,
													'mat-bid-no-bidder': !currentBidData.currentOwner?.uid
												}"
											>
												<div class="active-bid-team-logo-container">
													<div class="active-bid-team-logo">
														<img
															class="active-bid-team-logo-image-back"
															[src]="currentBidData.currentTeam?.logo"
														/>
														<img class="active-bid-team-logo-image" [src]="currentBidData.currentTeam?.logo" />
													</div>
												</div>
												<div class="active-bid-team-container">
													<h1 class="active-bid-team-text">
														{{ currentBidData.currentTeam?.displayName }} <br />{{
															currentBidData.currentTeam?.seed ? "#" + currentBidData.currentTeam?.seed : ""
														}}
														{{ currentBidData.currentTeam?.division }}
														{{
															currentBidData.currentTeam?.record
																? "(" + currentBidData.currentTeam?.record + ")"
																: ""
														}}
													</h1>
													<div class="current-offer-details">
														<div>
															<b>Current Bid:</b>
															{{ currentBidData.currentBid | currency: "USD" : "symbol" : "1.0-0" }}
															<span *ngIf="!!currentBidData.currentOwner">
																({{ currentBidData.currentOwner.displayName }})
															</span>
														</div>
													</div>
												</div>
												<div
													class="right-bid-panel-triangle"
													[style]="'border-top: 150px solid ' + currentBidData.currentTeam?.hexcode"
												></div>
												<div
													class="right-bid-panel"
													[style]="'background-color: ' + currentBidData.currentTeam?.hexcode"
												>
													<div class="timer-text mat-headline-4">
														{{ bidTimerSeconds$ | async }}
													</div>
													<div
														class="bid-controls"
														*ngIf="myAuctionData.roster.length < auction.totalTeamsPerManager"
													>
														<button
															mat-flat-button
															class="quick-bid"
															color="primary"
															[disabled]="
																bidDelay ||
																(bidTimerSeconds$ | async) === 0 ||
																(submittingBid$ | async) ||
																currentBidData.currentBid + 1 > myAuctionData.maxBid ||
																currentBidData?.currentOwner?.uid === myAuctionData.uid
															"
															(click)="submitBid(currentBidData.currentBid + 1)"
														>
															Quick Bid ({{ currentBidData.currentBid + 1 }})
														</button>
														<div class="custom-bid">
															<mat-form-field subscriptSizing="dynamic">
																<mat-label>
																	Budget: {{ myAuctionData.amountAvailable }} | Max:
																	{{ myAuctionData.maxBid }}
																</mat-label>
																<input
																	matInput
																	type="number"
																	[min]="currentBidData.currentBid + 1"
																	[max]="myAuctionData.maxBid"
																	[formControl]="customBid"
																/>
															</mat-form-field>
															<button
																mat-flat-button
																class="custom-bid-button"
																color="primary"
																[disabled]="
																	bidDelay ||
																	(bidTimerSeconds$ | async) === 0 ||
																	(submittingBid$ | async) ||
																	(customBid && customBid.value > myAuctionData.maxBid) ||
																	(customBid &&
																		currentBidData?.currentBid &&
																		customBid.value <= currentBidData.currentBid) ||
																	currentBidData?.currentOwner?.uid === myAuctionData.uid
																"
																(click)="submitBid(customBid.value)"
															>
																Bid
															</button>

															<button
																mat-flat-button
																class="custom-bid-button"
																color="warn"
																(click)="pass()"
																[disabled]="
																	currentBidData?.currentOwner?.uid === myAuctionData.uid ||
																	!isInterested(auction.notInterestedOwners, myAuctionData.uid)
																"
															>
																Pass
															</button>
														</div>
													</div>
													<div
														*ngIf="myAuctionData.roster.length === auction.totalTeamsPerManager"
														class="timer-text"
													>
														<h1>Roster full</h1>
													</div>
												</div>
											</div>
										</ng-container>
									</ng-container>
								</div>
								<div
									*ngSwitchCase="'Completed'"
									class="auction-banner"
									[style.background-image]="
										'linear-gradient(to right, rgba(0,0,0,2), rgba(0,0,0,0)), url(../assets/img/stock/' +
										event.type.toLowerCase() +
										'.jpg)'
									"
								>
									<h2 class="header">Your Auction is Complete</h2>
									<button
										class="action-button"
										mat-stroked-button
										color="default"
										(click)="returnToGroup(auction.eventId, auction.groupId)"
									>
										Back to Group
									</button>
								</div>
								<div
									*ngSwitchCase="'Abandoned'"
									class="auction-banner"
									[style.background-image]="
										'linear-gradient(to right, rgba(0,0,0,2), rgba(0,0,0,0)), url(../assets/img/stock/' +
										event.type.toLowerCase() +
										'.jpg)'
									"
								>
									<h2 class="header">Your Auction is Complete</h2>
									<button
										class="action-button"
										mat-stroked-button
										color="default"
										(click)="returnToGroup(auction.eventId, auction.groupId)"
									>
										Back to Group
									</button>
								</div>
								<div
									*ngSwitchDefault
									class="auction-banner"
									[style.background-image]="
										'linear-gradient(to right, rgba(0,0,0,2), rgba(0,0,0,0)), url(../assets/img/stock/' +
										event.type.toLowerCase() +
										'.jpg)'
									"
								>
									<ng-container *ngIf="{ value: allowedToStartAuction$ | async } as allowedToStartAuction">
										<h2>
											{{
												allowedToStartAuction.value
													? "Click Start Auction to Begin"
													: "Your Auction starts in " + (auction.startTime | countdown | async)
											}}
										</h2>
										<button
											mat-stroked-button
											*ngIf="allowedToStartAuction.value"
											[disabled]="startingAuction$ | async"
											(click)="startAuction(auction.id)"
											class="action-button"
											color="accent"
										>
											Start Auction
										</button>
									</ng-container>
								</div>
							</ng-container>

							<!-- Desktop Pills -->
							<div class="manager-overview" *ngIf="userAuctionData.length > 0">
								<div *ngFor="let userInfo of userAuctionData" class="manager-card" (click)="openRoster(userInfo, true)">
									<div class="color" *ngIf="userInfo.color" [style.background-color]="userInfo.color"></div>
									<div class="content">
										<div class="manager-content">
											<span class="manager-name">{{ userInfo.user?.displayName }}</span>
											<span class="budget">{{
												userInfo.amountAvailable | currency: "USD" : "symbol" : "1.0-0"
											}}</span>
										</div>
										<mat-divider></mat-divider>
										<div class="roster">Roster: ({{ userInfo.roster.length }}/{{ auction.totalTeamsPerManager }})</div>
									</div>
								</div>
							</div>

							<div class="main-content">
								<!-- Main Content TODO: Update rounds to be all rounds in the event -->
								<ng-container *ngIf="isBracketOnly(event); else normalTeams">
									<ng-container *ngIf="bracketMatchups$ | async as bracketMatchups">
										<am-event-bracket
											[rounds]="[]"
											[matchups]="bracketMatchups"
											[regions]="league.regions"
											[sport]="league.sport"
											[isAuction]="true"
										></am-event-bracket>
									</ng-container>
								</ng-container>

								<ng-template #normalTeams>
									<ng-container *ngIf="teamsDataByDivision$ | async as teamsByDivions">
										<div class="division-list">
											<div *ngFor="let division of teamsByDivions | keyvalue" class="division">
												<div class="header">{{ division.key }}</div>
												<div *ngFor="let team of division.value" class="team-card">
													<ng-container
														[ngTemplateOutlet]="teamCard"
														[ngTemplateOutletContext]="{
															teamData: {
																team: team.team,
																showColor: true,
																price: team.price,
																color: team.color
															}
														}"
													></ng-container>
												</div>
											</div>
										</div>
									</ng-container>
								</ng-template>
							</div>
						</div>
					</div>
				</ng-container>
			</ng-container>

			<ng-template #mobileView>
				<ng-container *ngIf="userAuctionData$ | async as userAuctionData">
					<div id="mobile">
						<ng-container [ngSwitch]="auction.status">
							<div *ngSwitchCase="'In Progress'" class="bid-container">
								<ng-container *ngIf="myAuctionData$ | async as myAuctionData">
									<ng-container *ngIf="currentBidData$ | async as currentBidData">
										<div
											*ngIf="showResult"
											[@slideInOut]
											class="result-view-mobile"
											[style]="'background-color: ' + resultTeam?.hexcode + '73'"
										>
											<div class="results">
												<img class="completed-bid-team-logo-back" [src]="resultTeam?.logo" />
												<img class="completed-bid-team-logo" [src]="resultTeam?.logo" />

												<h2 *ngIf="resultOwner">
													{{ resultTeam?.displayName }} awarded to: {{ resultOwner.displayName }} for ${{
														resultBid
													}}
												</h2>
												<h2 *ngIf="!resultOwner">
													No bids on {{ resultTeam?.displayName }}, they have been added to the back of the line.
												</h2>
											</div>
										</div>
										<div
											class="current-offer"
											[ngClass]="{
												'mat-bid-owned': currentBidData.currentOwner?.uid === myAuctionData.uid,
												'mat-bid-lost':
													currentBidData.currentOwner?.uid &&
													currentBidData.currentOwner?.uid !== myAuctionData.uid,
												'mat-bid-no-bidder': !currentBidData.currentOwner?.uid
											}"
											*ngIf="!showResult"
											[@slideInOut]
										>
											<div class="active-bid-team-logo-container">
												<div class="active-bid-team-logo-mobile">
													<img class="active-bid-team-logo-image-back" [src]="currentBidData.currentTeam?.logo" />
													<img class="active-bid-team-logo-image" [src]="currentBidData.currentTeam?.logo" />
												</div>
											</div>
											<div class="active-bid-team-container-mobile">
												<h1 class="active-bid-team-text-mobile">
													{{ currentBidData.currentTeam?.displayName }} <br />{{
														currentBidData.currentTeam?.seed ? "#" + currentBidData.currentTeam?.seed : ""
													}}
													{{ currentBidData.currentTeam?.division }}
													{{
														currentBidData.currentTeam?.record
															? "(" + currentBidData.currentTeam?.record + ")"
															: ""
													}}
												</h1>
												<div class="current-offer-details-mobile">
													<div>
														<b>Current Bid:</b>
														{{ currentBidData.currentBid | currency: "USD" : "symbol" : "1.0-0" }}
														<span *ngIf="!!currentBidData.currentOwner"
															>({{ currentBidData.currentOwner.displayName }})</span
														>
													</div>
												</div>
											</div>
											<div
												class="right-bid-panel"
												[style]="'background-color: ' + currentBidData.currentTeam?.hexcode"
											>
												<div class="timer-text-mobile mat-headline-5">{{ bidTimerSeconds$ | async }}</div>
											</div>
										</div>
									</ng-container>
								</ng-container>
							</div>
							<div
								*ngSwitchCase="'Completed'"
								class="auction-banner"
								[style.background-image]="
									'linear-gradient(to right, rgba(0,0,0,2), rgba(0,0,0,0)), url(../assets/img/stock/' +
									event.type.toLowerCase() +
									'.jpg)'
								"
							>
								<h2 class="header">Your Auction is Complete</h2>
								<button
									class="action-button"
									mat-stroked-button
									color="default"
									(click)="returnToGroup(auction.eventId, auction.groupId)"
								>
									Back to Group
								</button>
							</div>
							<div
								*ngSwitchCase="'Abandoned'"
								class="auction-banner"
								[style.background-image]="
									'linear-gradient(to right, rgba(0,0,0,2), rgba(0,0,0,0)), url(../assets/img/stock/' +
									event.type.toLowerCase() +
									'.jpg)'
								"
							>
								<h2 class="header">Your Auction is Complete</h2>
								<button
									class="action-button"
									mat-stroked-button
									color="default"
									(click)="returnToGroup(auction.eventId, auction.groupId)"
								>
									Back to Group
								</button>
							</div>
							<div
								*ngSwitchDefault
								class="auction-banner"
								[style.background-image]="
									'linear-gradient(to right, rgba(0,0,0,2), rgba(0,0,0,0)), url(../assets/img/stock/' +
									event.type.toLowerCase() +
									'.jpg)'
								"
							>
								<ng-container *ngIf="{ value: allowedToStartAuction$ | async } as allowedToStartAuction">
									<h2>
										{{
											allowedToStartAuction.value
												? "Click Start Auction to Begin"
												: "Your Auction starts in " + (auction.startTime | countdown | async)
										}}
									</h2>
									<button
										mat-stroked-button
										*ngIf="allowedToStartAuction.value"
										[disabled]="startingAuction$ | async"
										(click)="startAuction(auction.id)"
										class="action-button"
										color="accent"
									>
										Start Auction
									</button>
								</ng-container>
							</div>
						</ng-container>

						<!-- Mobile Pills -->
						<div class="manager-overview-mobile manager-overview" *ngIf="userAuctionData.length > 0">
							<div *ngFor="let userInfo of userAuctionData" class="manager-card-mobile" (click)="openRoster(userInfo, true)">
								<div class="color" *ngIf="userInfo.color" [style.background-color]="userInfo.color"></div>
								<div class="content">
									<div class="name">{{ userInfo.user?.displayName }}</div>
									<mat-divider></mat-divider>
									<div class="teams">
										<b>{{ userInfo.roster.length }}/{{ auction.totalTeamsPerManager }}</b>
										<span class="budget">{{ userInfo.amountAvailable | currency: "USD" : "symbol" : "1.0-0" }}</span>
									</div>
								</div>
							</div>
						</div>

						<div class="main-content-mobile">
							<div class="nav mobile-nav" *ngIf="auction.status !== 'In Progress'">
								<button
									matTooltip="Group info"
									mat-stroked-button
									color="primary"
									type="button"
									(click)="openGroupInfo(auction.eventId, auction.groupId)"
								>
									Group Info
								</button>
								<button
									matTooltip="How to play"
									mat-stroked-button
									color="primary"
									(click)="openTutorial(auction, undefined)"
									type="button"
								>
									How to Play
								</button>
							</div>
							<mat-tab-group style="height: 100%" [selectedIndex]="mobileTabIndex" (selectedTabChange)="onTabChange($event)">
								<mat-tab label="Teams">
									<!-- Main Content TODO: Update rounds to be all rounds in the event -->
									<ng-container *ngIf="isBracketOnly(event); else normalTeams">
										<ng-container *ngIf="bracketMatchups$ | async as bracketMatchups">
											<am-event-bracket
												[rounds]="[]"
												[matchups]="bracketMatchups"
												[regions]="league.regions"
												[sport]="league.sport"
												[isAuction]="true"
											></am-event-bracket>
										</ng-container>
									</ng-container>

									<ng-template #normalTeams>
										<ng-container *ngIf="teamsDataByDivision$ | async as teamsByDivions">
											<div class="mobile-list division-list">
												<div *ngFor="let division of teamsByDivions | keyvalue" class="division">
													<div class="header">{{ division.key }}</div>
													<div *ngFor="let team of division.value" class="team-card">
														<ng-container
															[ngTemplateOutlet]="teamCard"
															[ngTemplateOutletContext]="{
																teamData: {
																	team: team.team,
																	showColor: true,
																	price: team.price,
																	color: team.color
																}
															}"
														></ng-container>
													</div>
												</div>
											</div>
										</ng-container>
									</ng-template>
								</mat-tab>
								<mat-tab label="Rosters">
									<div class="roster">
										<mat-select
											class="full-width"
											(selectionChange)="selectUser($event)"
											[value]="currentSelectedUser$ | async"
										>
											<mat-option *ngFor="let userData of userAuctionData" [value]="userData.user?.uid">
												{{ userData.user?.displayName }}
											</mat-option>
										</mat-select>
										<ng-container *ngIf="selectedUserData$ | async as selectedUserData">
											<div class="rosters">
												<span
													*ngIf="totalTeamsPerManager$ | async as totalTeamsPerManager"
													class="teams-per-manager"
												>
													{{ selectedUserData.roster.length }} / {{ totalTeamsPerManager }} Teams
												</span>
												<ng-container *ngFor="let item of selectedUserData.placeholderArray; let i = index">
													<div *ngIf="selectedUserData.roster[i] as team; else placeholder" class="team-card">
														<ng-container
															[ngTemplateOutlet]="teamCard"
															[ngTemplateOutletContext]="{
																teamData: { team: team.team, showColor: false, price: team.price }
															}"
														></ng-container>
													</div>
													<ng-template #placeholder>
														<div class="placeholder-team-card"></div>
													</ng-template>
												</ng-container>
											</div>
										</ng-container>
									</div>
								</mat-tab>
							</mat-tab-group>
						</div>

						<div class="action-buttons" *ngIf="auction.status === 'In Progress' && (currentBidData$ | async) as currentBidData">
							<ng-container *ngIf="myAuctionData$ | async as myAuctionData">
								<div
									class="bid-controls-mobile"
									[ngClass]="{
										'mat-bid-owned': currentBidData.currentOwner?.uid === myAuctionData.uid,
										'mat-bid-lost':
											currentBidData.currentOwner?.uid && currentBidData.currentOwner?.uid !== myAuctionData.uid,
										'mat-bid-no-bidder': !currentBidData.currentOwner?.uid
									}"
									*ngIf="myAuctionData.roster.length < auction.totalTeamsPerManager"
								>
									<button
										mat-fab
										extended
										class="quick-bid"
										color="warn"
										(click)="pass()"
										[disabled]="
											currentBidData?.currentOwner?.uid === myAuctionData.uid ||
											!isInterested(auction.notInterestedOwners, myAuctionData.uid) ||
											showResult
										"
									>
										Pass
									</button>
									<div class="bid-slider-card-mobile">
										<mat-slider
											class="bid-slider-mobile"
											[max]="myAuctionData.maxBid"
											[min]="currentBidData.currentBid + 1"
											[step]="1"
											[discrete]="true"
										>
											<input matSliderThumb [formControl]="customBid" [defaultValue]="1" />
										</mat-slider>
									</div>
									<button
										mat-fab
										extended
										class="quick-bid"
										color="primary"
										[disabled]="
											bidDelay ||
											(bidTimerSeconds$ | async) === 0 ||
											(submittingBid$ | async) ||
											(customBid && customBid.value > myAuctionData.maxBid) ||
											(customBid && currentBidData?.currentBid && customBid.value <= currentBidData.currentBid) ||
											currentBidData?.currentOwner?.uid === myAuctionData.uid ||
											showResult
										"
										(click)="submitBid(customBid.value)"
									>
										Bid {{ customBid.value }}
									</button>
								</div>
								<div
									*ngIf="myAuctionData.roster.length === auction.totalTeamsPerManager"
									class="roster-full-text-mobile bid-controls-mobile timer-text-mobile mat-headline-5"
								>
									Roster full
								</div>
							</ng-container>
						</div>
					</div>
				</ng-container>
			</ng-template>

			<ng-template #teamCard let-teamData="teamData">
				<div class="card-content">
					<div class="color" *ngIf="teamData.showColor && teamData.color" [style.background-color]="teamData.color"></div>
					<img width="45" height="45" [src]="teamData.team.logo" [style.padding-left]="'5px'" />
					<div class="team-info">
						<b>{{ teamData.team.displayName }}</b>
						<span>{{ teamData.team.seed ? "#" + teamData.team.seed : "" }} {{ teamData.team.division }}</span>
					</div>
					<div class="team-price">{{ teamData.price | currency: "USD" : "symbol" : "1.0-0" }}</div>
				</div>
			</ng-template>
		}
	}
}

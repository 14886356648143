import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { BaseComponent } from "src/app/shared/components/base.component";
import { isMobile } from "src/app/state/app/app.selectors";

@Component({
	selector: "am-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.scss"]
})
export class FooterComponent extends BaseComponent implements OnInit {
	isMobile$: Observable<boolean>;
	copyrightYear = new Date().getFullYear();

	constructor(private store: Store) {
		super();
	}

	ngOnInit(): void {
		this.isMobile$ = this.store.select(isMobile);
	}

	openExternal(link: string): void {
		window.open(link, "_blank");
	}
}

import { Injectable } from "@angular/core";
import { from, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import {
	collection,
	doc,
	docData,
	documentId,
	Firestore,
	getDoc,
	getDocs,
	Query,
	query,
	QueryDocumentSnapshot,
	SnapshotOptions,
	updateDoc,
	where
} from "@angular/fire/firestore";
import { Functions, httpsCallableData } from "@angular/fire/functions";
import { IUser, IUserEventGroup, IPrivate } from "../models/user";
import { DocumentData } from "firebase-admin/firestore";

@Injectable({
	providedIn: "root"
})
export class UserService {
	constructor(private fs: Firestore, private fns: Functions) {}

	// TODO: Implement live updates: https://firebase.google.com/docs/firestore/query-data/listen#listen_to_multiple_documents_in_a_collection
	getFullUserInfoByID(uid: string): Observable<IUser | undefined> {
		return from(getDoc(doc(this.fs, "users", uid).withConverter(userConverter))).pipe(
			map((user) => {
				return user.data();
			})
		);
	}

	getBasicUserInfoByIDs(uids: string[]): Observable<IUser[]> {
		return from(getDocs(query(collection(this.fs, "users").withConverter(userConverter), where(documentId(), "in", uids)))).pipe(
			map((response) => response.docs.map((c) => c.data()))
		);
	}

	updateUser(uid: string, displayName: string, profileIcon?: string): Promise<void> {
		return updateDoc(doc(this.fs, "users", uid).withConverter(userConverter), { displayName, profileIcon });
	}

	async deleteUser(): Promise<void> {
		await httpsCallableData(this.fns, "user-deleteUser");
	}

	getUserEventGroupById(uid: string): Observable<Query<DocumentData>> {
		return of(query(collection(this.fs, `user-event-groups/${uid}/groups`)).withConverter(userEventGroupConverter));
	}

	getUserPrivateInfoById(uid: string): Observable<IPrivate | undefined> {
		return docData(doc(this.fs, "user-private", uid).withConverter(userLegalConverter));
	}

	agreeToPolicy(termsAndConditionsVersion: number, privacyPolicyVersion: number, uid: string): Observable<string> {
		const callable = httpsCallableData(this.fns, "user-agreeToPolicy");
		return callable({ termsAndConditionsVersion, privacyPolicyVersion, uid }) as Observable<string>;
	}

	saveTutorialVersion(uid: string, version: number): Observable<number> {
		const callable = httpsCallableData(this.fns, "user-saveTutorialVersion");
		return callable({ uid, version }) as Observable<number>;
	}

	markNotificationAsSeen(uid: string, notificationId: string): Observable<string> {
		const callable = httpsCallableData(this.fns, "user-markNotificationAsSeen");
		return callable({ notificationId, uid }) as Observable<string>;
	}

	updateEmailNotificationSettings(uid: string, emailNotifications: boolean): Observable<string> {
		const callable = httpsCallableData(this.fns, "user-updateNotificationSettings");
		return callable({ uid, emailNotifications: emailNotifications }) as Observable<string>;
	}

	addPushNotificationDevice(uid: string, name: string, token: string): Observable<string> {
		const callable = httpsCallableData(this.fns, "user-updateNotificationSettings");
		return callable({ uid, addPushNotification: { name: name, token: token } }) as Observable<string>;
	}

	removePushNotificationDevices(uid: string, tokens: string[]): Observable<string> {
		const callable = httpsCallableData(this.fns, "user-updateNotificationSettings");
		return callable({ uid, removePushNotificationTokens: tokens }) as Observable<string>;
	}
}

const userConverter = {
	toFirestore: (user: IUser) => {
		return {};
	},
	fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions | undefined) => {
		return { uid: snapshot.id, ...snapshot.data(options) } as IUser;
	}
};

const userLegalConverter = {
	toFirestore: (legal: IPrivate) => {
		return {};
	},
	fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions | undefined) => {
		return { ...snapshot.data(options) } as IPrivate;
	}
};

const userEventGroupConverter = {
	toFirestore: (userEventGroup: IUserEventGroup) => {
		return {};
	},
	fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions | undefined) => {
		const data = snapshot.data(options);
		return { groupId: snapshot.id, ...data, date: data["date"] ? new Date(data["date"]) : undefined } as IUserEventGroup;
	}
};

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { of, startWith, switchMap, take, takeUntil } from "rxjs";
import { ILeagueIntegrationInfo } from "src/app/models/league";
import { IntegrationService } from "src/app/services/integration.service";
import { BaseComponent } from "src/app/shared/components/base.component";

@Component({
	selector: "am-integration-league-lookup",
	templateUrl: "./integration-league-lookup.component.html",
	styleUrls: ["./integration-league-lookup.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntegrationLeagueLookupComponent extends BaseComponent implements OnInit, OnChanges {
	constructor(private dataService: IntegrationService, private fb: FormBuilder, private cdr: ChangeDetectorRef) {
		super();

		this.leagueForm = this.fb.group({
			leagueId: this.fb.control(this.existingLeagueId || ""),
			seasonId: this.fb.control(this.existingSeasonId || "")
		});
	}

	@Input() existingLeagueId: string;
	@Input() existingSeasonId: string;
	@Input() eventSport: string;
	@Input() eventYear: string;

	leagueForm: FormGroup;

	showForm: boolean = true;

	apiLeagues: ILeagueIntegrationInfo[] = [];
	apiSeasons: string[] = [];

	leaguesLoading = false;
	seasonsLoading = false;

	@Output() integrationIdChange = new EventEmitter<{ leagueId: string; seasonId: string }>();

	ngOnInit() {
		this.leaguesLoading = true;
		this.leagueForm.get("leagueId")?.disable();
		this.dataService
			.getLeagues()
			.pipe(take(1))
			.subscribe((response) => {
				this.apiLeagues = response.sort((a, b) => (a.name < b.name ? -1 : 1));
				this.leaguesLoading = false;
				this.leagueForm.get("leagueId")?.enable();

				if (!this.leagueForm.get("leagueId")?.value && this.eventSport) {
					let match = this.apiLeagues.find((l) => l.name === this.eventSport);
					if (match) {
						this.leagueForm.get("leagueId")?.setValue(match.integrationId);
					}
				}

				this.cdr.detectChanges();
			});

		this.leagueForm
			.get("leagueId")
			?.valueChanges.pipe(
				startWith(this.existingLeagueId),
				switchMap((leagueId) => {
					this.seasonsLoading = true;
					this.leagueForm.get("seasonId")?.disable();
					if (leagueId) {
						return this.dataService.getSeasons(leagueId).pipe(take(1));
					} else {
						return of([]);
					}
				})
			)
			.subscribe((seasons) => {
				this.apiSeasons = seasons.sort((a, b) => (a > b ? -1 : 1));
				this.seasonsLoading = false;
				this.leagueForm.get("seasonId")?.enable();

				if (!this.leagueForm.get("seasonId")?.value && this.eventYear) {
					let match = this.apiSeasons.find((s) => s === "" + this.eventYear || s === this.existingSeasonId);
					if (match) {
						this.leagueForm.get("seasonId")?.setValue(match);
					}
				}

				this.cdr.detectChanges();
			});

		this.leagueForm
			.get("seasonId")
			?.valueChanges.pipe(takeUntil(this.destroyed$))
			.subscribe(() => {
				this.emitChange();
			});
	}

	ngOnChanges(): void {
		if (!this.leagueForm.get("leagueId")?.value && this.eventSport) {
			let match = this.apiLeagues.find((l) => l.name === this.eventSport);
			if (match) {
				this.leagueForm.get("leagueId")?.setValue(match.integrationId);
			}
		}
	}

	emitChange() {
		this.integrationIdChange.emit({
			leagueId: this.leagueForm.get("leagueId")?.value,
			seasonId: this.leagueForm.get("seasonId")?.value
		});
	}
}

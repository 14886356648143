import { createAction, props } from "@ngrx/store";
import { IEvent } from "src/app/models/event";

export const loadEvents = createAction("[Event] Load Event List");
export const loadEvent = createAction("[Event] Load Event By Id", props<{ eventId: string }>());
export const loadEventsSuccess = createAction("[Event] Load Event List Success", props<{ events: IEvent[] }>());
export const loadEventsError = createAction("[Event] Load Event List Error", props<{ errorMessage: string }>());
export const loadEventSuccess = createAction("[Event] Load Event Success", props<{ event: IEvent }>());

export const setSelectedEvent = createAction("[Event] Event Selected", props<{ eventId: string }>());
export const setSelectedEventSuccess = createAction("[Event] Event Selected Success", props<{ eventId: string }>());
export const setSelectedEventFailed = createAction("[Event] Event Selected Failed");

export const setSelectedPhase = createAction("[Event] Phase Selected", props<{ phaseId: string }>());

export const clearSelectedEvent = createAction("[Event] Clear Selected Event");
export const startLiveUpdates = createAction("[Event] Start Tracking Live Updates");
export const stopLiveUpdates = createAction("[Event] Stop Tracking Live Updates");

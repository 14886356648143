import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@Component({
	standalone: true,
	imports: [CommonModule, MatButtonModule, MatProgressSpinnerModule],
	selector: "am-loading-button",
	template: `
		<button (click)="clicked.emit($event)" mat-raised-button [color]="color" [disabled]="disabled">
			<span *ngIf="!loading">{{ title }}</span>
			<ng-container *ngIf="color !== 'primary'; else colorSpinner">
				<mat-spinner *ngIf="loading" diameter="25"></mat-spinner>
			</ng-container>
			<ng-template #colorSpinner>
				<mat-spinner color="accent" *ngIf="loading" diameter="25"></mat-spinner>
			</ng-template>
		</button>
	`,
	styles: []
})
export class LoadingButtonComponent {
	@Input() title: string;
	@Input() loading: boolean | null = false;
	@Input() color: string;
	@Input() type: "submit" | "button" | "reset" = "button";
	@Input() disabled: boolean | null = false;
	@Output() clicked: EventEmitter<any> = new EventEmitter<any>();
}

import { NgModule } from "@angular/core";
import { NFLBracketComponent } from "./nfl/nfl-bracket.component";
import { AllMaterialModule } from "../all-material-module";
import { CommonModule } from "@angular/common";
import { MatIconRegistry } from "@angular/material/icon";
import { AppRoutingModule } from "src/app/app-routing.module";
import { PipeModule } from "../pipes/pipe.module";
import { ReactiveFormsModule } from "@angular/forms";
import { SharedComponentsModule } from "../components/shared-component.module";
import { LeagueBracketComponent } from "./league/league-bracket.component";
import { NCAAMBracketComponent } from "./ncaam/ncaam-bracket.component";
import { NHLBracketComponent } from "./nhl/nhl-bracket.component";
import { NBABracketComponent } from "./nba/nba-bracket.component";

const components = [NFLBracketComponent, LeagueBracketComponent, NCAAMBracketComponent, NHLBracketComponent, NBABracketComponent];

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [AllMaterialModule, CommonModule, AppRoutingModule, PipeModule, ReactiveFormsModule, SharedComponentsModule],
	providers: [MatIconRegistry]
})
export class BracketModule {}

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Router, RouterLink } from "@angular/router";
import { Store } from "@ngrx/store";
import { catchError, combineLatest, filter, switchMap, take } from "rxjs";
import { AnalyticsService } from "src/app/services/analytics.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { UserService } from "src/app/services/user.service";
import { getConfig } from "src/app/state/app/app.selectors";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel, MatError } from "@angular/material/form-field";
import { NgIf } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { MatButton } from "@angular/material/button";

@Component({
    selector: "am-signup",
    templateUrl: "./signup.component.html",
    styleUrls: ["./signup.component.scss"],
    standalone: true,
    imports: [MatButton, MatIcon, NgIf, FormsModule, ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatError, RouterLink]
})
export class SignupComponent implements OnInit {
	createAccount: FormGroup = this.fb.group({
		email: new FormControl("", [Validators.required, Validators.email]),
		displayName: new FormControl("", [Validators.required]),
		password: new FormControl("", [Validators.required, Validators.maxLength(25), Validators.minLength(6), Validators.pattern("")]),
		password2: new FormControl("", [Validators.required, Validators.maxLength(25), Validators.minLength(6), Validators.pattern("")])
	});

	updating: boolean = false;
	success: boolean = false;
	errorMessage: string = "";

	get email() {
		return this.createAccount?.get(["email"]) as FormControl;
	}

	get displayName() {
		return this.createAccount?.get(["displayName"]) as FormControl;
	}

	get password() {
		return this.createAccount?.get(["password"]) as FormControl;
	}

	get password2() {
		return this.createAccount?.get(["password2"]) as FormControl;
	}

	constructor(
		public store: Store,
		private fb: FormBuilder,
		private router: Router,
		private authenticationSerivce: AuthenticationService,
		private analyticService: AnalyticsService
	) {}

	ngOnInit(): void {
		this.createAccount.addValidators(() => {
			if (this.password.value !== this.password2.value) return { match_error: "Passwords do not match" };
			return null;
		});
	}

	goToLogin() {
		this.router.navigate(["login"]);
	}

	async createUserAccount() {
		this.updating = true;
		const accountInfo = this.createAccount.value;
		this.authenticationSerivce
			.createUserAccount(accountInfo.email, accountInfo.password, accountInfo.displayName)
			.pipe(
				take(1),

				catchError((error) => {
					if (error.message.includes("email-already-exists")) {
						this.errorMessage = "This email is already associated with an account, login or reset your password.";
					} else {
						this.errorMessage = "An Error Occured Creating Account";
					}

					this.updating = false;
					throw error;
				})
			)
			.subscribe(async () => {
				await this.analyticService.logEvent("sign_up", { provider: "Generic" });
				this.success = true;
				this.updating = false;
			});
	}
}

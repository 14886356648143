import { Component, Input } from "@angular/core";
import { IPhase, ITier } from "src/app/models/event";
import { IMatchup } from "src/app/models/game";
import { BaseComponent } from "src/app/shared/components/base.component";

@Component({
	selector: "am-event-bracket",
	templateUrl: "./event-bracket.component.html",
	styleUrls: ["./event-bracket.component.scss"]
})
export class EventBracketComponent extends BaseComponent {
	@Input() tiers: ITier[];
	@Input() matchups: IMatchup[];
	@Input() phase: IPhase;
	@Input() sport: string;
	@Input() isAuction: boolean = false;

	constructor() {
		super();
	}

	getMatchupsByRound(tierId: string): IMatchup[] {
		return this.matchups.filter((x) => x.tierId === tierId);
	}
}

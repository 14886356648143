<ng-container *ngIf="event$ | async as event">
	<div
		*ngIf="!(isMobile$ | async)"
		class="background-image"
		[style.background-image]="
			'linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0)), url(../assets/img/stock/' + event.type.toLowerCase() + '.jpg)'
		"
	></div>
</ng-container>
<router-outlet></router-outlet>

<h2>System Admin</h2>

<mat-tab-group dynamicHeight>
	<mat-tab label="Notifications">
		<div class="notification">
			<button mat-raised-button color="accent" (click)="addNotification()">Add Notifications</button>

			<ng-container *ngIf="notifications != null">
				<ng-container *ngFor="let notification of notifications.controls">
					<form [formGroup]="notification" class="notification-form" (ngSubmit)="saveNotification(notification)">
						<div class="form-section">
							<mat-form-field appearance="outline">
								<mat-label>Title</mat-label>
								<input matInput type="text" formControlName="title" />
							</mat-form-field>
							<mat-form-field appearance="outline">
								<mat-label>Icon</mat-label>
								<input matInput type="text" formControlName="icon" />
							</mat-form-field>
							<mat-form-field appearance="outline">
								<mat-label>Action Link</mat-label>
								<input matInput type="text" formControlName="actionLink" />
							</mat-form-field>
							<a [href]="'https://www.auctionmadness.com/' + notification.get('actionLink')?.value">
								https://www.auctionmadness.com/{{ notification.get("actionLink")?.value }}
							</a>
						</div>
						<div class="form-section">
							<mat-form-field appearance="outline">
								<mat-label>Message</mat-label>
								<textarea rows="3" matInput formControlName="message"></textarea>
							</mat-form-field>

							<div class="actions">
								<button mat-raised-button color="accent" type="submit">Save</button>
								<button
									mat-raised-button
									color="warn"
									(click)="deleteNotification(notification.get('id')?.value)"
									type="button"
								>
									Delete
								</button>
							</div>
						</div>
					</form>
					<mat-divider></mat-divider>
				</ng-container>
			</ng-container>
		</div>
	</mat-tab>

	<mat-tab label="Storage">
		<div class="cache-control">
			<h4>Enter a file path and then click submit to update the images cache to be two months.</h4>
			<mat-form-field appearance="outline">
				<mat-label>File Path</mat-label>
				<input [formControl]="filePath" matInput />
			</mat-form-field>
			<am-loading-button title="Update Cache" [loading]="cacheLoading" color="primary" (clicked)="updateCache()"> </am-loading-button>
		</div>
		<!-- 
			TODO
		<div>
			<h4>Upload file to Firebase storage with the correct cache amount.</h4>
			<div>
				<mat-form-field appearance="outline">
					<mat-label>File Path</mat-label>
					<input [formControl]="newFilePath" matInput />
				</mat-form-field>
				<input type="file" (change)="onFileChange($event)" />
				<span *ngIf="newFile">{{ newFile.name }}</span>
			</div>
			<am-loading-button title="Add File" [loading]="uploading" color="primary" (clicked)="addFile()"> </am-loading-button>
		</div> -->
	</mat-tab>
</mat-tab-group>

import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Capacitor } from "@capacitor/core";
import { BaseComponent } from "src/app/shared/components/base.component";
import { DOCUMENT } from "@angular/common";
import { AnalyticsService } from "src/app/services/analytics.service";
import { MatButton } from "@angular/material/button";

@Component({
    selector: "am-app-links",
    template: `
		<h1>Get the Official Auction Madness App Below</h1>

		<button mat-button (click)="routeBack()">Continue with Browser</button>

		<div class="app-store-list">
			<img
				style="cursor: pointer"
				height="60"
				width="140"
				(click)="routePlayStore()"
				src="../assets/img/app-store-logos/google-play-badge.png"
			/>
			<img
				style="cursor: pointer"
				class="apple-logo"
				height="40"
				width="120"
				(click)="routeAppStore()"
				src="../assets/img/app-store-logos/app-store-badge.svg"
			/>
		</div>
	`,
    styles: [
        `
   			:host {
   				display: flex;
   				flex-direction: column;
   				align-items: center;
   				text-align: center;
   
   				margin-top: 30px;
   			}
   
   			.app-store-list {
   				margin: 35px;
   
   				.apple-logo {
   					margin: 10px;
   				}
   			}
   		`
    ],
    standalone: true,
    imports: [MatButton]
})
export class AppLinksComponent extends BaseComponent implements OnInit {
	androidLink = "https://play.google.com/store/apps/details?id=com.auctionmadness.app";
	iosLink = "https://apps.apple.com/app/apple-store/id6463294856?pt=126553057&ct=app%20install&mt=8";

	constructor(private router: Router, @Inject(DOCUMENT) private document: Document, private analyticsService: AnalyticsService) {
		super();
	}

	async ngOnInit(): Promise<void> {
		await this.analyticsService.logEvent("App_Check");
		if (navigator.userAgent.match(/android/i)) {
			this.routePlayStore();
		} else if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/iPad/i)) {
			this.routeAppStore();
		}
	}

	routeBack(): void {
		this.router.navigate(["home"]);
	}

	routePlayStore() {
		this.document.location = this.androidLink;
	}

	routeAppStore() {
		this.document.location = this.iosLink;
	}
}

import { Component, Input, OnInit } from "@angular/core";
import { IMatchup } from "src/app/models/game";
import { BaseComponent } from "src/app/shared/components/base.component";
import { LeagueBracketComponent } from "../../shared/brackets/league/league-bracket.component";
import { NCAAMBracketComponent } from "../../shared/brackets/ncaam/ncaam-bracket.component";
import { NBABracketComponent } from "../../shared/brackets/nba/nba-bracket.component";
import { NHLBracketComponent } from "../../shared/brackets/nhl/nhl-bracket.component";
import { NFLBracketComponent } from "../../shared/brackets/nfl/nfl-bracket.component";
import { NgSwitch, NgSwitchCase, NgIf } from "@angular/common";
import { IRegion } from "src/app/models/league";

@Component({
	selector: "am-event-bracket",
	templateUrl: "./event-bracket.component.html",
	styleUrls: ["./event-bracket.component.scss"],
	standalone: true,
	imports: [
		NgSwitch,
		NgSwitchCase,
		NgIf,
		NFLBracketComponent,
		NHLBracketComponent,
		NBABracketComponent,
		NCAAMBracketComponent,
		LeagueBracketComponent
	]
})
export class EventBracketComponent extends BaseComponent {
	@Input() rounds: string[];
	@Input() matchups: IMatchup[];
	@Input() regions: IRegion[];
	@Input() sport: string;
	@Input() isAuction: boolean = false;

	constructor() {
		super();
	}
}

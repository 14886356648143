import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard, customClaims, redirectLoggedInTo, redirectUnauthorizedTo } from "@angular/fire/auth-guard";
import { ProfileComponent } from "./pages/profile/profile.component";
import { GroupEditComponent } from "./pages/group-edit/group-edit.component";
import { UnauthenticatedComponent } from "./pages/unauthenticated/unauthenticated.component";
import { InviteComponent } from "./pages/invite/invite.component";
import { pipe } from "rxjs";
import { map } from "rxjs";
import { LoginComponent } from "./pages/login/login.component";
import { SignupComponent } from "./pages/login/components/signup/signup.component";
import { GenericLoginComponent } from "./pages/login/components/generic-login/generic-login.component";
import { PaswordResetComponent } from "./pages/login/components/password-reset/password-reset.component";
import { ResendVerificationComponent } from "./pages/login/components/resend-verification/resend-verification.component";
import { LegalComponent } from "./pages/legal/legal.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { EventPanelComponent } from "./pages/event-panel/event-panel.component";
import { EventScheduleComponent } from "./pages/event-schedule/event-schedule.component";
import { EventScoreboardComponent } from "./pages/event-scoreboard/event-scoreboard.component";
import { GroupAuditDialogComponent } from "./shared/components/group-audit-dialog/group-audit-dialog.component";
import { ProfileManagementComponent } from "./pages/profile-management/profile-management.component";
import { AuctionComponent } from "./pages/auction/auction.component";
import { AppLinksComponent } from "./pages/app-links/app-links.component";
import { SystemAdminComponent } from "./pages/system-admin/system-admin.component";
import { LeagueAdminComponent } from "./pages/league-admin/league-admin.component";
import { LeagueEventAdminComponent } from "./pages/league-event-admin/league-event-admin.component";
import { ValuePicksComponent } from "./pages/value-picks/value-picks.component";

const adminOnly = () =>
	pipe(
		customClaims,
		map((claims: any) => {
			return claims.roles.includes("admin");
		})
	);

const routes: Routes = [
	{
		path: "",
		title: "Auction Madness",
		component: UnauthenticatedComponent,
		canActivate: [AuthGuard],
		data: {
			authGuardPipe: () => redirectLoggedInTo(["/home"])
		}
	},
	{
		path: "app",
		component: AppLinksComponent
	},
	{
		path: "invite",
		title: "Auction Madness | Join Group",
		component: InviteComponent
	},
	{
		path: "login",
		title: "Auction Madness | Login",
		component: LoginComponent,
		canActivate: [AuthGuard],
		data: {
			authGuardPipe: () => redirectLoggedInTo(["/home"])
		},
		children: [
			{
				path: "",
				component: GenericLoginComponent
			},
			{
				path: "signup",
				component: SignupComponent
			},
			{
				path: "reset-password",
				component: PaswordResetComponent
			},
			{
				path: "verification",
				component: ResendVerificationComponent
			}
		]
	},
	{
		path: "home",
		title: "Auction Madness | Home",
		component: ProfileComponent,
		canActivate: [AuthGuard],
		data: {
			authGuardPipe: () => redirectUnauthorizedTo([""])
		}
	},
	{
		path: "admin",
		title: "Auction Madness | Admin",
		canActivate: [AuthGuard],
		data: {
			authGuardPipe: adminOnly
		},
		children: [
			{
				path: "system",
				component: SystemAdminComponent,
				canActivate: [AuthGuard],
				data: {
					authGuardPipe: adminOnly
				}
			},
			{
				path: "league",
				title: "Auction Madness | League Admin",
				component: LeagueAdminComponent,
				canActivate: [AuthGuard],
				data: {
					authGuardPipe: adminOnly
				}
			},
			{
				path: "league/:leagueId",
				title: "Auction Madness | League Admin",
				component: LeagueAdminComponent,
				canActivate: [AuthGuard],
				data: {
					authGuardPipe: adminOnly
				}
			},
			{
				path: "league/:leagueId/event",
				component: LeagueEventAdminComponent,
				canActivate: [AuthGuard],
				data: {
					authGuardPipe: adminOnly
				}
			},

			{
				path: "league/:leagueId/event/:eventId",
				title: "Auction Madness | Admin",
				component: LeagueEventAdminComponent,
				canActivate: [AuthGuard],
				data: {
					authGuardPipe: adminOnly
				}
			}
		]
	},

	{
		path: "league/:leagueId/:eventId/value",
		title: "Auction Madness | Value Picks",
		component: ValuePicksComponent,
		canActivate: [AuthGuard],
		data: {
			authGuardPipe: () => redirectUnauthorizedTo([""])
		}
	},
	{
		path: "league/:leagueId/:eventId/create",
		title: "Auction Madness | Create Group",
		component: GroupEditComponent,
		canActivate: [AuthGuard],
		data: {
			authGuardPipe: () => redirectUnauthorizedTo([""])
		}
	},
	{
		path: "event/:leagueId/:eventId/:groupId/edit",
		title: "Auction Madness | Edit Group",
		component: GroupEditComponent,
		canActivate: [AuthGuard],
		data: {
			authGuardPipe: () => redirectUnauthorizedTo([""])
		},
		children: [
			{
				path: ":groupId/audit",
				component: GroupAuditDialogComponent,
				canActivate: [AuthGuard],
				data: {
					authGuardPipe: () => redirectUnauthorizedTo([""])
				}
			}
		]
	},
	{
		path: "event/:eventId/:groupId",
		component: EventPanelComponent,
		canActivate: [AuthGuard],
		data: {
			authGuardPipe: () => redirectUnauthorizedTo([""])
		},
		children: [
			{
				path: "schedule",
				title: "Auction Madness | Schedule",
				component: EventScheduleComponent,
				canActivate: [AuthGuard],
				data: {
					authGuardPipe: () => redirectUnauthorizedTo([""])
				}
			},
			{
				path: "scoreboard",
				title: "Auction Madness | Scoreboard",
				component: EventScoreboardComponent,
				canActivate: [AuthGuard],
				data: {
					reuseComponent: false,
					authGuardPipe: () => redirectUnauthorizedTo([""])
				}
			},
			{
				path: "",
				title: "Auction Madness | Schedule",
				component: EventScheduleComponent,
				canActivate: [AuthGuard],
				data: {
					authGuardPipe: () => redirectUnauthorizedTo([""])
				}
			}
		]
	},
	{
		path: "auction/:auctionId",
		title: "Auction Madness | Auction",
		component: AuctionComponent,
		canActivate: [AuthGuard],
		data: {
			authGuardPipe: () => redirectUnauthorizedTo([""])
		}
	},
	{
		path: "profile/manage",
		component: ProfileManagementComponent,
		title: "Auction Madness | Manage Profile",
		canActivate: [AuthGuard],
		data: {
			authGuardPipe: () => redirectUnauthorizedTo([""])
		}
	},

	// Legal
	{
		path: "cookies",
		title: "Auction Madness | Cookies",
		component: LegalComponent,
		data: {
			type: "cookies"
		}
	},
	{
		path: "terms-of-service",
		title: "Auction Madness | Terms Of Service",
		component: LegalComponent,
		data: {
			type: "terms-of-service"
		}
	},
	{
		path: "privacy-policy",
		title: "Auction Madness | Privacy Policy",
		component: LegalComponent,
		data: {
			type: "privacy-policy"
		}
	},

	{
		path: "**",
		component: NotFoundComponent
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}

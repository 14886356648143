import { Component, Input } from "@angular/core";
import { IMatchup } from "src/app/models/game";

@Component({
	selector: "am-bracket-matchup-box",
	templateUrl: "./bracket-matchup-box.component.html",
	styleUrls: ["./bracket-matchup-box.component.scss"]
})
export class BracketMatchupBoxComponent {
	@Input() matchup: IMatchup;
	@Input() isReverse: boolean;

	hasOwners: boolean;
	detailsOpen: boolean = false;

	ngOnChanges(): void {
		this.hasOwners = this.matchup.team1Owner != null || this.matchup.team2Owner != null;
	}
}

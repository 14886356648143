import { Component, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import * as appSelectors from "./state/app/app.selectors";
import * as eventSelector from "./state/event/event.selectors";
import * as groupSelector from "./state/group/group.selectors";
import * as appActions from "./state/app/app.actions";
import { BehaviorSubject, Observable, combineLatest, filter, map, switchMap, take, takeUntil } from "rxjs";
import { NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { IGroup } from "./models/group";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { BreakPointDisplayNameMap } from "./models/constants";
import { MatDrawer, MatSidenavModule } from "@angular/material/sidenav";
import { ICurrentUser, IUser, IUserEventGroup } from "./models/user";
import { currentUserData } from "./state/user/user.selectors";
import { BaseComponent } from "./shared/components/base.component";
import { MatDialog } from "@angular/material/dialog";
import { loadGroups } from "./state/group/group.actions";
import { userEventGroups } from "./state/user-event-group/user-event-group.selectors";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { IconService } from "./services/icon.service";
import { LegalDialogComponent } from "./shared/components/legal-dialog/legal-dailog.component";
import { watchForPushNotifications } from "./state/profile/profile.actions";
import { privateInfoLoaded } from "./state/profile/profile.selectors";
import { AnalyticsService } from "./services/analytics.service";
import { Title } from "@angular/platform-browser";
import { NotificationService } from "./services/notification.service";
import { INotification } from "./models/notification";
import { UserService } from "./services/user.service";
import { getUserData } from "./state/user/user.actions";
import { isAfter } from "date-fns";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatBadgeModule } from "@angular/material/badge";
import { OverlayModule } from "@angular/cdk/overlay";
import { MatButtonModule } from "@angular/material/button";
import { SelectAdminDialog } from "./shared/components/select-event-admin-dialog/select-admin-dialog.component";

@Component({
	selector: "am-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
	standalone: true,
	imports: [
		CommonModule,
		RouterOutlet,
		MatIconModule,
		MatToolbarModule,
		MatDividerModule,
		MatSidenavModule,
		MatListModule,
		MatMenuModule,
		MatBadgeModule,
		OverlayModule,
		MatButtonModule
	]
})
export class AppComponent extends BaseComponent implements OnInit {
	@ViewChild("rightDrawer")
	rightDrawer: MatDrawer;

	isMobile$: Observable<boolean>;
	currentUser$: Observable<ICurrentUser | undefined>;
	userEventGroups$: Observable<IUserEventGroup[]>;
	authChecked$: Observable<boolean>;
	currentGroup$: Observable<IGroup | undefined>;
	isAdmin$: Observable<boolean>;
	onLoginPage$: Observable<boolean>;
	notifications$: Observable<INotification[]>;
	reloadNotifications$ = new BehaviorSubject<boolean>(false);

	showNotifications: boolean = false;

	constructor(
		private router: Router,
		private store: Store,
		private breakpointObserver: BreakpointObserver,
		private dialog: MatDialog,
		private iconService: IconService,
		private analyticsService: AnalyticsService,
		private titleService: Title,
		private notificationService: NotificationService,
		private userService: UserService
	) {
		super();
		this.iconService.createIcons();
	}

	ngOnInit() {
		if (Capacitor.isNativePlatform()) {
			nativeAppListeners();
			this.trackPageViews();
		}

		this.store.dispatch(appActions.checkLogin());
		this.store.dispatch(appActions.getConfigs());
		this.store.dispatch(watchForPushNotifications());

		this.currentUser$ = this.store.select(currentUserData).pipe(takeUntil(this.destroyed$));
		this.authChecked$ = this.store.select(appSelectors.checkedAuth);
		this.userEventGroups$ = this.store.select(userEventGroups);
		this.currentGroup$ = this.store.select(groupSelector.currentGroup);
		this.isMobile$ = this.store.select(appSelectors.isMobile);

		this.isAdmin$ = this.store.select(appSelectors.userRoles).pipe(
			map((userRoles) => {
				return userRoles.includes("admin");
			})
		);

		this.onLoginPage$ = this.router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
			map((event) => (event as NavigationEnd).url.includes("/login"))
		);

		combineLatest([this.store.select(appSelectors.isLoggedIn), this.store.select(eventSelector.eventIds)])
			.pipe(
				filter(([isLoggedIn, eventIds]) => isLoggedIn && !!eventIds && eventIds.length > 0),
				take(1)
			)
			.subscribe(() => {
				this.store.dispatch(loadGroups());
			});

		combineLatest([
			this.store.select(appSelectors.currentBreakPoint),
			this.breakpointObserver.observe([
				Breakpoints.XSmall,
				Breakpoints.Small,
				Breakpoints.Medium,
				Breakpoints.Large,
				Breakpoints.XLarge
			])
		])
			.pipe(takeUntil(this.destroyed$))
			.subscribe(([currentBreakPoint, result]) => {
				for (const query of Object.keys(result.breakpoints)) {
					if (result.breakpoints[query] && currentBreakPoint !== BreakPointDisplayNameMap.get(query)) {
						this.store.dispatch(
							appActions.updateCurrentBreakPoint({ currentBreakPoint: BreakPointDisplayNameMap.get(query)!! })
						);
					}
				}
			});

		this.currentUser$
			.pipe(
				filter((user) => !!user && !!user.userInfo && !user.userInfo.userEventGroupsFix),
				take(1),
				switchMap((user) => this.userService.fixUserEventGroups(user!!.uid))
			)
			.subscribe();

		combineLatest([
			this.store.select(appSelectors.getConfig("TermsAndConditionVersion")),
			this.store.select(appSelectors.getConfig("PrivacyPolicyVersion")),
			this.currentUser$,
			this.store.select(privateInfoLoaded)
		])
			.pipe(
				takeUntil(this.destroyed$),
				filter(([tos, pp, user, privateDataLoaded]) => !!tos && !!pp && !!user && !!user.privateInfo && privateDataLoaded)
			)
			.subscribe(([tos, pp, user]) => {
				const termsAndConditions = +tos;
				const priacyPolicy = +pp;

				if (
					user &&
					user.privateInfo &&
					(this.doesNotExistNorMatches(termsAndConditions, +user.privateInfo.termsAndConditionsVersion) ||
						this.doesNotExistNorMatches(priacyPolicy, +user.privateInfo.privacyPolicyVersion))
				) {
					this.dialog.open(LegalDialogComponent, {
						data: { uid: user.uid, termsAndConditions: termsAndConditions, priacyPolicy: priacyPolicy }
					});
				}
			});

		this.notifications$ = combineLatest([
			this.currentUser$,
			this.notificationService.getNotifications(),
			this.reloadNotifications$
		]).pipe(
			filter(([user, _]) => !!user && !!user.userInfo && !!user.privateInfo),
			takeUntil(this.destroyed$),
			map(([user, notifications, _]) => {
				const weekAgo = new Date();
				weekAgo.setDate(weekAgo.getDate() - 7);
				return notifications.filter(
					(x) => x.id && (isAfter(x.createDate, weekAgo) || !user?.userInfo.seenNotifications?.includes(x.id))
				);
			})
		);
	}

	unseenNotificaionCount(notifications: INotification[], user: IUser): number {
		return notifications.filter((x) => x.id && !user.seenNotifications?.includes(x.id)).length;
	}

	toggleDrawer(): void {
		this.rightDrawer.toggle();
	}

	navigate(path: string[]) {
		this.router.navigate(path);
	}

	openEventAdmin(): void {
		this.dialog.open(SelectAdminDialog);
	}

	getGroupsByEvent(eventGroups: IUserEventGroup[], eventId: string): IUserEventGroup[] {
		return eventGroups.filter((eventGroup) => eventGroup.eventId === eventId);
	}

	closeDrawers(): void {
		this.rightDrawer.toggle(false);
	}

	openEditProfile(): void {
		this.router.navigate(["profile/manage"]);
	}

	login() {
		this.router.navigate(["login"], { queryParams: { redirectPath: this.router.url } });
	}

	logout() {
		this.closeDrawers();
		this.store.dispatch(appActions.logout());
	}

	private doesNotExistNorMatches(expected: number, actual: number): boolean {
		return !actual || (actual !== expected && actual !== 0);
	}

	trackPageViews() {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.analyticsService.setPageName(this.titleService.getTitle());
			}
		});
	}

	openNotification(notification: INotification, uid: string): void {
		this.analyticsService.logEvent("open_notification", { notificationId: notification.id });
		this.userService.markNotificationAsSeen(uid, notification.id!!).subscribe((result) => {
			if (result === "Success") {
				this.reloadNotifications$.next(!this.reloadNotifications$.value);
				this.store.dispatch(getUserData({ userId: uid }));
			}
		});

		if (notification.actionLink) {
			this.router.navigateByUrl(notification.actionLink);
		}

		this.showNotifications = !this.showNotifications;
	}

	enableShowNotifications(notification: INotification[]): void {
		if (notification.length > 0 || this.showNotifications) {
			this.showNotifications = !this.showNotifications;
		}
	}
}

function nativeAppListeners() {
	App.addListener("backButton", ({ canGoBack }) => {
		if (!canGoBack) {
			App.exitApp();
		} else {
			window.history.back();
		}
	});
}

import { Injectable } from "@angular/core";
import {
	Firestore,
	getDoc,
	doc,
	collection,
	query,
	getDocs,
	QueryDocumentSnapshot,
	SnapshotOptions,
	addDoc,
	docData,
	where,
	setDoc
} from "@angular/fire/firestore";
import { catchError, from, map, Observable } from "rxjs";
import { IEvent, ITier } from "../models/event";
import { Functions, httpsCallableData } from "@angular/fire/functions";
import { FullMetadata, Storage, UploadResult, ref, updateMetadata, uploadBytes } from "@angular/fire/storage";

@Injectable({
	providedIn: "root"
})
export class EventService {
	constructor(
		private fs: Firestore,
		private fns: Functions,
		private storage: Storage
	) {}

	getRecent(isAdminUser: boolean = false): Observable<IEvent[]> {
		return from(
			getDocs(
				query(collection(this.fs, "events"), isAdminUser ? where("name", "!=", "") : where("status", "!=", "Draft")).withConverter(
					eventConverter
				)
			)
		).pipe(
			map((response) =>
				response.docs.map((d) => {
					return d.data();
				})
			),
			catchError((e) => {
				console.error(e);
				throw new Error(e);
			})
		);
	}

	getEventsByLeague(leagueId: string): Observable<IEvent[]> {
		return from(getDocs(query(collection(this.fs, "events"), where("leagueId", "==", leagueId)).withConverter(eventConverter))).pipe(
			map((response) => response.docs.map((d) => d.data()))
		);
	}

	getByID(eventId: string): Observable<IEvent | undefined> {
		return from(getDoc(doc(this.fs, "events", eventId).withConverter(eventConverter))).pipe(map((response) => response.data()));
	}

	getLiveUpdatesByID(eventId: string): Observable<IEvent | undefined> {
		return docData<IEvent>(doc(this.fs, "events", eventId).withConverter(eventConverter), { idField: "id" });
	}

	updateGameScores(): void {
		const callable = httpsCallableData(this.fns, "events-updateGameScores");
		callable();
	}

	// Move this to storage service
	updateCacheControl(filePath: string): Promise<FullMetadata> {
		const storageRef = ref(this.storage, filePath);

		return updateMetadata(storageRef, { cacheControl: "public,max-age=5256000" });
	}

	addFile(path: string, file: File): Promise<UploadResult> {
		const storageRef = ref(this.storage, path + "/" + file.name);

		return uploadBytes(storageRef, file, { cacheControl: "public,max-age=5256000" });
	}

	async createEvent(event: IEvent): Promise<string> {
		return (await addDoc(collection(this.fs, "events").withConverter(eventConverter), event)).id;
	}

	async updateEvent(event: IEvent, merge: boolean = true) {
		await setDoc(
			doc(this.fs, "events", event.id).withConverter(eventConverter),
			{
				...event,
				bots: event.bots ? event.bots?.map((x) => Object.fromEntries(x)) : undefined
			},
			{ merge: merge }
		);
	}
}

export const eventConverter = {
	toFirestore: (event: IEvent) => {
		return {
			name: event.name,
			leagueId: event.leagueId,
			type: event.type,
			minAuctionDatetime: event.minAuctionDatetime?.toISOString() ?? undefined,
			maxAuctionDatetime: event.maxAuctionDatetime?.toISOString() ?? undefined,
			defaultManagers: event.defaultManagers,
			status: event.status,
			tiers: event.tiers,
			phases: event.phases,
			publicRooms:
				event.publicRooms?.map((x) => {
					return {
						...x,
						auctionDate: x.auctionDate.toISOString()
					};
				}) ?? [],
			scoringPresets: event.scoringPresets,
			bots: event.bots,
			includedTeams: event.includedTeams,
			isDefault: event.isDefault,
			valuePicks: event.valuePicks ? Object.fromEntries(event.valuePicks) : undefined
		};
	},
	fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions | undefined) => {
		let data = snapshot.data(options);
		return {
			id: snapshot.id,
			...data,
			tiers: data["tiers"]?.map((x: any) => {
				return {
					...x,
					name: x.displayName ?? x.name
				} as ITier;
			}),
			bots: data["bots"]
				? data["bots"].map((x: any) => (!!x ? new Map(Object.entries(x)) : new Map()))
				: data["botValues"]?.map((x: any) => (!!x ? new Map(Object.entries(x)) : new Map())),
			minAuctionDatetime: data["minAuctionDatetime"] ? new Date(data["minAuctionDatetime"]) : undefined,
			maxAuctionDatetime: data["maxAuctionDatetime"] ? new Date(data["maxAuctionDatetime"]) : undefined,
			publicRooms: data["publicRooms"]?.map((x: any) => {
				return {
					...x,
					auctionDate: new Date(x.auctionDate)
				};
			}),
			valuePicks: data["valuePicks"] ? new Map(Object.entries(data["valuePicks"])) : undefined
		} as IEvent;
	}
};

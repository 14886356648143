import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { take } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";

@Component({
	selector: "am-confirm-password-reset",
	templateUrl: "./confirm-password-reset.component.html",
	styleUrls: ["./confirm-password-reset.component.scss"]
})
export class ConfirmPasswordResetComponent implements OnInit {
	resetPassword: FormGroup = this.fb.group({
		password: new FormControl("", [Validators.required, Validators.maxLength(25), Validators.minLength(6), Validators.pattern("")]),
		password2: new FormControl("", [Validators.required, Validators.maxLength(25), Validators.minLength(6), Validators.pattern("")])
	});

	updating: boolean = false;
	success: boolean = false;
	errorMessage: string = "";

	get password() {
		return this.resetPassword?.get(["password"]) as FormControl;
	}

	get password2() {
		return this.resetPassword?.get(["password2"]) as FormControl;
	}

	constructor(
		public store: Store,
		private activatedRoute: ActivatedRoute,
		private fb: FormBuilder,
		private router: Router,
		private authenticationSerivce: AuthenticationService
	) {}

	ngOnInit(): void {
		this.resetPassword.addValidators(() => {
			if (this.password.value !== this.password2.value) return { match_error: "Passwords do not match" };
			return null;
		});
	}

	updatePassword() {
		this.activatedRoute.queryParams.pipe(take(1)).subscribe(async (params) => {
			let verificationCode = params["oobCode"];
			if (verificationCode) {
				try {
					await this.authenticationSerivce.confirmPasswordReset(this.password.value, verificationCode);
					this.success = true;
				} catch (error) {
					this.errorMessage = "Failed to set new Password";
				}
			} else {
				this.errorMessage = "Failed to set new Password";
			}
		});
	}

	goToLogin() {
		this.router.navigate(["login"]);
	}
}

import { Component, inject, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogActions } from "@angular/material/dialog";
import { AuctionTutorialDialogComponent } from "../../components/auction-tutorial-dialog/auction-tutorial-dialog.component";
import { Router } from "@angular/router";
import { AnalyticsService } from "src/app/services/analytics.service";
import { Store } from "@ngrx/store";
import { MatButton } from "@angular/material/button";
import { CommonModule } from "@angular/common";
import { LeagueStore } from "src/app/state/league.store";

@Component({
	selector: "am-ad-dialog",
	templateUrl: "./ad-dialog.component.html",
	styleUrls: ["./ad-dialog.component.scss"],
	standalone: true,
	imports: [MatDialogActions, MatButton, CommonModule]
})
export class AdDialogComponent {
	readonly leagueStore = inject(LeagueStore);

	currentLeague = this.leagueStore.currentLeague;

	constructor(
		public dialogRef: MatDialogRef<AuctionTutorialDialogComponent>,
		private router: Router,
		private store: Store,
		@Inject(MAT_DIALOG_DATA) private data: string,
		private analyticsService: AnalyticsService
	) {}

	createRoom(): void {
		this.analyticsService.logEvent("ad_create_room");

		let newRelativeUrl = this.router.createUrlTree(["event", this.data, "createGroup"]);

		let baseUrl = window.location.href.replace(this.router.url, "");
		window.open(baseUrl + newRelativeUrl, "_blank");
		this.dialogRef.close();
	}
}

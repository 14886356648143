import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { switchMap, map, filter, catchError, tap, withLatestFrom } from "rxjs/operators";
import * as groupActions from "./group.actions";
import { Store } from "@ngrx/store";
import { GroupService } from "src/app/services/group.service";
import { Router } from "@angular/router";
import { of } from "rxjs";
import { currentGroup } from "./group.selectors";
import { SnackbarService } from "src/app/services/snackbar.service";
import { getUsersData } from "../user/user.actions";
import { currentUID, isLoggedIn } from "../app/app.selectors";
import { IGroup } from "src/app/models/group";
import { AnalyticsService } from "src/app/services/analytics.service";
import { eventIds } from "../event/event.selectors";
import { onSnapshot } from "@angular/fire/firestore";

@Injectable()
export class GroupEffects {
	constructor(
		private actions$: Actions,
		private store: Store,
		private router: Router,
		private groupService: GroupService,
		private snackbarService: SnackbarService,
		private analyticsService: AnalyticsService
	) {}

	loadGroup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(groupActions.loadGroup),
			withLatestFrom(this.store.select(isLoggedIn)),
			filter(([action, isLoggedIn]) => !!action && isLoggedIn),
			switchMap(([action]) => {
				return this.groupService.getByID(action.eventId, action.groupId).pipe(
					filter((group) => !!group),
					map((group) => {
						return groupActions.loadGroupSuccess({ group: group!! });
					})
				);
			})
		)
	);

	loadGroups$ = createEffect(() =>
		this.actions$.pipe(
			ofType(groupActions.loadGroups),
			withLatestFrom(this.store.select(isLoggedIn), this.store.select(currentUID), this.store.select(eventIds)),
			filter(([_, isLoggedIn, currentUID, eventIds]) => isLoggedIn && !!currentUID && !!eventIds && eventIds.length > 0),
			switchMap(([action, _, currentUID, eventIds]) => {
				return this.groupService.getUsersActiveGroups(currentUID, eventIds as string[]).pipe(
					switchMap((x) => [groupActions.loadGroupsSuccess({ groups: x.flat() })]),
					catchError((error) => of(groupActions.loadGroupsError({ errorMessage: error })))
				);
			})
		)
	);

	watchSelectedGroup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(groupActions.setSelectedGroup),
			switchMap((action) => {
				if (!!action.eventId && !!action.groupId) {
					return this.groupService.getLiveUpdatesByID(action.eventId, action.groupId);
				} else {
					return of(undefined);
				}
			}),
			filter((group) => !!group),
			switchMap((group) => [
				groupActions.loadGroupSuccess({ group: group!! }),
				groupActions.setSelectedGroupSuccess({ groupId: group!!.id })
			]),
			catchError((e) => {
				this.snackbarService.error("Failed to get the current group");
				return of(groupActions.setSelectedGroupError({ errorMessage: e.message }));
			})
		)
	);

	acceptGroupInvite$ = createEffect(() =>
		this.actions$.pipe(
			ofType(groupActions.acceptGroupInvite),
			switchMap((action) =>
				this.groupService.acceptGroupInvite(action.invite, action.eventType).pipe(
					tap(async () => {
						await this.analyticsService.logEvent("join_group");
						this.router.navigate(["event/" + action.invite.eventId + "/" + action.invite.groupId]);
					}),
					map((result) => {
						return groupActions.acceptGroupInviteSuccess();
					}),
					catchError(async (e: any) => {
						if (e.message === "User is already in the group") {
							this.router.navigate(["event/" + action.invite.eventId + "/" + action.invite.groupId]);
						}

						return groupActions.acceptGroupInviteError({ errorMessage: e });
					})
				)
			)
		)
	);

	leaveGroup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(groupActions.leaveGroup),
			switchMap((action) =>
				this.groupService.leaveGroup(action.eventId, action.groupId).pipe(
					tap(() => this.router.navigate(["home"])),
					switchMap((result) => [
						groupActions.setSelectedGroup({ eventId: "", groupId: "" }),
						groupActions.leaveGroupSuccess({ groupId: action.groupId })
					]),
					catchError(async (e: any) => {
						return groupActions.leaveGroupError({ errorMessage: e });
					})
				)
			)
		)
	);

	loadPublicRoommsWithCapacity$ = createEffect(() =>
		this.actions$.pipe(
			ofType(groupActions.loadPublicRoomsWithCapacity),
			switchMap((action) =>
				this.groupService.getPublicRoomsWithCapacity(action.eventId).pipe(
					map((groupDocuments) => {
						onSnapshot(groupDocuments, (querySnapshot) => {
							const groups: IGroup[] = [];
							querySnapshot.forEach((group) => {
								groups.push(group.data() as IGroup);
							});

							this.store.dispatch(groupActions.upsertPublicRoomsWithCapacity({ groups }));
						});
						return groupActions.loadPublicRoomsWithCapacitySuccess();
					})
				)
			)
		)
	);

	getGroupUsersData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(groupActions.setSelectedGroupSuccess),
			withLatestFrom(this.store.select(currentGroup)),
			filter(([action, group]) => !!group?.managerIds),
			map(([action, group]) => getUsersData({ userIds: group!!.managerIds }))
		)
	);
}

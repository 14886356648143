<div class="schedule-calendar">
	<button mat-icon-button (click)="updateIndex(-1)"><mat-icon>chevron_left</mat-icon></button>
	<div class="phase">
		@for (round of roundsToDisplay$ | async; track $index) {
			<div (click)="selectEvent(round)" [ngClass]="{ selected: isSelected(round) }">
				{{ round.name }}
			</div>
		}
	</div>

	<button mat-icon-button (click)="updateIndex(1)"><mat-icon>chevron_right</mat-icon></button>
</div>

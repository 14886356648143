import { Component, Input } from "@angular/core";
import { TeamScore } from "src/app/pages/event-scoreboard/event-scoreboard.component";
import { CommonModule } from "@angular/common";
import { MatTooltip } from "@angular/material/tooltip";
import { MatCard } from "@angular/material/card";

@Component({
	selector: "am-team-card",
	templateUrl: "./team-card.component.html",
	styleUrls: ["./team-card.component.scss"],
	standalone: true,
	imports: [MatCard, MatTooltip, CommonModule]
})
export class TeamCardComponent {
	@Input() team: TeamScore;
}

<mat-drawer-container class="event-admin">
	<form *ngIf="eventForm" [formGroup]="eventForm" class="event-info">
		<mat-toolbar color="primary" class="statusbar">
			Event Status: {{ eventForm.get("status")?.value || "" }}
			<span *ngIf="eventForm.get('status')?.dirty">*</span>
			<div class="statusbar-spacer"></div>
			<div class="statusbar-buttons">
				<button *ngIf="false" (click)="test()">Test</button>
				<button *ngIf="!eventForm.get('isPromotional')?.value" mat-raised-button color="accent" (click)="updateIsPromotional(true)">
					Mark as Promotional
				</button>
				<button *ngIf="eventForm.get('isPromotional')?.value" mat-raised-button color="accent" (click)="updateIsPromotional(false)">
					Remove Is Promotional
				</button>
				<button
					color="accent"
					mat-raised-button
					*ngIf="eventForm.get('status')?.value === 'Draft' || eventForm.get('status')?.value === 'New'"
					(click)="setEventStatus('Open')"
				>
					Publish
				</button>
				<button
					color="accent"
					mat-raised-button
					*ngIf="
						eventForm.get('status')?.value !== 'New' &&
						eventForm.get('status')?.value !== 'Draft' &&
						eventForm.get('status')?.value !== 'Archived'
					"
					(click)="setEventStatus('Archived')"
				>
					Archive
				</button>
			</div>
		</mat-toolbar>
		<div class="basic-info">
			<div class="event-details-row" *ngIf="!showAdvancedDetails">
				<h2 class="event-details-name">{{ eventForm.get("name")?.value || defaultName() }}</h2>
				<button
					mat-icon-button
					*ngIf="
						(showIntegrationFeature$ | async) &&
						eventForm.get(['integrationData', 'leagueId'])?.value &&
						eventForm.get(['integrationData', 'seasonId'])?.value
					"
					(click)="toggleIntegrationData()"
				>
					<mat-icon>link</mat-icon>
				</button>
				<button mat-icon-button (click)="showAdvancedDetails = !showAdvancedDetails">
					<mat-icon>{{ showAdvancedDetails ? "unfold_less" : "unfold_more" }}</mat-icon>
				</button>
			</div>
			<ng-container *ngIf="showAdvancedDetails">
				<div class="event-details-row">
					<mat-form-field>
						<mat-label>Year</mat-label>
						<input matInput type="number" formControlName="year" />
					</mat-form-field>
					<mat-form-field>
						<mat-label>Sport</mat-label>
						<mat-select formControlName="sport" required>
							<mat-option>--</mat-option>
							<mat-option *ngFor="let sport of sportOptions" [value]="sport">
								{{ sport }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field>
						<mat-label>Type</mat-label>
						<mat-select formControlName="type" required>
							<mat-option>--</mat-option>
							<mat-option *ngFor="let type of typeOptions" [value]="type">
								{{ type }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-slide-toggle [checked]="editLayout" (change)="editLayout = !editLayout">Enable Layout Edits</mat-slide-toggle>
					<button mat-icon-button (click)="showAdvancedDetails = !showAdvancedDetails">
						<mat-icon>{{ showAdvancedDetails ? "unfold_less" : "unfold_more" }}</mat-icon>
					</button>
				</div>
				<div class="event-details-row">
					<mat-form-field>
						<mat-label>Event Name ({{ defaultName() }})</mat-label>
						<input matInput formControlName="name" />
					</mat-form-field>
				</div>
				<mat-form-field>
					<mat-label>Description</mat-label>
					<textarea matInput formControlName="description"></textarea>
				</mat-form-field>
				<ng-container *ngIf="showIntegrationFeature$ | async">
					<span>Integration Info</span>
					<div class="event-details-row">
						<am-integration-league-lookup
							[existingLeagueId]="eventForm.get(['integrationData', 'leagueId'])?.value"
							[existingSeasonId]="eventForm.get(['integrationData', 'seasonId'])?.value"
							[eventSport]="eventForm.get('sport')?.value"
							[eventYear]="eventForm.get('year')?.value"
							(integrationIdChange)="updateIntegrationInfo($event)"
						>
						</am-integration-league-lookup>
						<button
							mat-icon-button
							(click)="toggleIntegrationData()"
							*ngIf="
								eventForm.get(['integrationData', 'leagueId'])?.value &&
								eventForm.get(['integrationData', 'seasonId'])?.value
							"
						>
							<mat-icon>link</mat-icon>
						</button>
					</div>
				</ng-container>
				<div class="event-details-row">
					<mat-form-field>
						<mat-label>Min Auction Datetime</mat-label>
						<input matInput type="datetime-local" formControlName="minAuctionDatetime" />
					</mat-form-field>
					<mat-form-field>
						<mat-label>Max Auction Datetime</mat-label>
						<input matInput type="datetime-local" formControlName="maxAuctionDatetime" />
					</mat-form-field>
					<mat-form-field>
						<mat-label>Default Room Size</mat-label>
						<input matInput type="number" formControlName="defaultManagers" />
					</mat-form-field>
				</div>
			</ng-container>
		</div>

		<div class="content-area" *ngIf="eventForm.get('sport')?.value">
			<mat-tab-group (selectedTabChange)="currentTabChanged($event)">
				<mat-tab label="Event Layout">
					<div class="event-layout">
						<mat-card class="phase" *ngFor="let phase of phases.controls; let phaseIndex = index" [formGroup]="phase">
							<div class="phase-setting-row">
								<mat-form-field>
									<mat-label>Phase Name</mat-label>
									<input matInput formControlName="name" />
								</mat-form-field>
								<mat-form-field>
									<mat-label>Number of Teams in phase</mat-label>
									<input matInput type="number" formControlName="numberOfTeamsInPhase" />
								</mat-form-field>
								<mat-checkbox formControlName="isSeriesBased">Series Based</mat-checkbox>
								<button mat-icon-button (click)="removePhase(phaseIndex)" *ngIf="editLayout">
									<mat-icon>delete</mat-icon>
								</button>
							</div>
							<div class="phase-setting-row">
								<mat-form-field>
									<mat-label>View Type</mat-label>
									<mat-select formControlName="viewType">
										<mat-option value="List">List</mat-option>
										<mat-option value="Bracket">Bracket</mat-option>
									</mat-select>
								</mat-form-field>
								<mat-form-field>
									<mat-label>Tier Prefix</mat-label>
									<input matInput formControlName="tierPrefix" />
								</mat-form-field>
							</div>
							<div class="tier-list">
								<div
									class="tier-info"
									*ngFor="let tier of getTiers(phaseIndex).controls; let tierIndex = index"
									[formGroup]="tier"
								>
									<mat-form-field>
										<mat-label>
											{{ phase.get(["tierPrefix"])?.value }} {{ tierIndex + 1 }} ({{
												getGames(phaseIndex, tierIndex).length
											}})
										</mat-label>
										<input matInput formControlName="displayName" />
									</mat-form-field>
									<mat-form-field>
										<mat-label>Order</mat-label>
										<input matInput type="number" formControlName="order" />
									</mat-form-field>
									<button mat-button color="warn" (click)="removeTier(phaseIndex, tierIndex)" *ngIf="editLayout">
										Delete
									</button>
								</div>
								<div class="tier-actions">
									<button mat-button (click)="addTier(phaseIndex)" *ngIf="editLayout">Add Tier</button>
								</div>
							</div>
							<mat-divider />
							<div *ngIf="isBracketPhase(phaseIndex)">
								<div class="regions">
									<form
										*ngFor="let region of getRegions(phaseIndex).controls; let regionIndex = index"
										[formGroup]="region"
									>
										<mat-card>
											<mat-form-field>
												<mat-label>Name</mat-label>
												<input matInput formControlName="name" />
											</mat-form-field>
											<mat-form-field>
												<mat-label>Location</mat-label>
												<input matInput formControlName="location" />
											</mat-form-field>
											<button
												mat-button
												color="warn"
												(click)="removeRegion(phaseIndex, regionIndex)"
												*ngIf="editLayout"
											>
												Delete
											</button>
										</mat-card>
									</form>
								</div>
								<button class="region-add" mat-raised-button (click)="createRegion(phaseIndex)" *ngIf="editLayout">
									Create Region
								</button>
							</div>
						</mat-card>
						<div class="phase-actions">
							<button mat-raised-button (click)="addPhase()" *ngIf="editLayout">Add Phase</button>
							<button mat-raised-button (click)="openPhaseDialog()" *ngIf="(showIntegrationFeature$ | async) && editLayout">
								Add Phase but better
							</button>
						</div>
					</div>
				</mat-tab>
				<mat-tab label="Scoring">
					<div class="scoring">
						<mat-card class="scoring-card">
							<button mat-raised-button (click)="createScoringPreset()">Add Scoring Preset</button>
							<mat-accordion>
								<mat-expansion-panel
									*ngFor="let preset of scoringPresets.controls; let scoringIndex = index"
									[formGroup]="preset"
									hideToggle
								>
									<mat-expansion-panel-header>
										<mat-panel-title>
											{{ preset.controls["name"].value ?? "Unknown" }}

											<mat-checkbox style="margin-left: auto" formControlName="isDefault" color="primary">
												Is Default
											</mat-checkbox>
											<button
												style="margin-left: 8px"
												mat-stroked-button
												color="warn"
												(click)="removeScoring(scoringIndex)"
											>
												Delete
											</button>
										</mat-panel-title>
									</mat-expansion-panel-header>
									<div class="row">
										<mat-form-field>
											<mat-label>Name</mat-label>
											<input matInput formControlName="name" />
										</mat-form-field>
										<mat-form-field>
											<mat-label>Buy in</mat-label>
											<input matInput formControlName="buyIn" />
										</mat-form-field>
										<mat-form-field>
											<mat-label>Sort Order</mat-label>
											<input matInput type="number" min="0" formControlName="sortOrder" />
										</mat-form-field>
									</div>
									<button style="margin-bottom: 8px" mat-raised-button (click)="addPhaseScoring(scoringIndex)">
										Add Phase Scoring
									</button>
									<div
										*ngFor="
											let phaseScoring of phaseScoringGroups(scoringIndex).controls;
											let phaseScoringIndex = index
										"
										[formGroup]="phaseScoring"
									>
										<div class="row">
											<mat-form-field>
												<mat-label>Phase</mat-label>
												<mat-select formControlName="phaseId">
													<mat-option *ngFor="let phase of phases.controls" [value]="phase.get('id')?.value">
														{{ phase.get("name")?.value }}
													</mat-option>
												</mat-select>
											</mat-form-field>
											<mat-form-field>
												<mat-label>Amount to make Phase</mat-label>
												<input matInput type="number" formControlName="amountToMakePhase" />
											</mat-form-field>

											<mat-form-field>
												<mat-label>Amount for all Tiers</mat-label>
												<input matInput type="number" formControlName="amountForAllTiers" />
											</mat-form-field>
											<mat-checkbox formControlName="dynamic">Is Dynamic</mat-checkbox>
										</div>

										<button
											mat-stroked-button
											color="warn"
											style="margin-right: 8px"
											(click)="removePhaseScoring(scoringIndex, phaseScoringIndex)"
										>
											Delete Phase
										</button>
										<button mat-raised-button (click)="addScoringTier(scoringIndex, phaseScoringIndex)">
											Add Tier Scoring
										</button>
										<div class="tier-group">
											<div
												*ngFor="
													let scoringTier of getScoringTiers(scoringIndex, phaseScoringIndex).controls;
													let scoringTierIndex = index
												"
												[formGroup]="scoringTier"
												class="tier"
											>
												<mat-card>
													<mat-form-field>
														<mat-label>Tier</mat-label>
														<mat-select formControlName="tierId">
															<mat-option
																*ngFor="
																	let tier of getTiers(
																		getPhaseIndeById(
																			phaseScoringGroup(scoringIndex, phaseScoringIndex).controls[
																				'phaseId'
																			].value
																		)
																	).controls
																"
																[value]="tier.get('id')?.value"
															>
																{{ tier.get("displayName")?.value }}
															</mat-option>
														</mat-select>
													</mat-form-field>
													<mat-form-field>
														<mat-label>Default Value</mat-label>
														<input matInput type="number" formControlName="defaultValue" />
													</mat-form-field>
													<button
														mat-stroked-button
														color="warn"
														(click)="removeScoringTier(scoringIndex, phaseScoringIndex, scoringTierIndex)"
													>
														Delete Tier
													</button>
												</mat-card>
											</div>
										</div>
									</div>
								</mat-expansion-panel>
							</mat-accordion>
						</mat-card>
					</div>
				</mat-tab>
				<mat-tab label="Teams">
					<div class="team-info">
						<mat-card class="teams">
							<table mat-table [dataSource]="teams.controls | keyvalue" *ngIf="teams.length > 0">
								<ng-container cdkColumnDef="seed">
									<mat-header-cell *cdkHeaderCellDef> Seed </mat-header-cell>
									<mat-cell *cdkCellDef="let element" [formGroup]="element.value">
										<input matInput type="text" formControlName="seed" />
									</mat-cell>
								</ng-container>
								<ng-container cdkColumnDef="location">
									<mat-header-cell *cdkHeaderCellDef> Location </mat-header-cell>
									<mat-cell *cdkCellDef="let element" [formGroup]="element.value">
										<input matInput type="text" formControlName="location" />
									</mat-cell>
								</ng-container>
								<ng-container cdkColumnDef="name">
									<mat-header-cell *cdkHeaderCellDef> Name </mat-header-cell>
									<mat-cell *cdkCellDef="let element" [formGroup]="element.value">
										<input matInput type="text" formControlName="name" />
									</mat-cell>
								</ng-container>
								<ng-container cdkColumnDef="displayName">
									<mat-header-cell *cdkHeaderCellDef> Display Name </mat-header-cell>
									<mat-cell *cdkCellDef="let element" [formGroup]="element.value">
										<input matInput type="text" formControlName="displayName" />
									</mat-cell>
								</ng-container>
								<ng-container cdkColumnDef="abbreviation">
									<mat-header-cell *cdkHeaderCellDef> Abbreviation </mat-header-cell>
									<mat-cell *cdkCellDef="let element" [formGroup]="element.value">
										<input matInput type="text" formControlName="abbreviation" />
									</mat-cell>
								</ng-container>
								<ng-container cdkColumnDef="division">
									<mat-header-cell *cdkHeaderCellDef> Division </mat-header-cell>
									<mat-cell *cdkCellDef="let element" [formGroup]="element.value">
										<input matInput type="text" formControlName="division" />
									</mat-cell>
								</ng-container>
								<ng-container cdkColumnDef="record">
									<mat-header-cell *cdkHeaderCellDef> Record </mat-header-cell>
									<mat-cell *cdkCellDef="let element" [formGroup]="element.value">
										<input matInput type="text" formControlName="record" />
									</mat-cell>
								</ng-container>
								<ng-container cdkColumnDef="hexcode">
									<mat-header-cell *cdkHeaderCellDef> Hexcode </mat-header-cell>
									<mat-cell *cdkCellDef="let element" [formGroup]="element.value">
										<input
											matInput
											type="text"
											formControlName="hexcode"
											class="hexcode-cell"
											[style]="'background-color: ' + element.value.get('hexcode')?.value"
										/>
									</mat-cell>
								</ng-container>
								<ng-container cdkColumnDef="logo">
									<mat-header-cell *cdkHeaderCellDef> Logo </mat-header-cell>
									<mat-cell *cdkCellDef="let element" [formGroup]="element.value">
										<img class="logo-cell" [src]="element.value.get('logo')?.value" />
										<input matInput type="text" formControlName="logo" />
									</mat-cell>
								</ng-container>
								<ng-container cdkColumnDef="isEliminated">
									<mat-header-cell *cdkHeaderCellDef> Eliminated </mat-header-cell>
									<mat-cell *cdkCellDef="let element" [formGroup]="element.value">
										<mat-checkbox style="margin: auto" formControlName="isEliminated" color="primary"></mat-checkbox>
									</mat-cell>
								</ng-container>
								<ng-container cdkColumnDef="useAbbreviation">
									<mat-header-cell *cdkHeaderCellDef> Use Abbreviation </mat-header-cell>
									<mat-cell *cdkCellDef="let element" [formGroup]="element.value">
										<mat-checkbox style="margin: auto" formControlName="useAbbreviation" color="primary"></mat-checkbox>
									</mat-cell>
								</ng-container>
								<ng-container cdkColumnDef="action">
									<mat-header-cell *cdkHeaderCellDef>Actions</mat-header-cell>
									<mat-cell *cdkCellDef="let element">
										<button mat-icon-button (click)="removeTeam(element.key)" *ngIf="editLayout">
											<mat-icon>delete</mat-icon>
										</button>
										<mat-icon *ngIf="element.value.get('integrationId')?.value">link</mat-icon>
									</mat-cell>
								</ng-container>

								<mat-header-row *matHeaderRowDef="teamTableColumns"></mat-header-row>
								<mat-row *matRowDef="let row; columns: teamTableColumns"></mat-row>
							</table>
							<button mat-button class="add-team" (click)="addTeam()" *ngIf="editLayout">Add New Team</button>
						</mat-card>
					</div>
				</mat-tab>
				<mat-tab label="Games">
					<mat-card class="bracket-view">
						<h3 *ngIf="phases?.length === 0">Need to add a Phase</h3>
						<ng-container *ngFor="let phase of phases.controls; let phaseIndex = index">
							<h3 *ngIf="getTiers(phaseIndex)?.length === 0">Need to add a tier</h3>
							<div
								class="round"
								*ngFor="let tier of getTiers(phaseIndex).controls; let tierIndex = index"
								[formGroup]="tier"
								[attr.id]="tier.get('id')?.value"
							>
								<div>
									<h2>{{ tier.get("displayName")?.value || phase.get("tierPrefix")?.value + " " + (tierIndex + 1) }}</h2>
									<mat-checkbox formControlName="isCurrent" color="primary">Is Current</mat-checkbox>
								</div>
								<button mat-button (click)="createGame(phaseIndex, tierIndex)" color="accent" *ngIf="editLayout">
									<mat-icon>add</mat-icon>Add Game
								</button>
								<button mat-button (click)="createSeries(phaseIndex, tierIndex)" color="accent" *ngIf="editLayout">
									<mat-icon>add</mat-icon>Add Series
								</button>
								<button
									mat-button
									(click)="refreshGamesInTier(phaseIndex, tierIndex)"
									*ngIf="
										(showIntegrationFeature$ | async) &&
										eventForm.get(['integrationData', 'leagueId'])?.value &&
										eventForm.get(['integrationData', 'seasonId'])?.value
									"
								>
									<mat-icon>refresh</mat-icon>Refresh Games
								</button>
								<mat-accordion>
									<mat-expansion-panel
										hideToggle
										*ngFor="let series of getAllSeries(phaseIndex, tierIndex).value; let serieIndex = index"
										[ngClass]="{
											'mat-gray': series.status === 'Upcoming',
											'mat-sparkler': series.status === 'In Progress',
											'mat-mint-green': series.status === 'Final'
										}"
									>
										<mat-expansion-panel-header>
											<div class="header">
												<div>
													<span *ngIf="series?.team1Id && series?.team1Id === series?.winnerId">*</span>
													{{ getTeamData(series?.team1Id || "")?.abbreviation || "TBD" }}
													<span *ngIf="series?.team1Score != null"> - {{ series?.team1Score }}</span>
												</div>
												<div>
													<span *ngIf="series?.team2Id && series?.team2Id === series?.winnerId">*</span>
													{{ getTeamData(series?.team2Id || "")?.abbreviation || "TBD" }}
													<span *ngIf="series?.team2Score != null"> - {{ series?.team2Score }}</span>
												</div>
											</div>
											<div>
												<div *ngIf="series?.regionId">
													{{ getRegionName(series?.regionId, phaseIndex) }}
												</div>
												<div *ngIf="series?.displayOrder">DisOrder: {{ series?.displayOrder }}</div>
											</div>

											<button mat-icon-button (click)="createGame(phaseIndex, tierIndex, series, $event)">
												<mat-icon>add</mat-icon>
											</button>
											<button mat-icon-button (click)="editSeries(series, phaseIndex)">
												<mat-icon>edit</mat-icon>
											</button>
											<button
												mat-icon-button
												(click)="deletSerie(serieIndex, tierIndex, phaseIndex)"
												*ngIf="editLayout"
											>
												<mat-icon>delete</mat-icon>
											</button>
										</mat-expansion-panel-header>
										<div class="game-list">
											<mat-card
												class="game"
												*ngFor="let game of getSeriesGames(phaseIndex, tierIndex, series.id); let gameIndex = index"
												[ngClass]="{
													'mat-gray': game.status === 'Upcoming',
													'mat-sparkler': game.status === 'In Progress',
													'mat-mint-green': game.status === 'Final'
												}"
											>
												<div class="game-team-info" (click)="editGame(game, phaseIndex)">
													<div>
														<span *ngIf="game?.team1Id && game?.team1Id === game?.winnerId">*</span>
														{{ getTeamData(game?.team1Id || "")?.abbreviation || "TBD" }}
														<span *ngIf="game?.team1Score != null"> - {{ game?.team1Score }}</span>
													</div>
													<div>
														<span *ngIf="game?.team2Id && game?.team2Id === game?.winnerId">*</span>
														{{ getTeamData(game?.team2Id || "")?.abbreviation || "TBD" }}
														<span *ngIf="game?.team2Score != null"> - {{ game?.team2Score }}</span>
													</div>
													<div *ngIf="game?.regionId">
														Region: {{ getRegionName(game?.regionId, phaseIndex) }}
													</div>
													<div *ngIf="game?.displayOrder">Display Order: {{ game?.displayOrder }}</div>
												</div>
												<button
													mat-icon-button
													(click)="deleteGame(gameIndex, tierIndex, phaseIndex, game.id)"
													*ngIf="editLayout"
												>
													<mat-icon>delete</mat-icon>
												</button>
												<mat-icon *ngIf="game.integrationId">link</mat-icon>
											</mat-card>
										</div>
									</mat-expansion-panel>
								</mat-accordion>
								<div class="game-list">
									<mat-card
										class="game"
										*ngFor="let game of getNonSeriesGames(phaseIndex, tierIndex); let gameIndex = index"
										[ngClass]="{
											'mat-gray': game.status === 'Upcoming',
											'mat-sparkler': game.status === 'In Progress',
											'mat-mint-green': game.status === 'Final'
										}"
									>
										<div class="game-team-info" (click)="editGame(game, phaseIndex)">
											<div>
												<span *ngIf="game?.team1Id && game?.team1Id === game?.winnerId">*</span>
												{{ getTeamData(game?.team1Id || "")?.abbreviation || "TBD" }}
												<span *ngIf="game?.team1Score != null"> - {{ game?.team1Score }}</span>
											</div>
											<div>
												<span *ngIf="game?.team2Id && game?.team2Id === game?.winnerId">*</span>
												{{ getTeamData(game?.team2Id || "")?.abbreviation || "TBD" }}
												<span *ngIf="game?.team2Score != null"> - {{ game?.team2Score }}</span>
											</div>
											<div *ngIf="game?.regionId">Region: {{ getRegionName(game?.regionId, phaseIndex) }}</div>
											<div *ngIf="game?.displayOrder">Display Order: {{ game?.displayOrder }}</div>
										</div>

										<button
											mat-icon-button
											(click)="deleteGame(gameIndex, tierIndex, phaseIndex, game.id)"
											*ngIf="editLayout"
										>
											<mat-icon>delete</mat-icon>
										</button>
										<mat-icon *ngIf="game.integrationId">link</mat-icon>
									</mat-card>
								</div>
								<button
									mat-button
									*ngIf="teamsToImport.length === 0 && gamesToImport.length > 0"
									(click)="mergeGames(phaseIndex, tierIndex)"
								>
									Merge Selected Games
								</button>
								<button
									mat-button
									*ngIf="teamsToImport.length === 0 && gamesToImport.length > 0"
									(click)="importGames(phaseIndex, tierIndex)"
								>
									Import Selected Games
								</button>
							</div>
						</ng-container>
					</mat-card>
				</mat-tab>
				<mat-tab label="Public Rooms">
					<mat-card class="public-room-tab">
						<button class="add-room" mat-raised-button (click)="createPublicRoom()">
							<mat-icon>add</mat-icon>Add Public Room
						</button>
						<div *ngFor="let room of publicRooms.controls; let i = index" [formGroup]="room">
							<div class="room-row">
								<mat-form-field>
									<mat-label>Room Name</mat-label>
									<input matInput formControlName="name" />
								</mat-form-field>
								<mat-form-field>
									<mat-label>Number of Managers</mat-label>
									<input matInput type="number" formControlName="numOfManagers" />
								</mat-form-field>
								<mat-form-field>
									<mat-label>Auction Time</mat-label>
									<input matInput type="datetime-local" formControlName="auctionDate" />
								</mat-form-field>
								<mat-form-field>
									<mat-label>Group Id</mat-label>
									<input matInput formControlName="groupId" />
								</mat-form-field>
								<mat-form-field>
									<mat-label>Scoring</mat-label>
									<mat-select formControlName="scoringPresetId">
										<mat-option value="">--</mat-option>
										<mat-option
											*ngFor="let scoringPreset of scoringPresets.controls"
											[value]="scoringPreset.controls['id'].value"
										>
											{{ scoringPreset.controls["name"].value }}
										</mat-option>
									</mat-select>
								</mat-form-field>
								<mat-checkbox formControlName="isFull" color="primary">Is Full</mat-checkbox>
								<mat-checkbox formControlName="isAutoRecreate" color="primary">Is Auto Recreate</mat-checkbox>
								<button mat-raised-button color="warn" [disabled]="room.get('groupId') == null" (click)="deleteRoom(i)">
									Delete
								</button>
							</div>
							<mat-divider></mat-divider>
						</div>
					</mat-card>
				</mat-tab>
				<mat-tab label="Bots">
					<mat-card class="bot">
						<button mat-raised-button (click)="createBot()">Add Bot</button>
						<mat-expansion-panel *ngFor="let bot of bots.controls; let botIndex = index" hideToggle>
							<mat-expansion-panel-header>
								<mat-panel-title>Bot{{ botIndex + 1 }}</mat-panel-title>
								<button style="margin-left: auto" mat-stroked-button color="warn" (click)="removeBot(botIndex)">
									Delete
								</button>
							</mat-expansion-panel-header>
							<div>
								<div *ngFor="let team of teams.controls; let teamIndex = index">
									<div class="bot-value">
										<div>{{ team.get("displayName")?.value }}</div>
										<mat-form-field>
											<mat-label>Value</mat-label>
											<input
												matInput
												type="number"
												(change)="updateBotValue($event, team.get('id')?.value, botIndex)"
												[value]="botValue(bot, team.get('id')?.value)"
											/>
										</mat-form-field>
									</div>
									<mat-divider></mat-divider>
								</div>
							</div>
						</mat-expansion-panel>
					</mat-card>
				</mat-tab>
				<mat-tab label="Group Lookup" *ngIf="eventId">
					<mat-card class="group-lookup-tab">
						<am-group-lookup [event]="originalEventInfo!!"></am-group-lookup>
					</mat-card>
				</mat-tab>
			</mat-tab-group>
		</div>
		<div class="bottom-bar">
			<button
				mat-raised-button
				color="primary"
				type="submit"
				(click)="onSubmit()"
				[disabled]="!eventForm.valid || eventForm.pristine || updating"
			>
				Update
			</button>
		</div>
	</form>
	<mat-drawer
		#drawer
		mode="side"
		position="end"
		[opened]="showIntegrationData"
		class="integration-data"
		*ngIf="eventForm && (showIntegrationFeature$ | async)"
	>
		<div class="integration-data-header">
			<button mat-icon-button (click)="toggleIntegrationData(false)"><mat-icon>close</mat-icon></button>
		</div>
		<div class="integration-data-content">
			<div class="integration-lookup">
				<am-integration-game-lookup
					[leagueId]="eventForm.get(['integrationData', 'leagueId'])?.value"
					[seasonId]="eventForm.get(['integrationData', 'seasonId'])?.value"
					[type]="eventForm.get(['type'])?.value"
					[sport]="eventForm.get(['sport'])?.value"
					[existingTeams]="teams.value"
					(selectionChange)="integrationDataSelected($event)"
				>
				</am-integration-game-lookup>
			</div>

			<div class="actions">
				<!-- <button mat-button>Merge Selected Teams</button> -->
				<button mat-button *ngIf="teamsToMerge.length > 0" (click)="mergeTeams()" color="accent">
					Merge {{ teamsToMerge.length }} Teams
				</button>
				<button mat-button *ngIf="teamsToImport.length > 0" (click)="importTeams()" color="accent">
					Import {{ teamsToImport.length }} Teams
				</button>
				<span mat-button *ngIf="teamsToImport.length > 0 && gamesToImport.length > 0" color="warn"
					>Must import all related teams before you can add games</span
				>
			</div>
		</div>
	</mat-drawer>
</mat-drawer-container>

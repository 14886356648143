<mat-card
	appearance="outlined"
	[id]="group.id"
	class="group-card"
	(click)="navigateToGroup(group)"
	[ngClass]="{ highlight: determineGroupState() === 'AuctionHappening', center: isMobile }"
>
	<div class="header">
		<h2>{{ group.name }}</h2>
		<img width="75" height="75" [src]="'../assets/icons/logos/' + (type ? type.toLowerCase() : 'generic') + '.svg'" />
	</div>
	<mat-divider />
	<h4>{{ group.status }}</h4>

	<ng-container [ngSwitch]="determineGroupState()">
		<ng-container *ngSwitchCase="'WaitingOnManagers'">
			<p>Prepare for the upcoming auction by reviewing the season schedule.</p>
			<div class="light-blue info">
				Waiting for <b>{{ group.numOfManagers - group.managerIds.length }}</b> manager{{
					group.numOfManagers - group.managerIds.length > 1 ? "s" : ""
				}}.
			</div>

			<div class="actions">
				<button mat-raised-button color="primary" (click)="copyInviteLink($event, group)">Invite</button>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="'InProgress'">
			<ng-container *ngIf="standing$ | async as standing">
				<h4>
					{{ getCurrentUserScore() | currency : "USD" : "symbol" : "1.2-2" }} | {{ standing.get(uid) ?? 0 | scoreboardPlace }}
				</h4>
			</ng-container>

			@if (teams(); as teams) {
			<div class="teams">
				<img
					width="50"
					height="50"
					[matTooltip]="team.isEliminated ? 'Team has been eliminated' : ''"
					[ngClass]="{ eliminated: team.isEliminated }"
					*ngFor="let team of getCurrentUsersTeams(teams).splice(0, 10)"
					[src]="team.logo"
				/>
			</div>
			}
		</ng-container>
		<ng-container *ngSwitchCase="'WaitingOnAuction'">
			<p>Prepare for the upcoming auction by reviewing the season schedule.</p>
			<div>
				Auction begins: <b>{{ group.auctionDate!! | countdown | async }}</b>
			</div>
			<div>{{ group.auctionDate!! | date : "short" }}</div>
			<button mat-raised-button color="accent" (click)="navigateToGroup(group)">Auction</button>
		</ng-container>
		<ng-container *ngSwitchCase="'AuctionHappening'">
			<h3>Auction has started, join now!</h3>
			<button mat-raised-button color="accent" (click)="navigateToGroup(group)">Auction</button>
		</ng-container>
	</ng-container>
</mat-card>

<mat-card [id]="matchup.id" (click)="openDetails()">
	<div
		[id]="matchup.team1?.id"
		class="team-box top"
		[ngClass]="{
			focus: matchup.team1Owner?.color === 'focus',
			eliminated: matchup.team1?.isEliminated && matchup.groupStatus !== 'Complete'
		}"
		[style]="isReverse ? 'flex-direction: row-reverse' : 'flex-direction: row'"
	>
		<ng-container *ngIf="matchup.team1">
			<div
				class="color"
				*ngIf="matchup.team1Owner?.color && matchup.team1Owner?.color !== 'focus'"
				[style.background-color]="matchup.team1Owner?.color ?? 'white'"
				[style.border-top-left-radius]="'2px'"
			></div>
			<h5 class="seed" *ngIf="matchup.team1.seed">{{ matchup.team1.seed }}</h5>
			<img [width]="40" [height]="40" [src]="matchup.team1.logo" />
			<h4 [style]="isReverse ? 'text-align: right' : 'text-align: left'">
				{{ matchup.team1.useAbbreviation ? matchup.team1.abbreviation : matchup.team1.displayName }}
			</h4>
			<div class="spacer"></div>
			<div class="score" *ngIf="!isAuction">
				<ng-container *ngFor="let game of team1Wins">
					<div [id]="game.id" class="series-box series-win" [ngClass]="{ eliminated: game.team1?.isEliminated }"></div>
				</ng-container>
				<ng-container *ngFor="let i of getRemainingGames(team1Wins.length); let index = index">
					<div tooltip-content [id]="index" class="series-box"></div>
				</ng-container>
			</div>
		</ng-container>
	</div>
	<div
		[id]="matchup.team2?.id"
		class="team-box"
		[ngClass]="{
			focus: matchup.team2Owner?.color === 'focus',
			eliminated: matchup.team2?.isEliminated && matchup.groupStatus !== 'Complete'
		}"
		[style]="isReverse ? 'flex-direction: row-reverse' : 'flex-direction: row'"
	>
		<ng-container *ngIf="matchup.team2">
			<div
				class="color"
				*ngIf="matchup.team2Owner?.color && matchup.team2Owner?.color !== 'focus'"
				[style.background-color]="matchup.team2Owner?.color ?? 'white'"
				[style.border-bottom-left-radius]="'2px'"
			></div>
			<h5 class="seed" *ngIf="matchup.team2.seed">{{ matchup.team2.seed }}</h5>
			<img [width]="40" [height]="40" [src]="matchup.team2.logo" />
			<h4 [style]="isReverse ? 'text-align: right' : 'text-align: left'">
				{{ matchup.team2.useAbbreviation ? matchup.team2.abbreviation : matchup.team2.displayName }}
			</h4>
			<div class="spacer"></div>
			<div class="score" *ngIf="!isAuction">
				<div
					*ngFor="let game of team2Wins"
					[id]="game.id"
					class="series-box series-win"
					[ngClass]="{ eliminated: game.team1?.isEliminated }"
				></div>
				<ng-container>
					<div *ngFor="let i of getRemainingGames(team2Wins.length); let index = index" [id]="index" class="series-box"></div>
				</ng-container>
			</div>
		</ng-container>
	</div>
</mat-card>

import { Component, Input } from "@angular/core";
import { IMatchup } from "src/app/models/game";
import { BracketMatchupBoxComponent } from "../../components/bracket-matchup-box/bracket-matchup-box.component";
import { NgIf, NgFor } from "@angular/common";
import { IRegion } from "src/app/models/league";

@Component({
	selector: "am-nfl-bracket",
	templateUrl: "./nfl-bracket.component.html",
	styleUrls: ["./nfl-bracket.component.scss"],
	standalone: true,
	imports: [NgIf, NgFor, BracketMatchupBoxComponent]
})
export class NFLBracketComponent {
	@Input() rounds: string[];
	@Input() matchups: IMatchup[];
	@Input() regions: IRegion[];

	getMatchupsByRound(round: string, regionId: string): IMatchup[] {
		return this.matchups.filter((x) => x.round === round && x.regionId === regionId);
	}

	getRegionByName(name: string): IRegion | undefined {
		return this.regions.find((x) => x.name === name);
	}

	removeSuperBowl(rounds: string[]): string[] {
		return rounds.filter((x) => x !== "superbowl");
	}
}

<div id="league-bracket">
	<h2>Knockout Stage</h2>
	<div class="round">
		<div *ngFor="let tier of tiers" class="matchups">
			<am-bracket-matchup-box
				*ngFor="let matchup of getMatchupsByRound(tier.id)"
				[matchup]="matchup"
				[isReverse]="false"
			></am-bracket-matchup-box>
		</div>
	</div>
</div>

import { Component, inject, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable, combineLatest, filter, map, takeUntil } from "rxjs";
import { determineGameValue } from "src/app/models/constants";
import { IEvent, ITier } from "src/app/models/event";
import { IGame, IMatchup, ISeries } from "src/app/models/game";
import { IGroup } from "src/app/models/group";
import { ITeam, ITeamOwner } from "src/app/models/team";
import { IUser } from "src/app/models/user";
import { BaseComponent } from "src/app/shared/components/base.component";
import { isMobile } from "src/app/state/app/app.selectors";
import { currentEvent } from "src/app/state/event/event.selectors";
import { currentGroup, currentGroupStandings } from "src/app/state/group/group.selectors";
import { getUsersData } from "src/app/state/user/user.actions";
import { users } from "src/app/state/user/user.selectors";
import { EventBracketComponent } from "../event-bracket/event-bracket.component";
import { ScheduleMatchupBoxComponent } from "../../shared/components/schedule-matchup-box/schedule-matchup-box.component";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatIcon } from "@angular/material/icon";
import { CdkOverlayOrigin, CdkConnectedOverlay } from "@angular/cdk/overlay";
import { MatIconButton } from "@angular/material/button";
import { ICalendarRound, ScheduleCalendarComponent } from "../../shared/components/schedule-calendar/schedule-calendar.component";
import { EventNavigationComponent } from "../../shared/components/event-navigation/event-navigation.component";
import { CommonModule } from "@angular/common";
import { ILeague } from "src/app/models/league";
import { LeagueStore } from "src/app/state/league.store";
import { toObservable } from "@angular/core/rxjs-interop";
import { isSameDay } from "date-fns";

@Component({
	selector: "am-event-schedule",
	templateUrl: "./event-schedule.component.html",
	styleUrls: ["./event-schedule.component.scss"],
	standalone: true,
	imports: [
		CommonModule,
		EventNavigationComponent,
		ScheduleCalendarComponent,
		MatIconButton,
		CdkOverlayOrigin,
		MatIcon,
		CdkConnectedOverlay,
		MatCheckbox,
		ScheduleMatchupBoxComponent,
		EventBracketComponent
	]
})
export class EventScheduleComponent extends BaseComponent implements OnInit {
	readonly leagueStore = inject(LeagueStore);

	league$: Observable<ILeague | undefined>;
	event$: Observable<IEvent | undefined>;
	group$: Observable<IGroup | undefined>;
	selectedGames$: Observable<IGame[]>;
	selectedSeries$: Observable<ISeries[]>;
	selectedMatchups$: Observable<IMatchup[]>;
	isMobile$: Observable<boolean>;
	users$: Observable<IUser[]>;

	selectedRound$ = new BehaviorSubject<ICalendarRound | undefined>(undefined);
	managerFilter$ = new BehaviorSubject<Map<string, boolean>>(new Map());
	isSeriesBased$ = new BehaviorSubject<boolean>(false);

	isFilterOpen = false;
	league = this.leagueStore.currentLeague;
	teams = this.leagueStore.teams;
	rounds = this.leagueStore.rounds;

	constructor(
		private store: Store,
		private sanitizer: DomSanitizer
	) {
		super();

		this.league$ = toObservable(this.leagueStore.currentLeague);
	}

	ngOnInit(): void {
		this.event$ = this.store.select(currentEvent).pipe(takeUntil(this.destroyed$));
		this.group$ = this.store.select(currentGroup).pipe(takeUntil(this.destroyed$));
		this.isMobile$ = this.store.select(isMobile).pipe(takeUntil(this.destroyed$));
		this.users$ = this.store.select(users).pipe(takeUntil(this.destroyed$));

		this.event$.pipe(takeUntil(this.destroyed$)).subscribe((event) => this.leagueStore.setCurrentLeague(event?.leagueId ?? null));

		this.selectedGames$ = combineLatest([this.selectedRound$, this.league$]).pipe(
			filter(([selectedRound, league]) => !!selectedRound && !!league),
			takeUntil(this.destroyed$),
			map(([selectedRound, league]) => {
				this.isSeriesBased$.next(selectedRound!!.isSeriesBased);

				if (selectedRound?.isDate) {
					// Rule only can selected 1 date
					const date = new Date(selectedRound.rounds[0]);

					return league!!.games?.filter((x) => x.startTime && isSameDay(x.startTime, date)) ?? [];
				} else {
					return league!!.games?.filter((x) => selectedRound?.rounds.includes(x.round)) ?? [];
				}
			})
		);

		this.selectedSeries$ = combineLatest([this.selectedRound$, this.league$]).pipe(
			filter(([selectedRounds, league]) => !!selectedRounds && !!league),
			takeUntil(this.destroyed$),
			// Cannot have daily series
			map(([selectedRounds, league]) => league!!.series?.filter((x) => selectedRounds?.rounds?.includes(x.round)) ?? [])
		);

		this.group$
			.pipe(
				takeUntil(this.destroyed$),
				filter((group) => !!group)
			)
			.subscribe((group) => {
				this.store.dispatch(getUsersData({ userIds: group!!.managerIds }));

				const mangaerFilterInit = new Map<string, boolean>();

				group?.managerIds.forEach((managerId) => {
					mangaerFilterInit.set(managerId, true);
				});

				this.managerFilter$.next(mangaerFilterInit);
			});

		this.getMatchups();
	}

	getUserById(uid: string, users: IUser[]): string {
		return users.find((x) => x.uid === uid)?.displayName ?? "Unknown Manager";
	}

	getTiers(event: IEvent, tierIds: string[]): ITier[] {
		return event.tiers.filter((x) => tierIds.includes(x.id)).sort((a, b) => a.order - b.order);
	}

	updateManagerFilter(managerId: string, show: boolean): void {
		const managerFilterMap = this.managerFilter$.value;

		managerFilterMap.set(managerId, show);

		this.managerFilter$.next(managerFilterMap);
	}

	getMatchups(): void {
		this.selectedMatchups$ = combineLatest([
			this.users$,
			this.selectedGames$,
			this.group$,
			this.store.select(currentGroupStandings),
			this.managerFilter$,
			this.event$,
			this.selectedSeries$,
			this.league$,
			this.isSeriesBased$
		]).pipe(
			takeUntil(this.destroyed$),
			filter(
				([, selectedGames, group, , , event, selectedSeries, league]) =>
					(selectedGames.length > 0 || selectedSeries.length > 0) && !!event && !!league && !!this.teams() && !!group
			),
			map(([users, selectedGames, group, standings, managerFilter, event, selectedSeries, league, isSeriesBased]) => {
				const managersToShow: string[] = [];

				managerFilter.forEach((value, key) => {
					if (value) {
						managersToShow.push(key);
					}
				});

				let games: any[] = [];

				// There is a group and the group has auctions
				if (group && group.status === "In Progress" && managersToShow.length !== group.numOfManagers) {
					const teamsToShow: string[] = [];

					group.rosters.forEach((value, key) => {
						if (managersToShow.includes(key)) {
							teamsToShow.push(...value);
						}
					});

					if (isSeriesBased) {
						games = selectedSeries.filter(
							(x) => (x.team1Id && teamsToShow.includes(x.team1Id)) || (x.team2Id && teamsToShow.includes(x.team2Id))
						);
					} else {
						games = selectedGames.filter(
							(x) => (x.team1Id && teamsToShow.includes(x.team1Id)) || (x.team2Id && teamsToShow.includes(x.team2Id))
						);
					}
				} else {
					if (isSeriesBased) {
						games = selectedSeries;
					} else {
						games = selectedGames;
					}
				}

				return games
					.map((game: any) =>
						this.createMatchup(game, group!!, standings, this.teams()!!, users, event!!, league!!, isSeriesBased)
					)
					.sort((a, b) => {
						if (a.startTime === b.startTime) {
							return 0;
						}

						if (a.startTime == null) {
							return 1;
						}

						if (b.startTime == null) {
							return -1;
						}

						return a.startTime.getTime() - b.startTime.getTime();
					});
			})
		);
	}

	private createMatchup(
		game: any,
		group: IGroup,
		standings: Map<string, number>,
		teams: ITeam[],
		users: IUser[],
		event: IEvent,
		league: ILeague,
		isSeries: boolean,
		numberOfSeriesGames?: number
	): IMatchup {
		const team1 = teams.find((x) => x.id === game.team1Id);
		const team2 = teams.find((x) => x.id === game.team2Id);

		let team1Owner: ITeamOwner | undefined = undefined;
		let team2Owner: ITeamOwner | undefined = undefined;
		let gameValue = -1;

		// We have a group and the Auction is complete
		if (group && (group.status === "In Progress" || group.status === "Complete")) {
			let owner1 = "";
			let owner1Count = 0;
			let owner2 = "";
			let owner2Count = 0;

			group.rosters.forEach((roster, owner) => {
				if (game.team1Id && roster.includes(game.team1Id!!)) {
					owner1Count++;
					owner1 = group.type === "auction" ? owner : String(owner1Count);
				}
				if (game.team2Id && roster.includes(game.team2Id!!)) {
					owner2Count++;
					owner2 = group.type === "auction" ? owner : String(owner2Count);
				}
			});

			team1Owner =
				owner1 !== ""
					? {
							color: group.managerColors.get(owner1) || "",
							price: group?.prices.get(game.team1Id || "") || 0,
							groupScore: group?.scores.get(game.team1Id || ""),
							owner: users.find((u) => u.uid === owner1)?.displayName || "",
							groupRank: standings.get(owner1) ?? 0,
							ownerPercentage: group.type === "picks" ? owner1Count / group.numOfManagers : undefined,
							uid: owner1
						}
					: undefined;

			team2Owner =
				owner2 !== ""
					? {
							color: group.managerColors.get(owner2) || "",
							price: group?.prices.get(game.team2Id || "") || 0,
							groupScore: group?.scores.get(game.team2Id || ""),
							owner: users.find((u) => u.uid === owner2)?.displayName || "",
							groupRank: standings.get(owner2) ?? 0,
							ownerPercentage: group.type === "picks" ? owner2Count / group.numOfManagers : undefined,
							uid: owner2
						}
					: undefined;
		}

		if (group) {
			const totalPot = group.numOfManagers * group.scoring.buyin;
			const tier = event?.tiers.find((x) => x.rounds.includes(game.round));

			if (tier) {
				gameValue = determineGameValue(group, totalPot, tier.id);
			}
		}
		return {
			id: game.id,
			gameStatus: game.status,
			winnerId: game.winnerId,
			startTime: game.startTime,
			tvProvider: game.tvProvider,
			round: game.round,
			groupType: group?.type,
			scoreType: group?.scoreType,
			team1: team1
				? {
						...team1,
						score: game.team1Score
					}
				: null,
			team2: team2
				? {
						...team2,
						score: game.team2Score
					}
				: null,
			team1Owner: team1Owner,
			team2Owner: team2Owner,
			tierId: game.tierId,
			regionId: game.regionId,
			value: numberOfSeriesGames ? gameValue / numberOfSeriesGames : gameValue,
			groupStatus: group?.status,
			eventStatus: event!!.status,
			displayOrder: game.displayOrder,
			clinchingAmount: game.clinchingAmount,
			highlightLink: game.highlightLink ? this.sanitizer.bypassSecurityTrustResourceUrl(game.highlightLink) : undefined,
			subMatchups: isSeries
				? league.games
						.filter((x) => x.seriesId === game.id)
						.map((g) => this.createMatchup(g, group, standings, teams, users, event, league, false, game.totalNumberOfGames))
				: []
		} as IMatchup;
	}
}

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "scoreboardPlace"
})
export class ScoreboardPlacePipe implements PipeTransform {
	transform(place: number): string {
		switch (place) {
			case 1:
				return "1st";
			case 2:
				return "2nd";
			case 3:
				return "3rd";
			default:
				return `${place}th`;
		}
	}
}

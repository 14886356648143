import { Component, Input } from "@angular/core";
import { IMatchup } from "src/app/models/game";
import { ScheduleMatchupBoxComponent } from "../schedule-matchup-box/schedule-matchup-box.component";
import { NgClass, NgIf, NgTemplateOutlet } from "@angular/common";
import { MatCard } from "@angular/material/card";
import { TooltipComponent } from "../tooltip/tooltip.component";

@Component({
	selector: "am-bracket-matchup-box",
	templateUrl: "./bracket-matchup-box.component.html",
	styleUrls: ["./bracket-matchup-box.component.scss"],
	standalone: true,
	imports: [TooltipComponent, MatCard, NgClass, NgIf, NgTemplateOutlet, ScheduleMatchupBoxComponent]
})
export class BracketMatchupBoxComponent {
	@Input() matchup: IMatchup;
	@Input() isReverse: boolean;

	hasOwners: boolean;
	detailsOpen: boolean = false;

	ngOnChanges(): void {
		this.hasOwners = this.matchup.team1Owner != null || this.matchup.team2Owner != null;
	}
}

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { filter, switchMap, take } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { NgIf } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { MatButton } from "@angular/material/button";

@Component({
    selector: "am-confirm-email",
    templateUrl: "./confirm-email.component.html",
    styleUrls: ["./confirm-email.component.scss"],
    standalone: true,
    imports: [MatButton, MatIcon, NgIf]
})
export class ConfirmEmailComponent implements OnInit {
	updating: boolean = false;
	status: "Checking" | "Success" | "Error" = "Checking";
	errorCode: string;

	constructor(private store: Store, private activatedRoute: ActivatedRoute, private router: Router, private authenticationSerivce: AuthenticationService) {}

	ngOnInit(): void {
		this.activatedRoute.queryParams.pipe(take(1)).subscribe(async (params) => {
			const verificationCode = params["oobCode"];
			if (verificationCode) {
				try {
					await this.authenticationSerivce.applyActionCode(verificationCode);
					this.status = "Success";
				} catch (error) {
					this.status = "Error";
					this.errorCode = "An error occured";
				}
			} else {
				this.status = "Error";
				this.errorCode = "Invalid Verification Code";
			}
		});
	}

	goToLogin() {
		this.router.navigate(["login"]);
	}
}

import { Pipe, PipeTransform } from "@angular/core";
import { map, Observable, timer } from "rxjs";

@Pipe({
    name: "countdown",
    standalone: true
})
export class CountdownPipe implements PipeTransform {
	transform(futureDate: Date): Observable<string> | undefined {
		if (!futureDate || this.getMsDiff(futureDate) < 0) {
			return undefined;
		}

		return timer(0, 1000).pipe(
			map(() => {
				return this.msToTime(this.getMsDiff(futureDate));
			})
		);
	}

	private getMsDiff = (futureDate: Date): number => new Date(futureDate).getTime() - new Date().getTime();

	private msToTime(msRemaining: number): string {
		if (msRemaining < 0) {
			return "";
		}

		var days = Math.floor(msRemaining / (1000 * 3600 * 24));
		let seconds = Math.floor((msRemaining / 1000) % 60);
		let minutes = Math.floor((msRemaining / (1000 * 60)) % 60);
		let hours = Math.floor((msRemaining / (1000 * 60 * 60)) % 24);

		let count = 0;
		let retval: string = "";

		if (days > 0) {
			retval += `${days} day${days > 1 ? "s" : ""}`;
			count++;
		}

		if (hours > 0) {
			retval += ` ${hours} hour${hours > 1 ? "s" : ""}`;
			count++;
		}

		if (minutes > 0 && count < 2) {
			retval += ` ${minutes} min${minutes > 1 ? "s" : ""}`;
			count++;
		}

		if (seconds > 0 && count < 2) {
			retval += ` ${seconds} sec${seconds > 1 ? "s" : ""}`;
			count++;
		}

		return retval.trim();
	}
}

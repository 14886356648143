import { formatDate } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BehaviorSubject, map, take, takeUntil } from "rxjs";
import { IGameIntegrationInfo } from "src/app/models/game";
import { ITeamIntegrationInfo } from "src/app/models/team";
import { IntegrationService } from "src/app/services/integration.service";
import { BaseComponent } from "src/app/shared/components/base.component";

@Component({
	selector: "am-integration-phase-wizard-dialog",
	templateUrl: "./integration-phase-wizard-dialog.component.html",
	styleUrls: ["./integration-phase-wizard-dialog.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntegrationPhaseWizardDialogComponent extends BaseComponent implements OnInit {
	phaseForm: FormGroup;
	loadingGames: boolean;

	splitOptions: string[] = ["Day"];

	apiTeams: ITeamIntegrationInfo[] = [];
	apiGames: IGameIntegrationInfo[] = [];

	tiers = new BehaviorSubject<{ displayName: string; games: IGameIntegrationInfo[] }[]>([]);

	constructor(
		private dataService: IntegrationService,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private dialogRef: MatDialogRef<IntegrationPhaseWizardDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private data: { leagueId: string; seasonId: string; type: string; sport: string }
	) {
		super();

		this.phaseForm = this.fb.group({
			startDate: this.fb.control(""),
			endDate: this.fb.control(""),
			splitOn: this.fb.control("", [Validators.required])
		});

		this.phaseForm.valueChanges
			.pipe(
				map((form) => {
					if (form.startDate && form.endDate) {
						let startDate = new Date(form.startDate);
						startDate.setHours(0, 0, 0, 0);
						let endDate = new Date(form.endDate);
						startDate.setHours(24, 0, 0, 0);

						let games = this.apiGames.filter((g) => {
							let gameDate = new Date(g.startTime);
							return startDate < gameDate && gameDate < endDate;
						});

						// if type = Day
						let days: { [key: string]: IGameIntegrationInfo[] } = {};
						for (let game of games) {
							let startDate = formatDate(new Date(game.startTime), "shortDate", "en-US");
							if (days[startDate]) {
								days[startDate].push(game);
							} else {
								days[startDate] = [game];
							}
						}
						return Object.keys(days).map((day) => ({
							displayName: day,
							games: days[day]
						}));
					} else {
						return [];
					}
				})
			)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((tiers) => this.tiers.next(tiers));
	}

	ngOnInit() {
		this.loadingGames = true;
		this.dataService
			.getTeamAndGameData(this.data.leagueId, this.data.seasonId, this.data.type, this.data.sport)
			.pipe(take(1))
			.subscribe((response) => {
				this.apiTeams = response.teamSummaries;
				this.apiGames = response.gameSummaries;

				this.loadingGames = false;
				this.cdr.detectChanges();
			});
	}

	submitPhase() {
		this.dialogRef.close({ tiers: this.tiers.value, teams: this.apiTeams });
	}
}

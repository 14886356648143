<mat-card class="team-card">
	<img
		width="50"
		height="50"
		[matTooltip]="team.isEliminated ? 'Team has been eliminated' : ''"
		[ngClass]="{ eliminated: team.isEliminated }"
		[src]="team.logoUrl"
	/>
	<div class="team-name">
		<span>{{ team.seed ? "#" + team.seed : "" }} {{ team.displayName }}</span>
		<span *ngIf="team.record">({{ team.record }})</span>
		<span matTooltip="Auction price">Auction: {{ team.price ? (team.price | currency : "USD" : "symbol" : "1.0-0") : "$0" }}</span>
	</div>
	<div class="team-score" matTooltip="Amount earned">{{ team.score | currency : "USD" : "symbol" : "1.2-2" }}</div>
</mat-card>

import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { take } from "rxjs";
import { SnackbarService } from "src/app/services/snackbar.service";
import { UserService } from "src/app/services/user.service";
import { logout } from "src/app/state/app/app.actions";
import { MatButton } from "@angular/material/button";
import { RouterLink } from "@angular/router";

@Component({
    selector: "am-legal-dialog",
    templateUrl: "./legal-dialog.component.html",
    styleUrls: ["./legal-dialog.component.scss"],
    standalone: true,
    imports: [MatDialogContent, RouterLink, MatDialogActions, MatButton]
})
export class LegalDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<LegalDialogComponent>,
		private store: Store,
		private userService: UserService,
		private snackbar: SnackbarService,
		@Inject(MAT_DIALOG_DATA) private data: { uid: string; priacyPolicy: number; termsAndConditions: number }
	) {}

	agree(): void {
		this.userService
			.agreeToPolicy(this.data.termsAndConditions, this.data.priacyPolicy, this.data.uid)
			.pipe(take(1))
			.subscribe(() => {
				this.snackbar.success("Successfully agree to terms and conditions / privacy policy");
				this.dialogRef.close();
			});
	}

	cancel(): void {
		this.store.dispatch(logout());
		this.dialogRef.close();
	}
}

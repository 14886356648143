import { Component, Input } from "@angular/core";
import { TeamScore } from "src/app/pages/event-scoreboard/event-scoreboard.component";

@Component({
	selector: "am-team-card",
	templateUrl: "./team-card.component.html",
	styleUrls: ["./team-card.component.scss"]
})
export class TeamCardComponent {
	@Input() team: TeamScore;
}

import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { BaseComponent } from "src/app/shared/components/base.component";
import { isMobile } from "src/app/state/app/app.selectors";
import { MatIcon } from "@angular/material/icon";
import { RouterLink } from "@angular/router";
import { MatAnchor, MatIconButton } from "@angular/material/button";
import { NgIf, AsyncPipe } from "@angular/common";

@Component({
    selector: "am-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
    standalone: true,
    imports: [NgIf, MatAnchor, RouterLink, MatIconButton, MatIcon, AsyncPipe]
})
export class FooterComponent extends BaseComponent implements OnInit {
	isMobile$: Observable<boolean>;
	copyrightYear = new Date().getFullYear();

	constructor(private store: Store) {
		super();
	}

	ngOnInit(): void {
		this.isMobile$ = this.store.select(isMobile);
	}

	openExternal(link: string): void {
		window.open(link, "_blank");
	}
}

import { Component, Input } from "@angular/core";

@Component({
	selector: "am-loader",
	template: `
		<mat-progress-spinner color="primary" *ngIf="loading"></mat-progress-spinner>
		<ng-content *ngIf="!loading"></ng-content>
	`,
	styles: [""]
})
export class LoaderComponent {
	@Input() loading: boolean | null;
}

import { NgModule } from "@angular/core";
import { NFLBracketComponent } from "./nfl/nfl-bracket.component";

import { CommonModule } from "@angular/common";
import { MatIconRegistry } from "@angular/material/icon";
import { AppRoutingModule } from "src/app/app-routing.module";

import { ReactiveFormsModule } from "@angular/forms";
import { LeagueBracketComponent } from "./league/league-bracket.component";
import { NCAAMBracketComponent } from "./ncaam/ncaam-bracket.component";
import { NHLBracketComponent } from "./nhl/nhl-bracket.component";
import { NBABracketComponent } from "./nba/nba-bracket.component";
import { BracketSeriesMatchupBoxComponent } from "../components/bracket-series-matchup-box/bracket-series-matchup-box.component";

const components = [NFLBracketComponent, LeagueBracketComponent, NCAAMBracketComponent, NHLBracketComponent, NBABracketComponent];

@NgModule({
	exports: [...components],
	imports: [CommonModule, AppRoutingModule, ReactiveFormsModule, BracketSeriesMatchupBoxComponent, ...components],
	providers: [MatIconRegistry]
})
export class BracketModule {}

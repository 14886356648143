import { Location, formatDate, CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, Inject, LOCALE_ID, OnInit } from "@angular/core";
import {
	AbstractControl,
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators,
	FormsModule,
	ReactiveFormsModule
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable, combineLatest, filter, map, merge, startWith, take, takeUntil, withLatestFrom } from "rxjs";
import { IEvent, IScoringPreset } from "src/app/models/event";
import { IGroup } from "src/app/models/group";
import { GroupService } from "src/app/services/group.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { BaseComponent } from "src/app/shared/components/base.component";
import { currentBreakPoint, currentUID } from "src/app/state/app/app.selectors";
import { setSelectedEvent } from "src/app/state/event/event.actions";
import { events } from "src/app/state/event/event.selectors";
import { getUsersData } from "src/app/state/user/user.actions";
import { usersMap } from "src/app/state/user/user.selectors";
import { MatDialog } from "@angular/material/dialog";
import { ColorMap } from "src/app/models/constants";
import { GroupInfoDialog } from "src/app/shared/components/group-info-dialog/group-info-dialog.component";
import { IPhaseScoringOverview } from "src/app/shared/components/scoring-overview/scoring-overview.component";
import { ConfirmationDialogComponent } from "src/app/shared/components/confirmation-dialog/confirmation-dialog.component";
import { AnalyticsService } from "src/app/services/analytics.service";
import { LoadingButtonComponent } from "../../shared/components/loading-button/loading-button.component";
import { MatDivider } from "@angular/material/divider";
import { ScoringOverviewComponent } from "../../shared/components/scoring-overview/scoring-overview.component";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatTooltip } from "@angular/material/tooltip";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatIcon } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatNativeDateModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatSuffix, MatPrefix, MatFormFieldModule } from "@angular/material/form-field";
import { LeagueStore } from "src/app/state/league.store";

interface IScoringOption {
	type: "madePhase" | "phase" | "tier";
	id: string;
	name: string;
	hidden: boolean;
	percent: number;
	totalValue: number;
	numberOfGames: number;
	valuePerGame: number;
	dependentOptions?: string[];
	isDynamic: boolean;
}

@Component({
	selector: "am-group-edit",
	templateUrl: "./group-edit.component.html",
	styleUrls: ["./group-edit.component.scss"],
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		MatButtonModule,
		MatIcon,
		MatSuffix,
		MatTooltip,
		MatButtonToggleModule,
		ScoringOverviewComponent,
		MatPrefix,
		MatDivider,
		LoadingButtonComponent,
		MatDatepickerModule,
		MatNativeDateModule
	],
	providers: [MatDatepickerModule],
	changeDetection: ChangeDetectionStrategy.Default
})
export class GroupEditComponent extends BaseComponent implements OnInit {
	readonly leagueStore = inject(LeagueStore);

	groupForm: FormGroup;
	leagueId: string;
	eventId: string;
	groupId: string;
	advancedScoringToggle: boolean = false;
	canGoBack: boolean;
	owner: string | undefined;

	updating: boolean = false;
	deleting: boolean = false;
	currentUID: string;
	event: IEvent;
	isCustomScoring: boolean = false;
	minAuctionDateTime: Date;
	maxAuctionDatetime: Date;
	isOwner: boolean = false;
	canDeleteGroup: boolean = false;

	sharedInviteId$ = new BehaviorSubject<string | undefined>(undefined);
	loading$ = new BehaviorSubject<boolean>(true);
	selectedEvent$: Observable<IEvent | undefined>;
	events$: Observable<IEvent[]>;
	isMobile$: Observable<boolean>;

	scoringPresetControl = new FormControl<string | undefined>(undefined);

	league = this.leagueStore.currentLeague;

	constructor(
		@Inject(LOCALE_ID) private locale: string,
		public store: Store,
		private fb: FormBuilder,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private groupService: GroupService,
		private snackbarService: SnackbarService,
		private dialog: MatDialog,
		public location: Location,
		private analyticService: AnalyticsService
	) {
		super();
		this.leagueId = this.activatedRoute.snapshot.params["leagueId"];
		this.eventId = this.activatedRoute.snapshot.params["eventId"];
		this.groupId = this.activatedRoute.snapshot.params["groupId"];
		this.canGoBack = !!this.router.getCurrentNavigation()?.previousNavigation;
	}

	ngOnInit(): void {
		this.isMobile$ = this.store.select(currentBreakPoint).pipe(map((breakpoint) => breakpoint === "XSmall"));
		this.events$ = this.store.select(events).pipe(map((events) => events.filter((x) => x.leagueId === this.leagueId)));

		if (this.leagueId) {
			this.leagueStore.setCurrentLeague(this.leagueId);
		}

		if (this.eventId) {
			this.store.dispatch(
				setSelectedEvent({
					eventId: this.eventId
				})
			);
		}

		this.selectedEvent$ = this.events$.pipe(
			takeUntil(this.destroyed$),
			map((events) => events.find((x) => x.id === this.eventId))
		);

		combineLatest([this.store.select(currentUID), this.selectedEvent$])
			.pipe(
				filter(([currentUID, event]) => !!currentUID && !!event),
				take(1)
			)
			.subscribe(([currentUID, event]) => {
				this.currentUID = currentUID;
				this.event = event!!;

				if (!this.groupId) {
					this.scoringPresetControl.setValue(event!!.scoringPresets?.find((x) => x.isDefault)?.id ?? "custom");
					this.createFormGroup(event!!, currentUID);
					this.loading$.next(false);
				} else {
					this.groupService
						.getByID(this.eventId, this.groupId)
						.pipe(takeUntil(this.destroyed$))
						.subscribe((group) => {
							this.scoringPresetControl.setValue(
								group?.scoring.scoringPresetId ?? event!!.scoringPresets?.find((x) => x.isDefault)?.id ?? "custom"
							);
							this.owner = group?.owner;
							this.sharedInviteId$.next(group?.sharedInviteId);
							this.createFormGroup(event!!, currentUID, group);
							this.loading$.next(false);
						});
				}
			});

		// Set DisplayNames
		this.loading$
			.pipe(
				takeUntil(this.destroyed$),
				filter((loading) => !loading)
			)
			.subscribe(() => {
				this.store
					.select(usersMap)
					.pipe(takeUntil(this.destroyed$))
					.subscribe((userMap) => {
						if (this.managers.controls) {
							for (let manager of this.managers.controls) {
								let managerInfo = manager.getRawValue();
								let lookup = userMap[managerInfo?.uid];
								if (lookup) {
									manager.setValue({ ...managerInfo, displayName: lookup.displayName });
								}
							}
						}
					});

				combineLatest([
					this.groupForm.controls["numOfManagers"].valueChanges.pipe(startWith(this.groupForm.controls["numOfManagers"].value)),
					this.groupForm.controls["managers"].valueChanges.pipe(startWith(this.groupForm.controls["managers"].value))
				])
					.pipe(
						takeUntil(this.destroyed$),
						filter(([_, __]) => !!this.league())
					)
					.subscribe(([numOfManagers, managers]) => {
						// Check totalNumOfTeams / numofManagers > 4 aka max number of managers for event
						let totalTeams = this.league()?.teams?.length || 0;

						// TODO: Can we remove this
						// if (event?.sport === "Mens College Basketball" || event?.sport === "Womens College Basketball") {
						// 	totalTeams = 64; // Placeholder
						// }

						let errors = this.groupForm.get("numOfManagers")?.errors;
						if (totalTeams / numOfManagers < 2) {
							this.groupForm.get("numOfManagers")?.setErrors({ ...errors, maxManagers: true });
						} else if (!errors?.["required"] && !errors?.["min"] && numOfManagers < managers.length) {
							this.groupForm.get("numOfManagers")?.setErrors({ ...errors, minManagers: true });
						} else {
							this.groupForm.get("numOfManagers")?.setErrors({ ...errors, minManagers: null, maxManagers: null });
							this.groupForm.get("numOfManagers")?.updateValueAndValidity({ emitEvent: false });
						}
					});

				if (this.scoring.enabled) {
					// A round total was updated
					merge(
						...this.scoringOptions.controls.map((control: AbstractControl, index: number) =>
							control.valueChanges.pipe(
								takeUntil(this.destroyed$),
								map((value) => ({ rowIndex: index, value }))
							)
						)
					).subscribe((changes) => {
						// Update Totals and percent
						let totalPot = this.scoring.get("totalPot")?.value;
						let newValuePerGame = changes.value.totalValue / changes.value.numberOfGames;
						let newPercent = newValuePerGame / totalPot;
						this.scoringOptions.at(changes.rowIndex).get("percent")?.setValue(newPercent, { emitEvent: false });
						this.scoringOptions
							.at(changes.rowIndex)
							.get("valuePerGame")
							?.setValue(Number(newValuePerGame.toFixed(2)), { emitEvent: false });

						if (changes.value.type === "phase") {
							this.scoringOptions.getRawValue().forEach((option, index) => {
								if (option["type"] === "tier" && changes.value.dependentOptions.includes(option["id"])) {
									if (changes.value.totalValue) {
										this.scoringOptions.at(index).get("hidden")?.setValue(true, { emitEvent: false });
									} else {
										this.scoringOptions.at(index).get("hidden")?.setValue(false, { emitEvent: false });
									}
								}
							});
						}

						let scoringOptionsTotal = this.scoringOptions
							.getRawValue()
							.filter((o) => !o["hidden"])
							.reduce((prev, s) => prev + s["totalValue"], 0);
						this.scoring.get("scoringOptionsTotal")?.setValue(scoringOptionsTotal, { emitEvent: false });
					});

					// Just recalculate all on a scoring update
					combineLatest([
						this.scoring.controls["buyInAmount"].valueChanges.pipe(startWith(this.scoring.controls["buyInAmount"].value)),
						this.groupForm.controls["numOfManagers"].valueChanges.pipe(
							startWith(this.groupForm.controls["numOfManagers"].value)
						)
					])
						.pipe(takeUntil(this.destroyed$))
						.subscribe(([buyInAmount, numOfManagers]) => {
							let totalPot = buyInAmount * numOfManagers;
							this.scoringOptions.getRawValue().forEach((option, index) => {
								let valuePerGame = option["percent"] * totalPot;
								let newTotalValue = valuePerGame * option["numberOfGames"];
								this.scoringOptions
									.at(index)
									.get("valuePerGame")
									?.setValue(Number(valuePerGame.toFixed(2)), { emitEvent: false });
								this.scoringOptions
									.at(index)
									.get("totalValue")
									?.setValue(Number(newTotalValue.toFixed(2)), { emitEvent: false });
							});
							let scoringOptionsTotal = this.scoringOptions
								.getRawValue()
								.filter((o) => !o["hidden"])
								.reduce((prev, s) => prev + s["totalValue"], 0);
							this.scoring.get("scoringOptionsTotal")?.setValue(scoringOptionsTotal, { emitEvent: false });
							this.scoring.get("totalPot")?.setValue(totalPot, { emitEvent: false });
						});
				}
			});

		this.scoringPresetControl.valueChanges
			.pipe(
				withLatestFrom(this.selectedEvent$),
				filter(([preset, event]) => !!preset && !!event && !!this.groupForm)
			)
			.subscribe(([presetId, event]) => {
				if (presetId === "custom") {
					this.isCustomScoring = true;
					return;
				}

				this.isCustomScoring = false;

				const preset = event?.scoringPresets.find((x) => x.id === presetId);

				if (!preset) {
					throw new Error(`Preset with id: ${presetId} could not be found.`);
				}

				const scoringGroup = this.createScoringGroup(preset, this.groupForm.get("numOfManagers")?.value, event!!, undefined);

				this.scoring.setValue(scoringGroup.value);
			});
	}

	createManagerForm(uid: string, type?: string, status?: string, displayName?: string, color?: string) {
		return this.fb.group({
			uid: this.fb.control(uid),
			displayName: this.fb.control(displayName || ""),
			type: this.fb.control(type || "Member"),
			status: this.fb.control(status || "Active"),
			color: this.fb.control(color)
		});
	}

	get managers() {
		return this.groupForm?.get(["managers"]) as FormArray<FormGroup>;
	}

	get scoring(): FormGroup {
		return this.groupForm?.get(["scoring"]) as FormGroup;
	}

	get scoringOptions(): FormArray<FormGroup> {
		return this.groupForm?.get(["scoring", "scoringOptions"]) as FormArray<FormGroup>;
	}

	toggleManager(managerIndex: number) {
		let manager = this.managers.at(managerIndex).value;
		const statusControl = this.managers.at(managerIndex)?.get(["status"]);

		if (statusControl && manager?.uid) {
			if (statusControl.value === "Inactive") {
				statusControl.setValue("Active");
				statusControl.markAsPristine();
			} else {
				statusControl.setValue("Inactive");
				statusControl.markAsDirty();
			}
		}
	}

	decimalFilter(event: any) {
		const reg = /^-?\d*(\.\d{0,2})?$/;
		let input = event.target.value + String.fromCharCode(event.charCode);

		if (!reg.test(input)) {
			event.preventDefault();
		}
	}

	createScoringOption(scoringOption: {
		type: string;
		id: string;
		name: string;
		hidden: boolean;
		percent: number;
		totalValue: number;
		numberOfGames: number;
		valuePerGame: number;
		dependentOptions?: string[];
		isDynamic?: boolean;
	}) {
		return this.fb.group({
			type: new FormControl(scoringOption.type),
			id: new FormControl(scoringOption.id),
			name: new FormControl(scoringOption.name),
			hidden: new FormControl(scoringOption.hidden || false),
			percent: new FormControl(scoringOption.percent),
			totalValue: new FormControl(scoringOption.totalValue),
			numberOfGames: new FormControl(scoringOption.numberOfGames),
			valuePerGame: new FormControl(scoringOption.valuePerGame),
			dependentOptions: new FormControl(scoringOption.dependentOptions),
			isDynamic: new FormControl(scoringOption.isDynamic || false)
		});
	}

	createFormGroup(event: IEvent, currentUID: string, group?: IGroup) {
		let numOfManagers = group?.numOfManagers ? group?.numOfManagers : event.defaultManagers;

		let managerList = this.fb.array<FormGroup>([]);
		if (group?.managerIds && group.managerIds.length > 0) {
			this.store.dispatch(getUsersData({ userIds: group.managerIds }));
			for (let manager of group?.managerIds) {
				managerList.push(
					this.createManagerForm(
						manager,
						group.owner === manager ? "Group Owner" : "Manager",
						"Active",
						undefined,
						group.managerColors.get(manager)
					)
				);
			}
		} else {
			managerList.push(this.createManagerForm(currentUID, "Group Owner", "Active"));
		}

		let auctionDate = new Date();
		this.minAuctionDateTime = event.minAuctionDatetime;
		this.maxAuctionDatetime = event.maxAuctionDatetime;

		if (group?.auctionDate) {
			auctionDate = group.auctionDate;
		} else {
			if (this.minAuctionDateTime > new Date()) {
				auctionDate = this.minAuctionDateTime;
			} else {
				// offset at least a day
				auctionDate.setDate(auctionDate.getDate() + 1);
			}
		}

		const auctionFormGroup = this.fb.group({
			auctionDate: new FormControl(auctionDate, Validators.required),
			auctionTime: new FormControl(formatDate(auctionDate, "HH:mm", this.locale), Validators.required)
		});

		auctionFormGroup.setValidators([
			this.afterMinAuctionDateValidator(this.minAuctionDateTime),
			this.beforeMaxAuctionDateValidator(this.maxAuctionDatetime)
		]);

		let defaultScoringPrest = event.scoringPresets?.find((x) => x.isDefault);

		if (!this.scoringPresetControl.value && event.scoringPresets.length > 0) {
			defaultScoringPrest = event.scoringPresets[0];
		}

		this.groupForm = this.fb.group({
			name: new FormControl(group?.name ? group.name : "", Validators.required),
			event: new FormControl(event.id, Validators.required),
			numOfManagers: new FormControl(numOfManagers, [Validators.min(2), Validators.required]),
			auctionData: auctionFormGroup,
			scoring: this.createScoringGroup(defaultScoringPrest!!, numOfManagers, event, group),
			managers: managerList
		});

		this.groupForm.disable();
		this.scoringPresetControl.disable();

		// Can only delete groups that you own and the auction has not happened yet
		this.isOwner = !!group && group.owner === currentUID;
		this.canDeleteGroup = this.isOwner && (group?.status === "Created" || group?.status === "Ready");

		if (!group || group.owner === currentUID) {
			//Can Always edit
			this.groupForm.get("name")?.enable();
			for (let manager of this.managers.controls) {
				manager.controls["color"].enable({});
			}

			if (event.status === "Open" && (!group || group?.status === "Created" || group?.status === "Ready")) {
				this.groupForm.get("scoring")?.enable();
				this.groupForm.get("numOfManagers")?.enable();
				this.groupForm.get("managers")?.enable();
				this.groupForm.get("auctionData")?.enable();
				this.scoringPresetControl.enable();
			}
		}

		for (let manager of this.managers.controls) {
			if (manager.get(["uid"])?.value === currentUID) {
				manager.controls["color"].enable({});
			}
		}
	}

	private createScoringGroup(
		scoringPreset: IScoringPreset | undefined,
		numOfManagers: number,
		event: IEvent,
		group: IGroup | undefined
	): FormGroup {
		const buyIn = group ? group.scoring.buyin : scoringPreset?.buyIn ?? 0;
		const totalPot = buyIn * numOfManagers;
		const league = this.league();

		if (league == null) {
			throw new Error("Could not find the associated league data");
		}

		const scoringOptions: IScoringOption[] = [];
		let scoringOptionsTotal = 0;

		event.phases.forEach((phase) => {
			let phaseScoring = scoringPreset?.phaseScorings.find((x) => x.phaseId === phase.id);
			let numberOfTeamsInPhase = phase.numberOfTeamsInPhase ?? 1;

			const isDynamic = group?.scoring?.dynamic ? group?.scoring?.dynamic[phase.id] ?? false : false;

			// If has amount to make phase add that in the scoring options
			if (numberOfTeamsInPhase) {
				const defaultScoring: IScoringOption = {
					type: "madePhase",
					id: phase.id,
					hidden: false,
					name: "Make it to: " + phase.name,
					numberOfGames: numberOfTeamsInPhase,
					percent: 0,
					totalValue: 0,
					valuePerGame: 0,
					isDynamic: isDynamic
				};

				if (group?.scoring?.makePhaseValues[phase.id]) {
					scoringOptions.push({
						...defaultScoring,
						...this.calculateValues(totalPot, numberOfTeamsInPhase, group?.scoring?.makePhaseValues[phase.id])
					});
				} else if (phaseScoring && phaseScoring.amountToMakePhase) {
					scoringOptions.push({
						...defaultScoring,
						...this.calculateValues(totalPot, numberOfTeamsInPhase, phaseScoring.amountToMakePhase)
					});
				}
			}

			const relatedTiers = event.tiers.filter((t) => t.phaseId === phase.id);

			// const tierIdsInPhase = relatedTiers.map((t) => t.id);
			const roundsInPhase = relatedTiers.map((t) => t.rounds).flat();
			const gamesInPhase = league.games.filter((x) => roundsInPhase.includes(x.round)).length;

			const defaultScoring: IScoringOption = {
				type: "phase",
				id: phase.id,
				hidden: false,
				name: "All games in: " + phase.name,
				numberOfGames: gamesInPhase,
				dependentOptions: relatedTiers.map((t) => t.id),
				percent: 0,
				totalValue: 0,
				valuePerGame: 0,
				isDynamic: isDynamic
			};

			if (group?.scoring?.phaseValues[phase.id]) {
				scoringOptions.push({
					...defaultScoring,
					...this.calculateValues(totalPot, gamesInPhase, group?.scoring?.phaseValues[phase.id])
				});
			} else if (phaseScoring && phaseScoring.amountForAllTiers) {
				scoringOptions.push({
					...defaultScoring,
					...this.calculateValues(totalPot, gamesInPhase, phaseScoring.amountForAllTiers),
					isDynamic: phaseScoring.dynamic
				});
			}

			relatedTiers.forEach((tier) => {
				const tierScoring = phaseScoring?.tiers.find((x) => x.tierId === tier.id);
				const gamesInTier = phase.isSeriesBased
					? league.series.filter((x) => tier.rounds.includes(x.round)).length
					: league.games.filter((x) => tier.rounds.includes(x.round)).length;

				const defaultScoring: IScoringOption = {
					type: "tier",
					id: tier.id,
					hidden: false,
					name: tier.name,
					numberOfGames: gamesInTier,
					dependentOptions: relatedTiers.map((t) => t.id),
					percent: 0,
					totalValue: 0,
					valuePerGame: 0,
					isDynamic: false
				};

				if (group?.scoring?.tierValues[tier.id]) {
					scoringOptions.push({
						...defaultScoring,
						...this.calculateValues(totalPot, gamesInTier, group?.scoring?.tierValues[tier.id])
					});
				} else if (tierScoring) {
					scoringOptions.push({
						...defaultScoring,
						...this.calculateValues(totalPot, gamesInTier, tierScoring.defaultValue),
						isDynamic: phaseScoring?.dynamic ?? false
					});
				}
			});
		});

		// Apply rounding and disable/enable?
		let disabledTiers: string[] = [];
		let scoringFormArray = [];

		for (let option of scoringOptions) {
			if (option.type === "phase" && option.totalValue !== 0 && option.dependentOptions && option.dependentOptions.length > 0) {
				disabledTiers.push(...option.dependentOptions);
			}

			if (option.type === "tier" && disabledTiers.includes(option["id"])) {
				let newFormGroup = this.createScoringOption({ ...option, hidden: true });
				scoringFormArray.push(newFormGroup);
			} else {
				scoringFormArray.push(this.createScoringOption(option));
				scoringOptionsTotal += option.totalValue;
			}
		}

		return this.fb.group({
			buyInAmount: new FormControl(buyIn),
			totalPot: new FormControl(totalPot),
			scoringOptionsTotal: scoringOptionsTotal,
			scoringOptions: this.fb.array(scoringFormArray)
		});
	}

	private calculateValues(totalPot: number, numberOfGames: number, percent: number) {
		let valuePerGame = percent * totalPot;
		let totalValue = valuePerGame * numberOfGames;

		let roundedTotalValue = Number(totalValue.toFixed(2));
		let newValuePerGame = roundedTotalValue / numberOfGames;
		let newPercent = newValuePerGame / totalPot;

		return {
			percent: newPercent,
			valuePerGame: Number(newValuePerGame.toFixed(2)),
			totalValue: roundedTotalValue
		};
	}

	private afterMinAuctionDateValidator(minAuctionDatetime: Date): ValidatorFn {
		return (group: AbstractControl): ValidationErrors | null => {
			const auctionDate = group.get("auctionDate");
			const auctionTime = group.get("auctionTime");
			let dateString = formatDate(new Date(auctionDate?.value), "shortDate", this.locale);
			let timeString = auctionTime?.value;
			const date = new Date(dateString + ", " + timeString);

			return date < minAuctionDatetime ? { minAuctionDatetime: true } : null;
		};
	}

	private beforeMaxAuctionDateValidator(maxAuctionDatetime: Date): ValidatorFn {
		return (group: AbstractControl): ValidationErrors | null => {
			const auctionDate = group.get("auctionDate");
			const auctionTime = group.get("auctionTime");
			let dateString = formatDate(new Date(auctionDate?.value), "shortDate", this.locale);
			let timeString = auctionTime?.value;
			const date = new Date(dateString + ", " + timeString);

			return date > maxAuctionDatetime ? { maxAuctionDatetime: true } : null;
		};
	}

	copySharedInviteToClipboard(inviteId: string): void {
		// TODO: Update to be test link as well as PD
		navigator.clipboard.writeText(`https://auctionmadness.com/invite?inviteId=${inviteId}`);
	}

	back() {
		if (this.canGoBack) {
			this.location.back();
		} else if (this.eventId && this.groupId) {
			this.router.navigate(["event", this.eventId, this.groupId]);
		} else {
			this.router.navigate(["home"]);
		}
	}

	saveGroup() {
		this.updating = true;
		let eventId = this.event.id;

		const formData = this.groupForm.getRawValue();

		let group = {
			name: formData.name,
			isPublic: false,
			isBot: false,
			type: "auction",
			scoreType: "money", // Default should have a flag for the future
			eventId: eventId,
			leagueId: this.leagueId
		} as Partial<IGroup>;

		if (formData.numOfManagers) {
			group.numOfManagers = formData.numOfManagers;
		}

		if (formData.managers) {
			let activeManagers = formData.managers.filter((m: { uid: string; status: string }) => m.status === "Active");

			let managerIds = [];
			let managerColors = new Map<string, string>();

			for (let managerInfo of activeManagers) {
				managerIds.push(managerInfo.uid);
				managerColors.set(managerInfo.uid, managerInfo.color);
			}

			group.managerIds = managerIds;
			group.managerColors = managerColors;
		}

		if (formData.auctionData) {
			let dateString = formatDate(new Date(formData.auctionData.auctionDate), "shortDate", this.locale);
			let timeString = formData.auctionData.auctionTime;
			group.auctionDate = new Date(dateString + ", " + timeString);
		}

		if (formData.scoring) {
			let buyin = +formData.scoring.buyInAmount || 20;

			group.scoring = {
				scoringPresetId: this.scoringPresetControl.value ?? "custom",
				buyin: buyin,
				makePhaseValues: {},
				phaseValues: {},
				tierValues: {},
				dynamic: {}
			};

			for (let option of formData.scoring.scoringOptions) {
				if (option.hidden) {
					continue;
				}

				if (option.type === "phase") {
					group.scoring.dynamic[option.id] = option.isDynamic;
					group.scoring.phaseValues[option.id] = option.percent;
				} else if (option.type === "tier") {
					group.scoring.dynamic[option.id] = option.isDynamic;
					group.scoring.tierValues[option.id] = option.percent;
				} else if (option.type === "madePhase") {
					group.scoring.makePhaseValues[option.id] = option.percent;
				}
			}
		}

		if (this.groupId) {
			group.owner = this.owner;
			group.id = this.groupId;
			this.groupService
				.updateGroup(eventId, group)
				.pipe(take(1))
				.subscribe({
					next: async (groupId) => {
						await this.analyticService.logEvent("update_group");
						this.updating = false;
						this.router.navigate([`event/${eventId}/${groupId}`]);
					},
					error: (e) => {
						this.updating = false;
						this.snackbarService.warn(`Failed to update group: ${e.message}`);
					}
				});
		} else {
			this.groupService
				.createGroup(group)
				.pipe(take(1))
				.subscribe({
					next: async (groupId) => {
						await this.analyticService.logEvent("create_group");
						this.updating = false;
						this.router.navigate([`event/${eventId}/${groupId}`]);
						this.dialog.open(GroupInfoDialog, { data: { eventId, groupId, owner: this.currentUID } });
					},
					error: (e) => {
						this.updating = false;
						this.snackbarService.warn(`Failed to create group: ${e.message}`);
					}
				});
		}
	}

	getRandomColor(currentColors: string[]): string {
		let color: string | undefined = undefined;

		while (!color) {
			const randomIndex = this.getRandomInt(0, 16);
			const randomColor = ColorMap.get(randomIndex);

			if (randomColor && !currentColors.includes(randomColor)) {
				color = randomColor;
			}
		}

		return color;
	}

	createScoringOverviewPhase(event: IEvent): IPhaseScoringOverview[] {
		const scoringPhases: IPhaseScoringOverview[] = [];

		event.phases.forEach((phase) => {
			const phaseScoring = this.scoringOptions.controls.filter((x) => x.controls["id"].value === phase.id);
			const tierIds = event.tiers.filter((x) => x.phaseId === phase.id).map((x) => x.id);
			const tierScoring = this.scoringOptions.controls
				.filter((x) => tierIds.includes(x.controls["id"].value))
				.map((x) => {
					return {
						name: x.controls["name"].value,
						amountForTier: x.controls["totalValue"].value / x.controls["numberOfGames"].value
					};
				});

			const amountForAllTiers = phaseScoring.find((x) => x.controls["type"].value === "phase");

			scoringPhases.push({
				name: phase.name,
				amountForAllTiers: amountForAllTiers
					? amountForAllTiers.controls["totalValue"].value / amountForAllTiers.controls["numberOfGames"].value
					: 0,
				amountToMakePhase: phaseScoring.find((x) => x.controls["type"].value === "madePhase")?.controls["valuePerGame"].value ?? 0,
				tiers: tierScoring
			});
		});

		return scoringPhases;
	}

	deleteGroup(): void {
		this.dialog
			.open(ConfirmationDialogComponent, { data: { text: "Are you sure you want to delete this group? This cannot be undone." } })
			.afterClosed()
			.pipe(take(1))
			.subscribe(async (result) => {
				if (result) {
					this.deleting = true;
					this.groupService
						.deleteGroup(this.eventId, this.groupId, this.currentUID)
						.then(async () => {
							this.snackbarService.success("Successfully delete the group.");
							await this.analyticService.logEvent("delete_group");
							this.router.navigate(["home"]);
							this.deleting = false;
						})
						.catch(() => {
							this.snackbarService.error("Something went wrong while deleting the group, please try again.");
							this.deleting = false;
						});
				}
			});
	}

	getRandomInt(min: number, max: number): number {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
	}

	filteredColorMap(originalColor: string, managers: FormGroup[]): Map<number, string> {
		if (this.isOwner) {
			return ColorMap;
		}

		const takenColors = managers.map((x) => x.value.color);
		const filterMap = new Map<number, string>();

		ColorMap.forEach((value, key) => {
			if (value === originalColor || !takenColors.includes(value)) {
				filterMap.set(key, value);
			}
		});

		return filterMap;
	}
}

import { Injectable } from "@angular/core";
import { Firestore } from "@angular/fire/firestore";
import { Functions, httpsCallableData } from "@angular/fire/functions";
import { Observable } from "rxjs";
import { ILeagueIntegrationInfo } from "../models/league";
import { IGameIntegrationInfo } from "../models/game";
import { ITeamIntegrationInfo } from "../models/team";

@Injectable({
	providedIn: "root"
})
export class IntegrationService {
	constructor(private fs: Firestore, private fns: Functions) {}

	// getbyId(leagueId: string): Observable<IAuction> {
	// 	return docData(doc(this.fs, "live-data", leagueId).withConverter(dataConverter));
	// }

	getLeagues() {
		const callable = httpsCallableData(this.fns, "data-getLeagues");
		return callable() as Observable<ILeagueIntegrationInfo[]>;
	}

	getSeasons(leagueId: string): Observable<string[]> {
		const callable = httpsCallableData(this.fns, "data-getSeasons");
		return callable({ leagueId: leagueId }) as Observable<string[]>;
	}

	getTeamAndGameData(
		leagueId: string,
		seasonId: string,
		type: string,
		sport: string
	): Observable<{ teamSummaries: ITeamIntegrationInfo[]; gameSummaries: IGameIntegrationInfo[] }> {
		const callable = httpsCallableData(this.fns, "data-getTeamAndGameData");
		return callable({ leagueId: leagueId, seasonId: seasonId, type: type, sport: sport }) as Observable<{
			teamSummaries: ITeamIntegrationInfo[];
			gameSummaries: IGameIntegrationInfo[];
		}>;
	}

	getGamesByIds(leagueId: string, seasonId: string, gameIds: string[]): Observable<IGameIntegrationInfo[]> {
		const callable = httpsCallableData(this.fns, "data-getGamesByIds");
		return callable({ leagueId: leagueId, seasonId: seasonId, gameIds: gameIds }) as Observable<IGameIntegrationInfo[]>;
	}

	getTeamDetails(sport: string, league: string, team: string) {
		const callable = httpsCallableData(this.fns, "data-getTeamDetail");
		return callable({ sport, league, team }) as Observable<unknown>;
	}
}

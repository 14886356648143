import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";

@Component({
	selector: "am-value-picks-dialog",
	standalone: true,
	imports: [CommonModule, MatDialogModule, MatButtonModule, MatDividerModule],
	templateUrl: "./value-picks-dialog.component.html",
	styleUrl: "./value-picks-dialog.component.scss"
})
export class ValuePicksDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<ValuePicksDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { teams: { logo: string; displayName: string; value: number }[]; remainingBudget: number }
	) {}

	join(): void {
		this.dialogRef.close(true);
	}
}

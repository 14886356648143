import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { switchMap, map, filter, catchError, withLatestFrom } from "rxjs/operators";
import * as eventActions from "./event.actions";
import { EventService } from "src/app/services/event.service";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { userRoles } from "../app/app.selectors";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class EventEffects {
	constructor(private actions$: Actions, private eventService: EventService, private store: Store, private _snackBar: MatSnackBar) {}

	getEvents$ = createEffect(() =>
		this.actions$.pipe(
			ofType(eventActions.loadEvents),
			withLatestFrom(this.store.select(userRoles)),
			switchMap(([action, roles]) => {
				return this.eventService.getRecent(roles?.includes("admin")).pipe(
					map((data) => eventActions.loadEventsSuccess({ events: data })),
					catchError(async (e) => eventActions.loadEventsError({ errorMessage: e }))
				);
			})
		)
	);

	getEvent$ = createEffect(() =>
		this.actions$.pipe(
			ofType(eventActions.loadEvent),
			switchMap((action) => {
				return this.eventService.getByID(action.eventId).pipe(
					filter((event) => !!event),
					map((event) => eventActions.loadEventsSuccess({ events: [event!!] })),
					catchError(async (e) => eventActions.loadEventsError({ errorMessage: e }))
				);
			})
		)
	);

	// On logout clear User, Groups, and UserEventGroup

	updateSelectedEvent$ = createEffect(() =>
		this.actions$.pipe(
			ofType(eventActions.setSelectedEvent),
			switchMap((action) => {
				return this.eventService.getLiveUpdatesByID(action.eventId);
			}),
			filter((event) => !!event),
			switchMap((event) => [
				eventActions.setSelectedEventSuccess({ eventId: event!!.id }),
				eventActions.loadEventSuccess({ event: event!! }),
				eventActions.setSelectedPhase({ phaseId: event?.tiers.find((x) => x.isCurrent)?.phaseId ?? "" })
			]),
			catchError(() => {
				this._snackBar.open("Failed to set the current event, please refresh.");
				return of(eventActions.setSelectedEventFailed());
			})
		)
	);
}

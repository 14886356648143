<div class="header" [ngClass]="{ auction: isAuction }">
	<mat-button-toggle-group [formControl]="viewControl">
		<mat-button-toggle value="normal">
			<mat-icon class="material-icons-outlined">view_stream</mat-icon>
		</mat-button-toggle>
		<mat-button-toggle value="simple">
			<mat-icon
				*ngIf="!(isBracketMobile$() | async); else mobileIcon"
				class="material-icons-outlined"
				svgIcon="table_rows_narrow"
			></mat-icon>
			<ng-template #mobileIcon><mat-icon class="material-icons-outlined" svgIcon="bracket"></mat-icon></ng-template>
		</mat-button-toggle>
	</mat-button-toggle-group>

	<ng-container *ngIf="!isAuction">
		<ng-container *ngIf="ownerInfo$ | async as ownerInfo">
			<div class="owner-pill" *ngFor="let owner of ownerInfo">
				<div class="color" [style.background-color]="owner.color"></div>
				<div class="content">
					<div class="name">
						<div class="display">{{ owner.displayName }}</div>
						<div>{{ owner.score | currency: "USD" : "symbol" : "1.2-2" }}</div>
					</div>
					<mat-divider></mat-divider>
					<div *ngIf="owner.status !== 'Complete'">{{ owner.alive }} / {{ owner.totalTeams }} Remaining</div>
					<div *ngIf="owner.status === 'Complete'">{{ owner.standing | scoreboardPlace }} Place</div>
				</div>
			</div>
		</ng-container>
	</ng-container>
</div>
<div
	id="ncaam-bracket"
	*ngIf="!(isBracketMobile$() | async) || view === 'simple'; else mobileBracket"
	[ngClass]="{ 'simple-mobile': (isBracketMobile$() | async) && view === 'simple' }"
>
	<div #topSection class="top section">
		<ng-container
			[ngTemplateOutlet]="bracket"
			[ngTemplateOutletContext]="{ data: { region: getRegionByLocation('Top Left'), isReverse: false, isBottom: false } }"
		></ng-container>
		<ng-container
			[ngTemplateOutlet]="bracket"
			[ngTemplateOutletContext]="{ data: { region: getRegionByLocation('Top Right'), isReverse: true, isBottom: false } }"
		></ng-container>
	</div>
	<div class="middle">
		<ng-container *ngIf="view === 'normal'">
			<ng-container *ngIf="getFinalFourMatchByRegion('Middle Left') as matchup">
				<am-bracket-matchup-box [matchup]="matchup" [isReverse]="false"></am-bracket-matchup-box>
			</ng-container>
			<ng-container *ngIf="getChampionshipMatchup() as matchup">
				<am-bracket-matchup-box [matchup]="matchup" [isReverse]="false"></am-bracket-matchup-box>
			</ng-container>
			<ng-container *ngIf="getFinalFourMatchByRegion('Middle Right') as matchup">
				<am-bracket-matchup-box [matchup]="matchup" [isReverse]="true"></am-bracket-matchup-box>
			</ng-container>
		</ng-container>
		<ng-container *ngIf="view === 'simple'">
			<ng-container *ngIf="getFinalFourMatchByRegion('Middle Left') as matchup">
				<am-bracket-matchup-box-simple [matchup]="matchup" [isReverse]="false"></am-bracket-matchup-box-simple>
			</ng-container>
			<ng-container *ngIf="getChampionshipMatchup() as matchup">
				<am-bracket-matchup-box-simple [matchup]="matchup" [isReverse]="false"></am-bracket-matchup-box-simple>
			</ng-container>
			<ng-container *ngIf="getFinalFourMatchByRegion('Middle Right') as matchup">
				<am-bracket-matchup-box-simple [matchup]="matchup" [isReverse]="true"></am-bracket-matchup-box-simple>
			</ng-container>
		</ng-container>
	</div>
	<div class="bottom section">
		<ng-container
			[ngTemplateOutlet]="bracket"
			[ngTemplateOutletContext]="{ data: { region: getRegionByLocation('Bottom Left'), isReverse: false, isBottom: true } }"
		></ng-container>
		<ng-container
			[ngTemplateOutlet]="bracket"
			[ngTemplateOutletContext]="{ data: { region: getRegionByLocation('Bottom Right'), isReverse: true, isBottom: true } }"
		></ng-container>
	</div>
</div>

<ng-template #bracket let-data="data">
	<div class="bracket">
		<h2 style="margin: 8px 0" [ngClass]="{ 'right-align': data.isReverse }">{{ data.region.name }}</h2>
		<div class="round" [ngClass]="{ reverse: data.isReverse }">
			<div class="tier" *ngFor="let round of removeFinalFourTiers(); let i = index">
				<h5>{{ !data.isBottom ? round : " " }}</h5>
				<div class="matchups">
					<ng-container *ngIf="view === 'normal'">
						<am-bracket-matchup-box
							*ngFor="let matchup of getMatchupsByRoundAndRegion(round, data.region.id)"
							[matchup]="matchup"
							[isReverse]="data.isReverse"
						></am-bracket-matchup-box>
					</ng-container>
					<ng-container *ngIf="view === 'simple'">
						<am-bracket-matchup-box-simple
							*ngFor="let matchup of getMatchupsByRoundAndRegion(round, data.region.id)"
							[matchup]="matchup"
							[isReverse]="data.isReverse"
						>
						</am-bracket-matchup-box-simple>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #mobileBracket>
	<div id="ncaam-bracket-mobile">
		<mat-tab-group *ngIf="!isAuction; else auctionMobile">
			<mat-tab *ngFor="let round of rounds" [label]="round">
				<div class="round">
					<ng-container>
						<am-bracket-matchup-box
							*ngFor="let matchup of getMatchupsByRound(round)"
							[matchup]="matchup"
						></am-bracket-matchup-box>
					</ng-container>
				</div>
			</mat-tab>
		</mat-tab-group>
	</div>

	<ng-template #auctionMobile>
		<div class="round">
			<am-bracket-matchup-box
				*ngFor="let matchup of getMatchupsByRound(getFirstRoundTier())"
				[matchup]="matchup"
			></am-bracket-matchup-box>
		</div>
	</ng-template>
</ng-template>

import { Component, Input } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { NgFor, NgIf, CurrencyPipe } from "@angular/common";
import { MatDivider } from "@angular/material/divider";

export interface IPhaseScoringOverview {
	name: string;
	amountForAllTiers: number;
	amountToMakePhase: number;
	tiers: { name: string; amountForTier: number }[];
}

@Component({
    selector: "am-scoring-overview",
    templateUrl: "./scoring-overview.component.html",
    styleUrls: ["./scoring-overview.component.scss"],
    standalone: true,
    imports: [MatDivider, NgFor, NgIf, MatIcon, CurrencyPipe]
})
export class ScoringOverviewComponent {
	@Input() phases: IPhaseScoringOverview[];
	@Input() totalPot: number;
}

import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, filter, map, of, take, takeUntil, tap, withLatestFrom } from "rxjs";
import { ICurrentUser, INotificationSettings, IPrivate, IUser } from "src/app/models/user";
import { BaseComponent } from "src/app/shared/components/base.component";
import { EditProfileDialog } from "src/app/shared/components/edit-profile-dialog/edit-profile-dialog.component";
import { isMobile } from "src/app/state/app/app.selectors";
import { currentUserData } from "src/app/state/user/user.selectors";
import { DeleteProfileDialogComponent } from "../../shared/components/delete-profile-dialog/delete-profile-dialog.component";
import { logout } from "src/app/state/app/app.actions";
import { Capacitor } from "@capacitor/core";
import {
	registerPushNotifications,
	removePushNotificationRegistrations,
	updateEmailPreference
} from "src/app/state/profile/profile.actions";
import { privateUserInfo, updating } from "src/app/state/profile/profile.selectors";
import { ConfirmationDialogComponent } from "src/app/shared/components/confirmation-dialog/confirmation-dialog.component";
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions, MatCheckbox } from "@angular/material/checkbox";
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from "@angular/material/expansion";
import { MatIconButton, MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { NgIf, NgFor, AsyncPipe, SlicePipe } from "@angular/common";

@Component({
    selector: "am-account-management-user",
    template: `
		<ng-container *ngIf="currentUser$ | async as user">
			<ng-container *ngIf="isMobile$ | async; else desktop">
				<div class="mobile-header">
					<!-- user Info Section -->
					<mat-icon class="profile-icon" svgIcon="am-profile"></mat-icon>
					<div class="display-name">
						<h2>{{ user.userInfo.displayName }}</h2>
						<button mat-icon-button (click)="openEditProfile()"><mat-icon>edit</mat-icon></button>
					</div>
					<div>{{ user.userInfo.email || user.privateInfo.email }}</div>
				</div>
			</ng-container>
			<ng-template #desktop>
				<div class="header">
					<!-- User Info Section -->
					<mat-icon class="profile-icon" svgIcon="am-profile"></mat-icon>
					<div class="user-info">
						<div class="display-name">
							<h1>{{ user?.userInfo?.displayName }}</h1>
							<button mat-icon-button (click)="openEditProfile()"><mat-icon>edit</mat-icon></button>
						</div>
						<div>{{ user?.userInfo?.email || user?.privateInfo?.email }}</div>
					</div>
				</div>
			</ng-template>
			<div class="content">
				<h2>Change Display Name</h2>
				<div class="subheading">Update the name visible to other members in a group</div>
				<button mat-stroked-button (click)="openEditProfile()">Change Display Name</button>

				<ng-container *ngIf="notificationSettings$ | async as notificationSettings">
					<h2>Notification Settings</h2>
					<div class="notification-list">
						<mat-checkbox
							[checked]="notificationSettings.emailNotifications"
							[disabled]="updatingProfile$ | async"
							[disableRipple]="true"
							(click)="updateEmailNotificationStatus(!notificationSettings.emailNotifications)"
							>Allow Auction Reminder Email Notifications</mat-checkbox
						>

						<mat-checkbox
							[checked]="notificationSettings.pushNotifications.length > 0"
							[disabled]="updatingProfile$ | async"
							[disableRipple]="true"
							(click)="togglePushNotifications()"
							>Allow Push Notifications</mat-checkbox
						>

						<ng-container *ngIf="notificationSettings.pushNotifications.length > 0">
							<mat-expansion-panel>
								<mat-expansion-panel-header>
									<mat-panel-title> Registered Devices </mat-panel-title>
								</mat-expansion-panel-header>
								<div class="device-list">
									<ul *ngFor="let notification of notificationSettings.pushNotifications">
										<li>
											{{ notification.name | slice : 0 : 10 }}
											<span *ngIf="notification.active">(Current Device)</span>
											<button
												mat-button
												(click)="removeDevice(notification.token)"
												[disabled]="updatingProfile$ | async"
											>
												Remove
											</button>
										</li>
									</ul>
									<button
										mat-button
										*ngIf="!notificationSettings.currentDeviceEnrolledInPushNotifications"
										(click)="registerDevice()"
										color="accent"
									>
										<mat-icon>add</mat-icon>
										Enroll Current Device
									</button>
								</div>
							</mat-expansion-panel>
						</ng-container>
					</div>
				</ng-container>

				<h2>Delete Account</h2>
				<div class="subheading">
					Click to delete your account and remove all personal information including name and email address
				</div>
				<button mat-stroked-button color="warn" (click)="openDeleteProfile()">Delete Account</button>
			</div>
		</ng-container>
	`,
    styles: [
        `
			.content {
				flex: 1;
				margin: 16px 10% 0 10%;
			}

			.subheading {
				margin-bottom: 15px;
			}

			.mobile-header {
				background-image: url("../../../assets/img/landing-background-mobile.png");
				background-repeat: no-repeat;
				color: white;
				width: 100%;
				text-align: center;
				padding: 16px 0;
				display: flex;
				flex-direction: column;
				align-items: center;

				.profile-icon {
					height: 75px;
					width: 75px;
				}
			}

			.header {
				display: flex;
				height: 250px;
				background-image: url("../../../assets/img/landing-background.png");
				background-size: cover;
				background-repeat: no-repeat;
				padding: 0 10%;
				margin-top: -2px;
				margin-left: -3px;

				.profile-icon {
					height: 150px;
					width: 150px;
					margin: auto 16px;
				}

				.user-info {
					text-align: center;
					color: white;
					flex: 1;
					margin: auto;
				}
			}

			.display-name {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.notification-list {
				display: flex;
				flex-direction: column;

				mat-expansion-panel {
					margin: 0px 40px;
					max-width: 450px;
				}

				.device-list {
					display: flex;
					flex-direction: column;
				}
			}
		`
    ],
    providers: [
        {
            provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
            useValue: { clickAction: "noop" } as MatCheckboxDefaultOptions
        }
    ],
    standalone: true,
    imports: [NgIf, MatIcon, MatIconButton, MatButton, MatCheckbox, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, NgFor, AsyncPipe, SlicePipe]
})
export class ProfileManagementComponent extends BaseComponent implements OnInit {
	currentUser$: Observable<ICurrentUser | undefined>;
	notificationSettings$: Observable<{
		emailNotifications: boolean;
		currentDeviceEnrolledInPushNotifications: boolean;
		pushNotifications: { name: string; token: string; date: Date; active: boolean }[];
	}>;
	isMobile$: Observable<boolean>;
	id: string;
	updatingProfile$: Observable<boolean>;

	constructor(private store: Store, private dialog: MatDialog, private router: Router, private cdr: ChangeDetectorRef) {
		super();
	}

	ngOnInit(): void {
		this.id = this.getMachineId();
		this.currentUser$ = this.store.select(currentUserData).pipe(
			takeUntil(this.destroyed$),
			filter((user) => !!user)
			//tap(() => this.isLoading$.next(false))
		);
		this.isMobile$ = this.store.select(isMobile).pipe(takeUntil(this.destroyed$));
		this.notificationSettings$ = this.store.select(privateUserInfo).pipe(
			map((privateInfo) => ({
				emailNotifications:
					privateInfo?.notificationSettings?.emailNotifications !== undefined
						? privateInfo.notificationSettings.emailNotifications
						: true,
				currentDeviceEnrolledInPushNotifications:
					privateInfo?.notificationSettings?.pushNotifications?.findIndex((n) => n.name === this.id) !== -1,
				pushNotifications:
					privateInfo?.notificationSettings?.pushNotifications?.map((n) => ({ ...n, active: n.name === this.id })) || []
			}))
		);

		this.updatingProfile$ = this.store.select(updating);
	}

	openEditProfile(): void {
		this.dialog.open(EditProfileDialog, { width: "400px" });
	}

	openDeleteProfile(): void {
		var ref = this.dialog.open(DeleteProfileDialogComponent);
		ref.afterClosed()
			.pipe(take(1))
			.subscribe((success) => {
				if (success) {
					this.store.dispatch(logout());
				}
			});
	}

	updateEmailNotificationStatus(newValue: boolean) {
		this.store.dispatch(updateEmailPreference({ newValue: newValue }));
	}

	togglePushNotifications() {
		this.notificationSettings$.pipe(take(1)).subscribe((notificationSettings) => {
			if (notificationSettings.pushNotifications?.length === 0) {
				this.registerDevice();
			} else {
				// add confirmationDialog
				this.dialog
					.open(ConfirmationDialogComponent, {
						data: { text: `Are you sure you want to remove all push notification registrations?` }
					})
					.afterClosed()
					.pipe(take(1))
					.subscribe((result) => {
						if (result) {
							this.store.dispatch(
								removePushNotificationRegistrations({ tokens: notificationSettings.pushNotifications.map((n) => n.token) })
							);
						}
					});
			}
		});
	}

	registerDevice(): void {
		this.store.dispatch(registerPushNotifications({ identifier: this.id }));
	}

	removeDevice(name: string) {
		this.store.dispatch(removePushNotificationRegistrations({ tokens: [name] }));
	}

	routeBack(): void {
		this.router.navigate(["home"]);
	}

	private getMachineId() {
		let machineId = localStorage.getItem("MachineId");

		if (!machineId) {
			machineId = Capacitor.getPlatform() + "-" + crypto.randomUUID();
			localStorage.setItem("MachineId", machineId);
		}

		return machineId;
	}
}

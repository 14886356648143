import { Action, createReducer, on } from "@ngrx/store";
import { IEvent } from "src/app/models/event";
import * as eventActions from "./event.actions";
import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";
import { logout } from "../app/app.actions";

export interface EventState extends EntityState<IEvent> {
	selectedEventId: string | null;
	selectedPhaseId: string | null;
	liveData: boolean;
}

function selectEventId(a: IEvent): string {
	return a.id;
}

const adapter: EntityAdapter<IEvent> = createEntityAdapter<IEvent>({
	selectId: selectEventId
});

export const InitialState: EventState = adapter.getInitialState({
	selectedEventId: "",
	selectedPhaseId: "",
	liveData: false
});

const reducer = createReducer(
	InitialState,
	on(eventActions.loadEventsSuccess, (state, { events }) => adapter.upsertMany(events, state)),
	on(eventActions.setSelectedEvent, (state, { eventId }) => ({ ...state, selectedEventId: eventId })),
	on(eventActions.setSelectedEventFailed, (state) => ({ ...state, selectedEventId: "" })),
	on(eventActions.startLiveUpdates, (state) => ({ ...state, liveData: true })),
	on(eventActions.stopLiveUpdates, (state) => ({ ...state, liveData: false })),
	on(eventActions.clearSelectedEvent, (state) => ({ ...state, selectedEventId: null })),
	on(eventActions.loadEventSuccess, (state, { event }) => adapter.upsertOne(event, state)),
	on(eventActions.setSelectedPhase, (state, { phaseId }) => ({ ...state, selectedPhaseId: phaseId }))
);

export function eventReducers(state: EventState | undefined, action: Action) {
	return reducer(state, action);
}

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of user ids
export const selectEventIds = selectIds;

// select the dictionary of user entities
export const selectEventEntities = selectEntities;

// select the array of users
export const selectAllEvents = selectAll;

// select the total user count
export const selectEventTotal = selectTotal;

import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@Component({
	standalone: true,
	imports: [CommonModule, MatButtonModule, MatProgressSpinnerModule],
	selector: "am-loading-button",
	template: `
		<button (click)="clicked.emit($event)" mat-raised-button [color]="color" [disabled]="disabled">
			<span *ngIf="!loading">{{ title }}</span>
			@if (color === "primary") {
				<mat-spinner color="accent" *ngIf="loading" diameter="25"></mat-spinner>
			} @else {
				<mat-spinner class="primary-color" *ngIf="loading" diameter="25"></mat-spinner>
			}
		</button>
	`,
	styles: []
})
export class LoadingButtonComponent {
	@Input() title: string;
	@Input() loading: boolean | null;
	@Input() color: "accent" | "primary" | "warn";
	@Input() type: "submit" | "button" | "reset" = "button";
	@Input() disabled: boolean | null = false;
	@Output() clicked: EventEmitter<any> = new EventEmitter<any>();
}

import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, HostListener, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable, combineLatest, filter, map, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/shared/components/base.component";
import { isMobile } from "src/app/state/app/app.selectors";
import { teams } from "src/app/state/event/event.selectors";
import { currentGroup, currentGroupStandings } from "src/app/state/group/group.selectors";
import { usersMap } from "src/app/state/user/user.selectors";

@Component({
	selector: "am-event-scoreboard",
	templateUrl: "./event-scoreboard.component.html",
	styleUrls: ["./event-scoreboard.component.scss"],
	animations: [
		trigger("detailExpand", [
			state("collapsed", style({ height: "0px", minHeight: "0" })),
			state("expanded", style({ height: "*" })),
			transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
		])
	]
})
export class EventScoreboardComponent extends BaseComponent implements OnInit {
	scores$: Observable<ManagerScore[]>;
	isMobile$: Observable<boolean>;

	columnsToDisplay: string[] = [];
	columnsToDisplayWithExpand: string[] = [];
	selectedManagerId: string | null;
	visibleTeams: number = 4;

	constructor(private store: Store) {
		super();
	}

	ngOnInit() {
		this.isMobile$ = this.store.select(isMobile).pipe(takeUntil(this.destroyed$));

		this.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			if (isMobile) {
				this.columnsToDisplay = ["rank", "displayName", "totalScore"];
			} else {
				this.columnsToDisplay = ["rank", "displayName", "totalScore", "teams"];
			}
			this.columnsToDisplayWithExpand = [...this.columnsToDisplay, "expand"];
		});

		this.scores$ = combineLatest([
			this.store.select(currentGroup),
			this.store.select(usersMap),
			this.store.select(teams),
			this.store.select(currentGroupStandings)
		]).pipe(
			takeUntil(this.destroyed$),
			filter(([currentGroup, userMap, teams, currentGroupStandings]) => {
				// Note userMap.length is >= because we don't clear user data out when switch groups and the number needs to be at least but will probably be more
				return !!teams && !!currentGroup && !!currentGroupStandings && Object.keys(userMap).length >= currentGroup.numOfManagers;
			}),
			map(([currentGroup, userMap, teams, currentGroupStandings]) => {
				let managerScores: ManagerScore[] = [];
				for (let userId of currentGroup?.managerIds || []) {
					let userTotal = 0;
					let teamScores =
						currentGroup?.rosters?.get(userId)?.map((teamId) => {
							let teamScore = currentGroup.scores.get(teamId) || 0;
							let price = currentGroup.prices.get(teamId);
							userTotal += teamScore;

							let teamInfo = teams.find((t) => t.id === teamId);

							return {
								teamId: teamId,
								seed: teamInfo?.seed || undefined,
								displayName: teamInfo?.displayName || "",
								score: teamScore,
								logoUrl: teamInfo?.logo || "",
								record: teamInfo?.record,
								price: price,
								isEliminated: teamInfo?.isEliminated && currentGroup.status !== "Complete"
							} as TeamScore;
						}) || [];

					managerScores.push({
						uid: userId,
						displayName: userMap[userId]?.displayName,
						totalScore: userTotal,
						teams: teamScores.sort((a, b) => b.score - a.score),
						rank: currentGroupStandings.get(userId)
					} as ManagerScore);
				}

				return managerScores.sort((a, b) => a.rank - b.rank);
			})
		);
	}

	setSelected(uid: string) {
		this.selectedManagerId = this.selectedManagerId === uid ? null : uid;
	}
}

interface ManagerScore {
	rank: number;
	uid: string;
	displayName: string;
	totalScore: number;
	teams: TeamScore[];
}

export interface TeamScore {
	teamId: string;
	seed: number | undefined;
	displayName: string;
	logoUrl: string;
	score: number;
	price: number;
	record: string | undefined;
	isEliminated: boolean;
}
